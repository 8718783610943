import { USER_LOGGED_IN, USER_LOGGED_OUT, REMEDIATION_DATA_GET, REMEDIATION_DATA_TOTAL, DASHBOARD_FETCHED, PASSWORD_RESET_REQUESTED, PASSWORD_RESET_DONE, TWO_FACTOR_ENABLED, SIDE_BAR_DATA_FETCHED, REMEDIATION_DATA_ADDED, REMEDIATION_DATA_REMOVED } from "../actions/";

export default function (state = { loading: true, response: null, remediationTotal: [], sideBarData: { remediationList: [] }, remediationListAll: [] }, action) {
  // console.log(action,'type')
  switch (action.type) {
    case USER_LOGGED_IN:
      return { state, user: action.payload, sideBarData: { remediationList: [] } };
    case TWO_FACTOR_ENABLED:
      return { twoFactor: action.payload };
    case USER_LOGGED_OUT:
      return { user: null, loading: false };
    case PASSWORD_RESET_REQUESTED:
      return { response: action.payload, loading: false };
    case DASHBOARD_FETCHED:
      return { ...state, response: action.payload, loading: false };
    case SIDE_BAR_DATA_FETCHED:
      return { ...state, sideBarData: action.payload, loading: false };
    case REMEDIATION_DATA_REMOVED:
      return { ...state, sideBarData: { ...state.sideBarData, remediationList: state.sideBarData.remediationList.filter(c => c._id != action.payload._id) } };
    case REMEDIATION_DATA_ADDED:
      return { ...state, sideBarData: { ...state.sideBarData, remediationList: [...state.sideBarData.remediationList, action.payload] } };
    case REMEDIATION_DATA_GET:
      return {
        ...state,
        remediationListAll: action.payload,
      };
    case REMEDIATION_DATA_TOTAL:
      return {
        ...state,
        remediationTotal: action.payload,
      };
    case PASSWORD_RESET_DONE:
      return { user: action.payload, loading: false };
    default:
      return state;
  }
}
