import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
    fetchUsers,
    updateUserById,
    createUser,
    deleteUser,
    sendUserInvite
} from "../../actions/customer";
import { IMAGE_URL } from "../../utils/constant";
import $ from "jquery";
import { toastr } from "react-redux-toastr";
import countryCodes from "../../utils/countryCode";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';


var Model = require("../information/model");

const User = () => {
    const dispatch = useDispatch();
    const id = useSelector(
        (state) =>
            state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
    );
    let users = useSelector((state) => state?.customer?.userList?.data);
    const [search, setSearch] = useState("");
    const [userId, setUserId] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [countryCode, setCountryCode] = useState("");
    const [accessData, setAccessData] = useState([
        { id: 1, val: "Admin", text: "Admin" },
        { id: 2, val: "Threat Analyst", text: "Threat Analyst" },
    ]);
    const [isModal, setModal] = useState("");
    const [reminderModal, setReminderModal] = useState(false);
    const [emailId, setEmailId] = useState("");

    const [primaryContactName, setContactName] = useState("");
    const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
    const [orgAdmin, setOrgAdmin] = useState(false);
    const [services, setServices] = useState([]);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [accessValue, setAccessLevel] = useState(accessData[0].val)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (id) {
            fetchUsers(id, dispatch);
        }
    }, [id]);

    const downloadCsvData = users?.map((res) => ({
        fullName: res?.fullName,
        EmailAddress: res?.email,
        ContactNumber: res?.contact || "NA",
        Role: res?.accessLevel,
        PasswordReset: res?.passwordReset ? "Yes" : "No",
        RegistrartionDate: moment(res?.website).format(
            "DD/MM/YYYY hh:mm a"
        )
    }));

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        FileSaver.saveAs(data, fileName + ".xlsx");
    };

    let mapData = search == ""
        ? users
        : users?.filter((row) => ((row?.fullName?.toLowerCase()?.indexOf(search?.toLowerCase()) != -1) || row?.email?.toLowerCase()?.indexOf(search?.toLowerCase()) != -1))

    const submitForm = () => {
        if (primaryContactName == "") {
            toastr.error("Error", "Please enter name.");
            return false;
        }
        if (email == "") {
            toastr.error("Error", "Please enter email.");
            return false;
        }
        var EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EmailRegex.test(email)) {
            toastr.error("Error", "Please enter valid email id.");
            return false;
        }

        if (isEdit) {
            updateUserById(
                {
                    id: userId,
                    fullName: primaryContactName,
                    email: email,
                    contact: phone,
                    twoFactorEnabled: twoFactorEnabled,
                    orgAdmin: accessValue == "Client" ? true : false,
                    // services: accessValue == "Client" ? _services : services,
                    accessLevel: accessValue,
                    countryCode: countryCode,
                    orgId: id
                },
                dispatch
            );
        } else {
            createUser(
                {
                    fullName: primaryContactName,
                    contact: phone,
                    accessLevel: accessValue,
                    twoFactorEnabled: twoFactorEnabled,
                    orgAdmin: accessValue == "Client" ? true : false,
                    // services: accessValue == "Client" ? _services : services,
                    email: email,
                    countryCode: countryCode,
                    organisationId: id || ""
                },
                dispatch,
            );
        }

        setModal("")
    }

    const addModal = () => {
        setIsEdit(false);
        setContactName("")
        setEmail("")
        setPhone("")
        setCountryCode("")
        setAccessLevel(accessData[0].val)
        setModal("Form Wizard modal")
    }

    const editModal = (row) => {
        setIsEdit(true);
        setContactName(row?.fullName)
        setEmail(row?.email)
        setPhone(row?.contact)
        setCountryCode(row?.countryCode);
        setAccessLevel(row?.accessLevel)
        setTwoFactorEnabled(row?.twoFactorEnabled);
        setServices(row?.services);
        setUserId(row?._id)
        setModal("Form Wizard modal")
    }

    const deleteUserById = (id) => {
        // const self = this;
        $.confirm({
            title: "Are you sure?",
            content: "Do you really want to delete this customer?",
            type: "red",
            buttons: {
                ok: {
                    text: "Delete",
                    btnClass: "btn-danger",
                    keys: ["enter"],
                    action: function () {
                        deleteUser(id, dispatch);
                        // self.setState({
                        //   deleting: true,
                        // });
                    },
                },
                cancel: function () {
                    //console.log("the user clicked cancel");
                },
            },
        });
    }

    const sendReminder = () => {
        setReminderModal(false)
        sendUserInvite({
            email: emailId
        })
    }

    return (
        <>
            <div className="row clearfix">
                <div className='col-sm-2'></div>

                <div className='col-sm-9' style={{ padding: '0' }}>
                    <div className="row clearfix">
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name, Email, Address"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12">
                            <button
                                type="button"
                                className="btn btn-sm btn-success"
                                onClick={() => addModal()}
                            >
                                Add User
                            </button>
                        </div>
                        <div className="col-md-6">
                            {users?.length === 0 ? null : (
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        exportToCSV(downloadCsvData, "User List");
                                    }}
                                    style={{ float: "right" }}
                                    href="/add-report"
                                    className="btn btn-primary mr-1"
                                >
                                    <i className="fa fa-file-excel-o" style={{ color: "black", fontWeight: "bold" }}></i>
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="row_boxes row_boxes_table">
                        <div className="table_box mt-4">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Full Name</th>
                                        <th>Email Address</th>
                                        <th>Contact Number</th>
                                        <th>Role</th>
                                        <th>Password Reset Status</th>
                                        <th>Registeration Date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mapData?.map((item) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <img
                                                        src={
                                                            item.avatar
                                                                ? `${IMAGE_URL}${item.avatar}`
                                                                : "../assets/images/xs/profile.png"
                                                        }
                                                        alt="Avatar"
                                                        className="w30 rounded-circle mr-2"
                                                    />{" "}
                                                    <span>{item.fullName}</span>
                                                </td>
                                                <td>
                                                    {item.email}
                                                </td>
                                                <td>
                                                    {item.contact}
                                                </td>
                                                <td>
                                                    {item.accessLevel}
                                                </td>
                                                <td>
                                                    <span className="badge badge-warning">{item.passwordReset ? "Yes" : "No"}</span>
                                                </td>
                                                <td>
                                                    {moment(item.createdAt).format(
                                                        "DD/MM/YYYY hh:mm a"
                                                    )}
                                                </td>
                                                <td>
                                                    <button className="btn btn-primary btn-sm mr-1" onClick={() => editModal(item)}
                                                        style={{ color: "black", fontWeight: "bold" }}>
                                                        Edit
                                                    </button>
                                                    <button className="btn btn-danger btn-sm" onClick={() => deleteUserById(item?._id)}>
                                                        Delete
                                                    </button>
                                                    {
                                                        !item.passwordReset && (
                                                            <span
                                                                style={{ marginLeft: "20px", fontSize: '20px', cursor: "pointer" }}
                                                                onClick={() => { setReminderModal(true); setEmailId(item.email) }}
                                                            >
                                                                <FontAwesomeIcon icon={faBell} />
                                                            </span>
                                                        )
                                                    }


                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
                    }`}
            >
                <div className="modal-dialog modal-lg" id="clientModal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Add New User
                            </h5>
                            <button
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                                style={{ backgroundColor: 'transparent' }}
                                onClick={() => setModal("")}
                            >
                                <span aria-hidden="true"
                                    style={{ color: '#fff' }}
                                >×</span>
                            </button>
                        </div>
                        <div className="modal-body pricing_page">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="card">
                                    <div className="row clearfix">
                                        <div className="col-lg-6 col-md-12">
                                            <label className="col-lg-12 col-md-12">Primary Contact Name</label>
                                            <div className="form-group col-lg-12 col-md-12">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Primary Contact Name *"
                                                    value={primaryContactName}
                                                    onChange={(e) => setContactName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <label className="col-lg-6 col-md-12">Email address</label>
                                            <div className="form-group ol-lg-12 col-md-12">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email address *"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <label className="col-lg-6 col-md-12">Phone</label>
                                            <div className="form-group ol-lg-12 col-md-12" style={{ display: "flex" }}>
                                                <select className="form-control" value={countryCode} onChange={(e) => setCountryCode(e.target.value)} style={{ width: '100px' }}>
                                                    {countryCodes?.countryCodes?.map((val) => {
                                                        return <option value={val.dial_code}>{val.name}</option>
                                                    })}
                                                </select>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Phone"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <label className="col-lg-6 col-md-12">Access Level</label>
                                            <div className="form-group ol-lg-12 col-md-12">
                                                <select className="form-control"
                                                    value={accessValue}
                                                    onChange={(e) => setAccessLevel(e.target.value)}
                                                >
                                                    {/* <option value="">-- Email Access * --</option> */}
                                                    {accessData?.map((row) => {
                                                        return (
                                                            <option value={row.val}>{row.text}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12" style={{ marginRight: "28px" }}>
                                            <button
                                                type="button"
                                                style={{ color: "black", fontWeight: "bold", }}
                                                className="btn btn-round btn-primary mr-1 pull-right"
                                                onClick={submitForm}
                                            >
                                                {isEdit ? "Update" : "Create"}
                                            </button>{" "}
                                            &nbsp;&nbsp;
                                            <button type="button" onClick={() => setModal("")} className="btn btn-round btn-default pull-right" style={{ marginRight: '10px' }}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal fade Form-Wizard-modal ${reminderModal ? "d-block show" : ""
                    }`}
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Reminder
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Do you really want to send reminder to this user?
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-round btn-default"
                                data-dismiss="modal"
                                onClick={() => setReminderModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-round btn-primary"
                                style={{ color: "black", fontWeight: "bold" }}
                                onClick={() => {
                                    sendReminder()
                                }}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default User;
