import React, { useDebugValue, useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { setSubmitFailed } from "redux-form";
import { toggleSearchBar } from "../../actions/settingsAction";
import img from "../../assets/images/dot-icon.png"
import trainningCommonImg from "../../assets/images/Training_CommonMenu.svg"
import aboutImg from "../../assets/images/About_CommonMenu.svg"
import companyImg from "../../assets/images/ABout_Comp_CommonMenu.svg"
import contactUs from "../../assets/images/Conatct_Us_CommonMenu.svg"
import legalInfo from "../../assets/images/Legal_CommonMenu.svg"
import privacyImg from "../../assets/images/Privacy_CommonMenu.svg"
import partnerImg from "../../assets/images/Partners_CommonMenu.svg"
import licencesImg from "../../assets/images/Licenses_CommonMenu.svg"
import userSetting from "../../assets/images/user_settings.svg"
import userGuide from "../../assets/images/guidance.svg"
import userGuideReport from "../../assets/images/UserGuide_CommonMenu.svg"





const Header = (
  {
    toggleSearchBar
  }) => {
  const location = useLocation();
  const history = useHistory();
  const [isModal, setModal] = useState(false);
  const orgName = JSON.parse(localStorage.getItem('org'))?.orgName;
  const auth = useSelector((state) => state?.auth?.user);
  const [currentRoute, setCurrentRoute] = useState("dashboard");
  const [mode, setMode] = useState('assessment-mode');
  const params = new URLSearchParams(location?.search);
  const modeValue = params.get('mode');

  const handleModeClick = (v) => {
    if(v=='settings-mode'){
      history.push('/user');
      return; 
    }else{
      history.push('/summary');
    }
    history.push({
      search: `?mode=${v}`
    });
    localStorage.setItem('mode', v);
  };
  
  

  let selectedRoute = "dashboard";

  useEffect(() => {
    const storedMode = localStorage.getItem('mode');
    if (storedMode) {
      setMode(storedMode);
    }
  }, []);

  useEffect(() => {
    if (location.pathname !== '/login') {
      history.push({
        ...location,
        search: `?mode=${mode}`
      });
      localStorage.setItem('mode', mode);
    }
  }, [location.pathname, mode]);
  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/summary") {
      selectedRoute = "dashboard";
    } else if (
      pathname.indexOf("security") !== -1 ||
      pathname === "/domains" ||
      pathname === "/known-vulnerabilities" ||
      pathname === "/ssl-certificates" ||
      pathname === "/web-technology"
    ) {
      selectedRoute = "security";
    } else if (pathname.indexOf("brand") !== -1 || pathname === "/mobile-apps" || pathname === "/look-alike" || pathname === "/social-media") {
      selectedRoute = "brand";
    } else if (
      pathname.indexOf("breach") !== -1 ||
      pathname === "/stolen-credentials" ||
      pathname === "/malware-credentials" ||
      pathname === "/source-code" ||
      pathname === "/leaked-sessions" ||
      pathname === "/cloud-bucket" ||
      pathname === "/ip-blacklist"
    ) {
      selectedRoute = "breach";
    }
    else if (
      pathname == "/assets" || pathname == "/assets/"
    ) {
      selectedRoute = "assets";
    }
    else if (
      pathname == "/risk"
    ) {
      selectedRoute = "risk";
    }
    else if (
      pathname == "/profile"
    ) {
      selectedRoute = "profile";
    }
    setCurrentRoute(selectedRoute);
  }, [location.pathname]);

  // console.log("header ", props, pathname.indexOf("security"), selectedRoute)
  const overlayToggle = {
    true: "sidemenu_item_overlay active",
    false: "sidemenu_item_overlay",
  };

  const activeToggle = (className, toggle) => {
    if (toggle) {
      return `${className} active`;
    }
    return className;
  };

  return (
    <>
      {location.pathname.replace('/', '') !== 'services' ?
        <>
          <header>
            <div class="nav_main">
              <div class="left_box_1">
                <div class="logo_img">
                  <img src="../assets/images/image-gallery/logo.svg" class="img-fluid" />
                </div>
                {modeValue != 'settings-mode' &&
                  <><Link to="/summary">
                    <div class={currentRoute === "dashboard" ? "text_logo_box activeheader" : "text_logo_box"}>
                      <p>
                        Home/
                        <span>Dashboard</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={"../assets/images/image-gallery/dashboard.svg"}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                    <Link to="/assets/#hostsDiscovered">
                      <div class={currentRoute === "assets" ? "text_logo_box activeheader" : "text_logo_box"}>
                        <p>
                          Assets
                          <span>Monitor</span>
                        </p>
                        <div class="circle_button">
                          <div class="inner_box">
                            <img
                              src={"../assets/images/Assets_IconMain_.svg"}
                              class="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="/security">
                      <div class={currentRoute === "security" ? "text_logo_box activeheader" : "text_logo_box"}>
                        <p>
                          Security
                          <span>Healthcheck</span>
                        </p>
                        <div class="circle_button">
                          <div class="inner_box">
                            <img
                              src="../assets/images/Health_IconMain_.svg"
                              class="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </Link><Link to="/brand">
                      <div class={currentRoute === "brand" ? "text_logo_box activeheader" : "text_logo_box"}>
                        <p>
                          Brand
                          <span>Security</span>
                        </p>
                        <div class="circle_button">
                          <div class="inner_box">
                            <img
                              src="../assets/images/Brand_IconMain_.svg"
                              class="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </Link><Link to="/breach">
                      <div class={currentRoute === "breach" ? "text_logo_box activeheader" : "text_logo_box"}>
                        <p>
                          Breach
                          <span>Detection</span>
                        </p>
                        <div class="circle_button">
                          <div class="inner_box">
                            <img
                              src="../assets/images/Breach_IconMain_.svg"
                              class="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link>
                      <div class={currentRoute === "risk" ? "text_logo_box activeheader" : "text_logo_box"}>
                        <p>
                          Risk
                          <span>Managment</span>
                        </p>
                        <div class="circle_button">
                          <div class="inner_box">
                            <img
                              src="../assets/images/Risk_Manegement_Main.svg"
                              class="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                }
              </div>
              <div class="right_box_2">
                <div
                  onClick={() => {
                    setMode('assessment-mode');
                    handleModeClick('assessment-mode');
                  }}
                  class={`go_text ${mode === 'assessment-mode' ? 'active_progressBar' : ''}`}
                  style={{ cursor: 'pointer' }}
                >
                  <div
                    class="progress"
                    role="progressbar"
                    aria-label="Basic example"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      class="progress-bar red_progress_bar"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                  <div class="progress_text">
                    <img
                      src="../assets/images/image-gallery/assesment_mode.svg"
                      class="img-fluid"

                    />
                    <p>Assessment Mode.</p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    setMode('report-mode');
                    handleModeClick('report-mode');
                  }}

                  class={`go_text ${mode === 'report-mode' ? 'active_progressBar' : ''}`}
                  style={{ cursor: 'pointer' }}
                >
                  <div
                    class="progress"
                    role="progressbar"
                    aria-label="Basic example"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      class="progress-bar green_progress_green"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                  <div class="progress_text">
                    <img
                      src="../assets/images/image-gallery/report_mode.svg"
                      class="img-fluid"

                    />
                    <p>Report Mode.</p>
                  </div>
                </div>
                <div
                  onClick={() => {
                    setMode('settings-mode');
                    handleModeClick('settings-mode');
                  }}
                  style={{ cursor: 'pointer' }}
                  class={`go_text ${mode === 'settings-mode' ? 'active_progressBar' : ''}`}
                >
                  <div
                    class="progress"
                    role="progressbar"
                    aria-label="Basic example"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      class="progress-bar green_progress_yellow"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                  <div class="progress_text">
                    <img
                      src="../assets/images/image-gallery/settings_mode.svg"
                      class="img-fluid"

                    />
                    <p>Settings Mode.</p>
                  </div>
                </div>
              </div>
              <div className="d-flex">
              {modeValue != 'settings-mode' &&
              <>
              <div className="left_box_1">
              <Link>
                    <div class='text_logo_box'>
                      <p>
                        Other Services
                      </p>
                    </div>
                  </Link>
                  <Link to="/services#identify">
                  <div class={"text_logo_box"}>
                    <p>
                      Valkyrie
                      <span>Special</span>
                      Services
                    </p>
                    <div class="circle_button">
                      <div class="inner_box">
                        <img
                          src="../assets/images/valkyrie_service.svg"
                          class="img-fluid" />
                      </div>
                    </div>
                  </div>
                </Link>
                <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Learning
                        <span>Training</span>
                        Certification
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={trainningCommonImg}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                  <div className="left_box_1">
              <Link>
                    <div class='text_logo_box'>
                      <p>
                        General Info
                      </p>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        About
                        <span>Info</span>
                        Contact
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={contactUs}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                  <div className="left_box_1">
              <Link>
                    <div class='text_logo_box'>
                      <p>
                        User Services
                      </p>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Userguides
                        <span>Support</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={userGuideReport}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/profile">
                  <div class={currentRoute === "profile" ? "text_logo_box activeheader" : "text_logo_box"}>
                    <p>
                      User
                      <span>Settings</span>
                    </p>
                    <div class="circle_button">
                      <div class="inner_box">
                        <img
                          src={userSetting}
                          class="img-fluid" />
                      </div>
                    </div>
                  </div>
                </Link>
                </div>
                </>
                }
              <div class="right_box_3">
                <div class="sign_out">
                  <a href="javascript:void(0)" onClick={() => setModal(true)}>
                    <img src="../assets/images/image-gallery/signout.svg" class="img-fluid" />
                    <p>Sign Out</p>
                  </a>
                  <p>Signed in as: <span style={{ maxWidth: '100px' }}>{orgName}</span></p>
                </div>
              </div>
              </div>
            </div>
            <div className={`modal fade  ${isModal ? "d-block show" : ""}`} id="exampleModal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Log out?
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}>
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure you want to Logout?</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-round btn-default" data-dismiss="modal" onClick={() => setModal(false)}>
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-round btn-primary"
                      onClick={() => {
                        localStorage.clear();
                        window.location.replace("/login");
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </> : <>
          <header>
            <div class="nav_main nav_main_serviceHeader">
              <div class="left_box_1">
                <div className="d-flex">
                  <div class="logo_img">
                    <img src="../assets/images/image-gallery/logo.svg" class="img-fluid" />
                  </div>
                  <Link to="/summary">
                    <div class="activeheader text_logo_box">
                      <p>
                        {'<< Close / Return'}
                        <span style={{
                          padding: ' 0px 0px 0px 14px;'
                        }}>to iOdin Panel</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src="https://liveattackmap.azurewebsites.net/images/map-elements/Map_Return_BTN.svg"
                            class="img-fluid imginner_box" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class='text_logo_box' style={{margin:'0px 4px'}}>
                      <p>
                        Other Services
                      </p>
                    </div>
                  </Link>
                  <Link>
                    <div class="text_logo_box text_logo_box_bg">
                      <p>
                        Valkyrie
                        <span>Special</span>
                        Services
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src="../assets/images/valkyrie_service.svg"
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Learning
                        <span>Training</span>
                        Certification
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={trainningCommonImg}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class='text_logo_box'>
                      <p>
                        General Info
                      </p>
                    </div>
                  </Link>
                  <Link>
                    <div class="text_logo_box">
                      <p>
                        About
                        <span>Us</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={aboutImg}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Company
                        <span>Info</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={companyImg}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Contact
                        <span>Us</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={contactUs}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Legal
                        <span>Info</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={legalInfo}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Privacy
                        <span>Info</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={privacyImg}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Partners
                        <span>Info</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={partnerImg}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Licences/
                        <span>Certifications</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={licencesImg}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="d-flex">
                  <Link>
                    <div class='text_logo_box'>
                      <p>
                        User Services
                      </p>
                    </div>
                  </Link>
                  <Link>
                    <div class={"text_logo_box"}>
                      <p>
                        Userguides
                        <span>Support</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={userGuide}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/profile">
                    <div class={"text_logo_box"}>
                      <p>
                        User
                        <span>Settings</span>
                      </p>
                      <div class="circle_button">
                        <div class="inner_box">
                          <img
                            src={userSetting}
                            class="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              {/* <div class="right_box_4">
                <div className="textImgBox">
                  <Link to="/profile">
                    <img src={userSetting} />
                    <p>
                      User Settings
                    </p>
                  </Link>
                </div>
              </div> */}
              {/* <div id="navbar-menu" style={{ position: "absolute", right: "110px", top: "45%", cursor: 'pointer' }}>
                <ul className="nav navbar-nav">
                  {auth?.accessLevel !== "Manager" &&
                    auth?.accessLevel !== "Client" &&
                    auth?.accessLevel !== "Vendor" && (
                      <li>
                        <span
                          onClick={() => toggleSearchBar(true)}
                          className="search_toggle icon-menu"
                          title="Search Result"
                        >
                          <i className="icon-magnifier"></i>
                        </span>
                      </li>
                    )}
                </ul>
              </div> */}
              <div class="right_box_3">
                <div class="sign_out">
                  <a href="javascript:void(0)" onClick={() => setModal(true)}>
                    <img src="../assets/images/image-gallery/signout.svg" class="img-fluid" />
                    <p>Sign Out</p>
                  </a>
                  <p>Signed in as: <span style={{ maxWidth: '100px' }}>{orgName}</span></p>
                </div>
              </div>
            </div>
            <div className={`modal fade  ${isModal ? "d-block show" : ""}`} id="exampleModal">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Log out?
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}>
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure you want to Logout?</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-round btn-default" data-dismiss="modal" onClick={() => setModal(false)}>
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-round btn-primary"
                      onClick={() => {
                        localStorage.clear();
                        window.location.replace("/login");
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </>
      }
    </>

  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  toggleSearchBar: (e) => dispatch(toggleSearchBar(e)),
});

// export default header;
export default connect(mapStateToProps, mapDispatchToProps)(Header);
