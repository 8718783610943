import React, { Component, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import img from "../../assets/images/Icon_Top_Action_Pane.svg";
import imgReport from "../../assets/images/report_mode.svg";
import imgadded from "../../assets/images/non-adedd.png";
import historyimg from "../../assets/images/history.png";
import manually from "../../assets/images/manually.png";
import types from "../../assets/images/types_added.png";
import task from "../../assets/images/Selected_Task.svg";
import ticket from "../../assets/images/Selected_Ticket.svg";
import traning from "../../assets/images/Selected_Training.svg";
import typesadded from "../../assets/images/types_added.png";
import totalAdd from "../../assets/images/total-added.png";
import refersh from "../../assets/images/refresh_selction.png";
import icon from "../../assets/images/Btn_View_Process.svg";
import iconSetting from "../../assets/images/Icon_process.png";
import iconDrag from "../../assets/images/icon_drag.svg";
import discovred from "../../assets/images/discovred.svg";
import imgtable from "../../assets/images/table_sign.png";
import imgselect from "../../assets/images/select_box.png";
import tickIcon from "../../assets/images/check-solid.svg";
import removeimg from "../../assets/images/remove.png"
import {
  getRemediationData,
  removeRemediationData,
  addRemediationData,
  remediationDataReset,
  getRemediationAction,
  remediationDataTotal,
  getRemediationActionType,
  getProcessList,
  getContainersList,
  getContainersBodyList
} from "../../actions/sub-domain";
import Pagination from "../Pagination/Pagination";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import $ from "jquery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import imgRemediationHistory from "../../assets/images/Remediation_History.svg";
import { fetchPendingCount, fetchRemidiationList } from "../../actions/summary";



const RightNav = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const id = useSelector(
    (state) =>
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
  );
  const reportSetting = useSelector(
    (state) => state?.summary?.reportSetting[0] || state?.summary?.reportSetting
  );
  const [pathname, setPathname] = useState(location.pathname);
  const [checkPath, setCheckPath] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const [category, setCategory] = useState("all");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [isModaltwo, setModalTwo] = useState(false);
  const [tableValue, setTableValue] = useState('Stolen Credentials Check');
  const [sessionScreen, setsessionScreen] = useState('');
  const textContent = `Lorem Ipsum is simply dummy`;
  const params = new URLSearchParams(location?.search);
  const modeValue = params.get('mode');
  let remediationList = useSelector((state) => state?.auth?.remediationListAll?.data);
  let remediationTotal = useSelector((state) => state?.auth?.remediationTotal?.data);
  let pendingTask = useSelector((state) => state?.summary?.pendingCount);
  let remediationlistTicket = useSelector((state) => state?.summary?.remediationList);
  let containersData = useSelector((state) => state?.subDomain?.processContainerList?.data);
  let containersDataBody = useSelector((state) => state?.subDomain?.processContainerBodyList?.data);

  useEffect(() => {
    setCheckPath(location.search)
    setPathname(location.pathname);
  }, [location.pathname, location.search]);

  useEffect(() => {
    fetchPendingCount(id, dispatch)
  }, [id])

  useEffect(() => {
    fetchRemidiationList(id, dispatch)
  }, [id])

  useEffect(() => {
    getRemediationData(dispatch, id, page, category)
  }, [id, page, category])

  useEffect(() => {
    setTotalItems(remediationList?.totalRecords)
  }, [remediationList?.totalRecords])

  useEffect(() => {
    remediationDataTotal(dispatch, id)
  }, [id])

  useEffect(() => {
    getContainersList(dispatch, id)
  }, [id])

  const deleteCart = (_id) => e => {
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to remove this from the Remediation List Panel?",
      type: "red",
      buttons: {
        ok: {
          text: "Remove",
          btnClass: "btn-danger",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            removeRemediationData(_id, dispatch, id)
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  const removeToCart = (_id) => e => {
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to remove this from the Remediation List Panel?",
      type: "red",
      buttons: {
        ok: {
          text: "Remove",
          btnClass: "btn-danger",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            removeRemediationData(_id, dispatch, id).then(() => {
              fetchRemidiationList(id, dispatch)
            })
          },
        },
        cancel: function () {
        },
      },
    });
  }

  const handleClick = () => {
    getProcessList(dispatch, id)
  };

  const addToCart = (row) => e => {
    let data = {
      "collectionName": row?.collectionName,
      "moduleName": row?.moduleName,
      "organisationId": id,
      "recordId": row?.record?._id,
    }
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to add this to the Remediation List Panel ?",
      type: "green",
      buttons: {
        ok: {
          text: "Add",
          btnClass: "btn-green",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            addRemediationData(data, dispatch).then(() => {
              fetchRemidiationList(id, dispatch)
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  const tabInOut = (e) => {
    e.preventDefault();
    var tab_uppper_button = document.getElementById("right_bar_button");
    var mypanel = document.getElementById("right_side_id");

    tab_uppper_button.classList.toggle("active_circle_two");
    mypanel.classList.toggle("right_side_bar_active");
  };

  const tabInOuttwo = (e) => {
    e.preventDefault();
    var right_side_list = document.getElementById("right_side_list");
    var tab_uppper_button = document.getElementById("right_bar_button_list");
    var mypanel = document.getElementById("full_list_pageId");
    if (right_side_list) {
      right_side_list.classList.toggle("right_side_list_active");
    }
    if (tab_uppper_button) {
      tab_uppper_button.classList.toggle("active_circle_two");
    }
    if (mypanel) {
      mypanel.classList.toggle("full_list_page_active");
    }
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };


  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event, name) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      const data = {
        action: "markAsRemidiated",
        recordIds: selectedIds == 0 ? _id : selectedIds,
        actionNote: "",
        for: "forever",
        techName: name,
      };
      getRemediationAction(
        dispatch,
        id,
        data,
        page,
        category
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    const data = {
      action: "resetAction",
      recordIds: selectedIds == 0 ? _id : selectedIds,
    };
    getRemediationAction(
      dispatch,
      id,
      data,
      page,
      category
    );
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      recordIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    getRemediationAction(
      dispatch,
      id,
      data,
      page,
      category
    );
    setModalTwo("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const addModal = () => {
    setModalTwo("Form Wizard modal two");
  };




  return (
    <>
      {pathname == "/summary" || pathname == "/" ? (
        <>
          {modeValue == 'assessment-mode' && (
            <div className="dashboard_remediation">
              <div className="box cursor"
                onClick={tabInOuttwo}>
                <div className="circle_box">
                  <div className="circle_box_one">
                    <img src={historyimg} />
                  </div>
                </div>
                <p>Remediation Task/actions</p>
                <h3><p>Pending</p>{pendingTask?.totalPedingTasks || 0}</h3>
              </div>
            </div>)}
        </>
      ) : (
        <>
          <div>
          </div>
        </>)}
      {modeValue != 'settings-mode' ? (<>
        {pathname == "/summary" || pathname == "/" ? (
          <>
            {checkPath == '?mode=report-mode' ?
              <div
                class="right_side_bar side_bar right_side_list"
                id="right_side_list"
              >
                <div
                  class="up_down_circle up_down_circle_two"
                  id="right_bar_button_list"
                >
                  {totalItems != 0 && checkPath != '?mode=report-mode' && (
                    <span className="counter">{totalItems || 0}</span>
                  )}
                  <a onClick={tabInOuttwo}>
                    <img
                      src="/assets/images/icon_drag.svg"
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div class="tab_boxes tab_boxes_ul">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li>
                      <img src={imgReport} style={{ width: '35px' }} />
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link active security_check_1"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected="true"
                      >
                        <span>
                          Report <span className="d-block">List Panel</span>
                        </span>
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link text_img_sidebar"
                        type="button"
                      >
                        <img src={imgadded} className="img-fluid" />
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link text_img_sidebar"
                        type="button"
                      >
                        <span style={{ color: 'rgb(61, 154, 223)' }}>05 active this section</span>
                        <h6 className="m-0">27</h6>
                        <span style={{ color: 'rgb(61, 154, 223)' }}>Total</span>
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link text_img_sidebar"
                        type="button"
                      >
                        <img src={icon} className="img-fluid" />
                      </a>
                    </li>
                    <li class="nav-item view-process" role="presentation" onClick={tabInOuttwo}>
                      <a
                        href="javascript:void(0)"
                        class="nav-link text_img_sidebar"
                        type="button"
                      >
                        <span style={{ color: 'rgb(61, 154, 223)' }}>View / Process</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              : <div class="right_side_bar side_bar" id="right_side_id">
                <div class="up_down_circle" id="right_bar_button">
                  {totalItems != 0 && checkPath != '?mode=report-mode' && (
                    <span className="counter">{totalItems || 0}</span>
                  )}
                  <a onClick={tabInOut}>
                    <img
                      src="/assets/images/icon_drag.svg"
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div class="tab_boxes tab_boxes_ul">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li></li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link active security_check_1"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected="true"
                      >
                        <span>
                          Check to <span className="d-block">+Add</span>
                        </span>
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link active security_check_1 security_check_img"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected="true"
                      >
                        <img
                          src="/assets/images/Icon_Drag_2.png"
                          class="img-fluid"
                          alt=""
                        />
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link text_img_sidebar"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="profile-tab-pane"
                        aria-selected="false"
                      >
                        <span>Slide to Proccess</span>
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="home-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      tabindex="0"
                    >
                      <h5>
                        {checkPath == '?mode=report-mode' ? 'Report model action' : 'Recommended Actions'}
                      </h5>
                      <div class="row_boxes row_boxes_table">
                        <div class="table_box table_box_rem">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>Status</th>
                                <th>Description</th>
                                <th>Rating</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {remediationlistTicket?.map((elem) => {
                                return (
                                  <tr>
                                    <td>{elem?.type}</td>
                                    <td>{elem?.status}</td>
                                    <td>{elem?.description || "N/A"}</td>
                                    <td>{elem?.severity}</td>
                                    <td>
                                      <td>
                                        {elem?.record?.isTicketChecked == false ? (
                                          <button
                                            onClick={addToCart({
                                              ...elem,
                                            })}
                                          >
                                            <span className="fa fa-plus action_icon"></span>
                                          </button>
                                        ) : (
                                          <button onClick={removeToCart(elem?.record?._id)}>
                                            <span className="fa fa-minus action_icon"></span>
                                          </button>
                                        )}
                                      </td>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        {/* <div className="dataTables_paginate">
                    {totalItems > 0 && (
                      <Pagination
                        className="pagination-bar"
                        currentPage={page}
                        totalCount={totalItems}
                        pageSize={10}
                        onPageChange={(page) => {
                          setPage(page);
                        }}
                      />
                    )}
                  </div> */}
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="contact-tab-pane"
                      role="tabpanel"
                      aria-labelledby="contact-tab"
                      tabindex="0"
                    ></div>
                  </div>
                </div>
              </div>}
            {checkPath == '?mode=report-mode' &&
              <div className="full_list_page" id="full_list_pageId">
                <div className="top_bar_list">
                  <img src={img} />
                  <p>Report List Panel</p>
                </div>
                <div className="main_flex_box main_flex_box_2">
                  <div className="first_box" style={{ width: '197px' }}>
                    <div className="up_down_circle_close">
                      <div
                        class="up_down_circle up_down_circle_2"
                        id="right_bar_button_three"
                      >
                        {totalItems != 0 && checkPath != '?mode=report-mode' && (
                          <span className="counter">{totalItems || 0}</span>
                        )}
                        <a onClick={tabInOuttwo}>
                          <img
                            src="/assets/images/icon_drag.svg"
                            class="img-fluid"
                            alt=""
                          />
                        </a>
                      </div>
                      <p className="p_absolute">Click to Close</p>
                    </div>
                  </div>
                  <div className="midBox">
                    <div className="table_box">
                      <p>Standard</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ borderColor: 'transparent !important', width: '100px' }}>
                                <span>
                                  <img src={tickIcon} style={{ width: '3%' }} />
                                </span>
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}
                              >
                                <span
                                  style={{
                                    width: '200px',
                                    display: 'inline-block'
                                  }}
                                >
                                  Front Page and settings
                                </span>
                                <FontAwesomeIcon icon={faBars}
                                  style={{
                                    color: '#5f646d',
                                    fontSize: '21px',
                                    marginLeft: '16px',
                                    marginBottom: '-4px'
                                  }}
                                />
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}>
                              </th>
                              <th style={{ borderColor: 'transparent !important', textAlign: 'end' }}>
                                <img src={imgtable} />
                              </th>
                              <th style={{ borderColor: 'transparent !important', textAlign: 'end', width: '140px' }}>don't be removed</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ borderColor: 'transparent !important', width: '100px' }}>
                                <span
                                >
                                  <img src={tickIcon} style={{ width: '3%' }} />
                                </span>
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}>
                                <span style={{
                                  width: '200px',
                                  display: 'inline-block'
                                }}>
                                  Summary Page
                                </span>
                                <FontAwesomeIcon icon={faBars}
                                  style={{
                                    color: '#5f646d',
                                    fontSize: '21px',
                                    marginLeft: '16px',
                                    marginBottom: '-4px'
                                  }}
                                />
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}></th>
                              <th style={{ borderColor: 'transparent !important' }}></th>
                              <th style={{ borderColor: 'transparent !important', textAlign: 'end' }}>
                                <img src={imgtable} />
                              </th>
                              <th style={{ borderColor: 'transparent !important', textAlign: 'end', width: '140px' }}>
                                <div className="d-flex">
                                  <div className="remove-box" style={{ cursor: 'pointer' }}>
                                    <div className="box">
                                      <img src={removeimg} />
                                    </div>
                                    <p>Remove</p>
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                    <div className="table_box">
                      <p>Added</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ borderColor: 'transparent !important', width: '100px' }}>
                                <span>
                                  <img src={tickIcon} style={{ width: '3%' }} />
                                </span>
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}>
                                <span>
                                  Security Health Checks
                                </span>
                              </th>
                              {
                                reportSetting?.securityHealthCheck?.AssetDiscovered_IpDiscoveryOpenPorts && (
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <span>
                                      Assets Discovery & Open Ports Check
                                    </span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                    />
                                  </th>
                                )
                              }
                              {
                                reportSetting?.securityHealthCheck?.dnsRecord && (
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <span>
                                      DNS Health Check
                                    </span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                    />
                                  </th>
                                )
                              }
                              {
                                reportSetting?.securityHealthCheck?.knownVulnerability_passiveVa && (
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <span>
                                      Known Vulnerabilities
                                    </span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                    />
                                  </th>
                                )
                              }
                              {
                                reportSetting?.securityHealthCheck?.sslCertificates && (
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <span>
                                      SSL Certififcates Check
                                    </span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                    />
                                  </th>
                                )
                              }
                              {
                                reportSetting?.securityHealthCheck?.subDomain_danglingSubDomain && (
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <span>
                                      Subdomain Health Check
                                    </span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                    />
                                  </th>
                                )
                              }
                              <th style={{ borderColor: 'transparent !important', textAlign: 'end' }}>
                                <img src={imgtable} />
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <div className="table-responsive table-responsive-color">
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ borderColor: 'transparent !important', width: '100px' }}>
                                <span>
                                  <img src={tickIcon} style={{ width: '3%' }} />
                                </span>
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}
                                className={`${tableValue == 'Stolen Credentials Check' || tableValue == 'IP Repulation Check' ? 'class-green' : ''}`}
                              >Breach Detection</th>
                              {
                                reportSetting?.breachDetection?.stolenCredentials && (
                                  <th style={{ borderColor: 'transparent !important' }}
                                    onClick={() => {
                                      setTableValue('Stolen Credentials Check')
                                    }}
                                  >
                                    <span
                                      className={`${tableValue == 'Stolen Credentials Check' ? 'class-green' : ''}`}
                                    >Stolen Credentials Check</span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                      className={`${tableValue == 'Stolen Credentials Check' ? 'class-green' : ''}`}
                                    />
                                  </th>
                                )
                              }
                              {
                                reportSetting?.breachDetection?.ipBlacklist && (
                                  <th style={{ borderColor: 'transparent !important' }}
                                    onClick={() => {
                                      setTableValue('IP Repulation Check')
                                    }}
                                  >
                                    <span className={`${tableValue == 'IP Repulation Check' ? 'class-green' : ''}`}>
                                      IP Repulation Check
                                    </span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                      className={`${tableValue == 'IP Repulation Check' ? 'class-green' : ''}`}
                                    />
                                  </th>
                                )}
                              <th style={{ borderColor: 'transparent !important' }}>
                              </th>
                              <th style={{ borderColor: 'transparent !important', textAlign: 'end', width: '140px' }}>
                                <div className="d-flex">
                                  <div className="remove-box" style={{ cursor: 'pointer' }}>
                                    <div className="box">
                                      <img src={removeimg} />
                                    </div>
                                    <p>Remove</p>
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </thead>
                        </table>
                        <div className="box-tab">
                          <div className="f-box">
                            <div className="tab_A">
                              <p>
                                {tableValue}
                              </p>
                              <ul>
                                <li>
                                  <input type="checkbox" id="apiIntegration" />
                                  <label htmlFor="apiIntegration">Show Description</label>
                                </li>
                                <li>
                                  <input type="checkbox" id="htmlCssDesign" />
                                  <label htmlFor="htmlCssDesign">Show Description</label>
                                </li>
                                <li>
                                  <input type="checkbox" id="frontendFunctionality" />
                                  <label htmlFor="frontendFunctionality">Show Description</label>
                                </li>
                                <li>
                                  <input type="checkbox" id="fullFrontendManagement" />
                                  <label htmlFor="fullFrontendManagement">Show Description</label>
                                </li>
                              </ul>

                            </div>
                            <div className="tab_B">
                              <div className="textEditBox">
                                <input type="checkbox" id="frontendFunctionalitys" />
                                <label htmlFor="frontendFunctionalitys" style={{
                                  marginBottom: '0px',
                                  marginTop: '15px',
                                  fontSize: '10px',
                                  color: '#fff'
                                }}>
                                  Show my rules
                                </label>
                                <textarea
                                  defaultValue={textContent}
                                  readOnly={true}
                                  style={{
                                    width: '300px',
                                    height: '150px',
                                    borderRadius: '20px',
                                    padding: '10px',
                                    margin: '5px'
                                  }}
                                />
                              </div>
                              <div className="textEditBox">
                                <input type="checkbox" id="frontendFunctionalitye" />
                                <label htmlFor="frontendFunctionalitye"
                                  style={{
                                    marginBottom: '0px',
                                    marginTop: '15px',
                                    fontSize: '10px',
                                    color: '#fff'
                                  }}>
                                  Show my rules
                                </label>
                                <textarea
                                  defaultValue={textContent}
                                  readOnly={true}
                                  style={{
                                    width: '300px',
                                    height: '150px',
                                    borderRadius: '20px',
                                    padding: '10px',
                                    margin: '5px'
                                  }}
                                />
                              </div>
                            </div>
                            <div className="tab_C">
                              <p>Select Main View</p>
                              <div className="d-flex mb-2">
                                <div className="select-box">
                                  <select className="select_box_mytask">
                                    <option>Current Status</option>
                                    <option>Add to My-Tasks</option>
                                    <option>Add to My-Tasks</option>
                                    <option>Add to My-Tasks</option>
                                  </select>
                                  <div className="select_box_img">
                                    <img src={imgselect} />
                                  </div>
                                </div>
                              </div>
                              <p>Select Main View</p>
                              <div className="d-flex">
                                <div className="select-box">
                                  <select className="select_box_mytask">
                                    <option>Current Status</option>
                                    <option>Add to My-Tasks</option>
                                    <option>Add to My-Tasks</option>
                                    <option>Add to My-Tasks</option>
                                  </select>
                                  <div className="select_box_img">
                                    <img src={imgselect} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ borderColor: 'transparent !important', width: '100px' }}>
                                <span>
                                  <img src={tickIcon} style={{ width: '3%' }} />
                                </span>
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}>Brand Security</th>
                              {
                                reportSetting?.brandSecurity?.fakeMobileApplications && (
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <span>
                                      Fake Mobile Application Check
                                    </span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                    />
                                  </th>
                                )}
                              {
                                reportSetting?.brandSecurity?.fakeSocialMediaProfiles && (
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <span>
                                      Fake Social Media Profiles Check
                                    </span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                    />
                                  </th>
                                )}
                              <th style={{ borderColor: 'transparent !important', textAlign: 'end' }}>
                                <img src={imgtable} />
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom_panel_list justify-content-center align-items-center">
                  <div className="box_left">
                    <div className="left-innerBox-1 left-innerBox">
                      <div className="outer_div">
                        <div className="inner_div">
                          <img src={refersh} className="img-fluid" />
                        </div>
                      </div>
                      <p>
                        Save <span>Current</span>
                      </p>
                    </div>
                    <div className="left-innerBox-1 left-innerBox">
                      <div className="outer_div">
                        <div className="inner_div">
                          <img src={refersh} className="img-fluid" />
                        </div>
                      </div>
                      <p>
                        Preview <span>Report</span>
                      </p>
                    </div>
                    <div className="left-innerBox-2 left-innerBox">
                      <div className="outer_div">
                        <div className="inner_div">
                          <img src={iconSetting} className="img-fluid" />
                        </div>
                      </div>
                      <p style={{ maxWidth: '88px' }}>
                        Create <span>Current Report</span>
                      </p>
                    </div>
                    <div className="left-innerBox-3 left-innerBox" id="right_bar_button_three" onClick={tabInOuttwo}>
                      <div className="outer_div">
                        <div className="inner_div">
                          <img src={iconDrag} className="img-fluid" />
                        </div>
                      </div>
                      <p>
                        Close <span>Panel</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={`modal fade Form-Wizard-modal ${isModaltwo === "Form Wizard modal two" ? "d-block show" : ""
                    }`}
                >
                  <div className="modal-dialog modal-lg" id="clientModal">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          {action == "markAsFalsePositive"
                            ? "Mark As False Positive"
                            : "Risk Accepted"}
                        </h5>
                        <button
                          type="button"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            setModalTwo("");
                            setActionNote("");
                          }}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body pricing_page modal_mob">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                          <div className="card">
                            <div className="row clearfix">
                              <div className="col-md-12">
                                <label className="col-lg-6 col-md-12">Snooze for</label>
                                <div className="form-group ol-lg-12 col-md-12">
                                  <select
                                    className="custom-select custom-select-sm form-control form-control-sm"
                                    onChange={(e) => setForValue(e.target.value)}
                                  >
                                    <option value="forever">Forever</option>
                                    <option value="3 months">3 Months</option>
                                    <option value="6 months">6 Months</option>
                                    <option value="12 months">12 Months</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <label className="col-lg-12 col-md-12">Detail</label>
                                <div className="form-group col-lg-12 col-md-12">
                                  <textarea
                                    onChange={(e) => setActionNote(e.target.value)}
                                    value={actionNote}
                                    className="form-control"
                                    rows="3"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div
                                className="col-lg-12 col-md-12"
                                style={{ marginRight: "28px" }}
                              >
                                <button
                                  type="button"
                                  style={{ color: "black", fontWeight: "bold" }}
                                  onClick={submit}
                                  className="btn btn-round btn-primary mr-1 pull-right"
                                >
                                  Submit
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  type="button"
                                  onClick={() => {
                                    setModalTwo("");
                                    setActionNote("");
                                  }}
                                  className="btn btn-round btn-default pull-right"
                                  style={{ marginRight: "10px" }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {/* {sessionScreen == 'sessionShow' && */}
            <div className="full_list_page" id="full_list_pageId">
              <div className="top_bar_list">
                <img src={imgRemediationHistory} />
                <p style={{ maxWidth: '130px' }}>Remediation <span>Session History</span></p>
                <div className="boxPending">
                  <span>
                    {pendingTask?.totalPedingTasks || 0}
                  </span>
                  <p>
                    Pending Activity
                  </p>
                </div>
              </div>
              <div className="main_flex_box main_flex_box_2">
                <div className="first_box" style={{ width: '197px' }}>
                  <div className="up_down_circle_close">
                    <div
                      class="up_down_circle up_down_circle_2"
                      id="right_bar_button_three"
                    >
                      {totalItems != 0 && checkPath != '?mode=report-mode' && (
                        <span className="counter">{totalItems || 0}</span>
                      )}
                      <a onClick={tabInOuttwo}>
                        <img
                          src="/assets/images/icon_drag.svg"
                          class="img-fluid"
                          alt=""
                        />
                      </a>
                    </div>
                    <p className="p_absolute">Click to Close</p>
                  </div>
                </div>
                <div className="midBox">
                  <div className="table_box">
                    <div className="table-responsive table-icons table-width">
                      <table className="table m-0">
                        <thead>
                          <tr>
                            <th style={{ borderColor: 'transparent !important' }}
                            >
                              Status
                            </th>
                            <th style={{ borderColor: 'transparent !important' }}>
                              Session
                              <span>Reference</span>
                            </th>
                            <th style={{ borderColor: 'transparent !important' }}>
                              Session
                              <span>Date</span>
                            </th>
                            <th style={{ borderColor: 'transparent !important' }}>
                              <img src={task} className="img-fluid" />
                              Session
                              <span>Tasks</span>
                            </th>
                            <th style={{ borderColor: 'transparent !important' }}>
                              <img src={ticket} className="img-fluid" />
                              Session
                              <span>Tickets</span>
                            </th>
                            <th style={{ borderColor: 'transparent !important' }}>
                              <img src={traning} className="img-fluid" />
                              Session
                              <span>Trainings</span>
                            </th>
                            <th style={{ borderColor: 'transparent !important' }}>
                              <img src="../assets/images/Risk_Manegement_Main.svg" className="img-fluid" />
                              Risk
                              <span>Management</span>
                            </th>
                            <th style={{ borderColor: 'transparent !important' }}>
                              <img src={totalAdd} className="img-fluid" />
                              Totals
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                    {containersData?.containersList?.map((container, index) => (
                      <><div className="table-responsive table-width" key={container?.containerId}>
                        <table className="table m-0">
                          <thead onClick={() => {
                            getContainersBodyList(dispatch, id, container?.containerId);
                          }}>
                            <tr>
                              <th style={{ borderColor: 'transparent !important' }}>
                                <span className={container?.status === 'Completed' ? 'greyDarkColour' : 'blueColour'}>
                                  {container?.status === 'Completed' ? 'Done' : 'Pending'}
                                </span>
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}>
                                #{container?.containerId}
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}>
                                {new Date(container?.containerCreationDate).toLocaleDateString()}
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}>
                                {container?.taskCount}
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}>
                                {container?.ticketCount}
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}>
                                {container?.trainingCount}
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}>
                                {container?.riskManagementCount}
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}>
                                {container?.totalTasksCount}
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}>
                              </th>
                              <th style={{ borderColor: 'transparent !important', textAlign: 'end', width: '500px' }}>
                                <FontAwesomeIcon icon={faBars} style={{ color: '#5f646d', fontSize: '21px', marginLeft: '16px', marginBottom: '-4px' }} />
                              </th>
                            </tr>
                          </thead>

                        </table>
                      </div>
                        {containersDataBody?.tasksList?.map((res) => {
                          if (res.containerId === container.containerId) {
                            return (
                              <div className="table-responsive table-short-width">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th style={{ borderColor: 'transparent !important' }}
                                      >
                                        Status
                                      </th>
                                      <th style={{ borderColor: 'transparent !important' }}>
                                        Description
                                      </th>
                                      <th style={{ borderColor: 'transparent !important' }}>
                                        category
                                      </th>
                                      <th style={{ borderColor: 'transparent !important' }}>
                                        Action
                                        <span className="d-block">
                                          selected
                                        </span>
                                      </th>
                                      <th style={{ borderColor: 'transparent !important' }}>
                                        Season
                                        <span className="d-block">
                                          Pending
                                        </span>
                                      </th>
                                      <th style={{ borderColor: 'transparent !important' }}>
                                        Reviews
                                        <span className="d-block">
                                          Action
                                        </span>
                                      </th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>

                                    <tr key={res._id}>
                                      <td> <span className={container?.status === 'Completed' ? 'greyDarkColour' : 'blueColour'}>
                                        {container?.status === 'Completed' ? 'Done' : 'Pending'}
                                      </span></td>
                                      <td>N/A</td>
                                      <td>{res?.collectionName}</td>
                                      <td>
                                        {res?.actionType}
                                      </td>
                                      <td>N/A</td>
                                      <td>N/A</td>
                                      <td>N/A</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            );
                          }
                        })}
                      </>
                    ))}
                    {/* <div>*/}
                  </div>
                </div>
              </div>
              <div className="bottom_panel_list justify-content-center align-items-center">
                <div className="box_left justify-content-center">
                  {/* <div className="left-innerBox-1 left-innerBox">
                          <div className="outer_div">
                            <div className="inner_div">
                              <img src={refersh} className="img-fluid" />
                            </div>
                          </div>
                          <p>
                            Refersh <span>Reviews</span>
                          </p>
                        </div> */}
                  {/* <div className="left-innerBox-2 left-innerBox">
                          <div className="outer_div">
                            <div className="inner_div">
                              <img src={iconSetting} className="img-fluid" />
                            </div>
                          </div>
                          <p style={{ maxWidth: '88px' }}>
                            Process <span>Reviews</span>
                          </p>
                        </div> */}
                  <div className="left-innerBox-3 left-innerBox" id="right_bar_button_three"
                    onClick={tabInOuttwo}>
                    <div className="outer_div">
                      <div className="inner_div">
                        <img src={iconDrag} className="img-fluid" />
                      </div>
                    </div>
                    <p>
                      Back to <span>Current</span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`modal fade Form-Wizard-modal ${isModaltwo === "Form Wizard modal two" ? "d-block show" : ""
                  }`}
              >
                <div className="modal-dialog modal-lg" id="clientModal">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        {action == "markAsFalsePositive"
                          ? "Mark As False Positive"
                          : "Risk Accepted"}
                      </h5>
                      <button
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setModalTwo("");
                          setActionNote("");
                        }}
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body pricing_page modal_mob">
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="card">
                          <div className="row clearfix">
                            <div className="col-md-12">
                              <label className="col-lg-6 col-md-12">Snooze for</label>
                              <div className="form-group ol-lg-12 col-md-12">
                                <select
                                  className="custom-select custom-select-sm form-control form-control-sm"
                                  onChange={(e) => setForValue(e.target.value)}
                                >
                                  <option value="forever">Forever</option>
                                  <option value="3 months">3 Months</option>
                                  <option value="6 months">6 Months</option>
                                  <option value="12 months">12 Months</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <label className="col-lg-12 col-md-12">Detail</label>
                              <div className="form-group col-lg-12 col-md-12">
                                <textarea
                                  onChange={(e) => setActionNote(e.target.value)}
                                  value={actionNote}
                                  className="form-control"
                                  rows="3"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-lg-12 col-md-12"
                              style={{ marginRight: "28px" }}
                            >
                              <button
                                type="button"
                                style={{ color: "black", fontWeight: "bold" }}
                                onClick={submit}
                                className="btn btn-round btn-primary mr-1 pull-right"
                              >
                                Submit
                              </button>
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                onClick={() => {
                                  setModalTwo("");
                                  setActionNote("");
                                }}
                                className="btn btn-round btn-default pull-right"
                                style={{ marginRight: "10px" }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* } */}
          </>
        ) : (
          <>
            {checkPath == '?mode=report-mode' ?
              (<div
                class="right_side_bar side_bar right_side_list"
                id="right_side_list"
              >
                <div
                  class="up_down_circle up_down_circle_two"
                  id="right_bar_button_list"
                >
                  {totalItems != 0 && checkPath != '?mode=report-mode' && (
                    <span className="counter">{totalItems || 0}</span>
                  )}
                  <a onClick={tabInOuttwo}>
                    <img
                      src="/assets/images/icon_drag.svg"
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div class="tab_boxes tab_boxes_ul">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li>
                      <img src={imgReport} style={{ width: '35px' }} />
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link active security_check_1"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected="true"
                      >
                        <span>
                          Report <span className="d-block">List Panel</span>
                        </span>
                      </a>
                    </li>
                    {/* <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link active security_check_1"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected="true"
                      >
                        <span style={{ color: '#3d9adf' }}>
                          Check<span className="d-block" style={{ color: 'rgb(61, 154, 223)' }}>Drag/Drop</span>
                        </span>
                      </a>
                    </li> */}
                    {/* <li class="nav-item" role="presentation">
                        <a
                          href="javascript:void(0)"
                          class="nav-link active security_check_1 security_check_img"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="home-tab-pane"
                          aria-selected="true"
                        >
                          <img
                            src="/assets/images/Icon_Drag_2.png"
                            class="img-fluid"
                            alt=""
                          />
                        </a>
                      </li> */}
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link text_img_sidebar"
                        type="button"
                      >
                        <img src={imgadded} className="img-fluid" />
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link text_img_sidebar"
                        type="button"
                      >
                        <span style={{ color: 'rgb(61, 154, 223)' }}>05 active this section</span>
                        <h6 className="m-0">27</h6>
                        <span style={{ color: 'rgb(61, 154, 223)' }}>Total</span>
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link text_img_sidebar"
                        type="button"
                      >
                        <img src={icon} className="img-fluid" />
                      </a>
                    </li>
                    <li class="nav-item view-process" role="presentation" onClick={tabInOuttwo}>
                      <a
                        href="javascript:void(0)"
                        class="nav-link text_img_sidebar"
                        type="button"
                      >
                        <span style={{ color: 'rgb(61, 154, 223)' }}>View / Process</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>) : <div
                class="right_side_bar side_bar right_side_list"
                id="right_side_list"
              >
                <div
                  class="up_down_circle up_down_circle_two"
                  id="right_bar_button_list"
                >
                  {totalItems != 0 && checkPath != '?mode=report-mode' && (
                    <span className="counter">{totalItems || 0}</span>
                  )}
                  <a onClick={tabInOuttwo}>
                    <img
                      src="/assets/images/icon_drag.svg"
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
                <div class="tab_boxes tab_boxes_ul">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li>
                      <img src={img} />
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link active security_check_1"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected="true"
                      >
                        <span>
                          Remediation <span className="d-block">List Panel</span>
                        </span>
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link active security_check_1 security_check_img useToadd"
                      >
                        Use +
                        <span>to add</span>
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link active security_check_1 security_check_img settingIcon"
                      >
                        <img
                          src="/assets/images/Icon_Drag_2.png"
                          class="img-fluid"
                          alt=""
                        />
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link text_img_sidebar"
                        type="button"
                      >
                        <img src={imgadded} className="img-fluid" />
                        {totalItems > 0 ? (
                          <>
                            <span>
                              Pending
                            </span>
                            <h5>{totalItems}</h5>
                          </>
                        ) : <>
                          <span>
                            Your list is empty
                          </span>
                          <h5>00</h5>
                        </>}
                      </a>
                    </li>
                    {/* <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link text_img_sidebar"
                        type="button"
                      >
                        <h6>{totalItems}</h6>
                        <span>Total</span>
                      </a>
                    </li> */}
                    <li class="nav-item" role="presentation">
                      <a
                        href="javascript:void(0)"
                        class="nav-link text_img_sidebar"
                        type="button"
                      >
                        <img src={icon} className="img-fluid" />
                      </a>
                    </li>
                    <li class="nav-item view-process" role="presentation" onClick={tabInOuttwo}>
                      <a
                        href="javascript:void(0)"
                        class="nav-link text_img_sidebar"
                        type="button"
                      >
                        <span>View / Process</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>}
            {checkPath == '?mode=report-mode' ?
              <div className="full_list_page" id="full_list_pageId">
                <div className="top_bar_list">
                  <img src={img} />
                  <p>Report List Panel</p>
                </div>
                <div className="main_flex_box main_flex_box_2">
                  <div className="first_box" style={{ width: '197px' }}>
                    <div className="up_down_circle_close">
                      <div
                        class="up_down_circle up_down_circle_2"
                        id="right_bar_button_three"
                      >
                        {totalItems != 0 && checkPath != '?mode=report-mode' && (
                          <span className="counter">{totalItems || 0}</span>
                        )}
                        <a onClick={tabInOuttwo}>
                          <img
                            src="/assets/images/icon_drag.svg"
                            class="img-fluid"
                            alt=""
                          />
                        </a>
                      </div>
                      <p className="p_absolute">Click to Close</p>
                    </div>
                  </div>
                  <div className="midBox">
                    <div className="table_box">
                      <p>Standard</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ borderColor: 'transparent !important', width: '100px' }}>
                                <span>
                                  <img src={tickIcon} style={{ width: '3%' }} />
                                </span>
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}
                              >
                                <span
                                  style={{
                                    width: '200px',
                                    display: 'inline-block'
                                  }}
                                >
                                  Front Page and settings
                                </span>
                                <FontAwesomeIcon icon={faBars}
                                  style={{
                                    color: '#5f646d',
                                    fontSize: '21px',
                                    marginLeft: '16px',
                                    marginBottom: '-4px'
                                  }}
                                />
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}>
                              </th>
                              <th style={{ borderColor: 'transparent !important', textAlign: 'end' }}>
                                <img src={imgtable} />
                              </th>
                              <th style={{ borderColor: 'transparent !important', textAlign: 'end', width: '140px' }}>don't be removed</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ borderColor: 'transparent !important', width: '100px' }}>
                                <span
                                >
                                  <img src={tickIcon} style={{ width: '3%' }} />
                                </span>
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}>
                                <span style={{
                                  width: '200px',
                                  display: 'inline-block'
                                }}>
                                  Summary Page
                                </span>
                                <FontAwesomeIcon icon={faBars}
                                  style={{
                                    color: '#5f646d',
                                    fontSize: '21px',
                                    marginLeft: '16px',
                                    marginBottom: '-4px'
                                  }}
                                />
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}></th>
                              <th style={{ borderColor: 'transparent !important' }}></th>
                              <th style={{ borderColor: 'transparent !important', textAlign: 'end' }}>
                                <img src={imgtable} />
                              </th>
                              <th style={{ borderColor: 'transparent !important', textAlign: 'end', width: '140px' }}>
                                <div className="d-flex">
                                  <div className="remove-box" style={{ cursor: 'pointer' }}>
                                    <div className="box">
                                      <img src={removeimg} />
                                    </div>
                                    <p>Remove</p>
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                    <div className="table_box">
                      <p>Added</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ borderColor: 'transparent !important', width: '100px' }}>
                                <span>
                                  <img src={tickIcon} style={{ width: '3%' }} />
                                </span>
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}>
                                <span>
                                  Security Health Checks
                                </span>
                              </th>
                              {
                                reportSetting?.securityHealthCheck?.AssetDiscovered_IpDiscoveryOpenPorts && (
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <span>
                                      Assets Discovery & Open Ports Check
                                    </span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                    />
                                  </th>
                                )
                              }
                              {
                                reportSetting?.securityHealthCheck?.dnsRecord && (
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <span>
                                      DNS Health Check
                                    </span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                    />
                                  </th>
                                )
                              }
                              {
                                reportSetting?.securityHealthCheck?.knownVulnerability_passiveVa && (
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <span>
                                      Known Vulnerabilities
                                    </span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                    />
                                  </th>
                                )
                              }
                              {
                                reportSetting?.securityHealthCheck?.sslCertificates && (
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <span>
                                      SSL Certififcates Check
                                    </span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                    />
                                  </th>
                                )
                              }
                              {
                                reportSetting?.securityHealthCheck?.subDomain_danglingSubDomain && (
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <span>
                                      Subdomain Health Check
                                    </span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                    />
                                  </th>
                                )
                              }
                              <th style={{ borderColor: 'transparent !important', textAlign: 'end' }}>
                                <img src={imgtable} />
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <div className="table-responsive table-responsive-color">
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ borderColor: 'transparent !important', width: '100px' }}>
                                <span>
                                  <img src={tickIcon} style={{ width: '3%' }} />
                                </span>
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}
                                className={`${tableValue == 'Stolen Credentials Check' || tableValue == 'IP Repulation Check' ? 'class-green' : ''}`}
                              >Breach Detection</th>
                              {
                                reportSetting?.breachDetection?.stolenCredentials && (
                                  <th style={{ borderColor: 'transparent !important' }}
                                    onClick={() => {
                                      setTableValue('Stolen Credentials Check')
                                    }}
                                  >
                                    <span
                                      className={`${tableValue == 'Stolen Credentials Check' ? 'class-green' : ''}`}
                                    >Stolen Credentials Check</span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                      className={`${tableValue == 'Stolen Credentials Check' ? 'class-green' : ''}`}
                                    />
                                  </th>
                                )
                              }
                              {
                                reportSetting?.breachDetection?.ipBlacklist && (
                                  <th style={{ borderColor: 'transparent !important' }}
                                    onClick={() => {
                                      setTableValue('IP Repulation Check')
                                    }}
                                  >
                                    <span className={`${tableValue == 'IP Repulation Check' ? 'class-green' : ''}`}>
                                      IP Repulation Check
                                    </span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                      className={`${tableValue == 'IP Repulation Check' ? 'class-green' : ''}`}
                                    />
                                  </th>
                                )}
                              <th style={{ borderColor: 'transparent !important' }}>
                              </th>
                              <th style={{ borderColor: 'transparent !important', textAlign: 'end', width: '140px' }}>
                                <div className="d-flex">
                                  <div className="remove-box" style={{ cursor: 'pointer' }}>
                                    <div className="box">
                                      <img src={removeimg} />
                                    </div>
                                    <p>Remove</p>
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </thead>
                        </table>
                        <div className="box-tab">
                          <div className="f-box">
                            <div className="tab_A">
                              <p>
                                {tableValue}
                              </p>
                              <ul>
                                <li>
                                  <input type="checkbox" id="apiIntegration" />
                                  <label htmlFor="apiIntegration">Show Description</label>
                                </li>
                                <li>
                                  <input type="checkbox" id="htmlCssDesign" />
                                  <label htmlFor="htmlCssDesign">Show Description</label>
                                </li>
                                <li>
                                  <input type="checkbox" id="frontendFunctionality" />
                                  <label htmlFor="frontendFunctionality">Show Description</label>
                                </li>
                                <li>
                                  <input type="checkbox" id="fullFrontendManagement" />
                                  <label htmlFor="fullFrontendManagement">Show Description</label>
                                </li>
                              </ul>

                            </div>
                            <div className="tab_B">
                              <div className="textEditBox">
                                <input type="checkbox" id="frontendFunctionalitys" />
                                <label htmlFor="frontendFunctionalitys" style={{
                                  marginBottom: '0px',
                                  marginTop: '15px',
                                  fontSize: '10px',
                                  color: '#fff'
                                }}>
                                  Show my rules
                                </label>
                                <textarea
                                  defaultValue={textContent}
                                  readOnly={true}
                                  style={{
                                    width: '300px',
                                    height: '150px',
                                    borderRadius: '20px',
                                    padding: '10px',
                                    margin: '5px'
                                  }}
                                />
                              </div>
                              <div className="textEditBox">
                                <input type="checkbox" id="frontendFunctionalitye" />
                                <label htmlFor="frontendFunctionalitye"
                                  style={{
                                    marginBottom: '0px',
                                    marginTop: '15px',
                                    fontSize: '10px',
                                    color: '#fff'
                                  }}>
                                  Show my rules
                                </label>
                                <textarea
                                  defaultValue={textContent}
                                  readOnly={true}
                                  style={{
                                    width: '300px',
                                    height: '150px',
                                    borderRadius: '20px',
                                    padding: '10px',
                                    margin: '5px'
                                  }}
                                />
                              </div>
                            </div>
                            <div className="tab_C">
                              <p>Select Main View</p>
                              <div className="d-flex mb-2">
                                <div className="select-box">
                                  <select className="select_box_mytask">
                                    <option>Current Status</option>
                                    <option>Add to My-Tasks</option>
                                    <option>Add to My-Tasks</option>
                                    <option>Add to My-Tasks</option>
                                  </select>
                                  <div className="select_box_img">
                                    <img src={imgselect} />
                                  </div>
                                </div>
                              </div>
                              <p>Select Main View</p>
                              <div className="d-flex">
                                <div className="select-box">
                                  <select className="select_box_mytask">
                                    <option>Current Status</option>
                                    <option>Add to My-Tasks</option>
                                    <option>Add to My-Tasks</option>
                                    <option>Add to My-Tasks</option>
                                  </select>
                                  <div className="select_box_img">
                                    <img src={imgselect} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ borderColor: 'transparent !important', width: '100px' }}>
                                <span>
                                  <img src={tickIcon} style={{ width: '3%' }} />
                                </span>
                              </th>
                              <th style={{ borderColor: 'transparent !important' }}>Brand Security</th>
                              {
                                reportSetting?.brandSecurity?.fakeMobileApplications && (
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <span>
                                      Fake Mobile Application Check
                                    </span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                    />
                                  </th>
                                )}
                              {
                                reportSetting?.brandSecurity?.fakeSocialMediaProfiles && (
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <span>
                                      Fake Social Media Profiles Check
                                    </span>
                                    <FontAwesomeIcon icon={faBars}
                                      style={{
                                        color: '#5f646d',
                                        fontSize: '21px',
                                        marginLeft: '16px',
                                        marginBottom: '-4px'
                                      }}
                                    />
                                  </th>
                                )}
                              <th style={{ borderColor: 'transparent !important', textAlign: 'end' }}>
                                <img src={imgtable} />
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom_panel_list justify-content-center align-items-center">
                  <div className="box_left">
                    <div className="left-innerBox-1 left-innerBox">
                      <div className="outer_div">
                        <div className="inner_div">
                          <img src={refersh} className="img-fluid" />
                        </div>
                      </div>
                      <p>
                        Save <span>Current</span>
                      </p>
                    </div>
                    <div className="left-innerBox-1 left-innerBox">
                      <div className="outer_div">
                        <div className="inner_div">
                          <img src={refersh} className="img-fluid" />
                        </div>
                      </div>
                      <p>
                        Preview <span>Report</span>
                      </p>
                    </div>
                    <div className="left-innerBox-2 left-innerBox">
                      <div className="outer_div">
                        <div className="inner_div">
                          <img src={iconSetting} className="img-fluid" />
                        </div>
                      </div>
                      <p style={{ maxWidth: '88px' }}>
                        Create <span>Current Report</span>
                      </p>
                    </div>
                    <div className="left-innerBox-3 left-innerBox" id="right_bar_button_three" onClick={tabInOuttwo}>
                      <div className="outer_div">
                        <div className="inner_div">
                          <img src={iconDrag} className="img-fluid" />
                        </div>
                      </div>
                      <p>
                        Close <span>Panel</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={`modal fade Form-Wizard-modal ${isModaltwo === "Form Wizard modal two" ? "d-block show" : ""
                    }`}
                >
                  <div className="modal-dialog modal-lg" id="clientModal">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          {action == "markAsFalsePositive"
                            ? "Mark As False Positive"
                            : "Risk Accepted"}
                        </h5>
                        <button
                          type="button"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            setModalTwo("");
                            setActionNote("");
                          }}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body pricing_page modal_mob">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                          <div className="card">
                            <div className="row clearfix">
                              <div className="col-md-12">
                                <label className="col-lg-6 col-md-12">Snooze for</label>
                                <div className="form-group ol-lg-12 col-md-12">
                                  <select
                                    className="custom-select custom-select-sm form-control form-control-sm"
                                    onChange={(e) => setForValue(e.target.value)}
                                  >
                                    <option value="forever">Forever</option>
                                    <option value="3 months">3 Months</option>
                                    <option value="6 months">6 Months</option>
                                    <option value="12 months">12 Months</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <label className="col-lg-12 col-md-12">Detail</label>
                                <div className="form-group col-lg-12 col-md-12">
                                  <textarea
                                    onChange={(e) => setActionNote(e.target.value)}
                                    value={actionNote}
                                    className="form-control"
                                    rows="3"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div
                                className="col-lg-12 col-md-12"
                                style={{ marginRight: "28px" }}
                              >
                                <button
                                  type="button"
                                  style={{ color: "black", fontWeight: "bold" }}
                                  onClick={submit}
                                  className="btn btn-round btn-primary mr-1 pull-right"
                                >
                                  Submit
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  type="button"
                                  onClick={() => {
                                    setModalTwo("");
                                    setActionNote("");
                                  }}
                                  className="btn btn-round btn-default pull-right"
                                  style={{ marginRight: "10px" }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              <>
                {sessionScreen !== 'sessionShow' ?
                  <div className="full_list_page" id="full_list_pageId">
                    <div className="top_bar_list">
                      <img src={img} />
                      <p>Remediation List Panel</p>
                    </div>
                    <div className="main_flex_box">
                      <div className="first_box">
                        <div className="up_down_circle_close">
                          <div
                            class="up_down_circle up_down_circle_2"
                            id="right_bar_button_three"
                          >
                            {totalItems != 0 && checkPath != '?mode=report-mode' && (
                              <span className="counter">{totalItems || 0}</span>
                            )}
                            <a onClick={tabInOuttwo}>
                              <img
                                src="/assets/images/icon_drag.svg"
                                class="img-fluid"
                                alt=""
                              />
                            </a>
                          </div>
                          <p className="p_absolute">Click to Close</p>
                        </div>
                        <div className="box cursor" onClick={() => {
                          setsessionScreen('sessionShow')
                        }}>
                          <div className="circle_box">
                            <div className="circle_box_one">
                              <img src={historyimg} />
                            </div>
                          </div>
                          <p>View History / Pending</p>
                          <h3>{pendingTask?.totalPedingTasks || 0}</h3>
                        </div>
                      </div>
                      <div className="midBox">
                        <div className="table_box">
                          <p>Discovered and Added</p>
                          <div className="table-responsive">
                            <img src={imgtable} className="icon-table" />
                            <table className="table">
                              <thead>
                                <tr>
                                  {(category === "all" ||
                                    category === "risk-accepted" ||
                                    category === "false-positive") && (
                                      <th>
                                        <input
                                          type="checkbox"
                                          checked={selectedIds == 0 ? false : multiCheckTrue}
                                          onClick={() => {
                                            multicheck(remediationList?.data);
                                          }}
                                          className="checkUncheck"
                                        />
                                      </th>
                                    )}
                                  <th>Type</th>
                                  <th>Status</th>
                                  <th>Description</th>
                                  <th>Rating</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {remediationList?.data?.map((elem) => {
                                  return (
                                    <tr>
                                      {(category === "all" ||
                                        category === "risk-accepted" ||
                                        category === "false-positive") && (
                                          <td>
                                            <input
                                              onClick={() => singleClick(elem?._id)}
                                              checked={selectedIds?.includes(elem?._id)}
                                              type="checkbox"
                                              className="checkUncheck"
                                            />
                                          </td>
                                        )}
                                      <td>{elem?.type}</td>
                                      <td>{elem?.status}</td>
                                      <td>{elem?.description}</td>
                                      <td>{elem?.rating}</td>
                                      <td>
                                        <div className="d-flex">
                                          <div className="select-box">
                                            <select className="select_box_mytask"
                                              onChange={(e) => {
                                                if (e.target.value != '' || e.target.value != undefined) {
                                                  let data = {
                                                    "actionType": e.target.value
                                                  }
                                                  getRemediationActionType(dispatch, elem?._id, data, id)
                                                }
                                              }}>
                                              <option value="">Please select</option>
                                              <option value="riskManagement">Send to risk management</option>
                                              <option value="ticket">Send ticket</option>
                                              <option value="task">Send task</option>
                                              <option value="training">Send training</option>
                                            </select>
                                            <div className="select_box_img">
                                              <img src={imgselect} />
                                            </div>
                                          </div>
                                          <div className="remove-box" onClick={deleteCart(elem?._id)} style={{ cursor: 'pointer' }}>
                                            <div className="box">
                                              <img src={removeimg} />
                                            </div>
                                            <p>Remove</p>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="dataTables_paginate">
                          {totalItems > 0 && (
                            <Pagination
                              className="pagination-bar"
                              currentPage={page}
                              totalCount={totalItems}
                              pageSize={10}
                              onPageChange={(page) => {
                                setPage(page);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bottom_panel_list">
                      <div className="box_right">
                        <div className="box-a">
                          <div className="inner_box">
                            <div className="types_added">
                              {/* <div className="boxadded">
                            <img src={discovred} />
                            <h6>07</h6>
                            <p>Discovered Added</p>
                          </div>
                          <div className="boxadded">
                            <img src={manually} />
                            <h6>{totalItems}</h6>
                            <p>Manually Added</p>
                          </div>
                          <div className="boxadded">
                            <img src={manually} />
                            <h6>00</h6>
                            <p>Service Added</p>
                          </div> */}
                            </div>
                            {/* <div className="added_types">
                          <p>Types added</p>
                          <img src={types} className="img-fluid" />
                        </div> */}
                          </div>
                          <div className="inner_box">
                            <div className="types_added">
                              <div className="boxadded">
                                <img src={task} className="img-fluid" />
                                <h6>{remediationTotal?.task || 0}</h6>
                                <p>Task Added</p>
                              </div>
                              <div className="boxadded">
                                <img src={ticket} className="img-fluid" />
                                <h6>{remediationTotal?.ticket || 0}</h6>
                                <p>Ticket Added</p>
                              </div>
                              <div className="boxadded">
                                <img src={traning} className="img-fluid" />
                                <h6>{remediationTotal?.training || 0}</h6>
                                <p>Training Added</p>
                              </div>
                              <div className="boxadded boxadded_1">
                                <img src="../assets/images/Risk_Manegement_Main.svg" style={{ width: '40px' }} className="img-fluid" />
                                <h6>{remediationTotal?.riskManagement || 0}</h6>
                                <p>Risk Management</p>
                              </div>
                            </div>
                            <div className="added_types">
                              <p>Actions Selected</p>
                              <img src={typesadded} className="img-fluid" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="box_left">
                        <div className="left-innerBox box_new">
                          <img src={totalAdd} className="img-fluid" />
                          <div>
                            <h5>
                              {
                                (remediationTotal?.task || 0) +
                                (remediationTotal?.ticket || 0) +
                                (remediationTotal?.training || 0) +
                                (remediationTotal?.riskManagement || 0)
                              }
                            </h5>
                            <p>TOTAL Added</p>
                          </div>
                        </div>
                        <div className="left-innerBox-1 left-innerBox cursor"
                          onClick={() => {
                            let data = {
                              "organisationId": id
                            }
                            remediationDataReset(dispatch,
                              data
                            )
                          }}>
                          <div className="outer_div">
                            <div className="inner_div">
                              <img src={refersh} className="img-fluid" />
                            </div>
                          </div>
                          <p>
                            Reset <span>Selections</span>
                          </p>
                        </div>
                        <div className="left-innerBox-2 left-innerBox cursor"
                          onClick={handleClick}
                        >
                          <div className="outer_div">
                            <div className="inner_div">
                              <img src={iconSetting} className="img-fluid" />
                            </div>
                          </div>
                          <p>
                            Process <span>Current List</span>
                          </p>
                        </div>
                        <div className="left-innerBox-3 left-innerBox" id="right_bar_button_three" onClick={tabInOuttwo}>
                          <div className="outer_div">
                            <div className="inner_div">
                              <img src={iconDrag} className="img-fluid" />
                            </div>
                          </div>
                          <p>
                            Close <span>Panel</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`modal fade Form-Wizard-modal ${isModaltwo === "Form Wizard modal two" ? "d-block show" : ""
                        }`}
                    >
                      <div className="modal-dialog modal-lg" id="clientModal">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              {action == "markAsFalsePositive"
                                ? "Mark As False Positive"
                                : "Risk Accepted"}
                            </h5>
                            <button
                              type="button"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() => {
                                setModalTwo("");
                                setActionNote("");
                              }}
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body pricing_page modal_mob">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                              <div className="card">
                                <div className="row clearfix">
                                  <div className="col-md-12">
                                    <label className="col-lg-6 col-md-12">Snooze for</label>
                                    <div className="form-group ol-lg-12 col-md-12">
                                      <select
                                        className="custom-select custom-select-sm form-control form-control-sm"
                                        onChange={(e) => setForValue(e.target.value)}
                                      >
                                        <option value="forever">Forever</option>
                                        <option value="3 months">3 Months</option>
                                        <option value="6 months">6 Months</option>
                                        <option value="12 months">12 Months</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <label className="col-lg-12 col-md-12">Detail</label>
                                    <div className="form-group col-lg-12 col-md-12">
                                      <textarea
                                        onChange={(e) => setActionNote(e.target.value)}
                                        value={actionNote}
                                        className="form-control"
                                        rows="3"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div
                                    className="col-lg-12 col-md-12"
                                    style={{ marginRight: "28px" }}
                                  >
                                    <button
                                      type="button"
                                      style={{ color: "black", fontWeight: "bold" }}
                                      onClick={submit}
                                      className="btn btn-round btn-primary mr-1 pull-right"
                                    >
                                      Submit
                                    </button>
                                    &nbsp;&nbsp;
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setModalTwo("");
                                        setActionNote("");
                                      }}
                                      className="btn btn-round btn-default pull-right"
                                      style={{ marginRight: "10px" }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> :
                  <div className="full_list_page" id="full_list_pageId">
                    <div className="top_bar_list">
                      <img src={imgRemediationHistory} />
                      <p style={{ maxWidth: '130px' }}>Remediation <span>Session History</span></p>
                      <div className="boxPending">
                        <span>
                          {pendingTask?.totalPedingTasks || 0}
                        </span>
                        <p>
                          Pending Activity
                        </p>
                      </div>
                    </div>
                    <div className="main_flex_box main_flex_box_2">
                      <div className="first_box" style={{ width: '197px' }}>
                        <div className="up_down_circle_close">
                          <div
                            class="up_down_circle up_down_circle_2"
                            id="right_bar_button_three"
                          >
                            {pendingTask?.totalPedingTasks != 0 && checkPath != '?mode=report-mode' && (
                              <span className="counter"> {pendingTask?.totalPedingTasks || 0}</span>
                            )}
                            <a
                              onClick={() => {
                                setsessionScreen('')
                              }} >
                              <img
                                src="/assets/images/icon_drag.svg"
                                class="img-fluid"
                                alt=""
                              />
                            </a>
                          </div>
                          <p className="p_absolute">Click to Close</p>
                        </div>
                      </div>
                      <div className="midBox">
                        <div className="table_box">
                          <div className="table-responsive table-icons table-width">
                            <table className="table m-0">
                              <thead>
                                <tr>
                                  <th style={{ borderColor: 'transparent !important' }}
                                  >
                                    Status
                                  </th>
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    Session
                                    <span>Reference</span>
                                  </th>
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    Session
                                    <span>Date</span>
                                  </th>
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <img src={task} className="img-fluid" />
                                    Session
                                    <span>Tasks</span>
                                  </th>
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <img src={ticket} className="img-fluid" />
                                    Session
                                    <span>Tickets</span>
                                  </th>
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <img src={traning} className="img-fluid" />
                                    Session
                                    <span>Trainings</span>
                                  </th>
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <img src="../assets/images/Risk_Manegement_Main.svg" className="img-fluid" />
                                    Risk
                                    <span>Management</span>
                                  </th>
                                  <th style={{ borderColor: 'transparent !important' }}>
                                    <img src={totalAdd} className="img-fluid" />
                                    Totals
                                  </th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                          {containersData?.containersList?.map((container, index) => (
                            <><div className="table-responsive table-width" key={container?.containerId}>
                              <table className="table m-0">
                                <thead onClick={() => {
                                  getContainersBodyList(dispatch, id, container?.containerId);
                                }}>
                                  <tr>
                                    <th style={{ borderColor: 'transparent !important' }}>
                                      <span className={container?.status === 'Completed' ? 'greyDarkColour' : 'blueColour'}>
                                        {container?.status === 'Completed' ? 'Done' : 'Pending'}
                                      </span>
                                    </th>
                                    <th style={{ borderColor: 'transparent !important' }}>
                                      #{container?.containerId}
                                    </th>
                                    <th style={{ borderColor: 'transparent !important' }}>
                                      {new Date(container?.containerCreationDate).toLocaleDateString()}
                                    </th>
                                    <th style={{ borderColor: 'transparent !important' }}>
                                      {container?.taskCount}
                                    </th>
                                    <th style={{ borderColor: 'transparent !important' }}>
                                      {container?.ticketCount}
                                    </th>
                                    <th style={{ borderColor: 'transparent !important' }}>
                                      {container?.trainingCount}
                                    </th>
                                    <th style={{ borderColor: 'transparent !important' }}>
                                      {container?.riskManagementCount}
                                    </th>
                                    <th style={{ borderColor: 'transparent !important' }}>
                                      {container?.totalTasksCount}
                                    </th>
                                    <th style={{ borderColor: 'transparent !important' }}>
                                    </th>
                                    <th style={{ borderColor: 'transparent !important', textAlign: 'end', width: '500px' }}>
                                      <FontAwesomeIcon icon={faBars} style={{ color: '#5f646d', fontSize: '21px', marginLeft: '16px', marginBottom: '-4px' }} />
                                    </th>
                                  </tr>
                                </thead>

                              </table>
                            </div>
                              {containersDataBody?.tasksList?.map((res) => {
                                if (res.containerId === container.containerId) {
                                  return (
                                    <div className="table-responsive table-short-width">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th style={{ borderColor: 'transparent !important' }}
                                            >
                                              Status
                                            </th>
                                            <th style={{ borderColor: 'transparent !important' }}>
                                              Description
                                            </th>
                                            <th style={{ borderColor: 'transparent !important' }}>
                                              category
                                            </th>
                                            <th style={{ borderColor: 'transparent !important' }}>
                                              Action
                                              <span className="d-block">
                                                selected
                                              </span>
                                            </th>
                                            <th style={{ borderColor: 'transparent !important' }}>
                                              Season
                                              <span className="d-block">
                                                Pending
                                              </span>
                                            </th>
                                            <th style={{ borderColor: 'transparent !important' }}>
                                              Reviews
                                              <span className="d-block">
                                                Action
                                              </span>
                                            </th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>

                                          <tr key={res._id}>
                                            <td> <span className={container?.status === 'Completed' ? 'greyDarkColour' : 'blueColour'}>
                                              {container?.status === 'Completed' ? 'Done' : 'Pending'}
                                            </span></td>
                                            <td>N/A</td>
                                            <td>{res?.collectionName}</td>
                                            <td>
                                              {res?.actionType}
                                            </td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  );
                                }
                              })}
                            </>
                          ))}
                          {/* <div>*/}
                        </div>
                      </div>
                    </div>
                    <div className="bottom_panel_list justify-content-center align-items-center">
                      <div className="box_left justify-content-center">
                        {/* <div className="left-innerBox-1 left-innerBox">
                          <div className="outer_div">
                            <div className="inner_div">
                              <img src={refersh} className="img-fluid" />
                            </div>
                          </div>
                          <p>
                            Refersh <span>Reviews</span>
                          </p>
                        </div> */}
                        {/* <div className="left-innerBox-2 left-innerBox">
                          <div className="outer_div">
                            <div className="inner_div">
                              <img src={iconSetting} className="img-fluid" />
                            </div>
                          </div>
                          <p style={{ maxWidth: '88px' }}>
                            Process <span>Reviews</span>
                          </p>
                        </div> */}
                        <div className="left-innerBox-3 left-innerBox" id="right_bar_button_three"
                          onClick={() => {
                            setsessionScreen('')
                          }}>
                          <div className="outer_div">
                            <div className="inner_div">
                              <img src={iconDrag} className="img-fluid" />
                            </div>
                          </div>
                          <p>
                            Back to <span>Current</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`modal fade Form-Wizard-modal ${isModaltwo === "Form Wizard modal two" ? "d-block show" : ""
                        }`}
                    >
                      <div className="modal-dialog modal-lg" id="clientModal">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              {action == "markAsFalsePositive"
                                ? "Mark As False Positive"
                                : "Risk Accepted"}
                            </h5>
                            <button
                              type="button"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() => {
                                setModalTwo("");
                                setActionNote("");
                              }}
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body pricing_page modal_mob">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                              <div className="card">
                                <div className="row clearfix">
                                  <div className="col-md-12">
                                    <label className="col-lg-6 col-md-12">Snooze for</label>
                                    <div className="form-group ol-lg-12 col-md-12">
                                      <select
                                        className="custom-select custom-select-sm form-control form-control-sm"
                                        onChange={(e) => setForValue(e.target.value)}
                                      >
                                        <option value="forever">Forever</option>
                                        <option value="3 months">3 Months</option>
                                        <option value="6 months">6 Months</option>
                                        <option value="12 months">12 Months</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <label className="col-lg-12 col-md-12">Detail</label>
                                    <div className="form-group col-lg-12 col-md-12">
                                      <textarea
                                        onChange={(e) => setActionNote(e.target.value)}
                                        value={actionNote}
                                        className="form-control"
                                        rows="3"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div
                                    className="col-lg-12 col-md-12"
                                    style={{ marginRight: "28px" }}
                                  >
                                    <button
                                      type="button"
                                      style={{ color: "black", fontWeight: "bold" }}
                                      onClick={submit}
                                      className="btn btn-round btn-primary mr-1 pull-right"
                                    >
                                      Submit
                                    </button>
                                    &nbsp;&nbsp;
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setModalTwo("");
                                        setActionNote("");
                                      }}
                                      className="btn btn-round btn-default pull-right"
                                      style={{ marginRight: "10px" }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }</>}
          </>
        )}
      </>) : (
        <>
          <div>
          </div>
        </>)
      }
    </>
  );
};

export default RightNav;