import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import StolenCredentials from '../BreachRisk/StolenCredentials';
import MalwareCredentials from '../BreachRisk/MalwareCredentials';
import LeakedSessions from '../BreachRisk/LeakedSessions';
import SourceCodeLeakage from '../BreachRisk/SourceCodeLeakage';
import CloudBuckets from '../BreachRisk/CloudBuckets';
import IPBlacklist from '../BreachRisk/IPBlacklist';

const Breach = ({match}) => {
    console.log(match,' Breach')
    return (
        <>
            <Switch>
                <Route path={`${match.path}/stolen-credentials`} component={StolenCredentials} />
                <Route path={`${match.path}/malware-credentials`} component={MalwareCredentials} />
                <Route path={`${match.path}/source-code`} component={SourceCodeLeakage} />
                <Route path={`${match.path}/leaked-sessions`} component={LeakedSessions} />
                <Route path={`${match.path}/cloud-bucket`} component={CloudBuckets} />
                <Route path={`${match.path}/ip-blacklist`} component={IPBlacklist} />
                <Route path={`${match.path}`} component={StolenCredentials} />
            </Switch>
        </>
    )
}

export default Breach;