import { PASTEBIN_RECORD_FETCHED } from "../actions";

export default function (
  state = { loading: true, response: null, list: null, scanCompleted: true },
  action
) {
    //console.log(action,'PASTEBIN_RECORD_FETCHED');
  switch (action.type) {
    case PASTEBIN_RECORD_FETCHED:
      return {
        ...state,
        response: action.payload,
        loading: false,
        scanCompleted: action.payload.scanCompleted
      };
    default:
      return state;
  }
}
