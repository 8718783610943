import { FETCH_CLIENTS,REMEDIATION_LIST, GETING_DATA_BY_LOCALSTORAGE,REPORT_SETTING,PENDING_COUNT, SUMMARY_FETCHED, REPORT_DOWNLOAD, MAIN_SCREEN_SUMMARY_FETCHED, DASHBOARD_SUMMARY_FETCHED, VENDOR_SUMMARY_FETCHED } from "../actions";
const initialState = {
  clients: [],
  response: null,
  localClient: null,
  summary: null,
  mainSummary:null,
  reportDownload: false,
  dashboardSummary: [],
  vendorSummary: [],
  loading: true,
  reportSetting:[],
  pendingCount:[],
  remediationList:[]
};

const summary = (state = initialState, action) => {
  switch (action.type) {
    case REMEDIATION_LIST:
      return{
        ...state,
        remediationList:action.payload
      }
    case PENDING_COUNT:
      return{
        ...state,
        pendingCount:action.payload
      }
    case REPORT_SETTING:
      return{
        ...state,
        reportSetting:action.payload
      }
      case GETING_DATA_BY_LOCALSTORAGE:
        return {
          ...state,
          localClient: action.localClient,
        };
    case FETCH_CLIENTS:
      return {
        ...state,
        clients: action.clients,
        response: action.response,
      };
    case SUMMARY_FETCHED:
      return {
        ...state,
        summary: action.payload
      };
    case MAIN_SCREEN_SUMMARY_FETCHED:
      return {
        ...state,
        mainSummary: action.payload
      };
    case REPORT_DOWNLOAD:
      return {
        ...state,
        reportDownload: action.payload
      }
    case DASHBOARD_SUMMARY_FETCHED:
      return {
        ...state,
        dashboardSummary: action.payload,
        loading: action.payload ? false : true
      }
    case VENDOR_SUMMARY_FETCHED:
      return {
        ...state,
        vendorSummary: action.payload,
        loading: action.payload ? false : true
      }
    default:
      return state;
  }
};

export default summary;
