import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchIpDiscovery,
  fetchIpDiscoveryAction,
  createHOst,
  deleteIp,
  uploadIp,
  assetsMonitorInternalApi
} from "../../actions/inventory";
import { Link, useLocation } from "react-router-dom";
import Tooltip from "../common/toolTip";
import { VectorMap } from "react-jvectormap";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "../Pagination/Pagination";
import moment from "moment";
import { countryToCode } from "../../utils/countryCode";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BasicLineColumn from "../common/apexChart/lineChart";
import { Modal } from "@material-ui/core";
import $ from "jquery";
import { toastr } from "react-redux-toastr";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import SideNav from '../resuableComponent/sidenav';
import Header from '../resuableComponent/header';
import Loader from "../resuableComponent/loader";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import rightImg from "../../assets/images/right-clock.png";
import ReactApexChart from "react-apexcharts";
import leftImg from "../../assets/images/left-clock.png";
import GaugeChart from "react-gauge-chart";
import { has } from "immutable";
import { Reorder } from "framer-motion";
import {
  addRemediationData,
  removeRemediationData,
} from "../../actions/sub-domain";



function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    minWidth: "600px",
    minHeight: "300px",
  },
}));

const AssetDiscovery = () => {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const [isModal, setModal] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [modalBody, setModalBody] = useState([]);
  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [PageSize, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [filterData, setfilterData] = useState("all");
  const [addIpModel, setAddIpModel] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [addHost, setHost] = useState("");
  const classes = useStyles();
  const [ipRemoveModal, setIpRemoveModal] = useState(false);
  const [ipId, setIpId] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [category, setCategory] = useState("all");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const [isModaltwo, setModalTwo] = useState(false);
  const location = useLocation();
  const [hash, setHash] = useState(location.hash.replace('#', ''));
  const [checkPath, setCheckPath] = useState('');
  const queryParameters = new URLSearchParams(window.location.search)
  const latest = queryParameters.get("latest");
  const auth = useSelector(
    (state) => state?.auth?.user
  );

  const id = useSelector(
    (state) =>
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
  );

  const client = useSelector(
    (state) =>
      state?.summary?.localClient || state?.summary?.clients?.data?.[0]
  );

  // latest
  let summary = useSelector((state) => state?.summary?.dashboardSummary);
  let table = useSelector((state) => state?.inventory?.ipdiscovery?.data);
  let tableTotal = useSelector(
    (state) => state?.inventory?.ipdiscovery
  );
  let securityGraphValueASM = summary?.all?.Rating?.map(
    (row) => row?.attackSurfaceSecurityImpact
  );
  let securityGraphValueData = summary?.all?.Rating?.map(
    (row) => row?.darkWebSecurityImpact
  );
  let securityGraphValueBrand = summary?.all?.Rating?.map(
    (row) => row?.brandSecuritySecurityImpact
  );
  let securityGraphDate = summary?.all?.Rating?.map((row) =>
    moment(row?.createdAt).format("DD-MM-YYYY")
  );
  const loaded = useSelector((state) => state.inventory.loaded);

  const distinctLocationData = summary?.all?.assetMap;
  const values = distinctLocationData?.map(location => {
    return { [location.region]: "#3fa9f5" };
  });


  const options = {
    // grid: {
    //     strokeColors: ['#fff'], // Set the color of the grid lines
    //     zindex: -1,
    //   },
    grid: {
      show: true,
      borderColor: '#fff',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      },
      row: {
        colors: undefined,
        opacity: 0
      },
      column: {
        colors: undefined,
        opacity: 0
      },
    },

    chart: {
      id: 'mixed-chart',
      toolbar: {
        show: false // Hide the toolbar icons
      },
      background: 'none' // Hide the background line
    },
    plotOptions: {
      line: {
        // Add padding to the top of the chart
        offsetY: 10
      }
    },
    colors: ['#b0f127', '#ffff00', '#ff0000'],
    labels: securityGraphDate,
    xaxis: {
      labels: {
        show: false // Hide the x-axis labels
      },
      axisBorder: {
        show: true, // Show the x-axis line
        offsetY: 10 // Add padding to the top of the chart
      }
    },
    yaxis: {
      labels: {
        show: false // Hide the y-axis labels
      },
      axisBorder: {
        show: false, // Show the y-axis line
        offsetX: 0 // Add padding to the left of the chart
      }
    },
    legend: {
      show: false, // Show legends
    },
    markers: {
      size: 1, // Set the size of indicators
    }
  };

  const series = [
    {
      name: 'Attack Surface',
      type: 'line',
      data: securityGraphValueASM,
    },
    {
      name: 'Data Exposure',
      type: 'line',
      data: securityGraphValueData,
    },
    {
      name: 'Brand Security', // You can provide a name for the new line if needed
      type: 'line',
      data: securityGraphValueBrand, // Add your new data points here
    }
  ];
  const rating = summary?.all?.totalScoreDashboard;

  const valueResult = Math.floor(
    Number(rating?.attackSurfaceSecurityImpact) +
    Number(rating?.darkWebSecurityImpact) +
    Number(rating?.brandSecuritySecurityImpact)
  );

  useEffect(() => {
    setHash(location.hash.replace('#', ''));
  }, [location.hash]);

  useEffect(()=>{
    setCheckPath(location.search)
  },[location.search])

  useEffect(() => {
    if (hash === 'hostsDiscovered') {
      fetchIpDiscovery(id, dispatch, page, category);
    } else if (hash === 'hostsLocation') {
      assetsMonitorInternalApi(id, dispatch, 'hosts-locations');
    } else if (hash === 'cloudProvider') {
      assetsMonitorInternalApi(id, dispatch, 'cloud-providers');
    }
    else if (hash === 'openPort') {
      assetsMonitorInternalApi(id, dispatch, 'open-ports');
    }
    else if (hash === 'operatingSystem') {
      assetsMonitorInternalApi(id, dispatch, 'operating-systems');
    }
    else if (hash === 'networkService') {
      assetsMonitorInternalApi(id, dispatch, 'network-services');
    }
  }, [hash, id, dispatch, page, category]);

  useEffect(() => {
    if (hash !== 'hostsDiscovered') {
      setTotalItems(tableTotal?.totalRecords);
    } else {
      setTotalItems(tableTotal?.data?.totalRecords);
    }
  }, [hash, tableTotal]);


  const [currentTableData, setcurrentTableData] = useState([])
  useEffect(() => {
    if (hash === 'hostsDiscovered') {
      setcurrentTableData(table?.data);
    } else {
      setcurrentTableData(table);
    }
  }, [hash, table]);


  const createHost = () => {
    let host = $("#hostName").val();
    if (!host) {
      toastr.error("Error", "Please enter host address.");
      return false;
    }
    createHOst({ organisationId: id, ip: host }, dispatch);
  };

  const modalBodyData = (
    <div style={modalStyle} className={classes.paper}>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setAddIpModel(false)}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div>
        <h2>Add Targets</h2>
        <div>
          <div className="row">
            <label className="col-form-label col-md-4">Host Name</label>
            <div className="col-md-7">
              <input
                type="text"
                className="form-control"
                // style={{ width: 200 }}
                id="hostName"
                placeholder="Add Host"
                value={addHost}
                onChange={(e) => setHost(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="text-right mt-4">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => createHost()}
                >
                  Add Host
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event, ip, ips) => {
    let ids = [];
    ids.push(_id);
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      const data = {
        action: "markAsRemidiated",
        ipIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchIpDiscoveryAction(
        id,
        dispatch,
        page,
        category,
        data,
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);

    }

    if (event == "markAsIncorrectIp") {
      const filteredData = ips.filter(obj => selectedIds.includes(obj._id));
      const allIds = filteredData?.map((item) => item.ip);
      const data = {
        action: "markAsIncorrectIp",
        ips: allIds == 0 || undefined ? ip : allIds,
        actionNote: "",
      };
      fetchIpDiscoveryAction(
        id,
        dispatch,
        page,
        category,
        data,
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setMultiCheckTrue(false);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      ipIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchIpDiscoveryAction(
      id,
      dispatch,
      page,
      category,
      data,
    );
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      ipIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchIpDiscoveryAction(
      id,
      dispatch,
      page,
      category,
      data,
    );
    setModalTwo("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const addModal = () => {
    setModalTwo("Form Wizard modal two");
  };

  const removeToCart = (_id) => e => {
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to remove this from the Remediation List Panel?",
      type: "red",
      buttons: {
        ok: {
          text: "Remove",
          btnClass: "btn-danger",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            removeRemediationData(_id, dispatch, id).then((res) => {
            fetchIpDiscovery(id, dispatch, page, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  const addToCart = (row) => e => {
    let data = {
      "collectionName":'deltaipdetails',
      "organisationId":id,
      "recordId":row?._id,
      "moduleName":"ip_blacklist"
    }
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to add this to the Remediation List Panel ?",
      type: "green",
      buttons: {
        ok: {
          text: "Add",
          btnClass: "btn-green",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            addRemediationData(data, dispatch).then((res) => {
            fetchIpDiscovery(id, dispatch, page, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  return (
    <div>

      {/* <Header type="security" />
      <SideNav type="security" /> */}

      {!loaded ?
        <>
          <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
            <div className='col-sm-2'></div>
            {/* <div className='col-sm-9 gradinet-effect'>
              {scanDetails && (
                <BasicLineColumn
                  dates={scanDetails?.dates}
                  // openPortArray={openPortArray}
                  ipArray={ipArray}
                  color={["#008ffb", "#00e396"]}
                  yAxis="IP Discovered"
                />
              )}
            </div> */}
          </div>

          <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
            <div className='col-sm-2'></div>
            <div className='col-sm-9' style={{ padding: '0' }}>
              <div className="row_boxes row_boxes_table">
                {/* <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("all");
                        setPage(1);
                        setSelectedIds([]);
                      }}
                    >
                      Current
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("risk-accepted");
                        setPage(1);
                        setSelectedIds([]);
                      }}
                    >
                      Risk Accepted
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("false-positive");
                        setPage(1);
                        setSelectedIds([]);
                      }}
                    >
                      False Positive
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("remidiated");
                        setPage(1);
                        setSelectedIds([]);
                      }}
                    >
                      Remediated
                    </a>
                  </li>
                </ul> */}
                <div className="table_box">
                  <table className="table">
                    <thead>
                      {hash == "hostsDiscovered" && (
                        <tr>
                          <th>#</th>
                          <th>IP Address</th>
                          <th>Location</th>
                          <th>Cloud / ISP Provider</th>
                          <th>Open Ports</th>
                          <th>Network Services</th>
                          <th>Operating System</th>
                          <th>Last Updated on</th>
                        </tr>
                      )}
                      {
                        hash == 'hostsLocation' && (<tr>
                          <th>#</th>
                          <th>Host Location</th>
                          <th>Ip</th>
                          <th>Network Services</th>
                          <th>Open Ports</th>
                          <th>Cloud or ISP Provider Count</th>
                          <th>Operating System</th>
                        </tr>)
                      }
                      {
                        hash == 'cloudProvider' && (<tr>
                          <th>#</th>
                          <th>cloud or ISP Provider</th>
                          <th>Ip</th>
                          <th>Location</th>
                          <th>Network Services</th>
                          <th>Open Ports</th>
                          <th>Operating System</th>
                        </tr>)
                      }
                      {
                        hash == 'openPort' && (<tr>
                          <th>#</th>
                          <th>Open Ports</th>
                          <th>cloud or ISP Provider</th>
                          <th>Ip</th>
                          <th>Location</th>
                          <th>Network Services</th>
                          <th>Operating System</th>
                        </tr>)
                      }
                      {
                        hash == 'operatingSystem' && (<tr>
                          <th>#</th>
                          <th>Operating System</th>
                          <th>cloud or ISP Provider</th>
                          <th>Ip</th>
                          <th>Location</th>
                          <th>Network Services</th>
                          <th>Open Ports</th>
                        </tr>)
                      }
                      {
                        hash == 'networkService' && (<tr>
                          <th>#</th>
                          <th>Network Services</th>
                          <th>cloud or ISP Provider</th>
                          <th>Ip</th>
                          <th>Location</th>
                          <th>Open Ports</th>
                          <th>Operating System</th>
                        </tr>)
                      }
                    </thead>
                    <tbody>
                      {currentTableData?.map((row, index) => {
                        let services = [];
                        row?.services?.map((res, index) => {
                          if (services.indexOf(res?.service_name) == -1)
                            services.push(res?.service_name);
                        });
                        return (
                          <>
                            {hash === 'hostsLocation' && (
                              <tr>
                                <td>
                                  <span>{index + 1}</span>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <Tooltip text="Avatar Name" id="client1" />
                                    <div
                                      style={{
                                        color: "#17c2d7",
                                        fontWeight: 400,
                                        fontSize: 10,
                                      }}
                                    >
                                      {row?.hostLocation}
                                    </div>
                                  </div>
                                </td>
                                <td>{row?.ipCount}</td>
                                <td>{`${row?.networkServicesCount}`}</td>
                                <td>{row?.openPortsCount}</td>
                                <td>{row?.cloudOrISPProviderCount}</td>
                                <td>{row?.operatingSystemCount}</td>
                              </tr>
                            )}
                            {hash === 'cloudProvider' && (
                              <tr>
                                <td>
                                  <span>{index + 1}</span>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <Tooltip text="Avatar Name" id="client1" />
                                    <div
                                      style={{
                                        color: "#17c2d7",
                                        fontWeight: 400,
                                        fontSize: 10,
                                      }}
                                    >
                                      {row?.cloudOrISPProvider}
                                    </div>
                                  </div>
                                </td>
                                <td>{row?.ipCount}</td>
                                <td>{row?.locationCount}</td>
                                <td>{`${row?.networkServicesCount}`}</td>
                                <td>{row?.openPortsCount}</td>
                                <td>{row?.operatingSystemCount}</td>
                              </tr>
                            )}
                            {hash === 'openPort' && (
                              <tr>
                                <td>
                                  <span>{index + 1}</span>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <Tooltip text="Avatar Name" id="client1" />
                                    <div
                                      style={{
                                        color: "#17c2d7",
                                        fontWeight: 400,
                                        fontSize: 10,
                                      }}
                                    >
                                      {row?.openPort}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                      {row?.cloudOrISPProviderCount}
                                </td>
                                <td>{row?.ipCount}</td>
                                <td>{row?.locationCount}</td>
                                <td>{`${row?.networkServicesCount}`}</td>
                                <td>{row?.operatingSystemCount}</td>
                              </tr>
                            )}
                            {hash === 'operatingSystem' && (
                              <tr>
                                <td>
                                  <span>{index + 1}</span>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <Tooltip text="Avatar Name" id="client1" />
                                    <div
                                      style={{
                                        color: "#17c2d7",
                                        fontWeight: 400,
                                        fontSize: 10,
                                      }}
                                    >
                                      {row?.operatingSystem}
                                    </div>
                                  </div>
                                </td>
                                <td>{row?.cloudOrISPProviderCount}</td>
                                <td>{row?.ipCount}</td>
                                <td>{row?.locationCount}</td>
                                <td>{`${row?.networkServicesCount}`}</td>
                                <td>{row?.openPortsCount}</td>
                              </tr>
                            )}
                            {hash === 'networkService' && (
                              <tr>
                                <td>
                                  <span>{index + 1}</span>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <Tooltip text="Avatar Name" id="client1" />
                                    <div
                                      style={{
                                        color: "#17c2d7",
                                        fontWeight: 400,
                                        fontSize: 10,
                                      }}
                                    >
                                      {row?.networkService}
                                    </div>
                                  </div>
                                </td>
                                <td>{`${row?.cloudOrISPProviderCount}`}</td>
                                <td>{row?.ipCount}</td>
                                <td>{row?.locationCount}</td>
                                <td>{row?.openPortsCount}</td>
                                <td>{row?.operatingSystemCount}</td>
                              </tr>
                            )}
                            {hash == "hostsDiscovered" && (
                              <tr>
                                <td>
                                  <span>{index + 1}</span>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <Tooltip text="Avatar Name" id="client1" />
                                    <div
                                      style={{
                                        color: "#17c2d7",
                                        fontWeight: 400,
                                        fontSize: 10,
                                      }}
                                    >
                                      {row?.ip}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {row?.location}
                                </td>
                                <td>{`${row?.cloudOrISPProvider}`}</td>
                                <td>
                                  {row?.openPorts?.map((port) => {
                                    return <span className="badge">{port}</span>;
                                  })}
                                </td>
                                <td>
                                  {row?.networkServices?.map((service) => {
                                    return (
                                      <span className="badge badge-warning">
                                        {service}
                                      </span>
                                    );
                                  })}
                                </td>
                                <td>
                                  {row?.operatingSystem}
                                </td>
                                <td>
                                  {row?.updatedAt
                                    ? moment(row?.updatedAt).format(
                                      "DD-MM-YYYY HH:MM"
                                    )
                                    : row?.createdAt
                                      ? moment(row?.createdAt).format(
                                        "DD-MM-YYYY HH:MM"
                                      )
                                      : "NA"}
                                </td>
                                {checkPath == '?mode=assessment-mode' ?
                            <td>
                              {row?.isTicketChecked == false ? (
                                <button
                                  onClick={addToCart({
                                    ...row,
                                    remediationType: "IP Blacklist",
                                  })}
                                >
                                  <span className="fa fa-plus action_icon"></span>
                                </button>
                              ) : (
                                <button onClick={removeToCart(row?._id)}>
                                  <span className="fa fa-minus action_icon"></span>
                                </button>
                              )}
                            </td>
                            : null}
                              </tr>
                            )}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-4">
              <div class="map_box text-center">
                <VectorMap
                  containerStyle={{
                    height: "400px",
                  }}
                  map={"world_mill"}
                  backgroundColor="transparent"
                  borderColor="#fff"
                  borderOpacity={0.25}
                  borderWidth={0}
                  color="#e6e6e6"
                  regionStyle={{ initial: { fill: "#6c757d" } }}
                  markerStyle={{
                    initial: {
                      r: 5,
                      fill: "#fff",
                      "fill-opacity": 1,
                      stroke: "#000",
                      "stroke-width": 1,
                      "stroke-opacity": 0.4,
                    },
                  }}
                  markers={distinctLocationData}
                  series={{
                    regions: [
                      {
                        values: {
                          "SG": "blue"
                        },
                        attribute: "fill",
                      },
                    ],
                  }}
                  hoverOpacity={null}
                  normalizeFunction="linear"
                  zoomOnScroll={false}
                  scaleColors={["#000000", "#000000"]}
                  selectedColor="#000000"
                  selectedRegions={[]}
                  enableZoom={false}
                  hoverColor="#fff"
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="row_boxes">
                <div className="graph_line graph_summary_bk w-100 d-flex align-items-end justify-content-center">
                  <img src={rightImg} className="img-fluid" />
                  <div style={{ width: '91%' }}>
                    <ReactApexChart
                      options={options}
                      series={series}
                      type="line"
                      height={"220px"}
                    />
                  </div>
                  <img src={leftImg} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </> :
        <Loader />
      }

      <div
        className={`modal fade Form-Wizard-modal ${isModaltwo === "Form Wizard modal two" ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {action == "markAsFalsePositive"
                  ? "Mark As False Positive"
                  : "Risk Accepted"}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModalTwo("");
                  setActionNote("");
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page modal_mob">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card">
                  <div className="row clearfix">
                    <div className="col-md-12">
                      <label className="col-lg-6 col-md-12">Snooze for</label>
                      <div className="form-group ol-lg-12 col-md-12">
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={(e) => setForValue(e.target.value)}
                        >
                          <option value="forever">Forever</option>
                          <option value="3 months">3 Months</option>
                          <option value="6 months">6 Months</option>
                          <option value="12 months">12 Months</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="col-lg-12 col-md-12">Detail</label>
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea
                          onChange={(e) => setActionNote(e.target.value)}
                          value={actionNote}
                          className="form-control"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-12"
                      style={{ marginRight: "28px" }}
                    >
                      <button
                        type="button"
                        style={{ color: "black", fontWeight: "bold" }}
                        onClick={submit}
                        className="btn btn-round btn-primary mr-1 pull-right"
                      >
                        Submit
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        onClick={() => {
                          setModalTwo("");
                          setActionNote("");
                        }}
                        className="btn btn-round btn-default pull-right"
                        style={{ marginRight: "10px" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default AssetDiscovery;