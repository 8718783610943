import { 
  LOOK_ALIKE_FETCHED,
  DOMAIN_SQUATING,
  LOOK_ALIKE_FETCHED_BY_ID,
  DOMAIN_SQUATING_GRAPH } from "../actions/";

export default function (
  state = { loading: true, 
    response: { lookalike: [] }, 
  list: null,lookalikeById: [], 
  looklikegraph:[],
  scanCompleted: true },
  action
) {
  switch (action.type) {
    case LOOK_ALIKE_FETCHED:
      return {
        ...state,
        response: action.payload,
        list: action.payload.entries ? action.payload.entries : [],
        loading: false,
      };
      case DOMAIN_SQUATING_GRAPH:
      return {
        ...state,
        looklikegraph: action.payload,
      };
    case DOMAIN_SQUATING:
      return {
        ...state,
        domainSquating: action.payload,
        // list: action.payload.entries ? action.payload.entries : [],
        loading: false,
        scanCompleted: action.payload.scanCompleted
      };
    case LOOK_ALIKE_FETCHED_BY_ID:
      return {
        ...state,
        lookalikeById: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
