const initialState = {
  data: [],
  suspicious: []
};

const openCloud = (state = initialState, action) => {
  switch (action.type) {
    case "SET_OPEN_CLOUD":
      return {
        ...state,
        data: action.data,
      };
      case "SET_OPEN_CLOUD_ALL":
        return {
          ...state,
          data: action.data,
        };
      case "SET_SUSPICIOUS_CLOUD":
        return {
          ...state,
          suspicious: action.data,
        };
    default:
      return state;
  }
};

export default openCloud;
