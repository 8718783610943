import React, { Component, useEffect, useState } from "react";
import { Link, useLocation, } from "react-router-dom";
import askAI from '../../assets/images/Ask_Ai_Icon.svg'

const BottomNav = (props) => {

    const location = useLocation();


    const [pathname, setPathname] = useState(location.pathname);
    const params = new URLSearchParams(location?.search);
    const modeValue = params.get('mode');

    useEffect(() => {
        setPathname(location.pathname);
    }, [location.pathname]);
    console.log(pathname, 'pathName')

    const MenuTitle = {
        "/security": { title: "Security Healcheck", icon: "/assets/images/image-gallery/security_health.svg", subTitle: "Domain & DNS Health Check", subIcon: "/assets/images/image-gallery/domain_dns.svg" },
        "/client": { title: "Security Healcheck", icon: "/assets/images/image-gallery/security_health.svg", subTitle: "Domain & DNS Health Check", subIcon: "/assets/images/image-gallery/domain_dns.svg" },
        "/show-details/:id": { title: "Security Healcheck", icon: "/assets/images/image-gallery/security_health.svg", subTitle: "Domain & DNS Health Check", subIcon: "/assets/images/image-gallery/domain_dns.svg" },
        "/assets/": { title: "Security Healcheck", icon: "/assets/images/image-gallery/security_health.svg", subTitle: "Domain & DNS Health Check", subIcon: "/assets/images/image-gallery/domain_dns.svg" },
        "/assets": { title: "Security Healcheck", icon: "/assets/images/image-gallery/security_health.svg", subTitle: "Domain & DNS Health Check", subIcon: "/assets/images/image-gallery/domain_dns.svg" },
        "/security/domains": {
            title: "Security Healcheck", icon: "/assets/images/image-gallery/security_health.svg", subTitle: "Domain & DNS Health Check", subIcon: "/assets/images/image-gallery/domain_dns.svg",
            description: "A Domain & DNS Health Check is a comprehensive assessment of a domain's configuration and DNS records to ensure they are correctly set up and functioning properly.",
            recommendation: "To enhance your domain's security and email deliverability, enable and correctly configure DNSSEC to prevent DNS spoofing, regularly monitor its status, and manage keys effectively. For email security, create an SPF record specifying all authorized mail servers, keeping it simple and regularly validating it. Implement DMARC starting with a p=none policy to monitor traffic, then gradually enforce stricter policies (p=quarantine, p=reject). Use DMARC aggregate reports to monitor email authentication and ensure alignment with SPF and DKIM. Regularly review and adjust these settings to maintain robust protection against spoofing and phishing attempts.",
            faq: "What is DNSSEC?\nDNSSEC (Domain Name System Security Extensions) is a security protocol that adds an extra layer of security to your DNS. It helps prevent DNS spoofing and cache poisoning by ensuring the authenticity and integrity of the DNS records.\nHow do I enable DNSSEC for my domain?\n\nTo enable DNSSEC, you need to check if your domain registrar supports it. If they do, you can enable it through your domain registrar’s control panel. Ensure that you correctly configure DNSSEC and regularly monitor its status.\n\nWhat is an SPF record?\n\nAn SPF (Sender Policy Framework) record is a DNS record that specifies which mail servers are authorized to send emails on behalf of your domain. It helps prevent email spoofing and improves email deliverability.\nHow do I create an SPF record?\nTo create an SPF record, list all authorized mail servers in a TXT record in your DNS settings. Ensure the SPF record is concise and includes all your email-sending services."
        },
        "/security/sub-domain": { title: "Security Healcheck", icon: "/assets/images/image-gallery/security_health.svg", subTitle: "Subdomain & Dangling Subdomain", subIcon: "/assets/images/image-gallery/host_server.svg" },
        "/security/known-vulnerabilities": { title: "Security Healcheck", icon: "/assets/images/image-gallery/security_health.svg", subTitle: "Known Vulnerabilities", subIcon: "/assets/images/image-gallery/known_vulberability.svg" },
        "/security/ssl-certificates": { title: "Security Healcheck", icon: "/assets/images/image-gallery/security_health.svg", subTitle: "SSL Certificates", subIcon: "/assets/images/image-gallery/ssl_certificate.svg" },
        "/security/web-technology": { title: "Security Healcheck", icon: "/assets/images/image-gallery/security_health.svg", subTitle: "Web Technologies", subIcon: "/assets/images/image-gallery/web_technologies.svg" },
        "/brand": { title: "Brand Security", icon: "/assets/images/image-gallery/brand_security.svg", subTitle: "Mobile Application", subIcon: "/assets/images/image-gallery/Icon.svg" },
        "/brand/mobile-apps": { title: "Brand Security", icon: "/assets/images/image-gallery/brand_security.svg", subTitle: "Mobile Application", subIcon: "/assets/images/image-gallery/Icon.svg" },
        "/brand/look-alike": { title: "Brand Security", icon: "/assets/images/image-gallery/brand_security.svg", subTitle: "Look-alike Application", subIcon: "/assets/images/image-gallery/Icon_1.svg" },
        "/brand/social-media": { title: "Brand Security", icon: "/assets/images/image-gallery/brand_security.svg", subTitle: "Look-alike Application", subIcon: "/assets/images/image-gallery/Icon_Selected.svg" },
        "/breach": { title: "Breach Detection", icon: "/assets/images/image-gallery/breach.svg", subTitle: "Stolen Credentials", subIcon: "/assets/images/image-gallery/stolen_credentials.svg" },
        "/breach/stolen-credentials": { title: "Breach Detection", icon: "/assets/images/image-gallery/breach.svg", subTitle: "Stolen Credentials", subIcon: "/assets/images/image-gallery/stolen_credentials.svg" },
        "/breach/malware-credentials": { title: "Breach Detection", icon: "/assets/images/image-gallery/breach.svg", subTitle: "Malware Infected Computers", subIcon: "/assets/images/image-gallery/malware_infected_computers.svg" },
        "/breach/source-code": { title: "Breach Detection", icon: "/assets/images/image-gallery/breach.svg", subTitle: "Source Code Leakage", subIcon: "/assets/images/image-gallery/source_code_leakage.svg" },
        "/breach/leaked-sessions": { title: "Breach Detection", icon: "/assets/images/image-gallery/breach.svg", subTitle: "Leaked Sessions", subIcon: "/assets/images/image-gallery/leaked_session.svg" },
        "/breach/cloud-bucket": { title: "Breach Detection", icon: "/assets/images/image-gallery/breach.svg", subTitle: "Open Cloud Buckets", subIcon: "/assets/images/image-gallery/open_cloud_buckets.svg" },
        "/breach/ip-blacklist": { title: "Breach Detection", icon: "/assets/images/image-gallery/breach.svg", subTitle: "IP Blacklists", subIcon: "/assets/images/image-gallery/ip_blacklist.svg" }
    }

    const tabUpDown = (e) => {
        e.preventDefault();
        var tab_uppper_button = document.getElementById('tab_uppper_button');
        var mypanel = document.getElementById('bottom_panel');

        tab_uppper_button.classList.toggle('active_circle');
        mypanel.classList.toggle('active_bottom_panel');
    }

    const onTabClick = (tabId) => (e) => {
        console.log(e.target);
        let buttons = document.getElementsByClassName('nav-link');
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].classList.remove('active');
        }
        document.getElementById(tabId).classList.toggle('active');

        let tabs = document.getElementsByClassName('tab-pane');
        for (var i = 0; i < tabs.length; i++) {
            tabs[i].classList.remove('show');
            tabs[i].classList.remove('active');
        }
        document.getElementById(tabId + '-pane').classList.toggle('show');
        document.getElementById(tabId + '-pane').classList.toggle('active');
    }

    const convertNewlinesToHtml = (text) => {
        return text.split('\n').map((line, index) => (
            <p key={index}>{line}</p>
        ));
    };

    return (
        <>
                    <div class="bottom_panel" id="bottom_panel">
                        <div class="bottom_panel_header">
                            <div class="boxA">
                                <div class="upper_box">
                                    <img src={MenuTitle[pathname]?.icon} class="img-fluid" alt="" />
                                    <div class="text_box">
                                        <p>{MenuTitle[pathname]?.title}</p>
                                        <div
                                            class="progress"
                                            role="progressbar"
                                            aria-label="Basic example"
                                            aria-valuenow="100"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            <div
                                                class={`progress-bar ${modeValue == 'report-mode' ?
                                                    'green_progress_bar' : modeValue == 'assessment-mode' ?
                                                        'red_progress_bar' : modeValue == 'settings-mode' ? 'yellow_progress_bar' : 'red_progress_bar'}`}
                                                style={{ width: "100%" }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="inner_content">
                                    <p>{MenuTitle[pathname]?.subTitle}</p>
                                    <img src={MenuTitle[pathname]?.subIcon} class="img-fluid" alt="" />
                                </div>
                            </div>

                            <div class="tab_boxes">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" onClick={onTabClick("home-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/image-gallery/description.svg" class="img-fluid" alt="" />
                                                <p>Description</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" onClick={onTabClick("profile-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/image-gallery/guidance.svg" class="img-fluid" alt="" />
                                                <p>Guidance</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false" onClick={onTabClick("contact-tab")}>
                                            <div class="inner_tab_button">
                                                <img src={askAI} class="img-fluid" alt="" />
                                                <p>ASK AI</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation" style={{ visibility: "hidden" }}>
                                        <button class="nav-link" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#disabled-tab-pane" type="button" role="tab" aria-controls="disabled-tab-pane" aria-selected="false" disabled onClick={onTabClick("disabled-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/image-gallery/solution_center.svg" class="img-fluid" alt="" />
                                                {/* <p>Solution Center</p> */}
                                            </div>
                                        </button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                        <p>
                                            {MenuTitle[pathname]?.description}
                                        </p>

                                    </div>
                                    <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                        <p>
                                            {MenuTitle[pathname]?.recommendation}
                                        </p>
                                    </div>
                                    <div class="tab-pane fade tab-chat"
                                        id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
                                        {/* <p>
                                    {MenuTitle[pathname]?.faq && convertNewlinesToHtml(MenuTitle[pathname].faq)}
                               </p> */}
                                        <p>
                                            Ask me about anything
                                        </p>
                                        <input></input>
                                    </div>
                                    <div class="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabIndex="0">
                                        <h5>lorem</h5>
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae eveniet reiciendis sit ipsum enim blanditiis labore consequatur temporibus vero aperiam voluptas, aspernatur modi molestias maiores est, laborum error eos ut?
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veritatis eaque hic ea obcaecati. Facilis repellendus fugiat natus aspernatur nobis? Ipsa placeat expedita numquam rem veniam quasi rerum reprehenderit est. Consequatur.
                                        </p>
                                        <h5>lorem</h5>
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae eveniet reiciendis sit ipsum enim blanditiis labore consequatur temporibus vero aperiam voluptas, aspernatur modi molestias maiores est, laborum error eos ut?
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veritatis eaque hic ea obcaecati. Facilis repellendus fugiat natus aspernatur nobis? Ipsa placeat expedita numquam rem veniam quasi rerum reprehenderit est. Consequatur.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="boxA boxB">
                                <div class="upper_box">
                                    <div class="text_box">
                                        <span>Current Mode:</span>
                                        <p> {modeValue == 'report-mode' ?
                                            'Report' : modeValue == 'assessment-mode' ?
                                                'ASSESSMENT' : modeValue == 'settings-mode' ? 'Settings' : 'ASSESSMENT'} </p>
                                        <div
                                            class="progress"
                                            role="progressbar"
                                            aria-label="Basic example"
                                            aria-valuenow="100"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            <div
                                                class={`progress-bar ${modeValue == 'report-mode' ?
                                                    'green_progress_bar' : modeValue == 'assessment-mode' ?
                                                        'red_progress_bar' : modeValue == 'settings-mode' ? 'yellow_progress_bar' : 'red_progress_bar'}`}
                                                style={{ width: "100%" }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="inner_content">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="up_down_circle up_down_circle_bottom" id="tab_uppper_button">
                        <a onClick={tabUpDown}>
                            <img src="../assets/images/image-gallery/arrow_icon.png" class="img-fluid" alt="" />
                            <img src="../assets/images/image-gallery/arrow_icon.png" class="img-fluid" alt="" />
                        </a>
                    </div>

        </>
    )
}

export default BottomNav;