import {
  FETCH_INTEGRATION,
  FETCHING_ALERTS,
  ALERTS_FETCHED,
} from "../actions/";

const initialState = {
  loading: true,
  response: null,
};

const integration = function (state = initialState, action) {
  switch (action.type) {
    case FETCH_INTEGRATION:
      return {
        ...state,
        response: action.payload,
        loading: true,
      };
    case FETCHING_ALERTS:
      return {
        ...state,
        response: null,
        loading: true,
      };
    case ALERTS_FETCHED:
      return {
        ...state,
        response: action.payload,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
export default integration;
