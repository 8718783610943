import axios from "axios";
import { API_URL, DEHASH_API_URL } from "../utils/constant";
// import Cookies from "universal-cookie";
import { PASTEBIN_RECORD_FETCHED } from "./index";
// import { toastr } from 'react-redux-toastr';



export const fetchPastebinResult = (props, dispatch) => {
    if(props){
      axios
        .get(`${API_URL}/pastebin?organisationId=${props}`, {})
        .then((res) => {
            //console.log(res,'pastebin response')
          dispatch({
            type: PASTEBIN_RECORD_FETCHED,
            payload: res.data,
          });
          
        });
    }
  };