import React, { useEffect, useState, useRef } from "react";
import GaugeChart from "react-gauge-chart";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchDashboardReport, fetchReportSetting, updateReportSetting } from "../../actions/summary";
import PieChart from "../../components/common/dashboard12/piechart.js";
import { VectorMap } from "react-jvectormap";
import { countryToCode } from "../../utils/countryCode";
import SparkLineBoxChart from "../common/dashboard12/lineChart";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import AccessPage from "../Shared/accessPage";
import Header from '../resuableComponent/header';
import Loader from "../resuableComponent/loader";
import { containerClasses } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import rightImg from "../../assets/images/right-clock.png";
import { useLocation } from "react-router-dom";
import leftImg from "../../assets/images/left-clock.png";
import { teal } from "@mui/material/colors";
import DNS from "../../assets/images/DNS_Dashboard.svg";
import Known from "../../assets/images/VULNERAB_Dashboard.svg";
import SSL from "../../assets/images/SSLCERT_Dashboard.svg";
import Source from "../../assets/images/LEAKAGE_Dashboard.svg";
import Assets from "../../assets/images/ASSETSDISCOV_Dashboard.svg";
import Stolen from "../../assets/images/STOLENCRED_Dashboard.svg";
import Black from "../../assets/images/BLACKIP_Dashboard.svg";
import FakeMob from "../../assets/images/FAKESME_Dashboard.svg";
import FakeApp from "../../assets/images/FAKEAPP_Dashboard.svg";
import SUBDOM from "../../assets/images/SUBDOM_Dashboard.svg";
import Host from "../../assets/images/HOSTSDISC_Dashboard.svg";
import HostLocation from "../../assets/images/HOSTLOC_Dashboard.svg";
import CloudProviderSvg from "../../assets/images/CLOUDPROV_Dashboard.svg";
import OpenPorts from "../../assets/images/OPENPORTS_Dashboard.svg";
import OpertingSystem from "../../assets/images/OS_Dashboard.svg";
import networkService from "../../assets/images/NETWSERV_Dashboard.svg";



var Model = require("./model");


const Dashboard = () => {
    const dispatch = useDispatch();
    const { orgId } = useParams();
    const URl = useLocation();
    // const id = JSON.parse(localStorage.getItem("user")).organisationId;
    const [riskScore, setRiskScore] = useState("");
    const [riskScoreNumber, setRiskScoreValue] = useState("");
    const [fisrtLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(false);

    const [riskScoreVA, setRiskScoreVa] = useState("");
    const [riskScoreExpiredSSL, setRiskScoreExpiredSSL] = useState("");
    const [riskScoreVulnerableSSL, setRiskScoreVulnerableSSL] = useState("");
    const [riskScoreDangling, setRiskScoreDangling] = useState("");
    const params = new URLSearchParams(URl?.search);
    const [checkPath, setCheckPath] = useState('');
    const modeValue = params.get('mode');

    const id = useSelector(
        (state) =>
            state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
    );
    const client = useSelector(
        (state) => state?.summary?.localClient || state?.summary?.clients?.data?.[0]
    );

    const reportSetting = useSelector(
        (state) => state?.summary?.reportSetting[0] || state?.summary?.reportSetting
    );


    useEffect(() => {
        if (orgId)
            fetchDashboardReport(orgId, dispatch);
        else
            fetchDashboardReport(id, dispatch);
    }, [id]);

    useEffect(() => {
        if (id) {
            fetchReportSetting(id, dispatch)
        }
    }, [id, dispatch])

    useEffect(() => {
        setCheckPath(URl.search)
    }, [URl.search])

    let summary = useSelector((state) => state?.summary?.dashboardSummary);
    let summaryLoading = useSelector((state) => state?.summary?.loading);

    let securityGraphValueASM = summary?.all?.Rating?.map(
        (row) => row?.attackSurfaceSecurityImpact
    );
    let securityGraphValueData = summary?.all?.Rating?.map(
        (row) => row?.darkWebSecurityImpact
    );
    let securityGraphValueBrand = summary?.all?.Rating?.map(
        (row) => row?.brandSecuritySecurityImpact
    );
    let securityGraphDate = summary?.all?.Rating?.map((row) =>
        moment(row?.createdAt).format("DD-MM-YYYY")
    );

    const options = {
        // grid: {
        //     strokeColors: ['#fff'], // Set the color of the grid lines
        //     zindex: -1,
        //   },
        grid: {
            show: true,
            borderColor: '#fff',
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: false
                }
            },
            row: {
                colors: undefined,
                opacity: 0
            },
            column: {
                colors: undefined,
                opacity: 0
            },
        },

        chart: {
            id: 'mixed-chart',
            toolbar: {
                show: false // Hide the toolbar icons
            },
            background: 'none' // Hide the background line
        },
        plotOptions: {
            line: {
                // Add padding to the top of the chart
                offsetY: 10
            }
        },
        colors: ['#b0f127', '#ffff00', '#ff0000'],
        labels: securityGraphDate,
        xaxis: {
            labels: {
                show: false // Hide the x-axis labels
            },
            axisBorder: {
                show: true, // Show the x-axis line
                offsetY: 10 // Add padding to the top of the chart
            }
        },
        yaxis: {
            labels: {
                show: false // Hide the y-axis labels
            },
            axisBorder: {
                show: false, // Show the y-axis line
                offsetX: 0 // Add padding to the left of the chart
            }
        },
        legend: {
            show: false, // Show legends
        },
        markers: {
            size: 1, // Set the size of indicators
        }
    };

    const series = [
        {
            name: 'Attack Surface',
            type: 'line',
            data: securityGraphValueASM,
        },
        {
            name: 'Data Exposure',
            type: 'line',
            data: securityGraphValueData,
        },
        {
            name: 'Brand Security', // You can provide a name for the new line if needed
            type: 'line',
            data: securityGraphValueBrand, // Add your new data points here
        }
    ];
    const rating = summary?.all?.totalScoreDashboard;

    const valueResult = Math.floor(
        Number(rating?.attackSurfaceSecurityImpact) +
        Number(rating?.darkWebSecurityImpact) +
        Number(rating?.brandSecuritySecurityImpact)
    );


    let inventoryData = summary?.CVEDetails?.reduce((acc, curr) => {
        const str = JSON.stringify(curr?.ip);
        if (acc[str]) {
            acc[str].push(curr);
        } else {
            acc[str] = [curr];
        }
        return acc;
    }, {});

    let totalNotCompliant = 0;
    Object.keys(inventoryData || {}).map((key) => {
        let record = inventoryData[key];

        const criticalRecordData = record?.filter((row) => row?.results?.cvss >= 9);
        const highRecordData = record?.filter(
            (row) => row?.results?.cvss >= 7 && row?.results?.cvss < 9
        );
        const mediumRecordData = record?.filter(
            (row) => row?.results?.cvss >= 4 && row?.results?.cvss < 7
        );
        const lowRecordData = record?.filter((row) => row?.results?.cvss < 4);

        if (criticalRecordData?.length > 0) {
            totalNotCompliant++;
        } else if (highRecordData?.length > 0) {
            totalNotCompliant++;
        } else if (mediumRecordData?.length > 0) {
            totalNotCompliant++;
        } else if (lowRecordData?.length > 0) {
            totalNotCompliant++;
        }
    });

    let squareRootOfComplaint = Math.sqrt(totalNotCompliant?.toFixed(2));
    let squareRootOfBase = Math.sqrt(summary?.assetsDiscovery?.toFixed(2));

    let riskScoreValue = parseFloat(
        ((squareRootOfComplaint / squareRootOfBase) * 100)?.toFixed(2)
    );

    let riskTitle = "";
    if (fisrtLoad && !isNaN(riskScoreValue)) {
        setRiskScoreValue(riskScoreValue);
        if (riskScoreValue == 0) {
            setRiskScore("A1");
        } else if (riskScoreValue < 20) {
            setRiskScore("A2");
        } else if (riskScoreValue < 35) {
            setRiskScore("B1");
        } else if (riskScoreValue < 55) {
            setRiskScore("B2");
        } else if (riskScoreValue < 75) {
            setRiskScore("C1");
        } else {
            setRiskScore("C2");
        }
        setFirstLoad(false);
    }


    let location = summary?.assetMap?.map((value) => value?.location);
    location = location?.filter(function (element) {
        return element !== undefined;
    });
    let counts = location?.reduce((acc, curr) => {
        if (curr) {
            const str = JSON.stringify(curr["country"]);
            acc[str] = (acc[str] || 0) + 1;
            return acc;
        } else return acc;
    }, {});
    const distinctLocationData = summary?.all?.assetMap;
    const values = distinctLocationData?.map(location => {
        return { [location.region]: "#3fa9f5" };
    });
    let ipArray = [];
    for (
        let details = 0;
        details < summary?.scanDetailArray?.scanDetails?.length;
        details++
    ) {
        ipArray.push(summary?.scanDetailArray?.scanDetails[details]?.length);
    }
    const generateReport = () => {
        if (loading) {
            return false;
        }
        setLoading(true);
        Model.default.generateReport(id).then((response) => {
            setLoading(false);
            if (!response.error) {
                window.open(response.data.docx);
            }
        });
    };

    // Generate Grade for Passive VA


    let passiveVaComplaint = Math.sqrt(summary?.ipVulnerability?.toFixed(2));
    let passiveVaBase = Math.sqrt(summary?.assetsDiscovery);

    let passiveVaValue = parseFloat(
        ((passiveVaComplaint / passiveVaBase) * 100)?.toFixed(2)
    );

    if (!isNaN(passiveVaValue) && riskScoreVA == "") {
        if (passiveVaValue == 0) {
            setRiskScoreVa("A1");
        } else if (passiveVaValue < 20) {
            setRiskScoreVa("A2");
        } else if (passiveVaValue < 35) {
            setRiskScoreVa("B1");
        } else if (passiveVaValue < 55) {
            setRiskScoreVa("B2");
        } else if (passiveVaValue < 75) {
            setRiskScoreVa("C1");
        } else {
            setRiskScoreVa("C2");
        }
    }

    // End of generate grade VA
    let squareRootOfExpiredSSLComplaint =
        Math.sqrt(summary?.SSlEXpired)?.toFixed(2);
    let squareRootOfExpiredSSLBase = Math.sqrt(
        summary?.SSlCertificate?.toFixed(2)
    );

    let expiredSSLValue = parseFloat(
        (
            (squareRootOfExpiredSSLComplaint / squareRootOfExpiredSSLBase) *
            100
        )?.toFixed(2)
    );

    if (!isNaN(expiredSSLValue) && riskScoreExpiredSSL == "") {
        if (expiredSSLValue == 0) {
            setRiskScoreExpiredSSL("A1");
        } else if (expiredSSLValue < 20) {
            setRiskScoreExpiredSSL("A2");
        } else if (expiredSSLValue < 35) {
            setRiskScoreExpiredSSL("B1");
        } else if (expiredSSLValue < 55) {
            setRiskScoreExpiredSSL("B2");
        } else if (expiredSSLValue < 75) {
            setRiskScoreExpiredSSL("C1");
        } else {
            setRiskScoreExpiredSSL("C2");
        }
    }

    // End of generate grade for expired SSL

    // Generate Grade for Vulnerable SSL

    let vulnerableSSLComplaint = Math.sqrt(
        (summary?.SSlVulnerable)?.toFixed(2)
    );

    let vulnerableSSLValue = parseFloat(
        ((vulnerableSSLComplaint / squareRootOfExpiredSSLBase) * 100)?.toFixed(2)
    );


    if (!isNaN(vulnerableSSLValue) && riskScoreVulnerableSSL == "") {
        // setRiskScoreValue(riskScoreValue);
        if (vulnerableSSLValue == 0) {
            setRiskScoreVulnerableSSL("A1");
        } else if (vulnerableSSLValue < 20) {
            setRiskScoreVulnerableSSL("A2");
        } else if (vulnerableSSLValue < 35) {
            setRiskScoreVulnerableSSL("B1");
        } else if (vulnerableSSLValue < 55) {
            setRiskScoreVulnerableSSL("B2");
        } else if (vulnerableSSLValue < 75) {
            setRiskScoreVulnerableSSL("C1");
        } else {
            setRiskScoreVulnerableSSL("C2");
        }
    }


    const dangling = summary?.danglingData?.filter(
        (row) =>
            row?.dnsData?.ip &&
            ((row?._doc?.techStack?.urls?.[`https://${row?._doc?.subDomainName}/`]
                ?.status || "NA") == "404" ||
                (row?._doc?.techStack?.urls?.[`https://${row?._doc?.subDomainName}/`]
                    ?.status || "NA") == "NA")
    );

    let danglingBase = Math.sqrt(summary?.subdomains?.toFixed(2));

    let danglingValue = parseFloat(
        ((dangling?.length / danglingBase) * 100)?.toFixed(2)
    );


    if (!isNaN(danglingValue) && riskScoreDangling == "") {
        // setRiskScoreValue(riskScoreValue);
        if (danglingValue == 0) {
            setRiskScoreDangling("A1");
        } else if (danglingValue < 20) {
            setRiskScoreDangling("A2");
        } else if (danglingValue < 35) {
            setRiskScoreDangling("B1");
        } else if (danglingValue < 55) {
            setRiskScoreDangling("B2");
        } else if (danglingValue < 75) {
            setRiskScoreDangling("C1");
        } else {
            setRiskScoreDangling("C2");
        }
    }

    // Assets data
    const CloudProvider = summary?.assetMap?.map(
        (res) => res?.autonomous_system?.description
    );
    // for loop for getting the length of the nested array

    const lengthOfcloudLocations = CloudProvider?.filter(
        (item, index, self) => self.indexOf(item) === index
    );

    const distinctLocation = summary?.assetMap?.map((res) => res.location?.country);
    // for loop for getting the length of the nested array

    const lengthOfLocation = distinctLocation?.filter(
        (item, index, self) => self.indexOf(item) === index
    );

    const operatingSystem = summary?.assetMap?.filter(
        (row) => row?.operating_system?.product
    );



    const [containerClass, setContainerClass] = useState("data-container");
    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                setContainerClass("data-container active");
            }, 300);
        }
    }, [loading]);


    return (
        <>
            <div className="dashboard_remediation side_bar_map_logo">
                <div className="box">
                    <div className="circle_box">
                        <div className="circle_box_one">
                            <a href="https://liveattackmap.azurewebsites.net/" target="_blank" className="w-100">
                                <img
                                    src="https://liveattackmap.azurewebsites.net/images/map-elements/Map_Side_arrow.svg"
                                    class="img-fluid"
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                    <p>Threat Map</p>
                </div>
            </div>
            {loading ? <Loader /> :
                <section class="dashboard_main before_img_logo">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row_boxes row_boxes_summary">
                                    <div
                                        className={`box red_box ${summary?.dnsRecords === 0 ? 'error' : ''}`}
                                    >
                                        <Link to='/security/domains'>
                                            <div class="img_box">
                                                <img
                                                    src={DNS}
                                                    class="img-fluid" />
                                            </div>
                                            <div class="text_box_one">
                                                <h5>{summary?.dnsRecords}</h5>
                                                <p>DNS Records</p>
                                            </div>
                                            <div class="end_text">
                                                {/* <h6>{summary?.DNSHealthError || "--"}<span>Errors</span></h6>
                                                <h6>{summary?.DNSHealthWarning || "--"}<span>Warings</span></h6> */}
                                            </div>
                                        </Link>
                                        <span
                                            className={`box_after  
                                         ${reportSetting?.securityHealthCheck?.dnsRecord == true && modeValue == 'report-mode' ? 'box-active' : ''}`}
                                            onClick={() => {
                                                if (modeValue == 'report-mode') {
                                                    const newData = {
                                                        dnsRecord: !reportSetting?.securityHealthCheck?.dnsRecord
                                                    };
                                                    updateReportSetting(id, dispatch, { "securityHealthCheck": newData });
                                                }
                                            }}
                                        >

                                        </span>
                                    </div>
                                    <div
                                        className={`box red_box ${summary?.asm?.CVEDetails == 0 ? 'error' : ''} ${reportSetting?.securityHealthCheck?.knownVulnerability_passiveVa == true && modeValue == 'report-mode' ? 'box-active' : ''}`}>
                                        <Link to='/security/known-vulnerabilities'>
                                            <div class="img_box">
                                                <img src={Known} class="img-fluid" />
                                            </div>
                                            <div class="text_box_one">
                                                <h5>{summary?.asm?.CVEDetails}</h5>
                                                <p>Known
                                                    <span>Vulnerabilities</span>
                                                </p>
                                            </div>
                                            {/* <div class="end_text">
                                                <h6>{summary?.criticalKnownVuln }<span>Criticial</span></h6>
                                                <h6>{summary?.highKnownVuln }<span>High</span></h6>
                                            </div> */}
                                        </Link>
                                        <span
                                            className={`box_after ${reportSetting?.securityHealthCheck?.knownVulnerability_passiveVa == true && modeValue == 'report-mode' ? 'box-active' : ''}`}
                                            onClick={() => {
                                                if (modeValue == 'report-mode') {
                                                    const newData = {

                                                        knownVulnerability_passiveVa: !reportSetting?.securityHealthCheck?.knownVulnerability_passiveVa
                                                    };
                                                    updateReportSetting(id, dispatch, { "securityHealthCheck": newData });
                                                }
                                            }}
                                        >

                                        </span>
                                    </div>
                                    <div
                                        className={`box red_box ${summary?.asm?.SSlCertificate == 0 ? 'error' : ''} ${reportSetting?.securityHealthCheck?.sslCertificates == true && modeValue == 'report-mode' ? 'box-active' : ''}`}>
                                        <Link to='/security/ssl-certificates'>
                                            <div class="img_box">
                                                <img src={SSL} class="img-fluid" />
                                            </div>
                                            <div class="text_box_one">
                                                <h5>{summary?.asm?.SSlCertificate}</h5>
                                                <p>SSL Certificates</p>
                                            </div>
                                            <div class="end_text">
                                                <h6>{summary?.asm?.SSlEXpired || 0}<span>Expired</span></h6>
                                                <h6>{summary?.asm?.SSlVulnerable || 0}<span>Vulnerable</span></h6>
                                            </div>
                                        </Link>
                                        <span
                                            onClick={() => {
                                                if (modeValue == 'report-mode') {
                                                    const securityHealthCheck = {
                                                        sslCertificates: !reportSetting?.securityHealthCheck?.sslCertificates
                                                    };
                                                    updateReportSetting(id, dispatch, { "securityHealthCheck": securityHealthCheck });
                                                }
                                            }}
                                            className={`box_after ${reportSetting?.securityHealthCheck?.sslCertificates == true && modeValue == 'report-mode' ? 'box-active' : ''}`}>

                                        </span>
                                    </div>
                                    <div
                                        className={`box red_box ${summary?.data?.SourceCode == 0 ? 'error' : ''} ${reportSetting?.securityHealthCheck?.sourceCodeLeakage_ == true && modeValue == 'report-mode' ? 'box-active' : ''}`}>

                                        <Link to='/breach/source-code'>
                                            <div class="img_box">
                                                <img src={Source} class="img-fluid" />
                                            </div>
                                            <div class="text_box_one">
                                                <h5>{summary?.data?.SourceCode}</h5>
                                                <p>Source Code <span>Leakage</span></p>
                                            </div>
                                            <div class="end_text">
                                                {/* <h6>--<span>Verified</span></h6>
                                                <h6>--<span>Not Verified</span></h6> */}
                                            </div>
                                        </Link>
                                        <span
                                            className={`box_after  ${reportSetting?.securityHealthCheck?.sourceCodeLeakage_ == true && modeValue == 'report-mode' ? 'box-active' : ''}`}
                                            onClick={() => {
                                                if (modeValue == 'report-mode') {
                                                    const newData = {
                                                        sourceCodeLeakage_: !reportSetting?.securityHealthCheck?.sourceCodeLeakage_
                                                    };
                                                    updateReportSetting(id, dispatch, { "securityHealthCheck": newData });
                                                }
                                            }}
                                        >

                                        </span>
                                    </div>
                                    <div
                                        className={`box red_box ${summary?.asm?.assetsDiscovery == 0 ? 'error' : ''} ${reportSetting?.securityHealthCheck?.AssetDiscovered_IpDiscoveryOpenPorts == true && modeValue == 'report-mode' ? 'box-active' : ''}`}>
                                        <Link to='/assets/#hostsDiscovered'>
                                            <div class="img_box">
                                                <img src={Assets} class="img-fluid" />
                                            </div>
                                            <div class="text_box_one">
                                                <h5>{summary?.asm?.assetsDiscovery}</h5>
                                                <p>Assets Discovered</p>
                                            </div>
                                            <div class="text_box_one">
                                                {/* <h5>{summary?.OpenPort }</h5>
                                                <p>Open Ports</p> */}
                                            </div>
                                        </Link>
                                        <span
                                            className={`box_after ${reportSetting?.securityHealthCheck?.AssetDiscovered_IpDiscoveryOpenPorts == true && modeValue == 'report-mode' ? 'box-active' : ''}`}
                                            onClick={() => {
                                                if (modeValue == 'report-mode') {
                                                    const newData = {

                                                        AssetDiscovered_IpDiscoveryOpenPorts: !reportSetting?.securityHealthCheck?.AssetDiscovered_IpDiscoveryOpenPorts
                                                    };
                                                    updateReportSetting(id, dispatch, { "securityHealthCheck": newData });
                                                }
                                            }}
                                        >

                                        </span>
                                    </div>
                                    <div
                                        className={`box red_box ${summary?.data?.LeakCredential == 0 ? 'error' : ''} ${reportSetting?.breachDetection?.stolenCredentials == true && modeValue == 'report-mode' ? 'box-active' : ''}`}>

                                        <Link to='/breach/stolen-credentials'>
                                            <div class="img_box">
                                                <img src={Stolen} class="img-fluid" />
                                            </div>
                                            <div class="text_box_one">
                                                <h5>{summary?.data?.LeakCredential}</h5>
                                                <p>Stolen Credentials</p>
                                            </div>
                                            <div class="end_text end_text_blank">
                                                {/* <p>--</p>
                                                <p>-</p> */}
                                            </div>
                                        </Link>
                                        <span
                                            className={`box_after ${summary?.data?.LeakCredential == 0 ? 'error' : ''} ${reportSetting?.breachDetection?.stolenCredentials == true && modeValue == 'report-mode' ? 'box-active' : ''}`}
                                            onClick={() => {
                                                if (modeValue == 'report-mode') {
                                                    const newData = {

                                                        stolenCredentials: !reportSetting?.breachDetection?.stolenCredentials
                                                    };
                                                    updateReportSetting(id, dispatch, { "breachDetection": newData });
                                                }
                                            }}
                                        >

                                        </span>
                                    </div>
                                    <div
                                        className={`box red_box ${summary?.asm?.blackListedIp == 0 ? 'error' : ''} ${reportSetting?.breachDetection?.ipBlacklist == true && modeValue == 'report-mode' ? 'box-active' : ''}`}>

                                        <Link to='/breach/ip-blacklist'>
                                            <div class="img_box">
                                                <img src={Black} class="img-fluid" />
                                            </div>
                                            <div class="text_box_one">
                                                <h5>{summary?.asm?.blackListedIp}</h5>
                                                <p>Blacklisted IP's</p>
                                            </div>
                                            <div class="text_box_one">
                                                {/* <h5>00</h5>
                                                <p>Unlisting<span>Processes</span></p> */}
                                            </div>
                                        </Link>
                                        <span
                                            className={`box_after  ${reportSetting?.breachDetection?.ipBlacklist == true && modeValue == 'report-mode' ? 'box-active' : ''}`}
                                            onClick={() => {
                                                if (modeValue == 'report-mode') {
                                                    const newData = {

                                                        ipBlacklist: !reportSetting?.breachDetection?.ipBlacklist
                                                    };
                                                    updateReportSetting(id, dispatch, { "breachDetection": newData });
                                                }
                                            }}
                                        >

                                        </span>
                                    </div>
                                    <div
                                        className={`box red_box ${summary?.brand?.FakeApps == 0 ? 'error' : ''} ${reportSetting?.brandSecurity?.fakeMobileApplications == true && modeValue == 'report-mode' ? 'box-active' : ''}`}>

                                        <Link to='/brand/mobile-apps'>
                                            <div class="img_box">
                                                <img src={FakeApp} class="img-fluid" />
                                            </div>
                                            <div class="text_box_one">
                                                <h5>{summary?.brand?.FakeApps}</h5>
                                                <p>Fake Mobile App's</p>
                                            </div>
                                            <div class="end_text end_text_blank">
                                                {/* <p>--</p>
                                                <p>-</p> */}
                                            </div>
                                        </Link>
                                        <span
                                            className={`box_after ${summary?.brand?.FakeApps == 0 ? 'error' : ''} ${reportSetting?.brandSecurity?.fakeMobileApplications == true && modeValue == 'report-mode' ? 'box-active' : ''}`}
                                            onClick={() => {
                                                if (modeValue == 'report-mode') {
                                                    const newData = {

                                                        fakeMobileApplications: !reportSetting?.brandSecurity?.fakeMobileApplications
                                                    };
                                                    updateReportSetting(id, dispatch, { "brandSecurity": newData });
                                                }
                                            }}>

                                        </span>
                                    </div>
                                    <div
                                        className={`box red_box ${summary?.brand?.SocialMedia == 0 ? 'error' : ''} ${reportSetting?.brandSecurity?.fakeSocialMediaProfiles == true && modeValue == 'report-mode' ? 'box-active' : ''}`}>

                                        <Link to='/brand/social-media'>
                                            <div class="img_box">
                                                <img src={FakeMob} class="img-fluid" />
                                            </div>
                                            <div class="text_box_one">
                                                <h5>{summary?.brand?.SocialMedia}</h5>
                                                <p>Fake Social Profiles</p>
                                            </div>
                                            <div class="end_text end_text_blank">
                                                {/* <p>--</p>
                                                <p>-</p> */}
                                            </div>
                                        </Link>
                                        <span
                                            className={`box_after ${summary?.brand?.SocialMedia == 0 ? 'error' : ''} ${reportSetting?.brandSecurity?.fakeSocialMediaProfiles == true && modeValue == 'report-mode' ? 'box-active' : ''}`}
                                            onClick={() => {
                                                if (modeValue == 'report-mode') {
                                                    const newData = {

                                                        fakeSocialMediaProfiles: !reportSetting?.brandSecurity?.fakeSocialMediaProfiles
                                                    };
                                                    updateReportSetting(id, dispatch, { "brandSecurity": newData });
                                                }
                                            }}>

                                        </span>
                                    </div>
                                    <div

                                        className={`box red_box ${summary?.asm?.subdomains == 0 ? 'error' : ''} ${reportSetting?.securityHealthCheck?.subDomain_danglingSubDomain == true && modeValue == 'report-mode' ? 'box-active' : ''}`}>

                                        <Link to='/security/sub-domain'>
                                            <div class="img_box">
                                                <img src={SUBDOM} class="img-fluid" />
                                            </div>
                                            <div class="text_box_one">
                                                <h5>{summary?.asm?.subdomains}</h5>
                                                <p>Subdomain</p>
                                            </div>
                                            <div class="end_text end_text_blank">
                                                {/* <p>--</p>
                                                <p>-</p> */}
                                            </div>
                                        </Link>
                                        <span
                                            className={`box_after ${summary?.asm?.subdomains == 0 ? 'error' : ''} ${reportSetting?.securityHealthCheck?.subDomain_danglingSubDomain == true && modeValue == 'report-mode' ? 'box-active' : ''}`}
                                            onClick={() => {
                                                if (modeValue == 'report-mode') {
                                                    const newData = {

                                                        subDomain_danglingSubDomain: !reportSetting?.securityHealthCheck?.subDomain_danglingSubDomain
                                                    };
                                                    updateReportSetting(id, dispatch, { "securityHealthCheck": newData });
                                                }
                                            }}>

                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center">
                            <div class="col-md-2">
                                <div class="img_box_race">
                                    <div className="text-center" style={{ marginTop: "15%" }}>
                                        {/* {!isNaN(valueResult) && (
                      <> */}
                                        <GaugeChart
                                            id="gauge-chart3"
                                            nrOfLevels={30}
                                            colors={["#ffd861", "#E15858"]}
                                            arcWidth={0.3}
                                            // percent={1}
                                            percent={valueResult ? (valueResult / 900) : 0}
                                            formatTextValue={() => valueResult ? `${valueResult}/900` : ""}
                                        />

                                        {/* </> */}
                                        {/* )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row_boxes row_boxes_two">
                                    <div class="box">
                                        <Link to='/assets/#hostsDiscovered'>
                                            <div class="img_box">
                                                <img src={Host} class="img-fluid" />
                                            </div>
                                            <div class="text_box_one">
                                                <h5>{summary?.ipDiscovery?.totalHostsOrIPs}</h5>
                                                <p>Hosts Discovered</p>
                                            </div>
                                            <div class="text_box_one">
                                                <h5>View</h5>
                                                <p>Find more</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="box">
                                        <Link to='/assets/#hostsLocation'>
                                            <div class="img_box">
                                                <img src={HostLocation} class="img-fluid" />
                                            </div>
                                            <div class="text_box_one">
                                                <h5>{summary?.ipDiscovery?.totalCountries}</h5>
                                                <p>Hosts Locations</p>
                                            </div>
                                            <div class="text_box_one">
                                                <h5>View</h5>
                                                <p>Find  more</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="box">
                                        <Link to='/assets/#cloudProvider'>
                                            <div class="img_box">
                                                <img src={CloudProviderSvg} class="img-fluid" />
                                            </div>
                                            <div class="text_box_one">
                                                <h5>{summary?.ipDiscovery?.totalCloudOrISPProviders}</h5>
                                                <p>Cloud Provider</p>
                                            </div>
                                            <div class="text_box_one">
                                                <h5>View</h5>
                                                <p>Find more</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="box">
                                        <Link to='/assets/#openPort'>
                                            <div class="img_box">
                                                <img src={OpenPorts} class="img-fluid" />
                                            </div>
                                            <div class="text_box_one">
                                                <h5>{summary?.ipDiscovery?.totalOpenPorts}</h5>
                                                <p>Open Ports</p>
                                            </div>
                                            <div class="text_box_one">
                                                <h5>View</h5>
                                                <p>Find more</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="box">
                                        <Link to='/assets/#operatingSystem'>
                                            <div class="img_box">
                                                <img src={OpertingSystem} class="img-fluid" />
                                            </div>
                                            <div class="text_box_one">
                                                <h5> {summary?.ipDiscovery?.totalOperatingSystems}</h5>
                                                <p>Operating Systems</p>
                                            </div>
                                            <div class="text_box_one">
                                                <h5>View</h5>
                                                <p>Find more</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="box">
                                        <Link to='/assets/#networkService'>
                                            <div class="img_box">
                                                <img src={networkService} class="img-fluid" />
                                            </div>
                                            <div class="text_box_one">
                                                <h5>{summary?.ipDiscovery?.totalNetworkServices}</h5>
                                                <p>Network Services</p>
                                            </div>
                                            <div class="text_box_one">
                                                <h5>View</h5>
                                                <p>Find more</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-8">
                                <div class="map_box text-center">
                                    <VectorMap
                                        containerStyle={{
                                            height: "500px",
                                        }}
                                        map={"world_mill"}
                                        backgroundColor="transparent"
                                        borderColor="#fff"
                                        borderOpacity={0.25}
                                        borderWidth={0}
                                        color="#e6e6e6"
                                        regionStyle={{ initial: { fill: "#6c757d" } }}
                                        markerStyle={{
                                            initial: {
                                                r: 5,
                                                fill: "#fff",
                                                "fill-opacity": 1,
                                                stroke: "#000",
                                                "stroke-width": 1,
                                                "stroke-opacity": 0.4,
                                            },
                                        }}
                                        markers={distinctLocationData}
                                        series={{
                                            regions: [
                                                {
                                                    values: {
                                                        "SG": "blue"
                                                    },
                                                    attribute: "fill",
                                                },
                                            ],
                                        }}
                                        hoverOpacity={null}
                                        normalizeFunction="linear"
                                        zoomOnScroll={false}
                                        scaleColors={["#000000", "#000000"]}
                                        selectedColor="#000000"
                                        selectedRegions={[]}
                                        enableZoom={false}
                                        hoverColor="#fff"
                                        zoomButtons={false}
                                    />

                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-12">
                                <div class="row_boxes">
                                    <div className="graph_line graph_summary_bk w-100 d-flex align-items-end justify-content-center">
                                        <img src={rightImg} className="img-fluid" />
                                        <div style={{ width: '91%' }}>
                                            <ReactApexChart
                                                options={options}
                                                series={series}
                                                type="line"
                                                height={"220px"}
                                            />
                                        </div>
                                        <img src={leftImg} className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            }
        </>
    );
}

export default Dashboard;