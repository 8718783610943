import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Menu from "./Menu";
// import Header from './Header';
import ThemeSetting from "./ThemeSetting";
import Searchbar from "./Searchbar";
import SecurityRoute from "./securityRoute";
import BrandRoute from "./brandRoute";
import BreachRoute from "./breachRoute";
import ServiceRoute from "./serviceRoute";
import SummaryNew from "../Dashboard/summary_bk";
import { AnimatePresence } from "framer-motion/dist/framer-motion.dev";
import AssetDiscovery from "../SecurityRisk/assetsdiscovery";
import Clients from "../Projects/Clients";
import ShowInformation from "../information/ShowInformation";
import User from "../Dashboard/user";
import PageProfile from "../Pages/pageProfile";
const Layout = (props) => {
  return (
    <>
      {/* <ThemeSetting /> */}
      <div id="wrapper">
        <Searchbar />
        <div className="assignment_mode_security_health_check">
          <AnimatePresence>
            <Switch>
              <Route exact={true} key="dashboard" path="/summary" component={SummaryNew} />
              <Route exact={true} path="/user" component={User} />
              <Route exact={true} path="/profile" component={PageProfile} />
              <Route key="security" path="/security" component={SecurityRoute} />
              <Route key="services" path="/services" component={ServiceRoute} />
              <Route key="brand" path="/brand" component={BrandRoute} />
              <Route key="breach" path="/breach" component={BreachRoute} />
              <Route exact={true} key="dashboard" path="/" component={SummaryNew} />
              <Route exact={true} key="dashboard" path="/assets" component={AssetDiscovery} />
              <Route key="brand" path="/client" component={Clients} />
              <Route key="show-information" path="/show-details/:id" component={ShowInformation} />
            </Switch>
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};
// export default class Layout extends Component {
// 	render() {
// 		return (
// 			<>
// 				<ThemeSetting />
// 				<div className="overlay" />
// 				<div id="wrapper">
// 					{/* <Header /> */}
// 					<Searchbar />
// 					{/* <Megamenu /> */}
// 					{/* <Rightbar /> */}
// 					{/* <Menu {...this.props} /> */}
// 					<div id="main-content">
// 						<Switch>
// 							{Switch.map((layout, i) => {
// 								return <Route key={`r${i}`} exact={layout.exact} path={layout.path} component={layout.component}></Route>
// 							})}
// 						</Switch>
// 					</div>
// 				</div>
// 			</>
// 		);
// 	}
// }

export default Layout;
