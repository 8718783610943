import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchMobileApp,
  fetchFakeSocialMedia,
  markAsSuspicious,
  markSuspicious,
  fetchDarkWebMention,
  fetchMobileRemidiatedFalseTrue,
  fetchSocailRemidiatedFalseTrue,
} from "../../actions/fakeMobileApp";
import {
  fetchLeakedCode,
  fetchLeakedCodeAction,
} from "../../actions/leakedCode";
import { fetchPastebinResult } from "../../actions/pastebin";
import Pagination from "../Pagination/Pagination";
import moment from "moment";
import { Tabs, Tab, Row, Col, Nav } from "react-bootstrap";
import Tooltip from "../common/toolTip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NoData from "../common/noDataComponetnt";

const ShowInformation = () => {
  const [filterData, setfilterData] = useState("all");
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState("");
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const [category, setCategory] = useState("all");
  const [type, settype] = useState("all");
  const [totalItemsMob, setTotalItemsMob] = useState(0);
  const [totalItemsSource, setTotalItemsSource] = useState(0);
  const [repo, setRepo] = useState([]);
  const [snoozeCon, setSnoozeCon] = useState("");
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.data?.[0]
  );
  let totalDataMob = useSelector(
    (state) => state?.fakeMobileApp?.mobileData?.data
  );

  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const { id } = useParams();
  // const id = useSelector(
  //   (state) => state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
  // );
  const dispatch = useDispatch();
  const state = useSelector(
    (state) => state?.fakeMobileApp?.mobileData?.data?.data
  );

  let mapData = state;

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  // End of Mobile
  // Beginnning of Source Code

  const [filterSourceData, setfilterSourceData] = useState("all");
  const [sourcepage, setSourcePage] = useState(1);
  const [sourcePageSize, setSourcePageSize] = useState(10);
  const sourceState = useSelector(
    (sourceState) => sourceState?.leakedCode?.data?.data
  );
  let currentSourceTableData = sourceState?.data;
  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  //Beginning of Social Media Profile
  const [filterSocialData, setSocialfilterData] = useState("all");
  const [socialPage, setSocialPage] = useState(1);
  const [socialTotal, setSocialTotal] = useState(1);
  const socialState = useSelector(
    (socialState) => socialState?.fakeMobileApp?.fakeSocialMedia?.data
  );

  let socialData = socialState?.data;

  let SocialPageSize = "";
  let currentSocialTableData = [];
  currentSocialTableData = socialState?.data;

  const darkState = useSelector(
    (darkState) => darkState?.fakeMobileApp?.darkWebMention
  );

  // pastbin

  const [pasteTotal, setPasteTotal] = useState(1);
  const [pastePage, setPastePage] = useState(1);

  const pastebin = useSelector((state) => state?.pastebin?.response?.data);

  const snooze = (_id, event, option) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (option == "mob") {
      if (event == "markAsRemidiated") {
        const data = {
          action: "markAsRemidiated",
          lookalikeAppId: _id,
          actionNote: "",
          for: "forever",
        };
        fetchMobileRemidiatedFalseTrue(
          id,
          dispatch,
          data,
          page,
          type,
          category
        );
        setSelectedValue("pleaseSelect");
        setActionNote("");
      }
    }

    if (option == "source code") {
      if (event == "markAsRemidiated") {
        const data = {
          action: "markAsRemidiated",
          leakedCodeId: _id,
          actionNote: "",
          for: "forever",
        };
        fetchLeakedCodeAction(
          id,
          dispatch,
          sourcepage,
          filterSourceData,
          "all",
          data
        );
        setSelectedValue("pleaseSelect");
        setActionNote("");
      }
    }

    if (option == "social media") {
      if (event == "markAsRemidiated") {
        const data = {
          action: "markAsRemidiated",
          fakeSocialMediaId: _id,
          actionNote: "",
          for: "forever",
        };
        fetchSocailRemidiatedFalseTrue(id, dispatch, data, page, socialPage);
        setSelectedValue("pleaseSelect");
        setActionNote("");
      }
    }
  };

  const storeName = (val) => {
    if (val == "iOS") return "iOS";
    else if (val == "Android" || val == "google") return "Android";
    else return "Third Party";
  };

  const addModal = (v) => {
    setSnoozeCon(v);
    setModal("Form Wizard modal");
  };

  const submit = () => {
    if (snoozeCon == "mob") {
      const data = {
        action: action,
        lookalikeAppId: lookalikeAppId,
        actionNote: actionNote,
        for: forValue,
      };
      fetchMobileRemidiatedFalseTrue(id, dispatch, data, page, type, category);
      setModal("");
      setSelectedValue("pleaseSelect");
      setActionNote("");
    }
    if (snoozeCon == "source code") {
      const data = {
        action: action,
        leakedCodeId: lookalikeAppId,
        actionNote: actionNote,
        for: forValue,
      };
      fetchLeakedCodeAction(
        id,
        dispatch,
        sourcepage,
        filterSourceData,
        "all",
        data
      );
      setModal("");
      setSelectedValue("pleaseSelect");
      setActionNote("");
    }
    if (snoozeCon == "social media") {
      const data = {
        action: action,
        fakeSocialMediaId: lookalikeAppId,
        actionNote: actionNote,
        for: forValue,
      };
      fetchSocailRemidiatedFalseTrue(id, dispatch, data, page);
      setModal("");
      setSelectedValue("pleaseSelect");
      setActionNote("");
    }
  };

  const editModal = (row) => {
    setRepo(row);
    setModal("Form Wizard modal");
  };

  const auth = useSelector((state) => state?.auth?.user);

  useEffect(() => {
    fetchDarkWebMention(id, dispatch);
  }, [id]);

  useEffect(() => {
    setPasteTotal(pastebin?.totalApps);
  }, [pastebin?.totalApps]);

  useEffect(() => {
    fetchFakeSocialMedia(
      id,
      dispatch,
      socialPage,
      "all",
      filterSocialData,
      "all"
    );
  }, [id, dispatch, socialPage, filterSocialData]);

  useEffect(() => {
    fetchLeakedCode(id, dispatch, sourcepage, filterSourceData, "all");
  }, [id, dispatch, sourcepage, filterSourceData]);

  useEffect(() => {
    fetchMobileApp(id, dispatch, page, filterData, category);
  }, [id, dispatch, page, filterData, category]);

  useEffect(() => {
    fetchPastebinResult(id, dispatch, page, category);
  }, [id, dispatch, page, category]);

  useEffect(() => {
    setTotalItemsMob(totalDataMob?.totalApps);
  }, [totalDataMob?.totalApps]);

  useEffect(() => {
    setTotalItemsSource(sourceState?.totalLeakedCodes);
  }, [sourceState?.totalLeakedCodes]);

  useEffect(() => {
    setSocialTotal(socialState?.totalProfiles);
  }, [socialState?.totalProfiles]);

  return (
    <>
      <div className="row clearfix">
        <div className="col-sm-2"></div>

        <div className="col-sm-9" style={{ padding: "0" }}>
          <div className="card">
            <div className="body">
              <div id="wizard_horizontal">
                <Tabs
                  defaultActiveKey="First Step"
                  id="uncontrolled-tab-example"
                >
                  <Tab
                    eventKey="First Step"
                    title="Mobile Application"
                    tabClassName="form_wizard_tab form_wizard_width"
                  >
                    <div className="row clearfix">
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="card">
                          <div className="body">
                            <div className="row clearfix">
                              <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="row clearfix">
                                  <div className="col-12">
                                    <div className="row card">
                                      <div className="col-sm-4">
                                        <select
                                          className="custom-select custom-select-sm form-control form-control-sm"
                                          value={filterData}
                                          onChange={(e) =>
                                            setfilterData(e.target.value)
                                          }
                                        >
                                          <option value="all">All</option>
                                          <option value="android">
                                            Android
                                          </option>
                                          <option value="ios">IOS</option>
                                          <option value="third-party">
                                            Third Party
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="table-responsive">
                                      <table className="table table-custom spacing5 mb-0">
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Store Name</th>
                                            {/* <th>Application Icon</th> */}
                                            {/* <th style={{ width: "80" }}>Ratings</th> */}
                                            <th style={{ width: "80" }}>
                                              Application Name
                                            </th>
                                            <th style={{ width: "80" }}>
                                              Application Url
                                            </th>
                                            {/* <th style={{ width: "80" }}>Action</th> */}
                                            {(category === "all" ||
                                              category === "risk-accepted" ||
                                              category ===
                                                "false-positive") && (
                                              <th style={{ width: "80" }}>
                                                Action
                                              </th>
                                            )}
                                            {/* <th style={{ width: "60" }}>Summary</th> */}
                                            {/* <th>Developer Details</th> */}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {client?.subscriptionType ==
                                          "trial" ? (
                                            <tr>
                                              <td colSpan={8}>
                                                <NoData />
                                              </td>
                                            </tr>
                                          ) : (
                                            currentTableData?.map(
                                              (res, index) =>
                                                !res.isBlacklist && (
                                                  <tr key={index}>
                                                    <td>
                                                      <span>{index + 1}</span>
                                                    </td>
                                                    <td>
                                                      {storeName(
                                                        res?.source ||
                                                          res?.appStoreResource
                                                      )}
                                                    </td>
                                                    {/* <td>
                              {" "}
                              <img
                                alt="App Icon"
                                // src={res?.map((res) => res.icon)}
                                src={res.icon}
                                style={{ height: 80, width: 80 }}
                              />
                            </td> */}
                                                    {/* <td>{res?.scoreText}</td> */}
                                                    <td
                                                      style={{
                                                        whiteSpace: "unset",
                                                      }}
                                                    >
                                                      {res?.app_name}
                                                    </td>
                                                    <td
                                                      style={{
                                                        whiteSpace: "unset",
                                                      }}
                                                    >
                                                      <a
                                                        href={`${res?.url}`}
                                                        target="_blank"
                                                        style={{
                                                          color: "white",
                                                        }}
                                                      >
                                                        Link
                                                      </a>
                                                    </td>
                                                    {category === "all" && (
                                                      <td>
                                                        <Box
                                                          sx={{ width: 200 }}
                                                        >
                                                          <FormControl
                                                            fullWidth
                                                          >
                                                            {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                                            <Select
                                                              labelId="demo-simple-select-label"
                                                              id="demo-simple-select"
                                                              value={
                                                                selectedValue
                                                              }
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                setAction(
                                                                  event.target
                                                                    .value
                                                                );
                                                                snooze(
                                                                  res?._id,
                                                                  event.target
                                                                    .value,
                                                                  "mob"
                                                                );
                                                              }}
                                                            >
                                                              <MenuItem value="pleaseSelect">
                                                                Snooze Options
                                                              </MenuItem>
                                                              <MenuItem
                                                                value="riskAccepted"
                                                                onClick={() => {
                                                                  addModal(
                                                                    "mob"
                                                                  );
                                                                  setLookalikeAppId(
                                                                    res._id
                                                                  );
                                                                }}
                                                              >
                                                                Risk Accepted
                                                              </MenuItem>
                                                              <MenuItem
                                                                value="markAsFalsePositive"
                                                                onClick={() => {
                                                                  addModal(
                                                                    "mob"
                                                                  );
                                                                  setLookalikeAppId(
                                                                    res._id
                                                                  );
                                                                }}
                                                              >
                                                                False Positive
                                                              </MenuItem>
                                                              <MenuItem value="markAsRemidiated">
                                                                Remediated
                                                              </MenuItem>
                                                            </Select>
                                                          </FormControl>
                                                        </Box>
                                                      </td>
                                                    )}
                                                  </tr>
                                                )
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="dataTables_paginate">
                                      {totalItemsMob > 0 && (
                                        <Pagination
                                          className="pagination-bar"
                                          currentPage={page}
                                          totalCount={totalItemsMob}
                                          pageSize={10}
                                          onPageChange={(page) => setPage(page)}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="Second Step"
                    title="Source Code"
                    tabClassName="form_wizard_tab form_wizard_width"
                  >
                    <div className="row clearfix">
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="card">
                          <div className="body">
                            <div className="row clearfix">
                              <div className="row card">
                                <div className="col-sm-4">
                                  <select
                                    className="custom-select custom-select-sm form-control form-control-sm"
                                    value={filterSourceData}
                                    onChange={(e) =>
                                      setfilterSourceData(e.target.value)
                                    }
                                  >
                                    <option value="all">All</option>
                                    <option value="verified">Verified</option>
                                    <option value="unverified">
                                      Unverified
                                    </option>
                                    <option value="takedown">takedown</option>
                                    <option value="falsePositive">
                                      FalsePositive
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="table-responsive">
                                  <table className="table table-custom spacing5 mb-0">
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        {auth.accessLevel !== "Client" && (
                                          <th>Status</th>
                                        )}
                                        {/* <th style={{ width: "40" }}>Platform</th> */}
                                        <th>Repository Link</th>
                                        <th>Last Update at</th>
                                        <th>Created at</th>
                                        <th>Detail</th>
                                        {/* <th>Actions</th> */}
                                        {(category === "all" ||
                                          category === "risk-accepted" ||
                                          category === "false-positive") && (
                                          <th style={{ width: "80" }}>
                                            Action
                                          </th>
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {client?.subscriptionType == "trial" ? (
                                        <tr>
                                          <td colSpan={5}>
                                            <NoData />
                                          </td>
                                        </tr>
                                      ) : (
                                        currentSourceTableData?.map(
                                          (res, index) => (
                                            <tr key={index}>
                                              <td
                                                style={{
                                                  whiteSpace: "unset",
                                                }}
                                              >
                                                <span>{index + 1}</span>
                                              </td>
                                              {auth.accessLevel !==
                                                "Client" && (
                                                <th>
                                                  {" "}
                                                  <span
                                                    className={
                                                      res?.isSuspicious
                                                        ? "badge badge-success"
                                                        : "badge badge-danger"
                                                    }
                                                  >
                                                    {res?.isSuspicious
                                                      ? "Verified"
                                                      : "Unverified"}
                                                  </span>
                                                  {res?.isLinkDown && (
                                                    <span className="badge badge-warning">
                                                      Link down
                                                    </span>
                                                  )}
                                                </th>
                                              )}

                                              {/* <td style={{ whiteSpace: "unset" }}>
                              <i className="fa fa-github"></i>
                              GitHub
                            </td> */}
                                              {/* <td>{res?.keyword||"-"}</td> */}
                                              <td
                                                style={{
                                                  whiteSpace: "unset",
                                                }}
                                              >
                                                <a
                                                  href={`${res?.repoUrl}`}
                                                  target="_blank"
                                                  style={{ color: "white" }}
                                                >
                                                  {res?.repoUrl}
                                                </a>
                                              </td>
                                              {/* <td style={{ whiteSpace: "unset" }}>{res?.leakedCode||"-"}</td> */}
                                              {/* <td>Screenshot</td> */}
                                              <td>
                                                {" "}
                                                {res?.updatedAt
                                                  ? moment(
                                                      res?.updatedAt
                                                    ).format("DD-MM-YYYY HH:MM")
                                                  : res?.createdAt
                                                  ? moment(
                                                      res?.createdAt
                                                    ).format("DD-MM-YYYY HH:MM")
                                                  : "NA"}
                                              </td>
                                              <td>
                                                {" "}
                                                {res?.date
                                                  ? moment(res?.date).format(
                                                      "DD-MM-YYYY HH:MM"
                                                    )
                                                  : res?.createdAt
                                                  ? moment(
                                                      res?.createdAt
                                                    ).format("DD-MM-YYYY HH:MM")
                                                  : "NA"}
                                              </td>
                                              <td
                                                onClick={() =>
                                                  editModal(res?.repository[0])
                                                }
                                                style={{ cursor: "pointer" }}
                                              >
                                                Detail
                                              </td>
                                              {category === "all" && (
                                                <td>
                                                  <Box sx={{ width: 200 }}>
                                                    <FormControl fullWidth>
                                                      {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                                      <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={selectedValue}
                                                        onChange={(event) => {
                                                          setAction(
                                                            event.target.value
                                                          );
                                                          snooze(
                                                            res?._id,
                                                            event.target.value,
                                                            "source code"
                                                          );
                                                        }}
                                                      >
                                                        <MenuItem value="pleaseSelect">
                                                          Snooze Options
                                                        </MenuItem>
                                                        <MenuItem
                                                          value="riskAccepted"
                                                          onClick={() => {
                                                            addModal(
                                                              "source code"
                                                            );
                                                            setLookalikeAppId(
                                                              res._id
                                                            );
                                                          }}
                                                        >
                                                          Risk Accepted
                                                        </MenuItem>
                                                        <MenuItem
                                                          value="markAsFalsePositive"
                                                          onClick={() => {
                                                            addModal(
                                                              "source code"
                                                            );
                                                            setLookalikeAppId(
                                                              res._id
                                                            );
                                                          }}
                                                        >
                                                          False Positive
                                                        </MenuItem>
                                                        <MenuItem value="markAsRemidiated">
                                                          Remediated
                                                        </MenuItem>
                                                      </Select>
                                                    </FormControl>
                                                  </Box>
                                                </td>
                                              )}
                                            </tr>
                                          )
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="dataTables_paginate">
                                  {totalItemsSource > 0 && (
                                    <Pagination
                                      className="pagination-bar"
                                      currentPage={sourcepage}
                                      totalCount={totalItemsSource}
                                      pageSize={10}
                                      onPageChange={(sourcepage) =>
                                        setSourcePage(sourcepage)
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="Third Step"
                    title="Social Media Profile"
                    tabClassName="form_wizard_tab form_wizard_width"
                  >
                    <div className="row clearfix">
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="card">
                          <div className="body">
                            <div className="row clearfix">
                              <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="row card">
                                  <div className="col-sm-4">
                                    <select
                                      className="custom-select custom-select-sm form-control form-control-sm"
                                      value={filterSocialData}
                                      onChange={(e) =>
                                        setSocialfilterData(e.target.value)
                                      }
                                    >
                                      <option value="all">All</option>
                                      <option value="facebook">Facebook</option>
                                      <option value="linkedin">Linkedin</option>
                                      <option value="twitter">Twitter</option>
                                      <option value="instagram">
                                        Instagram
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="table-responsive">
                                  <table className="table table-custom spacing5 mb-0">
                                    <thead>
                                      <tr>
                                        <th>#No</th>
                                        <th>Status</th>
                                        <th>Platform</th>
                                        {/* <th>Page Name</th> */}
                                        {/* <th>Page Subtitle</th> */}
                                        <th>Page URL</th>
                                        <th>Keyword</th>
                                        {/* <th>Followers</th> */}
                                        <th>Last Updated at</th>
                                        {(category === "all" ||
                                          category === "risk-accepted" ||
                                          category === "false-positive") && (
                                          <th style={{ width: "80" }}>
                                            Action
                                          </th>
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {client?.subscriptionType == "trial" ? (
                                        <tr>
                                          <td colSpan={5}>
                                            <NoData />
                                          </td>
                                        </tr>
                                      ) : (
                                        currentSocialTableData?.map(
                                          (res, index) => {
                                            let source =
                                              res?.source ||
                                              res?.socialMediaPlatform ||
                                              "NA";
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <span>{index + 1}</span>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <span
                                                    className={
                                                      res?.isSuspicious
                                                        ? "badge badge-success"
                                                        : "badge badge-danger"
                                                    }
                                                  >
                                                    {res?.isSuspicious
                                                      ? "Verified"
                                                      : "Unverified"}
                                                  </span>
                                                  {res?.isLinkDown && (
                                                    <span className="badge badge-warning">
                                                      Take down
                                                    </span>
                                                  )}
                                                </td>
                                                <td>
                                                  {source
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    source.slice(1)}
                                                </td>
                                                {/* <td>{res.name}</td> */}
                                                {/* <td>{res.subTitle}</td> */}

                                                <td
                                                  style={{
                                                    whiteSpace: "unset",
                                                  }}
                                                >
                                                  <a
                                                    href={`${
                                                      res?.link ||
                                                      res?.fakePageUrl
                                                    }`}
                                                    target="_blank"
                                                    style={{ color: "white" }}
                                                  >
                                                    {res?.link ||
                                                      res?.fakePageUrl}
                                                  </a>
                                                </td>
                                                {/* <td>{res.followers}</td> */}

                                                <td
                                                  style={{
                                                    whiteSpace: "unset",
                                                  }}
                                                >
                                                  <span className="badge badge-warning">
                                                    {res.keyword || "NA"}
                                                  </span>
                                                </td>
                                                <td>
                                                  {res?.updatedAt
                                                    ? moment(
                                                        res?.updatedAt
                                                      ).format(
                                                        "DD-MM-YYYY HH:MM"
                                                      )
                                                    : res?.createdAt
                                                    ? moment(
                                                        res?.createdAt
                                                      ).format(
                                                        "DD-MM-YYYY HH:MM"
                                                      )
                                                    : "NA"}
                                                </td>
                                                {category === "all" && (
                                                  <td>
                                                    <Box sx={{ width: 200 }}>
                                                      <FormControl fullWidth>
                                                        {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                                        <Select
                                                          labelId="demo-simple-select-label"
                                                          id="demo-simple-select"
                                                          value={selectedValue}
                                                          onChange={(event) => {
                                                            setAction(
                                                              event.target.value
                                                            );
                                                            snooze(
                                                              res?._id,
                                                              event.target
                                                                .value,
                                                              "social media"
                                                            );
                                                          }}
                                                        >
                                                          <MenuItem value="pleaseSelect">
                                                            Snooze Options
                                                          </MenuItem>
                                                          <MenuItem
                                                            value="riskAccepted"
                                                            onClick={() => {
                                                              addModal(
                                                                "social media"
                                                              );
                                                              setLookalikeAppId(
                                                                res._id
                                                              );
                                                            }}
                                                          >
                                                            Risk Accepted
                                                          </MenuItem>
                                                          <MenuItem
                                                            value="markAsFalsePositive"
                                                            onClick={() => {
                                                              addModal(
                                                                "social media"
                                                              );
                                                              setLookalikeAppId(
                                                                res._id
                                                              );
                                                            }}
                                                          >
                                                            False Positive
                                                          </MenuItem>
                                                          <MenuItem value="markAsRemidiated">
                                                            Remediated
                                                          </MenuItem>
                                                        </Select>
                                                      </FormControl>
                                                    </Box>
                                                  </td>
                                                )}
                                              </tr>
                                            );
                                          }
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                  <div className="dataTables_paginate">
                                    {socialTotal > 0 && (
                                      <Pagination
                                        className="pagination-bar"
                                        currentPage={socialPage}
                                        totalCount={socialTotal}
                                        pageSize={10}
                                        onPageChange={(page) =>
                                          setSocialPage(page)
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="Forth Step"
                    title="Dark Web"
                    tabClassName="form_wizard_tab form_wizard_width"
                  >
                    <div className="row clearfix">
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="card">
                          <div className="body">
                            <div className="row clearfix">
                              <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="card">
                                  <div className="body">
                                    <div className="row clearfix">
                                      <div className="col-xl-12 col-lg-12 col-md-12">
                                        <div className="table-responsive">
                                          <table className="table table-custom spacing5 mb-0">
                                            <thead>
                                              <tr>
                                                <th>#No</th>
                                                <th>Actions</th>
                                                <th>Mention Type</th>
                                                <th>Dark Web URL</th>
                                                <th>Severity Level</th>
                                                <th>Details</th>
                                                <th>Last updated at</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {darkState?.map((res, index) => (
                                                <tr key={index + "darkweb"}>
                                                  <td>
                                                    <span>{index + 1}</span>
                                                  </td>
                                                  <td>
                                                    <button
                                                      type="button"
                                                      className="btn btn-sm btn-default"
                                                      title="Verify"
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      style={
                                                        res?.isSuspicious
                                                          ? {
                                                              backgroundColor:
                                                                "green",
                                                              color: "white",
                                                            }
                                                          : {}
                                                      }
                                                      onClick={(e) => {
                                                        //console.log(
                                                        //   "page id code id",
                                                        //   res?._id
                                                        // );
                                                        markSuspicious(
                                                          res?._id,
                                                          res?.isSuspicious
                                                            ? false
                                                            : true,
                                                          res?.isBlacklist,
                                                          res?.isLinkDown,
                                                          "darkWeb",
                                                          "Suspicious",
                                                          id,
                                                          dispatch
                                                        );
                                                      }}
                                                    >
                                                      <i className="fa fa-check"></i>
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="btn btn-sm btn-default "
                                                      title="Blacklist"
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      style={
                                                        res?.isBlacklist
                                                          ? {
                                                              backgroundColor:
                                                                "red",
                                                              color: "white",
                                                            }
                                                          : {}
                                                      }
                                                      onClick={(e) => {
                                                        //console.log(
                                                        //   "page id code id",
                                                        //   res?._id
                                                        // );
                                                        markSuspicious(
                                                          res?._id,
                                                          res?.isSuspicious,
                                                          res?.isBlacklist
                                                            ? false
                                                            : true,
                                                          res?.isLinkDown,
                                                          "darkWeb",
                                                          "Blacklist",
                                                          id,
                                                          dispatch
                                                        );
                                                      }}
                                                    >
                                                      <i className="fa fa-times"></i>
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="btn btn-sm btn-default "
                                                      title="Page Down"
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      style={
                                                        res?.isLinkDown
                                                          ? {
                                                              backgroundColor:
                                                                "blue",
                                                              color: "white",
                                                            }
                                                          : {}
                                                      }
                                                      onClick={(e) => {
                                                        //console.log(
                                                        //   "page id code id",
                                                        //   res?._id
                                                        // );
                                                        markSuspicious(
                                                          res?._id,
                                                          res?.isSuspicious,
                                                          res?.isBlacklist,
                                                          res?.isLinkDown
                                                            ? false
                                                            : true,
                                                          "darkWeb",
                                                          "LinkDown",
                                                          id,
                                                          dispatch
                                                        );
                                                      }}
                                                    >
                                                      <i className="fa fa-angle-down"></i>
                                                    </button>
                                                  </td>
                                                  <td>
                                                    <span className="badge badge-warning">
                                                      {res.WebMentionType}
                                                    </span>
                                                  </td>
                                                  <td
                                                    style={{
                                                      whiteSpace: "unset",
                                                    }}
                                                  >
                                                    <span className="badge badge-danger">
                                                      {res.WebSeverity}
                                                    </span>
                                                  </td>
                                                  <td>{res.repoUrl}</td>

                                                  <td>{res.WebDetails}</td>

                                                  <td>
                                                    {res?.updatedAt
                                                      ? moment(
                                                          res?.updatedAt
                                                        ).format(
                                                          "DD-MM-YYYY HH:MM"
                                                        )
                                                      : res?.createdAt
                                                      ? moment(
                                                          res?.createdAt
                                                        ).format(
                                                          "DD-MM-YYYY HH:MM"
                                                        )
                                                      : "NA"}
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="Five Step"
                    title="Pastbin Search"
                    tabClassName="form_wizard_tab form_wizard_width"
                  >
                    <div className="row clearfix">
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="card">
                          <div className="body">
                            <div className="row clearfix">
                              <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="card">
                                  <div className="body">
                                    <div className="row clearfix">
                                      <div className="col-xl-12 col-lg-12 col-md-12">
                                        <div className="table-responsive">
                                          <table className="table table-custom spacing5 mb-0">
                                            <thead>
                                              <tr>
                                                <th>#No</th>
                                                <th>Title</th>
                                                <th>Link</th>
                                                <th>Snippet</th>
                                                <th>Last modified on</th>
                                                {(category === "all" ||
                                                  category ===
                                                    "risk-accepted" ||
                                                  category ===
                                                    "false-positive") && (
                                                  <th style={{ width: "80" }}>
                                                    Action
                                                  </th>
                                                )}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {pastebin?.data?.map(
                                                (row, index) => (
                                                  <tr key={index + "pastebin"}>
                                                    <td>
                                                      <span>{index + 1}</span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        whiteSpace: "unset",
                                                        width: "350px",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          whiteSpace:
                                                            "pre-wrap",
                                                          overflowWrap:
                                                            "break-word",
                                                        }}
                                                      >
                                                        {row?.title}
                                                      </span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: "100px",
                                                      }}
                                                    >
                                                      <a href={row?.link}>
                                                        {row?.link}
                                                      </a>
                                                    </td>
                                                    <td>
                                                      <span
                                                        style={{
                                                          whiteSpace:
                                                            "pre-wrap",
                                                          overflowWrap:
                                                            "break-word",
                                                        }}
                                                      >
                                                        {row?.snippet}
                                                      </span>
                                                    </td>
                                                    <td
                                                      style={{
                                                        whiteSpace: "unset",
                                                      }}
                                                    >
                                                      <span className="badge badge-success">
                                                        {moment(
                                                          row?.createdAt
                                                        ).format("DD-MM-YYYY")}
                                                      </span>
                                                    </td>
                                                    {category === "all" && (
                                                      <td>
                                                        <Box
                                                          sx={{ width: 200 }}
                                                        >
                                                          <FormControl
                                                            fullWidth
                                                          >
                                                            {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                                            <Select
                                                              labelId="demo-simple-select-label"
                                                              id="demo-simple-select"
                                                              value={
                                                                selectedValue
                                                              }
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                setAction(
                                                                  event.target
                                                                    .value
                                                                );
                                                                snooze(
                                                                  row?._id,
                                                                  event.target
                                                                    .value
                                                                );
                                                              }}
                                                            >
                                                              <MenuItem value="pleaseSelect">
                                                                Snooze Options
                                                              </MenuItem>
                                                              <MenuItem
                                                                value="riskAccepted"
                                                                onClick={() => {
                                                                  addModal(
                                                                    "pastbin"
                                                                  );
                                                                  setLookalikeAppId(
                                                                    [row._id]
                                                                  );
                                                                }}
                                                              >
                                                                Risk Accepted
                                                              </MenuItem>
                                                              <MenuItem
                                                                value="markAsFalsePositive"
                                                                onClick={() => {
                                                                  addModal(
                                                                    "pastebin"
                                                                  );
                                                                  setLookalikeAppId(
                                                                    [row._id]
                                                                  );
                                                                }}
                                                              >
                                                                False Positive
                                                              </MenuItem>
                                                              <MenuItem value="markAsRemidiated">
                                                                Remediated
                                                              </MenuItem>
                                                            </Select>
                                                          </FormControl>
                                                        </Box>
                                                      </td>
                                                    )}
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                          <div className="dataTables_paginate">
                                            {pasteTotal > 0 && (
                                              <Pagination
                                                className="pagination-bar"
                                                currentPage={pastePage}
                                                totalCount={pasteTotal}
                                                pageSize={10}
                                                onPageChange={(page) =>
                                                  setPastePage(page)
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${
          isModal === "Form Wizard modal" ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {action == "markAsFalsePositive"
                  ? "Mark As False Positive"
                  : "Risk Accepted"}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModal("");
                  setActionNote("");
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page modal_mob">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card">
                  <div className="row clearfix">
                    <div className="col-md-12">
                      <label className="col-lg-6 col-md-12">Snooze for</label>
                      <div className="form-group ol-lg-12 col-md-12">
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={(e) => setForValue(e.target.value)}
                        >
                          <option value="forever">Forever</option>
                          <option value="3 months">3 Months</option>
                          <option value="6 months">6 Months</option>
                          <option value="12 months">12 Months</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="col-lg-12 col-md-12">Detail</label>
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea
                          onChange={(e) => setActionNote(e.target.value)}
                          className="form-control"
                          value={actionNote}
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-12"
                      style={{ marginRight: "28px" }}
                    >
                      <button
                        type="button"
                        style={{ color: "black", fontWeight: "bold" }}
                        onClick={submit}
                        className="btn btn-round btn-primary mr-1 pull-right"
                      >
                        Submit
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        onClick={() => {
                          setModal("");
                          setActionNote("");
                        }}
                        className="btn btn-round btn-default pull-right"
                        style={{ marginRight: "10px" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default ShowInformation;
