import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { forgotPassword } from "../../actions/auth";
import { toastr } from "react-redux-toastr";
import $ from "jquery";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class ForgotPassword extends Component {

	resetPassword = () => {
		let email = $("#signup-password").val();
		if (email == "") {
			toastr.error("Error", "Please enter email");
			return false;
		}

		this.props.forgotPassword({
			email
		});
	}

	render() {
		return (
			<>
				<div class="pattern">
					<span class="red"></span>
					<span class="indigo"></span>
					<span class="blue"></span>
					<span class="green"></span>
					<span class="orange"></span>
				</div>
				{/* <div className="auth-main"> */}
				{/* <div style={{ backgroundColor: '#0F4B6D', display: 'flex', height: '100vh' }}> */}
				<div className='login_container'>
					{/* <div className="auth_brand">
							<Link className="navbar-brand" to="/"><img src="../assets/images/icon.svg" width="30" height="30" className="d-inline-block align-top mr-2" alt="Logo" />BrandSek</Link>
						</div> */}
					<div style={{ margin: 'auto' }}>
						<div className="login loginBg" style={{height:'250px'}}>
							{/* <p className="lead mb-3"><strong>Oops</strong>,<br /> forgot something?</p>
								<p>Type email to recover password.</p> */}
							<h1 className="lead">Forgot Password</h1>
							<form className="form-auth-small" action="">
								<div className="form-group">
									<input type="email" className="form-control-new" id="signup-password" placeholder="Email Address" />
								</div>

								<div className="loginFooter">
									<p className="remember_me">
										<span className="helper-text">Know your password? <Link to="/login">Login</Link></span>

									</p>
									<p class="submit">
										{/* <input type="submit" name="commit" value="Login"> */}
										<button
											style={{marginLeft:'60px'}}
											type="button"
											onClick={this.resetPassword}
											className="loginButton"
										>
											Reset
										</button>
									</p>
								</div>


							</form>
						</div>
					</div>
				</div>
				{/* <div id="particles-js"></div>
				</div> */}
			</>
		);
	}
}

function mapStateToProps({ auth }) {
	return {
		auth,
	};
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			forgotPassword,
		},
		dispatch
	);
}


export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);