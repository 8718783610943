import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Identify from '../services/Identify';
import Protect from '../services/Protect';
import Detect from '../services/Detect';
import Respond from '../services/Respond';
import Recover from '../services/Recover';



const Breach = ({match}) => {
    return (
        <>
            <Switch>
                <Route path={`${match.path}/identify`} component={Identify} />
                <Route path={`${match.path}/protect`} component={Protect} />
                <Route path={`${match.path}/detect`} component={Detect} />
                <Route path={`${match.path}/respond`} component={Respond} />
                <Route path={`${match.path}/recover`} component={Recover} />
                <Route path={`${match.path}`} component={Identify} />
            </Switch>
        </>
    )
}

export default Breach;