import axios from "axios";
import { API_URL } from "../utils/constant";
import Cookies from "universal-cookie";
import {
  FETCH_CLIENTS,
  GETING_DATA_BY_LOCALSTORAGE,
  SUMMARY_FETCHED,
  MAIN_SCREEN_SUMMARY_FETCHED,
  DASHBOARD_SUMMARY_FETCHED,
  VENDOR_SUMMARY_FETCHED,
  REPORT_SETTING,
  PENDING_COUNT,
  REMEDIATION_LIST
} from "./index";
// import parser from "tld-extract";
import { toastr } from "react-redux-toastr";

export const fetchClients = (dispatch, accessLevel , page , searchBy) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .get(`${API_URL}/customer?page=${page}&limit=10&searchBy=${searchBy}`, {})
    .then((response) => {
      console.log(response.data);
      const res = response?.data?.data.map((res) => ({
        active: res?.active,
        address: res?.address,
        clientType: res?.clientType,
        country: res?.country,
        createdAt: res?.createdAt,
        currency: res?.currency,
        deletedAt: res?.deletedAt,
        description: res?.description,
        emailAccess: res?.emailAccess,
        employees: res?.employees,
        facebookUrl: res?.facebookUrl,
        jobTitle: res?.jobTitle,
        linkedinUrl: res?.linkedinUrl,
        message: res?.message,
        orgContact: res?.orgContact,
        orgContactName: res?.orgContactName,
        orgDescription: res?.orgDescription,
        orgEmail: res?.orgEmail,
        orgName: res?.orgName,
        socialMedia: res?.socialMedia,
        subscriptionType: res?.subscriptionType,
        twitterUrl: res?.twitterUrl,
        // website: parser(res?.website)?.domain,
        zipCode: res?.zipCode,
        keyWord: res?.keyWord,
        dmarcData: res?.dmarcData,
        website: res?.website,
        scanDetails: res?.scanDetails,
        scanCounter: res?.scanCounter,
        domains: res?.domains,
        __v: res?.__v,
        _id: res?._id,
      }));
      dispatch({
        type: FETCH_CLIENTS,
        clients: response.data,
      });
      // if (accessLevel === "Client") {
      //   localStorage.setItem("org", JSON.stringify(res[0] || {}));
      //   dispatch({
      //     type: GETING_DATA_BY_LOCALSTORAGE,
      //     localClient: res[0] || {},
      //   });
      // }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      dispatch({
        type: FETCH_CLIENTS,
        response: { error: true, userMessage: message },
        clients: [],
      });
    });
};

export const getClientByLocalStorage = (dispatch) => {
  const localRes = localStorage.getItem("org");
  console.log(localRes,'localres')
  dispatch({
    type: GETING_DATA_BY_LOCALSTORAGE,
    localClient: JSON.parse(localRes),
  });
};

export const fetchSummary = (props) => (dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .get(`${API_URL}/customers/${props.organisationId}/summary`, {})
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        dispatch({
          type: SUMMARY_FETCHED,
          payload: response.data.data,
        });
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      toastr.error("Error", message);
    });
};

export const fetchSummaryData = (props, dispatch) => {
  //console.log("fetchSummaryData action")
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  if(props){
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .get(`${API_URL}/getDashboardSummary/${props}`, {})
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        dispatch({
          type: MAIN_SCREEN_SUMMARY_FETCHED,
          payload: response.data.data,
        });
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      toastr.error("Error", message);
    });
  }
};

export const fetchDashboardReport = (props, dispatch) => {
  //console.log("fetchDashboardReport action")
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  if(props){
  // dispatch({
  //   type: DASHBOARD_SUMMARY_FETCHED,
  //   payload: null,
  // });
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .get(`${API_URL}/getDashboardDetailsV1/${props}?page=Iodine`, {})
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        dispatch({
          type: DASHBOARD_SUMMARY_FETCHED,
          payload: response.data.data,
        });
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      toastr.error("Error", message);
    });
  }
}

export const fetchReportSetting = (props, dispatch) => {
  if(props){
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .get(`${API_URL}/reportsetting`, {})
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        dispatch({
          type: REPORT_SETTING,
          payload: response.data.data,
        });
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      toastr.error("Error", message);
    });
  }
}

export const fetchPendingCount = (props, dispatch) => {
  if(props){
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .get(`${API_URL}/ticket-system/${props}/pending-tasks`, {})
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        dispatch({
          type: PENDING_COUNT,
          payload: response.data.data,
        });
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      toastr.error("Error", message);
    });
  }
}

export const fetchRemidiationList = (props, dispatch) => {
  if(props){
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .get(`${API_URL}/ticket-system/${props}/remidiation`)
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        dispatch({
          type: REMEDIATION_LIST,
          payload: response.data.data,
        });
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      toastr.error("Error", message);
    });
  }
}


export const updateReportSetting = (props, dispatch,data) => {
  if(props){
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .patch(`${API_URL}/reportsetting`, data)
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        // dispatch({
        //   type: REPORT_SETTING,
        //   payload: response.data.data,
        // });
        toastr.success("Sucess", response.data.userMessage);
        fetchReportSetting(props,dispatch)
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      toastr.error("Error", message);
    });
  }
}

export const fetchVendorSummary = (props, dispatch) => {
  //console.log("fetchVendorSummary action")
  if(props){
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .get(`${API_URL}/getVendorDetails/${props}`, {})
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        dispatch({
          type: VENDOR_SUMMARY_FETCHED,
          payload: response.data.data,
        });
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      toastr.error("Error", message);
    });
  }
}
