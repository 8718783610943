import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Dropzone from "../common/DropzoneExample";
import { Col, Nav, Row, Tab, Tabs } from "react-bootstrap";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  deleteCustomer,
  deleteCustomerNew,
  fetchVendors,
  refreshCustomer,
  createVendor,
  updateVendor,
  updateCustomer,
  fetchThreatAnalyst,
} from "../../actions/customer";
import { IMAGE_URL } from "../../utils/constant";
import { Link } from "react-router-dom";
import $ from "jquery";
import { toastr } from "react-redux-toastr";
import DatePicker from "react-datepicker";
import { style } from "@mui/system";

var Model = require("../information/model");

const Clients = () => {
  console.log('clients newwwwwww')
  const dispatch = useDispatch();

  const auth = useSelector((state) => state?.auth?.user);
  const [orgId, setOrgId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isModal, setModal] = useState("");
  const [refreshModal, setRefreshModal] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [status, setStatus] = useState("all");
  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [PageSize, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [filterData, setfilterData] = useState("all");

  const [orgName, setOrgName] = useState("");
  const [orgEmail, setOrgEmail] = useState("");
  const [orgContactName, setOrgContactName] = useState("");
  const [orgContact, setOrgContact] = useState("");
  const [website, setWebsite] = useState("");
  const [emailAccess, setEmailAccess] = useState("");
  const [clientType, setClientType] = useState("4");
  const [domains, setDomain] = useState([]);
  const [authMethod, setAuthMethod] = useState("default");
  const [assignTo, setAssignTo] = useState("");
  const [loginUrl, setLoginUrl] = useState("");
  const [orgLogo, setOrgLogo] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("trial");

  const [keyWord, setKeyword] = useState("");
  const [license, setLicense] = useState("");

  let clientTypeData = [];
  if (JSON.parse(localStorage.getItem("user")).accessLevel == "MSSP")
    clientTypeData = [{ id: 1, text: "Client" }];
  else clientTypeData = [{ id: 4, text: "Vendor" }];

  //console.log(auth, "auth");
  let customers = useSelector((state) => state?.customer?.response?.data);
  const threatAnalyist = useSelector(
    (state) => state?.customer?.threatAnalyist
  );
  if (threatAnalyist && threatAnalyist.length > 0 && assignTo == "") {
    setAssignTo(threatAnalyist[0]._id);
  }
  useEffect(() => {
    fetchVendors(dispatch);
  }, []);

  let clientList = [],
    msspList = [];
  for (let count = 0; count < customers?.length; count++) {
    if (customers[count].clientType == 1) {
      clientList.push(customers[count]);
    }
  }
  for (let count = 0; count < customers?.length; count++) {
    if (customers[count].clientType == 3) {
      msspList.push(customers[count]);
    }
  }

  let mapData =
    filterData === "all"
      ? customers
      : filterData === "client"
      ? clientList
      : filterData === "mssp"
      ? msspList
      : null;

  mapData =
    search == ""
      ? mapData
      : mapData?.filter(
          (row) =>
            row?.orgName?.toLowerCase()?.indexOf(search?.toLowerCase()) != -1 ||
            row?.orgContactName
              ?.toLowerCase()
              ?.indexOf(search?.toLowerCase()) != -1 ||
            row?.orgEmail?.toLowerCase()?.indexOf(search?.toLowerCase()) != -1
        );

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    // setFirstLoad(false)
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = mapData?.slice(firstPageIndex, lastPageIndex);
  }
  const downloadCsvData = customers?.map((res) => ({
    Client: res?.orgName,
    PrimaryContactName: res?.orgContactName,
    Email: res?.orgEmail,
    Website: res?.website,

    // Severity: severity(res?.results?.cvss),
    // Ageing:
    //   moment().diff(moment(res?.results["last-modified"]), "days") + " days",
    // Description: res?.results?.summary,
  }));

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const handleClick = (id) => {
    //console.log($("#keyword").val(), id);
    if (id == "keyword") {
      let val = $("#keyword").val();
      let _keyWord = keyWord || [];
      if (_keyWord.indexOf(val) != -1) {
        toastr.error("Error", "This keyword is already added.");
        return false;
      }
      $("#keyword").val("");
      // _keyWord.push(val);
      setKeyword([..._keyWord, val]);
    } else {
      let val = $("#domain").val();
      let _domains = domains || [];
      if (_domains.indexOf(val) != -1) {
        toastr.error("Error", "This domain is already added.");
        return false;
      }
      var re = new RegExp(
        /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
      );
      var valid = val.match(re);
      if (valid) {
        $("#domain").val("");
        // _domains.push(val);
        setDomain([..._domains, val]);
      } else {
        toastr.error("Error", "Please enter a valid domain.");
        return false;
      }
    }
  };

  const removeKeyword = (_id, _keyword) => {
    //console.log("removeKeyword");
    if (_id == "keyword") {
      let newkeyword = keyWord;
      newkeyword.splice(newkeyword.indexOf(_keyword), 1);
      setKeyword([...newkeyword]);
    } else {
      let _domain = domains;
      _domain.splice(_domain.indexOf(_keyword), 1);
      setDomain([..._domain]);
    }
  };

  const removeOrgLogo = () => {
    setOrgLogo("");
  };

  const handleLogoChange = (e) => {
    //console.log(e, e.target.files[0]);
    // Model.default.saveOrgLogo
    Model.default.saveOrgLogo(e.target.files[0]).then((response) => {
      //console.log({ response });
      if (!response.error) {
        //console.log(response.data, "data");
        let orgLogo = response.data;
        setOrgLogo(orgLogo);
      }
    });
  };

  const submitForm = () => {
    if (orgName == "") {
      toastr.error("Error", "Please enter organisation name.");
      return false;
    }
    if (orgContactName == "") {
      toastr.error("Error", "Please enter contact name.");
      return false;
    }
    var regex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    if (!regex.test(website)) {
      toastr.error("Error", "Website address is incorrect.");
      return false;
    }
    if (orgEmail == "") {
      toastr.error("Error", "Please enter organisation email.");
      return false;
    }
    // if(emailAccess == ""){
    //   toastr.error("Error", "Please select email access.");
    //   return false;
    // }
    // if (clientType == "") {
    //   toastr.error("Error", "Please select client type.");
    //   return false;
    // }
    // if (subscriptionType == "") {
    //   toastr.error("Error", "Please select subscruption level.");
    //   return false;
    // }

    if (isEdit) {
      updateVendor(
        {
          orgName,
          orgEmail,
          orgContact,
          website,
          emailAccess,
          clientType,
          domains,
          authMethod,
          assignTo,
          orgLogo,
          subscriptionType,

          keyWord,
          orgId,
          orgContactName,
          loginUrl,
        },
        dispatch
      );
    } else {
      createVendor(
        {
          orgName,
          orgEmail,
          orgContact,
          website,
          emailAccess,
          clientType,
          domains,
          authMethod,
          assignTo,
          orgLogo,
          subscriptionType,
          keyWord,
          orgContactName,
          loginUrl,
        },
        dispatch
      );
    }

    setModal("");
  };

  const addModal = () => {
    setIsEdit(false);
    setOrgName("");
    setOrgEmail("");
    setOrgContact("");
    setWebsite("");
    // setEmailAccess("")
    // setClientType("")
    // setSubscriptionType("")
    setDomain([]);
    setAuthMethod("");
    setAssignTo("");
    setOrgLogo("");
    // setSubscriptionType("")

    setKeyword("");
    setOrgContactName("");
    setModal("Form Wizard modal");
  };

  const editModal = (row) => {
    //console.log(row, "row");
    setIsEdit(true);
    setOrgId(row?._id);
    setOrgName(row?.orgName);
    setOrgEmail(row?.orgEmail);
    setOrgContact(row?.orgContact);
    setWebsite(row?.website);
    setEmailAccess(row?.emailAccess);
    setClientType(row?.clientType);
    setSubscriptionType(row?.subscriptionType);
    setDomain(row?.domains);
    setAuthMethod(row?.authMethod);
    setAssignTo(row?.assignTo);
    setOrgLogo(row?.orgLogo);
    // setSubscriptionType(row?.subscriptionType)

    setKeyword(row?.keyWord);
    setOrgContactName(row?.orgContactName);
    setLoginUrl(row?.loginUrl);
    setModal("Form Wizard modal");
  };

  const deleteCustomerOption = (id) => {
    const self = this;
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to delete this customer?",
      type: "red",
      buttons: {
        ok: {
          text: "Delete",
          btnClass: "btn-danger",
          keys: ["enter"],
          action: function () {
            deleteCustomerNew(id, dispatch);
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  };

  //console.log(threatAnalyist, domains.length, "domains && domains.length");
  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h2>Vendors</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">BrandSek</li>
                <li className="breadcrumb-item active" aria-current="page">
                  Details
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div className="body">
              <div className="row clearfix">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Vendor Name"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-12">
                  {/* <span className="btn btn-sm btn-primary mr-1" title>
                    Search
                  </span> */}
                  {auth?.accessLevel != "Threat Analyst" && (
                    <button
                      type="button"
                      className="btn btn-sm btn-success"
                      onClick={() => addModal()}
                    >
                      Add New
                    </button>
                  )}
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12"></div>
                <div className="col-lg-3 col-md-4 col-sm-12">
                  {customers?.length === 0 ? null : (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        exportToCSV(downloadCsvData, "Vendor Details");
                      }}
                      style={{ float: "right" }}
                      href="/add-report"
                      className="btn btn-primary mr-1"
                    >
                      <i className="fa fa-file-excel-o"></i>
                    </a>
                  )}
                </div>
              </div>
              <br/>
              <table className="table table-striped mb-0">
                <tbody>
                  <tr>
                    <th>#</th>
                    <th>Vendor</th>
                    <th>Email Address</th>
                    <th>Website</th>
                    <th>Added on</th>
                    <th>Actions</th>
                  </tr>
                  {currentTableData?.map((item, index) => {
                    return (
                      <tr>
                        <td>
                          <span>{index + 1}</span>
                        </td>
                        <td>
                          <span className="w100 text-info">
                            <strong>{item.orgName}</strong>
                          </span>

                          <p>{item.orgContactName}</p>
                        </td>

                        <td>
                          <span>{item.orgEmail}</span>
                        </td>

                        <td>
                          <a href={`${item?.website}`} target="_blank">
                            {item?.website}
                          </a>
                        </td>
                        <td>
                          <span>
                            {" "}
                            {moment(item.createdAt).format(
                              "DD/MM/YYYY hh:mm a"
                            )}{" "}
                          </span>
                        </td>
                        <td>
                          {auth?.accessLevel != "Threat Analyst" && (
                            <button
                              className="btn btn-sm btn-default"
                              onClick={() => editModal(item)}
                            >
                              <i
                                className="icon-pencil"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit Vendor"
                              ></i>
                            </button>
                          )}
                          {auth?.accessLevel != "Threat Analyst" && (
                            <button className="btn btn-sm btn-default">
                              {" "}
                              <a
                                href={`/vendor/${item._id}`}
                                title=""
                                style={{ color: "#77797c" }}
                              >
                                <i
                                  className="icon-eye"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="View Details"
                                ></i>
                              </a>
                            </button>
                          )}
                          {auth?.accessLevel != "Threat Analyst" && (
                            <button
                              className="btn btn-sm btn-default"
                              onClick={() => {
                                setSelectedOrgId(item._id);
                                setRefreshModal(true);
                              }}
                            >
                              <i
                                className="icon-refresh"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Refresh Vendor"
                              ></i>
                            </button>
                          )}
                          {auth?.accessLevel != "Threat Analyst" && (
                            <button
                              className="btn btn-sm btn-default"
                              onClick={() => {
                                deleteCustomerOption(item._id);
                              }}
                            >
                              <i
                                className="icon-trash"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete Vendor"
                              ></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="dataTables_paginate">
                {mapData && mapData.length > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={mapData?.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal vendorMainModal ${
          isModal === "Form Wizard modal" ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg vendorModal" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add New Vendor
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModal("")}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card">
                  <div className="row clearfix">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Vendor Name *"
                          value={orgName}
                          onChange={(e) => setOrgName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email address *"
                          value={orgEmail}
                          onChange={(e) => setOrgEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Primary Contact Name"
                          value={orgContactName}
                          onChange={(e) => setOrgContactName(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Contact Number"
                            value={orgContact}
                            onChange={(e) => setOrgContact(e.target.value)}
                          />
                        </div>
                      </div> */}
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="icon-globe"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="http://"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <select
                          className="form-control"
                          value={emailAccess}
                          onChange={(e) => setEmailAccess(e.target.value)}
                        >
                          {/* <option value="">-- Email Access * --</option> */}
                          <option value="">
                            Want to provide application access?
                          </option>
                          <option value={false}>No</option>
                          <option value={true}>Yes</option>
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={clientType}
                            onChange={(e) => setClientType(e.target.value)}
                          >
                            <option value="">-- Select Client Type * --</option>
                            {clientTypeData?.map((row) => {
                              return <option value={row.id}>{row.text}</option>;
                            })}
                            <option value="client">Client</option>
                            <option value="mssp">MSSP</option>
                          </select>
                        </div>
                      </div> */}
                    {/* <div className="col-lg-6 col-md-12 clientCalendar">
                        <div className="form-group">
                          <DatePicker
                            selected={expiredAt}
                            minDate={new Date()}
                            showTimeSelect
                            timeFormat="p"
                            timeIntervals={1}
                            onChange={(date) => selectDate(date)}
                            className="form-control datetimepicker"
                            dateFormat="MMMM d, yyyy h:mm aa"
                          />
                        </div>
                      </div> */}
                    {clientType == 3 && (
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="License"
                            value={license}
                            onChange={(e) => setLicense(e.target.value)}
                          />
                        </div>
                      </div>
                    )}

                    {/* <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={subscriptionType}
                            onChange={(e) =>
                              setSubscriptionType(e.target.value)
                            }
                          >
                            <option value="">-- Subscription Level * --</option>
                            <option value="trial">Trial</option>
                            <option value="one-time">One time</option>
                            <option value="gold">Gold</option>
                            <option value="platinum">Platinum</option>
                          </select>
                        </div>
                      </div> */}
                    {/* {clientType != 3 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Domains"
                              name="domain"
                              id="domain"
                              style={{ float: "left", width: "89%" }}
                            />
                            <button
                              type="button"
                              style={{ float: "left" }}
                              className="btn btn-primary"
                              onClick={() => handleClick("domain")}
                            >
                              <i
                                className="fa fa-plus"
                                style={{ fontSize: "22px" }}
                              ></i>
                            </button>
                          </div>
                        </div>
                      )}
                      {domains && domains.length > 0 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="row">
                            {domains?.map((keyword) => {
                              return (
                                <div className="col-md-4">
                                  <label
                                    style={{
                                      color: "white",
                                      background: "#43a5f5",
                                      padding: "4px 6px",
                                    }}
                                  >
                                    <span>
                                      {keyword}{" "}
                                      <span
                                        className="fa fa-times-circle"
                                        onClick={() =>
                                          removeKeyword("domain", keyword)
                                        }
                                      ></span>
                                    </span>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )} */}

                    <div className="col-lg-12 col-md-12">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="inputGroupFile01"
                          onChange={handleLogoChange}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="inputGroupFile01"
                        >
                          Upload logo
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      {keyWord && keyWord.length > 0 && (
                        <div className="row">
                          {keyWord.map((keyword) => {
                            return (
                              <div className="col-md-4">
                                <label
                                  style={{
                                    color: "white",
                                    background: "#43a5f5",
                                    padding: "4px 6px",
                                  }}
                                >
                                  <span>
                                    {keyword}{" "}
                                    <span
                                      className="fa fa-times-circle"
                                      onClick={() =>
                                        removeKeyword("keyword", keyword)
                                      }
                                    ></span>
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>

                    <div className="col-lg-12 col-md-12">
                      {orgLogo && orgLogo != "" && (
                        <div className="col-lg-6 col-md-12">
                          <div className="row">
                            <img
                              style={{ width: "100px" }}
                              src={`${IMAGE_URL}${orgLogo}`}
                            />
                            <span
                              className="fa fa-times-circle"
                              style={{
                                position: "absolute",
                                marginTop: "-8px",
                                marginLeft: "-8px",
                                color: "red",
                              }}
                              onClick={() => removeOrgLogo()}
                            ></span>
                          </div>
                        </div>
                      )}
                    </div>

                    <hr />
                  </div>
                  <button
                    type="button"
                    className="btn btn-round btn-primary mr-1"
                    onClick={submitForm}
                  >
                    {isEdit ? "Update" : "Create"}
                  </button>
                  &nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade  ${refreshModal ? "d-block show" : ""}`}
        id="exampleModal"
        onClick={() => setRefreshModal(false)}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Refresh Client?
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to refresh?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-round btn-default"
                data-dismiss="modal"
                onClick={() => setRefreshModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-round btn-primary"
                onClick={() => {
                  refreshCustomer(selectedOrgId);
                  setRefreshModal(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
