import axios from "axios";
import { API_URL } from "../utils/constant";
import {
  CUSTOMERS_FETCHED,
  CUSTOMER_CREATED,
  CREATING_CUSTOMER,
  EDIT_CUSTOMER_FETCHED,
  UPDATING_CUSTOMER,
  CUSTOMER_UPDATED,
  DELETING_CUSTOMER,
  CUSTOMER_DELETED,
  FETCHING_CUSTOMERS,
  FETCHING_THREAT_ANALYIST,
  CREATING_VENDOR,
  VENDORS_FETCHED,
  VENDOR_CREATED,
  FETCHING_VENDORS,
  UPDATE_COUNTER,
  GETING_DATA_BY_LOCALSTORAGE,
  USER_LOGGED_IN,
  USERS_FETCHED,
  USERS_CREATED
} from "./index";
import { toastr } from "react-redux-toastr";


export const fetchCustomers = (props) => (dispatch) => {

  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FETCHING_CUSTOMERS,
    payload: null,
  });
  axios
    .get(`${API_URL}/customer`, {})
    .then((response) => {
      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};


export const createCustomer = (props, auth, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: CREATING_CUSTOMER,
    payload: null,
  });
  axios
    .post(`${API_URL}/customer`, { ...props })
    .then((response) => {
      // window.location.href = '/customers'
      if (response.data.error)
        toastr.error("Errpr", response.data.userMessage);
      else
        toastr.success("Success", response.data.userMessage);
      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: response.data,
      });
      if (auth?.organisationId?.clientType == 3) {
        console.log(response.data.user, response.data.data, 'response.data.data.user')
        dispatch({
          type: USER_LOGGED_IN,
          payload: response.data.user,
        });
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }


      dispatch({
        type: CUSTOMER_CREATED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const createVendor = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: CREATING_VENDOR,
    payload: null,
  });
  axios
    .post(`${API_URL}/vendor`, { ...props })
    .then((response) => {
      // window.location.href = '/customers'
      if (response.data.error)
        toastr.error("Errpr", response.data.userMessage);
      else
        toastr.success("Success", response.data.userMessage);
      dispatch({
        type: VENDORS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      dispatch({
        type: VENDOR_CREATED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const updateVendor = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  // dispatch({
  //   type: VENDORS_FETCHED,
  //   payload: null,
  // });
  axios
    .put(`${API_URL}/vendor`, { ...props, customerId: props.orgId })
    .then((response) => {
      if (response.data.error)
        toastr.error("Errpr", response.data.userMessage);
      else
        toastr.success("Success", response.data.userMessage);
      dispatch({
        type: VENDORS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: VENDORS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const customerApproveToggle = (props) => (dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .put(`${API_URL}/customer`, { ...props })
    .then((response) => {
      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const fetchCustomersById = (props) => (dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  dispatch({
    type: FETCHING_CUSTOMERS,
    payload: null,
  });

  axios
    .get(`${API_URL}/customer/${props}`, {})
    .then((response) => {
      dispatch({
        type: EDIT_CUSTOMER_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: EDIT_CUSTOMER_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const deleteCustomer = (props) => (dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: DELETING_CUSTOMER,
    payload: null,
  });
  axios
    .delete(`${API_URL}/customer/${props}`, {})
    .then((response) => {
      dispatch({
        type: CUSTOMER_DELETED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: CUSTOMER_DELETED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const updateCustomer = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: UPDATING_CUSTOMER,
    payload: null,
  });
  axios
    .put(`${API_URL}/customer`, { ...props, customerId: props.orgId })
    .then((response) => {
      if (response.data.error)
        toastr.error("Errpr", response.data.userMessage);
      else
        toastr.success("Success", response.data.userMessage);
      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: CUSTOMER_UPDATED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const refreshCustomer = (props) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  // dispatch({
  //   type: CREATING_CUSTOMER,
  //   payload: null,
  // });
  axios
    .post(`${API_URL}/customer/refresh/${props}`)
    .then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        toastr.success("Success", response.data.userMessage);
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      // dispatch({
      //   type: CUSTOMER_CREATED,
      //   payload: { error: true, userMessage: message },
      // });
    });

}

export const fetchThreatAnalyst = (dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FETCHING_THREAT_ANALYIST,
    payload: null,
  });
  axios
    .get(`${API_URL}/threatAnalyist`, {})
    .then((response) => {
      dispatch({
        type: FETCHING_THREAT_ANALYIST,
        payload: response.data.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: FETCHING_THREAT_ANALYIST,
        payload: { error: true, userMessage: message },
      });
    });
}

export const fetchCustomersNew = (dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FETCHING_CUSTOMERS,
    payload: null,
  });
  axios
    .get(`${API_URL}/customer`, {})
    .then((response) => {
      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const fetchUsers = (props, dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .get(`${API_URL}/user?id=${props}`, {})
    .then((response) => {
      dispatch({
        type: USERS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: USERS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const sendUserInvite = (props) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .get(`${API_URL}/send-reminder/${props.email}`)
    .then((response) => {
      if (response.data.error)
        toastr.error("Error", response.data.userMessage);
      else
        toastr.success("Success", response.data.userMessage);
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
    });
}

export const updateUserById = (props, dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  axios.patch(`${API_URL}/user`, { ...props }).then((response) => {
    if (!response.data.error) {
      dispatch({
        type: USERS_FETCHED,
        payload: response.data,
      });
      toastr.success("Success", response.data.userMessage);
    } else {
      toastr.error("Error", response.data.userMessage);
    }
  });
};

export const createUser = (props, dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  axios
    .post(`${API_URL}/user`, { ...props })
    .then((response) => {
      dispatch({
        type: USERS_FETCHED,
        payload: response.data,
      });
      if (!response.data.error) {
        toastr.success("Success", response.data.userMessage);
      } else {
        toastr.error("Error", response.data.userMessage);
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
    });
};

export const deleteUser = (props,dispatch) => {
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .delete(`${API_URL}/user/${props}`, {})
    .then((response) => {
      dispatch({
        type: USERS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
    });
};


export const fetchVendors = (dispatch) => {
  //console.log("vendorcalled","customer called");

  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FETCHING_VENDORS,
    payload: null,
  });
  axios
    .get(`${API_URL}/vendor`, {})
    .then((response) => {
      dispatch({
        type: VENDORS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: VENDORS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};


export const deleteCustomerNew = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  // dispatch({
  //   type: DELETING_CUSTOMER,
  //   payload: null,
  // });
  axios
    .delete(`${API_URL}/customer/${props}`, {})
    .then((response) => {
      if (response.data.error)
        toastr.error("Errpr", response.data.userMessage);
      else
        toastr.success("Success", response.data.userMessage);
      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const updateScanCounter = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  // dispatch({
  //   type: VENDORS_FETCHED,
  //   payload: null,
  // });
  axios
    .put(`${API_URL}/updatedScanCounter/${props.scanCounter}/${props.orgId}`)
    .then((response) => {
      if (response.data.error)
        toastr.error("Errpr", response.data.userMessage);
      else
        toastr.success("Success", response.data.userMessage);
      localStorage.setItem("org", JSON.stringify(response.data.data));
      dispatch({
        type: GETING_DATA_BY_LOCALSTORAGE,
        localClient: response.data.data,
      });
      window.location.reload();
      // dispatch({
      //   type: VENDORS_FETCHED,
      //   payload: response.data,
      // });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: VENDORS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};