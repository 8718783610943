import React, { useEffect, useState, useRef } from "react";
import imgOne from "../../assets/images/business-contiunity.png"
import imgTwo from "../../assets/images/post-incident.png"
import imgThree from "../../assets/images/cyber-resilance.png"
const Detect = () => {
    return (
        <div>
            <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
                <div className="col-md-2"></div>
                <div className='col-md-2 gradinet-effect p-3 m-2'>
                    <img src={imgOne} className="serviceImg" />
                    <h6>
                        Business Continuity and
                        Disaster Recovery Planning
                    </h6>
                    <p>
                        We assist customers in preparing and executing plans to resume vital functions and services following a cyber incident. This is achieved through business continuity plans, disaster recovery plans, business impact analysis, recovery point objectives (RPO), and recovery time objectives (RTO).
                    </p>
                </div>
                <div className='col-md-2 gradinet-effect p-3 m-2'>
                    <img src={imgTwo} className="serviceImg" />
                    <h6>
                        Post-Incident
                        Remediation and Improvement
                    </h6>
                    <p>
                        We help clients prevent and solve cyber incidents by conducting root-cause analysis, implementing lessons learned, action plans, and continuous improvement.
                    </p>
                </div>
                <div className='col-md-2 gradinet-effect p-3 m-2'>
                    <img src={imgThree} className="serviceImg" />
                    <h6>
                        Cyber Resilience
                        Strategy and Implementation
                    </h6>
                    <p>
                        We assist clients in strengthening their ability to withstand and adapt to cyber incidents by utilizing cyber resilience frameworks, metrics, controls, and culture.
                    </p>
                </div>
            </div>
        </div>
    );
}
export default Detect;