import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchIpInventory,
  fetchIpInventoryAction,
  fetchIpInventoryGraph,
} from "../../actions/inventory";
import { Link } from "react-router-dom";
import moment from "moment";
import GaugeChart from "react-gauge-chart";
// import BasicColumn from "../common/apexChart/basicColumn";
import Pagination from "../Pagination/Pagination";
import BasicColumn from "../common/apexChart/basicLineColumn";
import TrialComponent from "../common/trialComponent";
import NoData from "../common/noDataComponetnt";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import SideNav from "../resuableComponent/sidenav";
import Header from "../resuableComponent/header";
import Loader from "../resuableComponent/loader";
import {
  fetchSubDomains,
  addRemediationData,
  removeRemediationData,
} from "../../actions/sub-domain";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import BasicLineColumn from "../common/apexChart/lineChart";
import { useLocation } from "react-router-dom";
import $ from "jquery";


const IPBlacklist = () => {
  const id = useSelector(
    (state) =>
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
  );
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.data?.[0]
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const [filterData, setfilterData] = useState("all");
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  let state = useSelector((state) => state?.inventory?.list);
  const isLoaded = useSelector((state) => state?.inventory?.loaded);
  const scanCompleted = useSelector((state) => state?.inventory?.scanCompleted);
  const [category, setCategory] = useState("all");
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const [totalItems, setTotalItems] = useState(0);
  const scanDetails = useSelector(
    (state) => state?.inventory?.ipblackgraph?.data
  );
  const [checkPath, setCheckPath] = useState('');
  let remediationList = useSelector(
    (state) => state?.auth?.remediationListAll?.data?.data
  );

  const [addedRemediations, setAddedRemediations] = useState([]);

  const cartDispatch = useDispatch();

  const removeToCart = (_id) => e => {
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to remove this from the Remediation List Panel?",
      type: "red",
      buttons: {
        ok: {
          text: "Remove",
          btnClass: "btn-danger",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            removeRemediationData(_id, cartDispatch, id).then((res) => {
              fetchIpInventory(id, dispatch ,page ,category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  const addToCart = (row) => e => {
    let data = {
      "collectionName":'deltaipdetails',
      "organisationId":id,
      "recordId":row?._id,
      "moduleName":"ip_blacklist"
    }
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to add this to the Remediation List Panel ?",
      type: "green",
      buttons: {
        ok: {
          text: "Add",
          btnClass: "btn-green",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            addRemediationData(data, cartDispatch).then((res) => {
              fetchIpInventory(id, dispatch ,page ,category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }



  useEffect(() => {
    if (remediationList) {
      const updatedRemediations = remediationList.reduce((results, elem) => {
        return { ...results, [elem._id]: elem };
      }, {});
      setAddedRemediations(updatedRemediations);
    }
  }, [remediationList]);

  useEffect(()=>{
    setCheckPath(location.search)
  },[location.search])

  //console.log("uniqueCloudProvider", uniqueCloudProvider);

  let compliantValue = [],
    ipArray = [];

  useEffect(() => {
    fetchIpInventory(id, dispatch ,page ,category);
  }, [id ,page ,category]);

  useEffect(()=>{
    setTotalItems(state?.totalIps)
  },[state?.totalIps])

  useEffect(() => {
    fetchIpInventoryGraph(id, dispatch);
  }, [id, dispatch]);

  let mapData = state?.data;

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const [containerClass, setContainerClass] = useState("data-container");
  useEffect(() => {
    if (!isLoaded) {
      setTimeout(() => {
        setContainerClass("data-container active");
      }, 300);
    }
  }, [isLoaded]);

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        blackListedIPIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchIpInventoryAction(id, dispatch, page, category, data);
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      blackListedIPIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchIpInventoryAction(id, dispatch, page, category, data);
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      blackListedIPIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchIpInventoryAction(id, dispatch, page, category, data);
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  return (
    <div>
      {/* <Header type="breach" />
      <SideNav type="breach" /> */}

      {!isLoaded ? (
        <div className={containerClass}>
          <div className="row clearfix no_margin" style={{ marginTop: "20px" }}>
            <div className="col-sm-2"></div>
            <div className="col-sm-9">
              <div className="row_boxes">
                <div className="gradinet-effect w-100">
                  {scanDetails && (
                    <BasicLineColumn
                      dates={scanDetails?.dates}
                      ipArray={scanDetails?.scanDetails}
                      color={["#6574cd"]}
                      yAxis="Records Found"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix no_margin" style={{ marginTop: "20px" }}>
            <div className="col-sm-2"></div>
            <div className="col-sm-9" style={{ padding: "0" }}>
              <div className="row_boxes row_boxes_table">
                <div className="table_box">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Host Name</th>
                        <th>Cloud / ISP Provider</th>
                        <th>Location</th>
                        <th>Blacklist Database</th>
                        <th>Last modified on</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.map((row, index) => {
                        // //console.log(ror,row)
                        let services = [];
                        row?.services?.map((res, index) => {
                          if (services.indexOf(res?.service_name) == -1)
                            services.push(res?.service_name);
                        });
                        return (
                          <tr>
                            <td style={{ whiteSpace: "unset" }}>
                                {/* <span>{index + 1}</span> */}
                                <span
                                  className={
                                    row?.isBlackListed == true
                                      ? "badge badge-critical"
                                      : "badge badge-low"
                                  }
                                >
                                  {row?.isBlackListed == true
                                    ? "Blacklisted"
                                    : "Not blacklisted"}
                                </span>
                              </td>
                              <td style={{ whiteSpace: "unset" }}>
                                <div className="d-flex align-items-center">
                                  <div
                                    className="ml-3"
                                    style={{
                                      color: "#ffffff",
                                      fontWeight: 400,
                                      fontSize: 14,
                                    }}
                                  >
                                    {row?.ip}
                                  </div>
                                </div>
                              </td>
                              <td style={{ whiteSpace: "unset" }}>
                                {row?.cloudOrISPProvider}
                              </td>

                              <td style={{ whiteSpace: "unset" }}>
                                {row?.location}
                              </td>
                              <td style={{ whiteSpace: "unset" }}>
                                {row?.blackListSource?.map((row, index) => {
                                  return (
                                    <span
                                      id={`ipblacklist${index}`}
                                      data-tip={row}
                                      className="badge badge-warning"
                                    >
                                      {row}
                                    </span>
                                  );
                                })}
                              </td>
                              <td style={{ whiteSpace: "unset" }}>
                                <span className="badge badge-success">
                                  {moment(
                                    row?.updatedAt || row?.createdAt
                                  ).format("DD-MM-YYYY")}
                                </span>
                              </td>
                              {checkPath == '?mode=assessment-mode' ?
                            <td>
                              {row?.isTicketChecked == false ? (
                                <button
                                  onClick={addToCart({
                                    ...row,
                                    remediationType: "IP Blacklist",
                                  })}
                                >
                                  <span className="fa fa-plus action_icon"></span>
                                </button>
                              ) : (
                                <button onClick={removeToCart(row?._id)}>
                                  <span className="fa fa-minus action_icon"></span>
                                </button>
                              )}
                            </td>
                            : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={`modal fade Form-Wizard-modal ${
              isModal === "Form Wizard modal" ? "d-block show" : ""
            }`}
          >
            <div className="modal-dialog modal-lg" id="clientModal">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {action == "markAsFalsePositive"
                      ? "Mark As False Positive"
                      : "Risk Accepted"}
                  </h5>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setModal("");
                      setActionNote("");
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body pricing_page modal_mob">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="card">
                      <div className="row clearfix">
                        <div className="col-md-12">
                          <label className="col-lg-6 col-md-12">
                            Snooze for
                          </label>
                          <div className="form-group ol-lg-12 col-md-12">
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={(e) => setForValue(e.target.value)}
                            >
                              <option value="forever">Forever</option>
                              <option value="3 months">3 Months</option>
                              <option value="6 months">6 Months</option>
                              <option value="12 months">12 Months</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label className="col-lg-12 col-md-12">Detail</label>
                          <div className="form-group col-lg-12 col-md-12">
                            <textarea
                              onChange={(e) => setActionNote(e.target.value)}
                              value={actionNote}
                              className="form-control"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-lg-12 col-md-12"
                          style={{ marginRight: "28px" }}
                        >
                          <button
                            type="button"
                            style={{ color: "black", fontWeight: "bold" }}
                            onClick={submit}
                            className="btn btn-round btn-primary mr-1 pull-right"
                          >
                            Submit
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="button"
                            onClick={() => {
                              setModal("");
                              setActionNote("");
                            }}
                            className="btn btn-round btn-default pull-right"
                            style={{ marginRight: "10px" }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default IPBlacklist;
