import { CREATING_CODE_RECORD } from "../actions";

const initialState = {
  data: [],
  scanCompleted: true,
  sourcecodegraph:[]
};
export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_LEAKED_CODE":
      return {
        ...state,
        data: action.data,
        scanCompleted: action.data.scanCompleted
      };
    case "SET_LEAKED_CODE_GRAPH":
      return {
        ...state,
        sourcecodegraph: action.payload,
        loading: true,
      }
    case CREATING_CODE_RECORD:
      return {
        ...state,
        response: action.payload,
        loading: true,
      };
    default:
      return state;
  }
}
