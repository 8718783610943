import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";
import moment from 'moment';
import Chart from 'react-apexcharts';

class BasicLineColumn extends Component {
    constructor(props) {
        super(props);
        console.log(props,'color props')
        const color = ['#2F7FB8','#D4983A']
        console.log(props, 'basic line top')
        // let largest = props?.ipArray.sort((a,b)=>a-b)[props?.ipArray.length - 1];
        //console.log(props, 'basic line',largest)
        this.state = {
            options: {
                grid: {
                    show: false
                  },
                chart: {
                    id:'mixed-chart',
                    toolbar: {
                        show: false // Hide the toolbar icons
                      }
                    // height: 350,
                    // toolbar: {
                    //     show: false,
                    // },
                },
                colors: color,
                // stroke: {
                //     width: [0, 1]
                // },
                // dataLabels: {
                //     enabled: false
                // },
                // tooltip: {
                //     y: {
                //         formatter: function (value, { dataPointIndex, w }) {
                //             return w.config.series[0].data[dataPointIndex]
                //             //or you can judt add 'lac' to value
                //             //return `${value} lac`
                //         }
                //     }
                // },
                // yaxis: {
                //     min: 0,
                //     // max: largest + 10,
                //     tickAmount: 4,
                //     title: {
                //         text: props?.yAxis || '',
                //     },
                // },

                plotOptions: {
                    bar: {
                        horizontal: false,
                        // borderRadius: 8,
                        columnWidth: "5%",
                        dataLabels: {
                            position: "top" // Add data labels at the top of the bars
                        },
                        style: {
                            borderRadius: '8px 8px 8px 8px' // Apply border radius only at the top corners
                        },
                        zIndex: 1000
                    },
                },
                // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                // labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
                labels:  props?.dates?.map((row) => moment(row).format('DD-MM-YYYY')),
                xaxis: {
                    // type: 'datetime'
                },
                yaxis: [{
                    title: {
                        text: props?.yAxis || 'Total Scan',
                    },

                }, {
                    opposite: true,
                    title: {
                        text: 'Compliance Percentage'
                    }
                }]
            },
            series: [{
                name: props?.yAxis || 'Total Scan',
                type: 'column',
                data: props?.ipArray
            }, {
                name: 'Compliance Percentage',
                type: 'line',
                data: props?.openPortArray
            }]
        //     [
        //         {
        //         name: props?.yAxis || 'Total Scan',
        //         type: 'column',
        //         data: props?.ipArray
        //     }
        // ]
        };

        // const chartOptions = {
        //     // Chart options...
        //     // (e.g., title, axes labels, etc.)
        //     options: {
        //       // ...other options...
        //       chart: {
        //         id: 'mixed-chart' // Unique ID for the chart
        //       }
        //     },
        //   };
    }

    render() {
        return (
            <div>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="line"
                    height={this.props.height || "350px"}
                />
            </div>
        );
    }
}

export default BasicLineColumn;
