import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import GaugeChart from "react-gauge-chart";
import ScanMessage from "../common/scanningMessage";
import { useSelector, useDispatch } from "react-redux";
import img from "../../../src/assets/images/race_png.png"
import {
  fetchSubDomains,
  fetchSubDomainsAction,
  addRemediationData,
  removeRemediationData,
  fetchSubDomainsTotal,
  fetchSubDomainsGraph,
} from "../../actions/sub-domain";
import Pagination from "../Pagination/Pagination";
import ReactTooltip from "react-tooltip";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BasicLineColumn from "../common/apexChart/lineChart";
import AccessPage from "../Shared/accessPage";
import SideNav from '../resuableComponent/sidenav';
import Header from '../resuableComponent/header';
import Loader from "../resuableComponent/loader";
import CanvasJSReact from '@canvasjs/react-charts';
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import $ from "jquery";
import { useLocation } from "react-router-dom";

//var CanvasJSReact = require('@canvasjs/react-charts');

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Dashboard5 = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  let subdomainsTotals = useSelector(
    (state) => state?.subDomain?.expiredtotal?.data
  );
  let remediationList = useSelector((state) => state?.auth?.remediationListAll?.data?.data);

  const [addedRemediations, setAddedRemediations] = useState([]);
  const scanDetails = useSelector(
    (state) => state?.subDomain?.expiredgraph?.data
  );
  const subdomains = useSelector((state) => state?.subDomain?.responseexp?.data);
  const subdomainTotal = useSelector((state) => state?.subDomain?.responseexp);

  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);

  const [firstLoad, setFirstLoad] = useState(true);
  const [riskScore, setRiskScore] = useState("");

  const [expiryLoad, setExpiryLoad] = useState(true);
  const [riskExpiryScore, setExpiryRiskScore] = useState("");

  const [filterData, setfilterData] = useState("all");
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("all");
  const [totalItems, setTotalItems] = useState(0);
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const [checkPath, setCheckPath] = useState('');

  const myRef = useRef(null);

  const id = useSelector(
    (state) =>
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
  );

  useEffect(() => {
    fetchSubDomainsTotal(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    fetchSubDomainsGraph(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    setTotalItems(subdomainTotal?.totalResults)
  }, [subdomainTotal?.totalResults])

  useEffect(() => {
    fetchSubDomains(id, page, dispatch, category);
  }, [id, category, page]);

  useEffect(()=>{
    setCheckPath(location.search)
  },[location.search])

  let currentTableData = [];

  let mapData = subdomains;

  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  if (mapData && mapData.length > 0) {
    setTimeout(function () {
      ReactTooltip.rebuild();
    }, 1000);
  }


  // end of rating system calculation

  const [containerClass, setContainerClass] = useState("data-container");

  useEffect(() => {
    if (subdomains) {
      setTimeout(() => {
        setContainerClass("data-container active");
      }, 300);
    }
  }, [subdomains]);

  const cartDispatch = useDispatch();

  useEffect(() => {
    if (remediationList) {
      const updatedRemediations = remediationList.reduce((results, elem) => {
        return { ...results, [elem._id]: elem };
      }, {});
      setAddedRemediations(updatedRemediations);
    }
  }, [remediationList]);

  
  const removeToCart = (_id) => e => {
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to remove this from the Remediation List Panel?",
      type: "red",
      buttons: {
        ok: {
          text: "Remove",
          btnClass: "btn-danger",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            removeRemediationData(_id, cartDispatch, id).then((res) => {
              fetchSubDomains(id, page, dispatch, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  const addToCart = (row) => e => {
    let data = {
      "collectionName":'deltasubdomaindetails',
      "organisationId":id,
      "recordId":row?._id,
      "moduleName":'ssl_certificates'
    }
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to add this to the Remediation List Panel ?",
      type: "green",
      buttons: {
        ok: {
          text: "Add",
          btnClass: "btn-green",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            addRemediationData(data, cartDispatch).then((res) => {
              fetchSubDomains(id, page, dispatch, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        subDomainIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchSubDomainsAction(
        id,
        dispatch,
        page,
        category,
        data
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      subDomainIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchSubDomainsAction(
      id,
      dispatch,
      page,
      category,
      data
    );
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      subDomainIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchSubDomainsAction(
      id,
      dispatch,
      page,
      category,
      data
    );
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  return (
    <div>

      {/* <Header type="security" />
      <SideNav type="security" /> */}

      {subdomains ?
        <div className={containerClass}>
          <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
            <div className='col-sm-2'></div>
            <div className='col-sm-9'>
              <div className="row">
              <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card mb-2">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="row clearfix">
                      <div className="col-12">
                        <h5 className="mb-0">
                          {subdomainsTotals?.totalExpiredSSls}
                        </h5>
                        <small className="text-muted color-white">Expired</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card mb-2">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="row clearfix">
                      <div className="col-12">
                        <h5 className="mb-0">
                          {subdomainsTotals?.totalSSLsWithRenewalIn30Days}
                        </h5>
                        <small className="text-muted color-white">Renewal in 30 days</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card mb-2">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="row clearfix">
                      <div className="col-7">
                        <h5 className="mb-0">
                          {subdomainsTotals?.totalSSLsWithRenewalIn60Days}
                        </h5>
                        <small className="text-muted color-white">Renewal in 60 days</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card mb-2">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="row clearfix">
                      <div className="col-7">
                        <h5 className="mb-0">
                          {subdomainsTotals?.totalSSLsWithRenewalIn90Days}
                        </h5>
                        <small className="text-muted color-white">Renewal in 90 days</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card mb-2">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="row clearfix">
                      <div className="col-12">
                        <h5 className="mb-0">
                          {subdomainsTotals?.mismatchedCertificateCount}
                        </h5>
                        <small className="text-muted color-white">Mismatched Certificate</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card mb-2">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="row clearfix">
                      <div className="col-12">
                        <h5 className="mb-0">
                          {subdomainsTotals?.revokedCertificateCount}
                        </h5>
                        <small className="text-muted color-white">Revoked Certificate</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card mb-2">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="row clearfix">
                      <div className="col-12">
                        <h5 className="mb-0">
                          {subdomainsTotals?.selfSignedCertificateCount}
                        </h5>
                        <small className="text-muted color-white">Self Signed Certificate</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card mb-2">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="row clearfix">
                      <div className="col-12">
                        <h5 className="mb-0">
                          {subdomainsTotals?.untrustedCertificateCount}
                        </h5>
                        <small className="text-muted color-white">Untrusted Certificate</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card mb-2">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="row clearfix">
                      <div className="col-12">
                        <h5 className="mb-0">
                          {subdomainsTotals?.totalSubDomainsHavingCertificatesSupportingWeakCiphers}
                        </h5>
                        <small className="text-muted color-white">SubDomains Supporting Weak Ciphers</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card mb-2">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="row clearfix">
                      <div className="col-12">
                        <h5 className="mb-0">
                          {subdomainsTotals?.totalSubDomainsHavingCertificatesWithOutdatedTlsVersion}
                        </h5>
                        <small className="text-muted color-white">Subdomains with Outdated TLS Version</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                {/* <div className="col-md-12">
                  <div className="row_boxes row_boxes_expire">
                    <div className="box">
                      <div className="text_box">
                        <div className="boxA">
                          <h5>{subdomainsTotals?.totalExpiredSSls}</h5>
                          <p>Expired</p>
                        </div>
                        <div className="boxB">
                          <h5>{(
                            subdomainsTotals?.totalExpiredSSls /
                            subdomainsTotals?.totalSSLRecords
                          ).toFixed(0) * 100}
                            %</h5>
                        </div>
                      </div>
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="20"
                      >
                        <div
                          className="progress-bar red_progress_bar"
                          style={{
                            width:
                              (
                                subdomainsTotals?.totalExpiredSSls /
                                subdomainsTotals?.totalSSLRecords
                              ).toFixed(0) *
                              100 +
                              "%",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="text_box">
                        <div className="boxA">
                          <h5>{subdomainsTotals?.totalSSLsWithRenewalIn30Days}</h5>
                          <p>Renewal in 30 days</p>
                        </div>
                        <div className="boxB">
                          <h5>{Math.floor(
                            (subdomainsTotals?.totalSSLsWithRenewalIn30Days /
                              subdomainsTotals?.totalSSLRecords) *
                            100
                          )}
                            %</h5>
                        </div>
                      </div>
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div
                          className="progress-bar red_progress_bar"
                          style={{
                            width:
                              Math.floor(
                                (subdomainsTotals?.totalSSLsWithRenewalIn30Days /
                                  subdomainsTotals?.totalSSLRecords) *
                                100
                              ) + "%",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="text_box">
                        <div className="boxA">
                          <h5>{subdomainsTotals?.totalSSLsWithRenewalIn60Days}</h5>
                          <p>Renewal in 60 days</p>
                        </div>
                        <div class="boxB">
                          <h5>{Math.floor(
                            (subdomainsTotals?.totalSSLsWithRenewalIn60Days /
                              subdomainsTotals?.totalSSLRecords) *
                            100
                          )}
                            %</h5>
                        </div>
                      </div>
                      <div
                        ClassName="progress"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="20"
                      >
                        <div
                          ClassName="progress-bar red_progress_bar"
                          style={{
                            width:
                              Math.floor(
                                (subdomainsTotals?.totalSSLsWithRenewalIn60Days /
                                  subdomainsTotals?.totalSSLRecords) *
                                100
                              ) + "%",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="text_box">
                        <div className="boxA">
                          <h5>{subdomainsTotals?.totalSSLsWithRenewalIn90Days}</h5>
                          <p>Renewal in 90 days</p>
                        </div>
                        <div className="boxB">
                          <h5>{Math.floor(
                            (subdomainsTotals?.totalSSLsWithRenewalIn90Days /
                              subdomainsTotals?.totalSSLRecords) *
                            100
                          )}
                            %</h5>
                        </div>
                      </div>
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="Basic example"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="20"
                      >
                        <div
                          className="progress-bar red_progress_bar"
                          style={{
                            width:
                              Math.floor(
                                (subdomainsTotals?.totalSSLsWithRenewalIn90Days /
                                  subdomainsTotals?.totalSSLRecords) *
                                100
                              ) + "%",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row_boxes row_boxes_security">
                    <div className="box_timer">
                      <div className="img_box">
                        <div class='speed'>
                          <div class='speed__wheel'>
                            <div class='speed__tick'>
                              <div class='tick' value='0'></div>
                              <div class='tick' value='10'></div>
                              <div class='tick' value='20'></div>
                              <div class='tick' value='30'></div>
                              <div class='tick' value='40'></div>
                              <div class='tick' value='50'></div>
                              <div class='tick' value='60'></div>
                              <div class='tick' value='70'></div>
                              <div class='tick' value='80'></div>
                              <div class='tick' value='90'></div>
                              <div class='tick' value='100'></div>
                              <div class='tick' value='110'></div>
                              <div class='tick' value='120'></div>
                              <div class='tick' value='130'></div>
                              <div class='tick' value='140'></div>
                              <div class='tick' value='150'></div>
                              <div class='tick' value='160'></div>
                              <div class='tick' value='170'></div>
                              <div class='tick' value='180'></div>
                              <div class='tick' value='190'></div>
                            </div>
                            <div class='pointer'
                              style={{
                                transform: `rotate(${(-75 + 50)}deg)`
                              }}>
                            </div>
                            <div class='endpoint'></div>
                          </div>
                        </div>
                        {/* <img src={img} className="img-fluid" alt=""/> */}
                      </div>
                      <div className="text_box_timer">
                        <div className="expired_49">
                          <h3>01 <span>49</span></h3>
                          <p>
                            Expired Certificates
                          </p>
                        </div>
                        <div className="expired_49">
                          <h3>43 <span>49</span></h3>
                          <p>
                            Vulnerable
                            Certificates
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="graph">
                      {scanDetails && (
                        <BasicLineColumn
                          height={250}
                          dates={scanDetails?.dates}
                          ipArray={scanDetails?.scanDetails}
                          color={["#008ffb", "#00e396"]}
                          yAxis="Unique Vulnerabilities"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
            <div className='col-sm-2'></div>
            <div className='col-sm-9' style={{ padding: '0' }}>
              <div className="row_boxes row_boxes_table">
                {/* <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("all");
                        setPage(1);
                        setfilterData("all");
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      Current
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("risk-accepted");
                        setPage(1);
                        setfilterData("all");
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      Risk Accepted
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("false-positive");
                        setPage(1);
                        setfilterData("all");
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      False Positive
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("remidiated");
                        setPage(1);
                        setfilterData("all");
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      Remediated
                    </a>
                  </li>
                </ul> */}
                <div className="table_box">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Sub-Domain Name</th>
                        <th>Expiry in</th>
                        <th>Country</th>
                        <th>Serial Number</th>
                        <th>Vulnerabilties</th>
                        <th>Last modified on</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.map((row) => {
                        return (
                          <tr>
                            <td>
                              <span
                                className={
                                  row?.status == "Active"
                                    ? "badge badge-success"
                                    : "badge badge-danger"
                                }
                              >
                                {row?.status}
                              </span>
                            </td>
                            <td>
                              <span className="text-warning">
                                {row?.subDomainName || "NA"}
                              </span>
                            </td>
                            <td>
                              {row?.expiresInDays + " days" || "NA"}
                            </td>
                            <td>{row?.issuerCountry || "NA"}</td>
                            <td>
                              {row?.certificateSerialNumber || "NA"}
                            </td>
                            <td>
                              {row?.vulnerabilties?.map((vul, index) => {
                                return (
                                  <>
                                    <span
                                      id={`vulnerability${index}`}
                                      data-tip={vul?.finding}
                                      className={
                                        vul?.id == "scanProblem" ||
                                          vul?.id == "scanTime"
                                          ? "badge badge-danger"
                                          : "badge badge-warning"
                                      }
                                    >
                                      {vul?.id == "scanProblem" ||
                                        vul?.id == "scanTime"
                                        ? "ERROR"
                                        : vul?.id}
                                    </span>
                                  </>
                                );
                              })}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                              <span className="badge badge-success">
                                {moment(
                                  row?.updatedAt || row?.createdAt
                                ).format("DD-MM-YYYY")}
                              </span>
                            </td>
                            {checkPath == '?mode=assessment-mode' ?
                            <td>
                            {row?.isTicketChecked == false ? (
                                <button
                                  onClick={addToCart({
                                    ...row,
                                    remediationType: "Sub Domain",
                                  })}
                                >
                                  <span className="fa fa-plus action_icon"></span>
                                </button>
                              ) : (
                                <button onClick={removeToCart(row?._id)}>
                                  <span className="fa fa-minus action_icon"></span>
                                </button>
                              )}
                            </td>
                            : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
              }`}
          >
            <div className="modal-dialog modal-lg" id="clientModal">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {action == "markAsFalsePositive"
                      ? "Mark As False Positive"
                      : "Risk Accepted"}
                  </h5>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setModal("");
                      setActionNote("");
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body pricing_page modal_mob">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="card">
                      <div className="row clearfix">
                        <div className="col-md-12">
                          <label className="col-lg-6 col-md-12">Snooze for</label>
                          <div className="form-group ol-lg-12 col-md-12">
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={(e) => setForValue(e.target.value)}
                            >
                              <option value="forever">Forever</option>
                              <option value="3 months">3 Months</option>
                              <option value="6 months">6 Months</option>
                              <option value="12 months">12 Months</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label className="col-lg-12 col-md-12">Detail</label>
                          <div className="form-group col-lg-12 col-md-12">
                            <textarea
                              onChange={(e) => setActionNote(e.target.value)}
                              value={actionNote}
                              className="form-control"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-lg-12 col-md-12"
                          style={{ marginRight: "28px" }}
                        >
                          <button
                            type="button"
                            style={{ color: "black", fontWeight: "bold" }}
                            onClick={submit}
                            className="btn btn-round btn-primary mr-1 pull-right"
                          >
                            Submit
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="button"
                            onClick={() => {
                              setModal("");
                              setActionNote("");
                            }}
                            className="btn btn-round btn-default pull-right"
                            style={{ marginRight: "10px" }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> :
        <Loader />
      }

    </div>
  );
}

export default Dashboard5;