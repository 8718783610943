import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toastr } from "react-redux-toastr";
import $ from "jquery";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GaugeChart from "react-gauge-chart";
import { VectorMap } from "react-jvectormap";
import SideNav from '../resuableComponent/sidenav';
import Header from '../resuableComponent/header';
import BasicLineColumn from "../common/apexChart/lineChart";

class DetailScreen extends Component {


    render() {
        let markers = [{ latLng: ["39.0438", "-77.4874"], name: 'United States (US) - 11' },
        { latLng: ['12.9896', '80.2485'], name: 'India (IN) - 33' },
        { latLng: ['45.5017', '-73.5673'], name: 'Canada (CA) - 1' },
        { latLng: ['40.1728', '29.0774'], name: 'Turkey (TR) - 1' },
        { latLng: ['38.0849', '-0.9455'], name: 'Spain (ES) - 1' }];
        let values = { India: "#ffec94" }
        console.log(markers, 'markers')
        return (
            <div>
                
                {/* <Header type="breach"/>
                <SideNav type="breach" /> */}

                <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
                    <div className='col-sm-2'></div>
                    <div className='col-sm-9 gradinet-effect'>
                        <BasicLineColumn
                            dates={['04-11-2023', '04-13-2023', '04-14-2023', '04-15-2023', '04-17-2023']}
                            ipArray={[10, 23, 27, 64, 73]}
                            color={["#6574cd"]}
                            yAxis="Records Found"
                        />
                    </div>
                </div>

                <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
                    <div className='col-sm-2'></div>
                    <div className='col-sm-9' style={{ padding: '0' }}>
                        <div className="row_boxes row_boxes_table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{ width: "20px" }}>Status</th>
                                        <th style={{ width: "140px" }}>Host Name</th>
                                        <th style={{ width: "80px" }}>Cloud / ISP Provider</th>
                                        <th style={{ width: "80px" }}>Location</th>
                                        <th style={{ width: "120px" }}>Blacklist Database</th>
                                        <th>Last modified on</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="badge badge-critical">
                                                Blacklisted
                                            </span>
                                        </td>
                                        <td>157.197.144.72</td>
                                        <td>Samsungsds</td>
                                        <td>South Korea (KR), Seongnam-si</td>
                                        <td>
                                            <span className="badge badge-warning">
                                                KOREA.SERVICES.NET
                                            </span>
                                        </td>
                                        <td>
                                            <span className="badge badge-success">
                                                17-04-2023
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="badge badge-critical">
                                                Blacklisted
                                            </span>
                                        </td>
                                        <td>157.197.144.72</td>
                                        <td>Samsungsds</td>
                                        <td>South Korea (KR), Seongnam-si</td>
                                        <td>
                                            <span className="badge badge-warning">
                                                KOREA.SERVICES.NET
                                            </span>
                                        </td>
                                        <td>
                                            <span className="badge badge-success">
                                                17-04-2023
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="badge badge-critical">
                                                Blacklisted
                                            </span>
                                        </td>
                                        <td>157.197.144.72</td>
                                        <td>Samsungsds</td>
                                        <td>South Korea (KR), Seongnam-si</td>
                                        <td>
                                            <span className="badge badge-warning">
                                                KOREA.SERVICES.NET
                                            </span>
                                        </td>
                                        <td>
                                            <span className="badge badge-success">
                                                17-04-2023
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="badge badge-critical">
                                                Blacklisted
                                            </span>
                                        </td>
                                        <td>157.197.144.72</td>
                                        <td>Samsungsds</td>
                                        <td>South Korea (KR), Seongnam-si</td>
                                        <td>
                                            <span className="badge badge-warning">
                                                KOREA.SERVICES.NET
                                            </span>
                                        </td>
                                        <td>
                                            <span className="badge badge-success">
                                                17-04-2023
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="badge badge-critical">
                                                Blacklisted
                                            </span>
                                        </td>
                                        <td>157.197.144.72</td>
                                        <td>Samsungsds</td>
                                        <td>South Korea (KR), Seongnam-si</td>
                                        <td>
                                            <span className="badge badge-warning">
                                                KOREA.SERVICES.NET
                                            </span>
                                        </td>
                                        <td>
                                            <span className="badge badge-success">
                                                17-04-2023
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="badge badge-critical">
                                                Blacklisted
                                            </span>
                                        </td>
                                        <td>157.197.144.72</td>
                                        <td>Samsungsds</td>
                                        <td>South Korea (KR), Seongnam-si</td>
                                        <td>
                                            <span className="badge badge-warning">
                                                KOREA.SERVICES.NET
                                            </span>
                                        </td>
                                        <td>
                                            <span className="badge badge-success">
                                                17-04-2023
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="badge badge-critical">
                                                Blacklisted
                                            </span>
                                        </td>
                                        <td>157.197.144.72</td>
                                        <td>Samsungsds</td>
                                        <td>South Korea (KR), Seongnam-si</td>
                                        <td>
                                            <span className="badge badge-warning">
                                                KOREA.SERVICES.NET
                                            </span>
                                        </td>
                                        <td>
                                            <span className="badge badge-success">
                                                17-04-2023
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default DetailScreen;