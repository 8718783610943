// import config from "config";
// export const API_URL= process.env.REACT_APP_API_URL || process.env.NODE_ENV === 'development' ? 'http://localhost:8090/api/admin': 'http://trook.kiwisofttech.com/api/admin';

// console.log(process.env.REACT_APP_API_URL || "https://api.brandsek.com/api");

// export const API_URL = config.apiUrl;
// export const API_URL = "http://localhost:8080/api";
export const API_URL = "https://api.iodin.io/api";
// export const API_URL = "https://api.brandsek.com/api";

// export const IMAGE_URL = config.imageapiUrl;
// export const IMAGE_URL = "http://localhost:8080";
export const IMAGE_URL = "https://api.iodin.io";

export const DEHASH_API_URL = "https://api.iodin.io/api";
export const MEDIA_URL =
  process.env.REACT_APP_MEDIA_URL || "https://api.iodin.io";

export const GOOGLE_MAPS_API =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyA6rzkm6pD8bXg_L5HpcJ3YSmbE1mKkWG8&v=3.exp&libraries=geometry,drawing,places";
