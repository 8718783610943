import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Domains from '../SecurityRisk/SubDomain';
import ExpiredSSLCertificates from '../SecurityRisk/ExpiredSSLCertificates';
import KnownVulnerabilities from '../SecurityRisk/KnownVulnerabilities';
import WebTechnology from '../SecurityRisk/WebTechnology';
import Clients from '../Projects/Vendors';
import SubDomainDiscovery from '../SecurityRisk/SubDomainDiscovery';
// import Clients from '../Projects/Clients';

const Security = ({match}) => {
    return (
        <>
            <Switch>
                <Route path={`${match.path}/domains`} component={Domains} />
                <Route path={`${match.path}/known-vulnerabilities`} component={KnownVulnerabilities} />
                <Route path={`${match.path}/ssl-certificates`} component={ExpiredSSLCertificates} />
                <Route path={`${match.path}/web-technology`} component={WebTechnology} />
                <Route path={`${match.path}/sub-domain`} component={SubDomainDiscovery} />
                <Route path={`${match.path}`} component={Domains} />
            </Switch>
        </>
    )
}

export default Security;