import React, { useEffect, useState, useRef } from "react";
import imgOne from "../../assets/images/Incident-Response.png"
import imgTwo from "../../assets/images/Crisis Managemet.png"
import imgThree from "../../assets/images/ransomware.png"
import imgFour from "../../assets/images/breach.png"
const Detect = () => {
    return (
        <div>
            <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
                <div className="col-md-2"></div>
                <div className='col-md-2 gradinet-effect p-3 m-2'>
                    <img src={imgOne} className="serviceImg" />
                    <h6>
                        Incident Response
                        and Forensics
                    </h6>
                    <p>
                        We assist clients in containing and resolving cyber incidents by utilizing incident response plans, teams, and forensic analysis tools.
                    </p>
                </div>
                <div className='col-md-2 gradinet-effect p-3 m-2'>
                    <img src={imgTwo} className="serviceImg" />
                    <h6>
                        Crisis Management
                        and Communication
                    </h6>
                    <p>
                        We assist clients in managing and communicating the impact and consequences of cyber incidents using crisis management plans, teams, and communication strategies.
                    </p>
                </div>
                <div className='col-md-2 gradinet-effect p-3 m-2'>
                    <img src={imgThree} className="serviceImg" />
                    <h6>

                        Ransomware
                        Recovery and Mitigation
                    </h6>
                    <p>
                        We assist clients in recovering and restoring their data and operations following a ransomware attack using recovery, decryption, ransom negotiation, and backup options.
                    </p>
                </div>
                <div className='col-md-2 gradinet-effect p-3 m-2'>
                    <img src={imgFour} className="serviceImg" />
                    <h6>
                        Breach Simulation
                        and Testing
                    </h6>
                    <p>
                        We assist clients in testing and enhancing their readiness and response capabilities by simulating realistic cyber scenarios and attacks using tools such as breach simulation, red teaming, blue teaming, purple teaming, and more.
                    </p>
                </div>
            </div>
        </div>
    );
}
export default Detect;