import React, { useEffect, useState, useRef } from "react";
import imgOne from "../../assets/images/security-monitoring.png"
import imgTwo from "../../assets/images/threat-intellgince.png"
import imgThree from "../../assets/images/vulnerability-assement.png"
const Detect = () => {
  return (
    <div>
      <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
        <div className="col-md-2"></div>
        <div className='col-md-2 gradinet-effect p-3 m-2'>
          <img src={imgOne} className="serviceImg" />
          <h6>
            Security Monitoring and Analytics
          </h6>
          <p>
            We assist our clients using security information and event management (SIEM), security orchestration automation and response (SOAR), AI, and ML to monitor systems and networks for malicious or anomalous activity.
          </p>
        </div>
        <div className='col-md-2 gradinet-effect p-3 m-2'>
          <img src={imgTwo} className="serviceImg" />
          <h6>
            Threat Intelligence
          </h6>
          <p>
            We provide our clients with a comprehensive threat intelligence solution, iOdin, which enables us to identify and analyze current and emerging cyber threats from various sources.
          </p>
        </div>
        <div className='col-md-2 gradinet-effect p-3 m-2'>
          <img src={imgThree} className="serviceImg" />
          <h6>
            Vulnerability Assessment
            and Penetration Testing
          </h6>
          <p>
            We help clients identify and exploit weaknesses in their systems and networks by using vulnerability scanning and penetration testing tools.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Detect;