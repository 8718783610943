import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../utils/constant";
import { getUserById, updateUserById, getLogedInUser } from "../../actions/users";
import { resetPassword } from "../../actions/auth";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import $ from "jquery";
import { countryCodes } from "../../utils/countryCode";
import img2 from "../../assets/images/dummyImage.png";


var Model = require("../information/model");


const PageProfile = (props) => {
console.log(props,'props')
  let profilePic = img2;
  if(JSON.parse(localStorage.getItem("user")).logo && JSON.parse(localStorage.getItem("user")).logo != "")
		profilePic = `${IMAGE_URL}${JSON.parse(localStorage.getItem("user")).logo}`
	const data = useSelector((state) => state?.users?.logedInUser?.data);
  console.log(data,'data')
  const dispatch = useDispatch();
	const [email, setEmail] = useState("");
	const [primaryContactName, setPrimaryContactName] = useState("");
	const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
	const [logo, setLogo] = useState("");

  useEffect(() => {
    setPrimaryContactName(data?.fullName);
    setEmail(data?.email);
    setPhone(data?.contact);
    setCountryCode(data?.countryCode);
  setLogo(data?.logo)
  }, [data]);

  useEffect(() => {
    getLogedInUser('',dispatch);
  }, []);

  //console.log(data,'data')
  const submit = (e) => {
		e.preventDefault();
		updateUserById(
			{
				id: data?._id,
				fullName: primaryContactName,
				email: email,
        countryCode: countryCode,
				contact: phone,
				logo: logo,
				redirect: true
			}
		);
	};

	const handleLogoChange = (e) => {
		//console.log(e, e.target.files[0])
		Model.default.saveOrgLogo(e.target.files[0]).then((response) => {
			//console.log({ response });
			if (!response.error) {
				//console.log(response.data, 'data')
				let orgLogo = response.data;
				setLogo(orgLogo)
			}
		});
	}

  const showPassword = (e) => {
    //console.log(e);
    // //console.log(e.getAttribute("type"))
    document.getElementById("current_password").setAttribute("type", document.getElementById("current_password").getAttribute("type") === 'password' ? 'text' : 'password');
    document.getElementById("toggle1").setAttribute("class", document.getElementById("current_password").getAttribute("type") === 'password' ? 'toggle-password fa fa-eye' : 'toggle-password fa fa-eye-slash');
}
const showPassword1 = (e) => {
    //console.log(e);
    // //console.log(e.getAttribute("type"))
    document.getElementById("new_password").setAttribute("type", document.getElementById("new_password").getAttribute("type") === 'password' ? 'text' : 'password');
    document.getElementById("toggle2").setAttribute("class", document.getElementById("new_password").getAttribute("type") === 'password' ? 'toggle-password fa fa-eye' : 'toggle-password fa fa-eye-slash');
}
const showPassword2 = (e) => {
    //console.log(e);
    // //console.log(e.getAttribute("type"))
    document.getElementById("confirm_password").setAttribute("type", document.getElementById("confirm_password").getAttribute("type") === 'password' ? 'text' : 'password');
    document.getElementById("toggle3").setAttribute("class", document.getElementById("confirm_password").getAttribute("type") === 'password' ? 'toggle-password fa fa-eye' : 'toggle-password fa fa-eye-slash');
}

const updatePassword = (e) => {
  console.log('click')
  let current_password = $("#current_password").val();
  let new_password = $("#new_password").val();
  let confirm_password = $("#confirm_password").val()
  if(!current_password){
      toastr.error("Error", "Please enter current password");
      return false;
  }
  if(!new_password){
      toastr.error("Error", "Please enter new password");
      return false;
  }
  if(!confirm_password){
      toastr.error("Error", "Please enter confirm password");
      return false;
  }
  if(new_password != confirm_password){
      toastr.error("Error", "Password mismatch.");
      return false;
  }

  resetPassword({ 
    password:current_password,
    confirmPassword:confirm_password, 
    redirect:true,
    propRedirect : props
   },dispatch);

}
  return (
    <>
      <div className="container-fluid">
        <div className="row clearfix no_margin">
          <div className="col-md-12">
            <div className="row clearfix no_margin">
              <div className="col-md-2">

              </div>
              <div className="col-md-9">
                <div className="card">
                  <div className="body" style={{minHeight:"266px"}}>
                    <div className="header">
                      <h2>Edit Profile</h2>
                    </div>
                    <div className="row clearfix no_margin">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                            value={primaryContactName} 
                            onChange={(e) => setPrimaryContactName(e.target.value)}
                            // defaultValue={
                            //   JSON.parse(localStorage.getItem("user")).fullName
                            // }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email Address"
                            disabled={true} 
                            value={email}
                            onChange={(e) => 
                              setEmail(e.target.value)
                            }
                            // defaultValue={
                            //   JSON.parse(localStorage.getItem("user")).email
                            // }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row clearfix no_margin">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group" style={{display:"flex"}}>
                          <select className="form-control" value={countryCode} onChange={(e) => setCountryCode(e.target.value)} style={{width:'100px'}}>
                            {countryCodes?.map((val) => {
                              return <option value={val.dial_code}>{val.name}</option>
                            })}
                          </select>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Contact Number"
                            value={phone}
												    onChange={(e) => setPhone(e.target.value)}
                            // defaultValue={
                            //   JSON.parse(localStorage.getItem("user")).contact
                            // }
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            // value={appStoreResource}
                            // onChange={(e) =>
                            //   setAppStoreResource(e.target.value)
                            // }
                          >
                            <option value="">-- Select Role --</option>
                            <option value="Admin">Admin</option>
                            <option value="Threat Analyst">
                              Threat Analyst
                            </option>
                          </select>
                        </div>
                      </div> */}
                      <div className="col-lg-6 col-md-12">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="inputGroupFile01"
                            onChange={handleLogoChange}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="inputGroupFile01"
                          >
                            Upload Profile Picture
                          </label>
                        </div>
                      </div>
                    </div>
                    <br />
                    <button
                      type="button"
                      className="btn btn-round btn-primary mr-1"
                      onClick={submit}
                    >
                      Update
                    </button>{" "}
                    &nbsp;&nbsp;
                    <button type="button" className="btn btn-round btn-default">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-2">

              </div>
              <div className="col-md-9">
                <div className="card">
                  <div className="body" style={{minHeight:"266px"}}>
                    <div className="row clearfix no_margin">
                      <div className="col-lg-12 col-md-12">
                        <div className="header">
                          <h2>Change Password</h2>
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Current Password"
                            id="current_password"
                          />
                          <span id="toggle1" style={{marginRight:"8px",marginTop:"-16px"}} onClick={(e) => showPassword(e)} className="fa fa-eye toggle-password"></span>
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="New Password"
                            id="new_password" 
                          />
                          <span id="toggle2" style={{marginRight:"8px",marginTop:"-16px"}} onClick={(e) => showPassword1(e)} className="fa fa-eye toggle-password"></span>
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Confirm New Password"
                            id="confirm_password" 
                          />
                          <span id="toggle3" style={{marginRight:"8px",marginTop:"-16px"}} onClick={(e) => showPassword2(e)} className="fa fa-eye toggle-password"></span>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-round btn-primary mr-1"
                      onClick={()=>updatePassword()}
                    >
                      Update
                    </button>{" "}
                    &nbsp;&nbsp;
                    <button type="button" className="btn btn-round btn-default">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PageProfile;
