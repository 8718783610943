import $ from "jquery";
import { API_URL } from "../../utils/constant";
import {
    toastr
} from "react-redux-toastr";

var Actions = {
    saveOrgLogo: file => {
        return new Promise((resolve, reject) => {
            var form = new FormData();
            form.append("file", file);
            const session = localStorage.getItem("session");
            var settings = {
                async: true,
                /*  */
                crossDomain: true,
                url: API_URL + "/customer/uploadImage",
                method: "POST",
                processData: false,
                contentType: false,
                mimeType: "multipart/form-data",
                headers: {
                    Authorization: "Bearer " + session
                },
                data: form,
                success: function (response) {
                    response = JSON.parse(response);
                    if (response.error) {
                        toastr.error("Error", response.userMessage);
                    } else {
                        resolve(response);
                    }
                },
                error: function (error) {
                    //console.log({
                    //     error
                    // });

                    if (error.status == 401) {
                        localStorage.clear();
                    } else {
                        // message.error("Some error has occurred.");
                    }
                }
            };

            $.ajax(settings).done();
        });
    },
    uploadFile: file => {
        return new Promise((resolve, reject) => {
            var form = new FormData();
            form.append("file", file);
            const session = localStorage.getItem("session");
            var settings = {
                async: true,
                /*  */
                crossDomain: true,
                url: API_URL + "/customer/uploadExecutiveList",
                method: "POST",
                processData: false,
                contentType: false,
                mimeType: "multipart/form-data",
                headers: {
                    Authorization: "Bearer " + session
                },
                data: form,
                success: function (response) {
                    response = JSON.parse(response);
                    if (response.error) {
                        toastr.error("Error", response.userMessage);
                    } else {
                        resolve(response);
                    }
                },
                error: function (error) {
                    //console.log({
                    //     error
                    // });

                    if (error.status == 401) {
                        localStorage.clear();
                    } else {
                        // message.error("Some error has occurred.");
                    }
                }
            };

            $.ajax(settings).done();
        });
    }
}

export default Actions;