import { FETCH_MOBILE_APP,FETCH_MOBILE__GRAPH, FETCH_FAKE_SOCIAL_MEDIA_EXCEL, SUSPICIOUS_CLOUD_GRAPH, FETCH_FAKE_SOCIAL_MEDIA_TOTAL, FETCH_FAKE_SOCIAL_MEDIA, FETCH_DARK_WEB_MENTION, FETCH_WEB_DEFACEMENT } from "../actions";
const initialState = {
  mobileData: [],
  cloudgraph:[],
  fetchedMobileData: [],
  fakeSocialMedia: [],
  darkWebMention: [],
  webDefacement: [],
  faketotal:[],
  fakemobapp:[],
  socialMediaGraph:[],
  scanCompleted: true
};

const fakeMobileApp = (state = initialState, action) => {
  switch (action.type) {
    case SUSPICIOUS_CLOUD_GRAPH:
      return {
        ...state,
        cloudgraph: action.data,
      };
    case FETCH_MOBILE_APP:
      return {
        ...state,
        mobileData: action.mobileData,
        scanCompleted: action.mobileData.scanCompletd
      };
    case "FETCHED_MOBILE_DATA":
      return {
        ...state,
        fetchedMobileData: action.fetchedMobileData,
      };
    case "FETCH_FAKE_SOCIAL_MEDIA":
      return {
        ...state,
        fakeSocialMedia: action.mobileData,
        scanCompleted: action.mobileData.scanCompletd
      };
    case "FETCH_DARK_WEB_MENTION":
      return {
        ...state,
        darkWebMention: action.data,
      };
      case FETCH_FAKE_SOCIAL_MEDIA_EXCEL:
        return {
          ...state,
          socialMediaGraph: action.action.data,
        };
    case FETCH_WEB_DEFACEMENT:
      return {
        ...state,
        webDefacement: action.data,
      };
      case FETCH_MOBILE__GRAPH:
      return {
        ...state,
        fakemobapp: action.mobileData,
      };
      case FETCH_FAKE_SOCIAL_MEDIA_TOTAL:
      return {
        ...state,
        faketotal: action.fakeSocialCount,
      };
      
    default:
      return state;
  }
};

export default fakeMobileApp;
