// import React, { useState, useEffect } from 'react';
// import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
// import configureStore from './store';
// import toastr  from 'react-redux-toastr';
// import './index.css';
// import App from './App';
// import ReduxToastr from 'react-redux-toastr';
// import { BrowserRouter } from 'react-router-dom';
// import axios from 'axios'; // Import axios
// import * as serviceWorker from './serviceWorker';
// import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
// import 'jquery-confirm/dist/jquery-confirm.min.css';
// window.$ = window.JQuery = require("jquery");
// require('jquery-confirm');

// const store = configureStore();

// const [loading, setLoading] = useState(false);

// const showSpinner = () => {
//   setLoading(true);
// };

// const hideSpinner = () => {
//   setLoading(false);
// };
// axios.interceptors.request.use(config => {
//   return config;
// }, error => {
//   return Promise.reject(error);
// });

// axios.interceptors.response.use(response => {
//     if (response?.status == 401 || response?.data?.status == '401') {
//         toastr.success("Error", response.data.userMessage);
//         window.location.reload()
//         localStorage.clear()
//         sessionStorage.clear()
//         window.location.replace("/login");
//       }
//   return response;
// }, error => {
//   return Promise.reject(error);
// });

// ReactDOM.render(
//   <Provider store={store}>
//     <React.StrictMode>
//       <ReduxToastr />
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </React.StrictMode>
//   </Provider>,
//   document.getElementById('root')
// );

// serviceWorker.unregister();


import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';
import toastr from 'react-redux-toastr';
import './index.css';
import App from './App';
import ReduxToastr from 'react-redux-toastr';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios'; // Import axios
import * as serviceWorker from './serviceWorker';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'jquery-confirm/dist/jquery-confirm.min.css';
import Loader from './components/Components/loader';
window.$ = window.JQuery = require("jquery");
require('jquery-confirm');

const store = configureStore();

const AppWithInterceptors = () => {
  const [loading, setLoading] = React.useState(false);

  const showSpinner = () => {
    setLoading(true);
  };

  const hideSpinner = () => {
    setLoading(false);
  };

  React.useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(config => {
    //   showSpinner();
      return config;
    }, error => {
      return Promise.reject(error);
    });

    const responseInterceptor = axios.interceptors.response.use(response => {
      if (response?.status === 401 || response?.data?.status === '401') {
        toastr.success("Error", response.data.userMessage);
        // window.location.reload();
        // localStorage.clear();
        // sessionStorage.clear();
        // window.location.replace("/login");
      }
    //   hideSpinner();
      return response;
    }, error => {
    //   hideSpinner();
      return Promise.reject(error);
    });

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return (
    <Provider store={store}>
      <React.StrictMode>
      {/* {loading && <Loader />} */}
        <ReduxToastr />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  );
};

ReactDOM.render(
  <AppWithInterceptors />,
  document.getElementById('root')
);

serviceWorker.unregister();

