import React, { Component } from "react";
// import metisMenu from './metisMenu';
import DefaultLink from "./DefaultLink";
import MetisMenu from "react-metismenu";

const SideBar = (props) => {
  console.log(props.props.accessLevel,'propsesss')
  const toggleSubMenu = (e) => {
    let menucClass = "";
    if (e.itemId) {
      const subClass = e.items.map((menuItem) => {
        if (
          menuItem.id === "main" ||
          menuItem.id === "app" ||
          menuItem.id === "account" ||
          menuItem.id === "extra" ||
          menuItem.id === "ui"
        ) {
          menucClass = "header";
        }
        if (menuItem.to === props.location.pathname) {
          menucClass = "";
        } else {
          menucClass = "collapse";
        }
        return menucClass;
      });
      return subClass;
      // return "collapse";
    } else {
      return e.visible ? "collapse" : "metismenu";
    }
  };
  let metisMenu = [];

  if (props.props.accessLevel !== "Threat Analyst") {
    metisMenu.push({
      id: "main",
      label: "Main",
    });
  }

  if (props.props.accessLevel !== "Threat Analyst") {
    metisMenu.push({
      id: 101,
      icon: "icon-home",
      label: "Dashboard",
      to: "/summary",
    });
    //   metisMenu.push({
    //     id: 107,
    //     label: "Assets Discovery",
    //     to: "/assets",
    //   });
  }

  // if (props.props.accessLevel !== "Threat Analyst") {
  //   metisMenu.push({
  //     id: 7002,
  //     icon: "icon-lock",
  //     label: "Asset Discovery",
  //     to: "/",
  //     content: [

  //     ],
  //   });
  // }

  if (props.props.accessLevel === "Admin" || props.props.accessLevel === "Client" || (props.props.accessLevel === "Manager" && props.props.services.indexOf('AttackSurface') > -1)) {
    // if (props.props.accessLevel !== "Threat Analyst") {
    metisMenu.push({
      id: 1001,
      icon: "icon-shield",
      label: "Attack Surface",
      to: "/",
      content: [
        {
          id: 716,
          label: "IP Discovery",
          to: "/assets",
        },

        // {
        //   id: 7162,
        //   label: "Open Ports",
        //   to: "/assets",
        // },

        {
          id: 716,
          label: "Subdomains",
          to: "/subdomains",
        },
        {
          id: 718,
          label: "DNS Records",
          to: "/domains",
        },
        {
          id: 717,
          label: "Outdated web technologies",
          to: "/web-technology",
        },
        {
          id: 7170,
          label: "Web Security",
          to: "/security-header",
        },
        {
          id: 109,
          label: "Passive Vulnerability Assessment",
          to: "/known-vulnerabilities",
        },

        {
          id: 111,
          label: "Email security",
          to: "/spf-dmarc",
        },

        {
          id: 110,
          label: "Expired SSL Certificates",
          to: "/ssl-certificates",
        },
        {
          id: 112,
          label: "Vulnerable SSL Certificates",
          to: "/vulnerable-certificates",
        },

        {
          id: 11,
          label: "Dangling Subdomains",
          to: "/danglingdomain",
        },

        {
          id: 1161,
          label: "Stealer Logs",
          to: "/malware-credentials",
        },
      
        {
          id: 117,
          label: "Cloud Storage",
          to: "/cloud-bucket",
        },
        {
          id: 118,
          label: "Blacklisted IP",
          to: "/ip-blacklist",
        },
      ],
    });
  }

  // if (props.props.accessLevel !== "Threat Analyst") {
  if (props.props.accessLevel === "Admin" || props.props.accessLevel === "Client" || (props.props.accessLevel === "Manager" && props.props.services.indexOf('DarkWeb') > -1)) {
    metisMenu.push({
      id: 2002,
      icon: "icon-lock",
      label: 'Darkweb & Data Leak',
      to: "/",
      content: [
        {
          id: 116,
          label: "Breached Credentials",
          to: "/stolen-credentials",
        },

        {
          id: 1162,
          label: "Leaked Sessions",
          to: "/leaked-sessions",
        },
        {
          id: 115,
          label: "Source Code Leakage",
          to: "/source-code",
        },

        {
          id: 1261,
          label: "Pastebin Search",
          to: "/pastebin-search",
        },

        {
          id: 1262,
          label: "Personal Information",
          to: "/pii",
        },

      ],
    });
  }
  // if (props.props.accessLevel !== "Threat Analyst") {
  if (props.props.accessLevel === "Admin" || props.props.accessLevel === "Client" || (props.props.accessLevel === "Manager" && props.props.services.indexOf('BrandSecurity') > -1)) {
    metisMenu.push({
      id: 2001,
      icon: "icon-layers",
      label: "Brand Security",
      to: "/",
      content: [
        {
          id: 1102,
          label: "Look-alike Domains",
          to: "/look-alike",
        },
        {
          id: 113,
          label: "Rogue Mobile Apps",
          to: "/mobile-apps",
        },
        {
          id: 114,
          label: "Social Media Profiles",
          to: "/social-media",
        },
        {
          id: 115,
          label: "Web defacement",
          to: "/web-defacement",
        },
        // {
        //   id: 1162,
        //   label: "Phishing Domains",
        //   to: "/phishing",
        // },
      ],
    });
  }

  // if (
  //   props.props.accessLevel !== "Admin" &&
  //   props.props.accessLevel !== "MSSP" &&
  //   props.props.accessLevel !== "Threat Analyst" &&
  //   props.props.accessLevel !== "Vendor"
  // ) {
  if (props.props.accessLevel === "Client" || (props.props.accessLevel === "Manager" && props.props.services.indexOf('TPSA') > -1)) {
    metisMenu.push({
      id: "app",
      label: "Third Parties",
    });
  }
  // if (
  //   props.props.accessLevel !== "Admin" &&
  //   props.props.accessLevel !== "MSSP" &&
  //   props.props.accessLevel !== "Threat Analyst" &&
  //   props.props.accessLevel !== "Vendor"
  // ) {
  if (props.props.accessLevel === "Client" || (props.props.accessLevel === "Manager" && props.props.services.indexOf('TPSA') > -1)) {
    metisMenu.push({
      id: 4001,
      icon: "icon-layers",
      label: "TPSA",
      to: "/",
      content: [
        {
          id: 41101,
          label: "Dashboard",
          to: "/vendor-summary",
        },
        {
          id: 41102,
          label: "Manage Vendor",
          to: "/vendor",
        },
      ],
    });
  }

  metisMenu.push({
    id: "main",
    label: "Manage",
  });

  if (props.props.accessLevel === "Admin" || (props.props.accessLevel === "Client" && props.props.orgAdmin)) {
    metisMenu.push({
      id: 106,
      icon: "icon-users",
      label: "Invite User",
      to: "/invite-user",
    });
  }
  if (props.props.accessLevel !== "Threat Analyst") {
    metisMenu.push({
      id: 501,
      icon: "icon-user",
      label: "Profile",
      to: "/profile",
    });
  }
  if (
    props.props.accessLevel !== "Client" &&
    props.props.accessLevel !== "Manager" &&
    props.props.accessLevel !== "Vendor"
  ) {
    metisMenu.push({
      id: 105,
      icon: "icon-briefcase",
      label: "Clients",
      to: "/client",
    });
  }

  if (props.props.accessLevel !== "Threat Analyst") {
    metisMenu.push({
      id: 502,
      icon: "icon-envelope",
      label: "Integrations",
      to: "/integrations",
    });
  }

  // if (props.props.accessLevel !== "Threat Analyst") {
  //   metisMenu.push({
  //     id: 503,
  //     icon: "icon-credit-card",
  //     label: "My plan",
  //     to: "/subscription",
  //   });
  // }
  // if (props.props.accessLevel !== "Threat Analyst") {
  //   metisMenu.push({
  //     id: 504,
  //     icon: "icon-settings",
  //     label: "Settings",
  //     to: "/settings",
  //   });
  // }

  // if (props.props.accessLevel !== "Threat Analyst") {
  //   if (props.props.accessLevel !== "Threat Analyst") {
  //     metisMenu.push({
  //       id: "app",
  //       label: "Vulnerability Management",
  //     });
  //   }
  //   metisMenu.push({
  //     id: 3001,
  //     icon: "icon-lock",
  //     label: "Vulnerability Assessment",
  //     to: "/",
  //     content: [
  //       {
  //         id: 121,
  //         label: "Scan Details",
  //         to: "/scan",
  //       },
  //       {
  //         id: 119,
  //         label: "Infrastructure",
  //         to: "/infra-va",
  //       },
  //       {
  //         id: 120,
  //         label: "Application",
  //         to: "/app-va",
  //       },
  //     ],
  //   });
  // }
  // if (props.props.accessLevel !== "Threat Analyst") {
  //   metisMenu.push({
  //     id: "app",
  //     label: "Cloud Security",
  //   });
  //   metisMenu.push({
  //     id: 4001,
  //     icon: "icon-lock",
  //     label: "Cloud Security",
  //     to: "/",
  //     content: [
  //       // {
  //       //     id: 401,
  //       //     label: "Dashboard",
  //       //     to: "/",
  //       // },
  //       {
  //         id: 402,
  //         label: "Cloud Accounts",
  //         to: "/cloud-account",
  //       },
  //       // {
  //       //     id: 403,
  //       //     label: "Scans",
  //       //     to: "/",
  //       // },
  //     ],
  //   });
  // }

  return (
    <MetisMenu
      content={metisMenu}
      noBuiltInClassNames={true}
      classNameItemActive="active"
      classNameContainer={(e) => toggleSubMenu(e)}
      classNameContainerVisible="in"
      iconNamePrefix=""
      LinkComponent={(e) => <DefaultLink itemProps={e} />}
    />
  );
};

export default SideBar;
