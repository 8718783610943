import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { fetchLeakedSession ,fetchLeakedSessionAction , fetchLeakedSessionGraph} from "../../actions/leakedCredentials";
import BasicLineColumn from "../common/apexChart/lineChart";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import NoData from "../common/noDataComponetnt";
import AccessPage from "../Shared/accessPage";
import SideNav from '../resuableComponent/sidenav';
import Header from '../resuableComponent/header';
import Loader from "../resuableComponent/loader";
import {
  fetchSubDomains,
  addRemediationData,
  removeRemediationData,
} from "../../actions/sub-domain";
import { useLocation } from "react-router-dom";
import $ from "jquery";


const LeakedSession = () => {
  const id = useSelector(
    (state) => state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
  );
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.data?.[0]
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [category, setCategory] = useState("all");
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);
  const [checkPath, setCheckPath] = useState('');

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };
  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        leakedSessionIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchLeakedSessionAction(
        id, dispatch, page, category , data
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      leakedSessionIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchLeakedSessionAction(
      id, dispatch, page, category , data
    );
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      leakedSessionIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchLeakedSessionAction(
      id, dispatch, page, category , data
    );
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const state = useSelector(
    (state) => state?.leakedCredential?.sessions?.data
  );
  const [addedRemediations, setAddedRemediations] = useState([]);

  let oldDnsDetails = useSelector((state) => state.leakedCredential?.leakedsessiongraph?.data);

  let ipArray = [], totalIp = 0;
  if (oldDnsDetails?.scanDetails) {
    ipArray.push(oldDnsDetails?.scanDetails || 0)
    totalIp = oldDnsDetails?.scanDetails || 0;
  }

  let remediationList = useSelector((state) => state?.auth?.remediationListAll?.data?.data);

  const cartDispatch = useDispatch();
  
  const removeToCart = (_id) => e => {
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to remove this from the Remediation List Panel?",
      type: "red",
      buttons: {
        ok: {
          text: "Remove",
          btnClass: "btn-danger",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            removeRemediationData(_id, cartDispatch, id).then((res) => {
              fetchLeakedSession(id, dispatch, page, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  const addToCart = (row) => e => {
    let data = {
      "collectionName":'deltaleakedsessions',
      "organisationId":id,
      "recordId":row?._id,
    }
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to add this to the Remediation List Panel ?",
      type: "green",
      buttons: {
        ok: {
          text: "Add",
          btnClass: "btn-green",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            addRemediationData(data, cartDispatch).then((res) => {
              fetchLeakedSession(id, dispatch, page, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

 
  useEffect(() => {
    if (remediationList) {
      const updatedRemediations = remediationList.reduce((results, elem) => {
        return { ...results, [elem._id]: elem };
      }, {});
      setAddedRemediations(updatedRemediations);
    }
  }, [remediationList]);
  
  useEffect(() => {
    fetchLeakedSession(id, dispatch, page, category);
  }, [id, dispatch, page, category]);

  useEffect(()=>{
    fetchLeakedSessionGraph(id,dispatch)
  },[id,dispatch])

  useEffect(()=>{
    setCheckPath(location.search)
  },[location.search])

  useEffect(()=>{
    setTotalItems(state?.totalLeakedSessions)
  },[state?.totalLeakedSessions])

  let response = state?.data;

  response = response?.sort((a, b) => (new Date(a.expires * 1000).getTime() > new Date(b.expires * 1000).getTime()) ? -1 : ((new Date(b.expires * 1000).getTime() > new Date(a.expires * 1000).getTime()) ? 1 : 0))

  let mapData = response;


  let currentTableData = [];
  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const [containerClass, setContainerClass] = useState("data-container");
  useEffect(()=>{
    if(state){
      setTimeout(()=>{
        setContainerClass("data-container active");
      }, 300);
    }
  },[state]);
  
  return (
    <div>

      {/* <Header type="breach" />
      <SideNav type="breach" /> */}

      {state ?
        <div className={containerClass}>
          <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
            <div className='col-sm-2'></div>
            <div className='col-sm-9'>
            <div className="row_boxes"> 
             <div className="gradinet-effect w-100">
              {oldDnsDetails && (
                <BasicLineColumn
                  dates={oldDnsDetails?.dates}
                  ipArray={oldDnsDetails?.scanDetails}
                  color={["#6574cd"]}
                  yAxis="Records Found"
                />
              )}
              </div>
              </div>
            </div>
          </div>

          <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
            <div className='col-sm-2'></div>
            <div className='col-sm-9' style={{ padding: '0' }}>
              <div className="row_boxes row_boxes_table">
                <div className="table_box">
                  <table className="table">
                  <thead>
                  <tr>
                    <th>Name</th>
                    <th>Domain</th>
                    <th>Value</th>
                    <th>Path</th>
                    <th>Session Expiry</th>
                    <th>Found On</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {client?.subscriptionType == "trial" ? (
                    <tr>
                      <td colSpan={6}>
                        <NoData />
                      </td>
                    </tr>
                  ) : (
                    currentTableData?.map((row, index) => {
                      //console.log(new Date(row.expires * 1000), 'new Date(row.expires)')
                      // let value = row?.val?.split("") || ['N', 'A'];
                      let date = row?.fnd
                        ? row?.fnd?.toString().substr(0, 4) +
                          "-" +
                          row?.fnd?.toString().substr(4, 2) +
                          "-" +
                          row?.fnd?.toString().substr(6, 2)
                        : "NA";
                      return (
                        <tr key={`leaked${index}`}>
                          <td>{row?.name || "NA"}</td>
                          <td style={{ width: "100px" }}>{row?.dom || "NA"}</td>
                          <td
                            style={{
                              width: "100px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              cursor: "pointer", // Add cursor style to indicate it's clickable
                            }}
                          >
                            <span title={row?.val}>
                              {(row?.val || "NA").slice(0, 50)}
                            </span>
                          </td>

                          <td style={{ width: "100px" }}>
                            {row?.path || "NA"}
                          </td>
                          <td style={{ whiteSpace: "unset" }}>
                            {row?.expires
                              ? moment(row?.expires).format("DD-MM-YYYY HH:MM")
                              : "NA"}
                          </td>

                          <td style={{ whiteSpace: "unset" }}>
                            {row?.createdAt
                              ? moment(row?.createdAt).format(
                                  "DD-MM-YYYY HH:MM"
                                )
                              : "NA"}
                          </td>
                          {checkPath == '?mode=assessment-mode' ?
                          <td>
                          {row?.isTicketChecked == false ? (
                              <button
                                onClick={addToCart({
                                  ...row,
                                  remediationType: "Source Code Leakage",
                                })}
                              >
                                <span className="fa fa-plus action_icon"></span>
                              </button>
                            ) : (
                              <button onClick={removeToCart(row?._id)}>
                                <span className="fa fa-minus action_icon"></span>
                              </button>
                            )}
                          </td>
                          : null}
                        </tr>
                      );
                    })
                  )}
                </tbody>
                  </table>
                </div>
              </div>
              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
          <div
        className={`modal fade Form-Wizard-modal ${
          isModal === "Form Wizard modal" ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {action == "markAsFalsePositive"
                  ? "Mark As False Positive"
                  : "Risk Accepted"}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModal("");
                  setActionNote("");
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page modal_mob">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card">
                  <div className="row clearfix">
                    <div className="col-md-12">
                      <label className="col-lg-6 col-md-12">Snooze for</label>
                      <div className="form-group ol-lg-12 col-md-12">
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={(e) => setForValue(e.target.value)}
                        >
                          <option value="forever">Forever</option>
                          <option value="3 months">3 Months</option>
                          <option value="6 months">6 Months</option>
                          <option value="12 months">12 Months</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="col-lg-12 col-md-12">Detail</label>
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea
                          onChange={(e) => setActionNote(e.target.value)}
                          value={actionNote}
                          className="form-control"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-12"
                      style={{ marginRight: "28px" }}
                    >
                      <button
                        type="button"
                        style={{ color: "black", fontWeight: "bold" }}
                        onClick={submit}
                        className="btn btn-round btn-primary mr-1 pull-right"
                      >
                        Submit
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        onClick={() => {
                          setModal("");
                          setActionNote("");
                        }}
                        className="btn btn-round btn-default pull-right"
                        style={{ marginRight: "10px" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div> :
        <Loader />
      }

    </div>
  );
}

export default LeakedSession;