import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toastr } from "react-redux-toastr";
import $ from "jquery";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GaugeChart from "react-gauge-chart";
import { VectorMap } from "react-jvectormap";

class HomeScreen extends Component {


    render() {
        let markers = [{latLng: ["39.0438", "-77.4874"], name: 'United States (US) - 11'},
        {latLng: ['12.9896', '80.2485'], name: 'India (IN) - 33'},
        {latLng: ['45.5017', '-73.5673'], name: 'Canada (CA) - 1'},
        {latLng: ['40.1728', '29.0774'], name: 'Turkey (TR) - 1'},
        {latLng: ['38.0849', '-0.9455'], name: 'Spain (ES) - 1'}];
        let values = {India : "#ffec94"}
        console.log(markers,'markers')
        return (
            <div className='home_screen'>
                <div className='row clearfix headerTop'>
                    <div className='col-lg-1 col-md-1 col-sm-1 logoBox'>
                        <img className='logoImage' src='../assets/images/image-gallery/logo.svg'/>
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-1 home_icon'>
                        <span className='home_icon_text'>Home/ <br />Dashboard</span>
                        <i
                            className="fa fa-object-group home_fa_icon"
                        ></i>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3'></div>
                    <div className='col-lg-2 col-md-2 col-sm-2' style={{marginTop:'1%'}}>
                        <span className='assesment_mode'>
                            Go <br />
                            <span>Assesment Mode</span>
                        </span>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2' style={{marginTop:'1%'}}>
                        <span className='report_mode'>
                            Go <br />
                            <span>Report Mode</span>
                        </span>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3'></div>

                </div>
                <div className='row clearfix no_margin' style={{marginTop:'20px'}}>
                    <div className='col-sm-1'>

                    </div>
                    <div className='col-sm-10'>
                        

                        <div className='dashboard_box' style={{marginTop:'60px'}}>
                            <div className="dashboard_tiles">
                                <div className="text-center">
                                    <i
                                        className="fa fa-dns"
                                        style={{ color: "#17c2d7", fontSize:'26px' }}
                                    ></i>
                                    <h5 className="m-t-20 mb-0">
                                        332
                                    </h5>
                                    <p className="text-muted1" style={{ color: "#ffffff" }}>DNS Records</p>
                                    <p style={{overflow:'auto', fontSize:'10px', marginBottom:'20px'}}>
                                        <span style={{float:'left'}}><span className='tiles_text'>67</span> <br/>Errors</span>
                                        <span style={{float:'right'}}><span className='tiles_text'>69</span> <br/>Warnings</span>
                                    </p>
                                </div>
                            </div>
                            <p className='dashboard_circle'></p>
                        </div>

                        <div className='dashboard_box' style={{marginTop:'25px'}}>
                            <div className="dashboard_tiles">
                                <div className="text-center">
                                    <i
                                        className="fa fa-vuln"
                                        style={{ color: "#17c2d7", fontSize:'26px' }}
                                    ></i>
                                    <h5 className="m-t-20 mb-0">
                                        12
                                    </h5>
                                    <p className="text-muted1" style={{ color: "#ffffff" }}>Known Vulnerabilities</p>
                                    <p style={{overflow:'auto', fontSize:'10px', marginBottom:'20px'}}>
                                        <span style={{float:'left'}}><span className='tiles_text'>01</span> <br/>Critical</span>
                                        <span style={{float:'right'}}><span className='tiles_text'>00</span> <br/>High</span>
                                    </p>
                                </div>
                            </div>
                            <p className='dashboard_circle'></p>
                        </div>

                        <div className='dashboard_box' style={{marginTop:'10px'}}>
                            <div className="dashboard_tiles">
                                <div className="text-center">
                                    <i
                                        className="fa fa-ssl"
                                        style={{ color: "#17c2d7", fontSize:'26px' }}
                                    ></i>
                                    <h5 className="m-t-20 mb-0">
                                        47
                                    </h5>
                                    <p className="text-muted1" style={{ color: "#ffffff" }}>SSL Certificates</p>
                                    <p style={{overflow:'auto', fontSize:'10px', marginBottom:'20px'}}>
                                        <span style={{float:'left'}}><span className='tiles_text'>15</span> <br/>Expired</span>
                                        <span style={{float:'right'}}><span className='tiles_text'>47</span> <br/>Vulnerable</span>
                                    </p>
                                </div>
                            </div>
                            <p className='dashboard_circle'></p>
                        </div>

                        <div className='dashboard_box'>
                            <div className="dashboard_tiles">
                                <div className="text-center">
                                    <i
                                        className="fa fa-source"
                                        style={{ color: "#17c2d7", fontSize:'26px' }}
                                    ></i>
                                    <h5 className="m-t-20 mb-0">
                                        09
                                    </h5>
                                    <p className="text-muted1" style={{ color: "#ffffff" }}>Source Code Leakage</p>
                                    <p style={{overflow:'auto', fontSize:'10px', marginBottom:'20px'}}>
                                        <span style={{float:'left'}}><span className='tiles_text'>04</span> <br/>Verified</span>
                                        <span style={{float:'right'}}><span className='tiles_text'>05</span> <br/>Not verified</span>
                                    </p>
                                </div>
                            </div>
                            <p className='dashboard_circle'></p>
                        </div>

                        <div className='dashboard_box'>
                            <div className="dashboard_tiles">
                                <div className="text-center">
                                    <i
                                        className="fa fa-assets"
                                        style={{ color: "#17c2d7", fontSize:'26px' }}
                                    ></i>
                                    <h5 className="m-t-20 mb-0">
                                        27
                                    </h5>
                                    <p className="text-muted1" style={{ color: "#ffffff" }}>Assets Discovered</p>
                                    <p style={{overflow:'auto', fontSize:'10px', marginBottom:'20px'}}>
                                        <span><span className='tiles_text'>08</span> <br/>Open Ports</span>
                                    </p>
                                </div>
                            </div>
                            <p className='dashboard_circle'></p>
                        </div>

                        <div className='dashboard_box'>
                            <div className="dashboard_tiles">
                                <div className="text-center">
                                    <i
                                        className="fa fa-stolen"
                                        style={{ color: "#17c2d7", fontSize:'26px' }}
                                    ></i>
                                    <h5 className="m-t-20 mb-0">
                                        284
                                    </h5>
                                    <p className="text-muted1" style={{ color: "#ffffff" }}>Stolen Credentials</p>
                                    <p style={{overflow:'auto', fontSize:'10px', marginBottom:'20px'}}>
                                        <span><span className='tiles_text'>--</span> <br/>--</span>
                                    </p>
                                </div>
                            </div>
                            <p className='dashboard_circle'></p>
                        </div>

                        <div className='dashboard_box' style={{marginTop:'10px'}}>
                            <div className="dashboard_tiles">
                                <div className="text-center">
                                    <i
                                        className="fa fa-blacklisted"
                                        style={{ color: "#17c2d7", fontSize:'26px' }}
                                    ></i>
                                    <h5 className="m-t-20 mb-0">
                                        00
                                    </h5>
                                    <p className="text-muted1" style={{ color: "#ffffff" }}>Blaclisted IP's</p>
                                    <p style={{overflow:'auto', fontSize:'10px', marginBottom:'20px'}}>
                                        <span><span className='tiles_text'>01</span> <br/>Unlisting Process</span>
                                    </p>
                                </div>
                            </div>
                            <p className='dashboard_circle'></p>
                        </div>

                        <div className='dashboard_box' style={{marginTop:'25px'}}>
                            <div className="dashboard_tiles">
                                <div className="text-center">
                                    <i
                                        className="fa fa-fake-mobile"
                                        style={{ color: "#17c2d7", fontSize:'26px' }}
                                    ></i>
                                    <h5 className="m-t-20 mb-0">
                                        00
                                    </h5>
                                    <p className="text-muted1" style={{ color: "#ffffff" }}>Fake Mobile App's</p>
                                    <p style={{overflow:'auto', fontSize:'10px', marginBottom:'20px'}}>
                                        <span><span className='tiles_text'>--</span> <br/>--</span>
                                    </p>
                                </div>
                            </div>
                            <p className='dashboard_circle'></p>
                        </div>

                        <div className='dashboard_box' style={{marginTop:'60px'}}>
                            <div className="dashboard_tiles">
                                <div className="text-center">
                                    <i
                                        className="fa fa-social"
                                        style={{ color: "#17c2d7", fontSize:'26px' }}
                                    ></i>
                                    <h5 className="m-t-20 mb-0">
                                        00
                                    </h5>
                                    <p className="text-muted1" style={{ color: "#ffffff" }}>Fake Social Profiles</p>
                                    <p style={{overflow:'auto', fontSize:'10px', marginBottom:'20px'}}>
                                        <span><span className='tiles_text'>--</span> <br/>--</span>
                                    </p>
                                </div>
                            </div>
                            <p className='dashboard_circle'></p>
                        </div>
                    </div>

                    <div className='col-sm-1'>
                        
                    </div>
                </div>
                <div className='row clearfix no_margin' style={{marginTop:'-50px', marginBottom:'20px'}}>
                    <div className='col-sm-5'></div>
                    <div className="col-sm-2">
                    <div className="w_card3">
                        <div className="guage_body">
                            <div className="text-center">
                                <GaugeChart
                                    id="gauge-chart3"
                                    nrOfLevels={30}
                                    colors={["#ffd861", "#E15858"]}
                                    arcWidth={0.3}
                                    percent={30 / 100}
                                    hideText={false}
                                    formatTextValue={() => 30}
                                />
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className='col-sm-5'></div>
                </div>

                <div className='row clearfix no_margin'>
                    <div className='col-sm-3'></div>
                    <div className="col-sm-6">
                        <div className='dashboard_box_second' style={{marginTop:'40px'}}>
                            <div className="dashboard_tiles">
                                <div className="text-center">
                                    <i
                                        className="fa fa-hosts"
                                        style={{ color: "#17c2d7", fontSize:'26px' }}
                                    ></i>
                                    <h5 className="m-t-20 mb-0">
                                        27
                                    </h5>
                                    <p className="text-muted1" style={{ color: "#ffffff" }}>Hosts Discovered</p>
                                    <p style={{overflow:'auto', fontSize:'10px', marginBottom:'20px'}}>
                                        <b>View</b><br/><span>Find more</span>
                                    </p>
                                </div>
                            </div>
                            <p className='dashboard_circle_second'></p>
                        </div>

                        <div className='dashboard_box_second' style={{marginTop:'15px'}}>
                            <div className="dashboard_tiles">
                                <div className="text-center">
                                    <i
                                        className="fa fa-location"
                                        style={{ color: "#17c2d7", fontSize:'26px' }}
                                    ></i>
                                    <h5 className="m-t-20 mb-0">
                                        05
                                    </h5>
                                    <p className="text-muted1" style={{ color: "#ffffff" }}>Hosts Location</p>
                                    <p style={{overflow:'auto', fontSize:'10px', marginBottom:'20px'}}>
                                        <b>View</b><br/><span>Find more</span>
                                    </p>
                                </div>
                            </div>
                            <p className='dashboard_circle_second'></p>
                        </div>

                        <div className='dashboard_box_second'>
                            <div className="dashboard_tiles">
                                <div className="text-center">
                                    <i
                                        className="fa fa-cloud-provider"
                                        style={{ color: "#17c2d7", fontSize:'26px' }}
                                    ></i>
                                    <h5 className="m-t-20 mb-0">
                                        05
                                    </h5>
                                    <p className="text-muted1" style={{ color: "#ffffff" }}>Cloud Provider</p>
                                    <p style={{overflow:'auto', fontSize:'10px', marginBottom:'20px'}}>
                                        <b>View</b><br/><span>Find more</span>
                                    </p>
                                </div>
                            </div>
                            <p className='dashboard_circle_second'></p>
                        </div>

                        <div className='dashboard_box_second'>
                            <div className="dashboard_tiles">
                                <div className="text-center">
                                    <i
                                        className="fa fa-open-port"
                                        style={{ color: "#17c2d7", fontSize:'26px' }}
                                    ></i>
                                    <h5 className="m-t-20 mb-0">
                                        08
                                    </h5>
                                    <p className="text-muted1" style={{ color: "#ffffff" }}>Open Ports</p>
                                    <p style={{overflow:'auto', fontSize:'10px', marginBottom:'20px'}}>
                                        <b>View</b><br/><span>Find more</span>
                                    </p>
                                </div>
                            </div>
                            <p className='dashboard_circle_second'></p>
                        </div>

                        <div className='dashboard_box_second' style={{marginTop:'15px'}}>
                            <div className="dashboard_tiles">
                                <div className="text-center">
                                    <i
                                        className="fa fa-operating-system"
                                        style={{ color: "#17c2d7", fontSize:'26px' }}
                                    ></i>
                                    <h5 className="m-t-20 mb-0">
                                        02
                                    </h5>
                                    <p className="text-muted1" style={{ color: "#ffffff" }}>Operating Systems</p>
                                    <p style={{overflow:'auto', fontSize:'10px', marginBottom:'20px'}}>
                                        <b>View</b><br/><span>Find more</span>
                                    </p>
                                </div>
                            </div>
                            <p className='dashboard_circle_second'></p>
                        </div>

                        <div className='dashboard_box_second' style={{marginTop:'40px'}}>
                            <div className="dashboard_tiles">
                                <div className="text-center">
                                    <i
                                        className="fa fa-network-service"
                                        style={{ color: "#17c2d7", fontSize:'26px' }}
                                    ></i>
                                    <h5 className="m-t-20 mb-0">
                                        03
                                    </h5>
                                    <p className="text-muted1" style={{ color: "#ffffff" }}>Network Services</p>
                                    <p style={{overflow:'auto', fontSize:'10px', marginBottom:'20px'}}>
                                        <b>View</b><br/><span>Find more</span>
                                    </p>
                                </div>
                            </div>
                            <p className='dashboard_circle_second'></p>
                        </div>
                    </div>
                    <div className='col-sm-3'></div>
                </div>

                <div className='row clearfix no_margin'>
                    <div className='col-sm-4'></div>
                    <div className='col-sm-4'>
                        <div id="world-map-markers" className="jvector-map main-map">
                            <VectorMap
                            containerStyle={{
                                height: "400px",
                            }}
                            map={"world_mill"}
                            backgroundColor="transparent"
                            borderColor="#fff"
                            borderOpacity={0.25}
                            borderWidth={0}
                            color="#e6e6e6"
                            regionStyle={{ initial: { fill: "#6c757d" } }}
                            markerStyle={{
                                initial: {
                                r: 5,
                                fill: "#fff",
                                "fill-opacity": 1,
                                stroke: "#000",
                                "stroke-width": 1,
                                "stroke-opacity": 0.4,
                                },
                            }}
                            markers={markers}
                            series={{
                                regions: [
                                {
                                    values: values,
                                    attribute: "fill",
                                },
                                ],
                            }}
                            hoverOpacity={null}
                            normalizeFunction="linear"
                            zoomOnScroll={false}
                            scaleColors={["#000000", "#000000"]}
                            selectedColor="#000000"
                            selectedRegions={[]}
                            enableZoom={false}
                            hoverColor="#fff"
                            />
                        </div>
                    </div>
                    <div className='col-sm-4'></div>
                </div>
            </div>
        );
    }
}

export default HomeScreen;