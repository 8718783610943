import React from "react";
import { Oval } from 'react-loader-spinner';

const Loader = () => {
    return (
        <div className="row no_margin">
            <div className='col-sm-2'></div>
            <div className='col-sm-8'>
                <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass="loaderClass"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
            <div className='col-sm-2'></div>
        </div>
    )
}

export default Loader;