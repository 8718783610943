import { LEAKEDCREDS_REQUESTS_FETCHED,LEAKED_SESSION_FETCHED_GRAPH, MALWARE_CREDENTIALS_FETCHED_GRAPH, LEAKEDCREDS_REQUESTS_FETCHED_GRAPH, MALWARE_CREDENTIALS_FETCHED, LEAKED_SESSION_FETCHED, GET_PHISHING_PAGE, PII_DETAILS } from "../actions";

export default function (
  state = { loading: true, response: null,  leakedsessiongraph:null, malwaregraph:null, leakedgraph:null, list: null, malware: null, sessions:null, scanCompleted: true, phishingPage:null, piidetails: null },
  action
) {
  switch (action.type) {
    case LEAKED_SESSION_FETCHED_GRAPH: return{
      ...state,
      leakedsessiongraph: action.payload,    
    }
    case LEAKEDCREDS_REQUESTS_FETCHED_GRAPH: return{
      ...state,
      leakedgraph: action.payload,    
    }
    case MALWARE_CREDENTIALS_FETCHED_GRAPH: return{
      ...state,
      malwaregraph: action.payload,    
    }
    case LEAKEDCREDS_REQUESTS_FETCHED:
      return {
        ...state,
        response: action.payload,
        list: action.payload.entries ? action.payload.entries : [],
        loading: false,
        scanCompleted: action.payload.scanCompleted
      };
    case MALWARE_CREDENTIALS_FETCHED:
      return {
        ...state,
        malware: action.payload,
        loading: false,
      };
    case LEAKED_SESSION_FETCHED:
      return {
        ...state,
        sessions: action.payload,
        loading: false,
      }
    case GET_PHISHING_PAGE:
      return {
        ...state,
        phishingPage: action.payload || [],
        loading: false,
      }
    case PII_DETAILS:
      return{
        ...state,
        piidetails: action.payload || [],
        loading: false,
      }
    default:
      return state;
  }
}
