import axios from "axios";
import { API_URL } from "../utils/constant";
import { FETCH_CVE_DETAILS ,FETCH_CVE_DETAILS_GRAPH , FETCH_CVE_DETAILS_TOTAL } from "./index";
import { toastr } from "react-redux-toastr";

export const fetchCVEDetails = (props, dispatch, page, pageSize, search , category) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  if (props) {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    axios
      .get(
        `${API_URL}/cve-details/${props}/delta?page=${page}&limit=${pageSize}&severity=${search}&category=${category}`
      )
      .then((response) => {
        //console.log(response.data);
        dispatch({
          type: FETCH_CVE_DETAILS,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: FETCH_CVE_DETAILS,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchCVEDetailsAction = (props, dispatch, page, pageSize, search , category , data) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  if (props) {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    axios
      .post(
        `${API_URL}/cve-details/${props}/action?page=${page}&limit=${pageSize}&severity=${search}&category=${category} `,data
      )
      .then((response) => {
        //console.log(response.data);
        dispatch({
          type: FETCH_CVE_DETAILS,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: FETCH_CVE_DETAILS,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchCVEDetailsGraph = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  if (props) {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    axios
      .get(
        `${API_URL}/cve-details/${props}/graph`
      )
      .then((response) => {
        //console.log(response.data);
        dispatch({
          type: FETCH_CVE_DETAILS_GRAPH,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: FETCH_CVE_DETAILS_GRAPH,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchCVEDetailsTotal = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  if (props) {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    axios
      .get(
        `${API_URL}/cve-details/${props}/totals`
      )
      .then((response) => {
        //console.log(response.data);
        dispatch({
          type: FETCH_CVE_DETAILS_TOTAL,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: FETCH_CVE_DETAILS_TOTAL,
          payload: { error: true, userMessage: message },
        });
      });
  }
};


  export const fetchMasterScan = (props,type, dispatch) => {
    // const cookie = new Cookies();
    // const session = cookie.get("session");
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  
    axios
      .get(`${API_URL}/getMasterScanDetails/${props}?type=${type}`)
      .then((response) => {
        //console.log(response.data);
        dispatch({
          type: "MASTER_SCAN",
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: "MASTER_SCAN",
          payload: { error: true, userMessage: message },
        });
      });
  };

  export const getMasterScanData = (props,orgId, dispatch) => {
    // const cookie = new Cookies();
    // const session = cookie.get("session");
    if(props && props != ""){
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  
    axios
      .get(`${API_URL}/getMasterScans/${props}/${orgId}`)
      .then((response) => {
        //console.log(response.data);
        dispatch({
          type: "MASTER_SCAN_DETAILS",
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: "MASTER_SCAN_DETAILS",
          payload: { error: true, userMessage: message },
        });
      });
    }
  };