import {
    CREATING_CUSTOMER,
    CUSTOMERS_FETCHED,
    CUSTOMER_CREATED,
    CUSTOMER_DELETED,
    CUSTOMER_UPDATED,
    DELETING_CUSTOMER,
    EDIT_CUSTOMER_FETCHED,
    FETCHING_CUSTOMERS,
    UPDATING_CUSTOMER,
    FETCHING_THREAT_ANALYIST,
    FETCHING_VENDORS,
    VENDORS_FETCHED,
    USERS_FETCHED,
    USERS_CREATED
  } from "../actions/";
  
  const initialState = {
    loading: true,
    response: null,
    list: null,
    data: null,
    fetchedData: {},
    threatAnalyist: [],
    userList:[],
    userCreate:[]
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case FETCHING_CUSTOMERS:
        return {
          ...state,
          response: action.payload,
          fetchedData: null,
          customer: null,
          loading: true
        };
        case FETCHING_VENDORS:
          return {
            ...state,
            response: action.payload,
            fetchedData: null,
            vendor: null,
            loading: true
          };
      case CREATING_CUSTOMER:
        return {
          ...state,
          response: action.payload,
          loading: true,
        };
  
      case CUSTOMER_CREATED:
        return {
          ...state,
          // response: action.payload,
          data: !action.payload.error ? action.payload.data : null,
          loading: false,
        };
  
      case CUSTOMERS_FETCHED:
        return {
          ...state,
          response: action.payload,
          list: !action.payload.error ? action.payload.data : [],
          loading: false,
        };
        case VENDORS_FETCHED:
          return {
            ...state,
            response: action.payload,
            list: !action.payload.error ? action.payload.data : [],
            loading: false,
          };
      case EDIT_CUSTOMER_FETCHED:
        return {
          ...state,
          fetchedData: action.payload,
          customer: !action.payload.error ? action.payload.data : null
        };
  
      case UPDATING_CUSTOMER:
        return {
          ...state,
          fetchedData: action.payload,
          loading: true,
        };
      case CUSTOMER_UPDATED:
        return {
          ...state,
          fetchedData: action.payload,
          loading: false,
        };
      
      case DELETING_CUSTOMER:
        return {
          ...state,
          response: action.payload,
          loading: true,
        };
      case CUSTOMER_DELETED:
        return {
          ...state,
          response: action.payload,
          loading: false,
        };
      case FETCHING_THREAT_ANALYIST:
        return {
          ...state,
          threatAnalyist: action.payload,
          loading: false,
        };
        case USERS_FETCHED:
          return {
            ...state,
            userList: action.payload,
            loading: false,
          };
          case USERS_CREATED:
          return {
            ...state,
            userCreate: action.payload,
            loading: false,
          };
    
      default:
        return state;
    }
  }
  