import axios from "axios";
import { API_URL } from "../utils/constant";
import {
  USERS_FETCHED,
  USERS_CREATED,
  CREATING_USER,
  EDIT_USER_FETCHED,
  UPDATING_USER,
  USER_UPDATED,
  DELETING_USER,
  USER_DELETED,
  FETCHING_USERS,
  LOGED_IN_USER,
  FETCHING_ORGANISATION,
  CUSTOMERS_FETCHED,
  FETCHING_CUSTOMERS,
  EDIT_CUSTOMER_FETCHED,
  UPDATING_CUSTOMER,
  CUSTOMER_UPDATED
} from "./index";
import { toastr } from "react-redux-toastr";

export const fetchUsers = (props,dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: FETCHING_USERS,
    payload: null,
  });
  axios
    .get(`${API_URL}/user?id=${props}`, {})
    .then((response) => {
      dispatch({
        type: USERS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: USERS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const getUserById = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  axios.get(`${API_URL}/user/${props}`).then((response) => {
    //console.log(response.data);
    dispatch({
      type: EDIT_USER_FETCHED,
      fetchedData: response.data,
    });
    if (!response.data.error) {
      toastr.success("Success", response.data.userMessage);
    } else {
      toastr.error("Error", response.data.userMessage);
    }
  });
};
export const updateUserById = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  axios.patch(`${API_URL}/user`, { ...props }).then((response) => {
    if (!response.data.error) {
      dispatch({
        type: USERS_CREATED,
        payload: response.data,
      });
      toastr.success("Success", response.data.userMessage);
      // if(!props.redirect){
      //   if(props.orgId)
      //     history.push("/users/"+props.orgId);
      //   else
      //     history.push("/users");
      // }
    } else {
      toastr.error("Error", response.data.userMessage);
    }
  });
};

export const createUser = (props, dispatch, history) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  axios
    .post(`${API_URL}/user`, { ...props })
    .then((response) => {
      //console.log(response.data);
      dispatch({
        type: USERS_CREATED,
        payload: response.data,
      });
      if (!response.data.error) {
        toastr.success("Success", response.data.userMessage);
        if(props.organisationId)
          history.push("/user/"+props.organisationId);
        // else
        //   history.push("/invite-user");
      } else {
        toastr.error("Error", response.data.userMessage);
      }
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      dispatch({
        type: USERS_CREATED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const customerApproveToggle = (props) => (dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  axios
    .put(`${API_URL}/customer`, { ...props })
    .then((response) => {
      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: CUSTOMERS_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const fetchCustomersById = (props) => (dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  dispatch({
    type: FETCHING_CUSTOMERS,
    payload: null,
  });

  axios
    .get(`${API_URL}/customer/${props}`, {})
    .then((response) => {
      dispatch({
        type: EDIT_CUSTOMER_FETCHED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: EDIT_CUSTOMER_FETCHED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const deleteUser = (props,dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: DELETING_USER,
    payload: null,
  });
  axios
    .delete(`${API_URL}/user/${props}`, {})
    .then((response) => {
      dispatch({
        type: USERS_CREATED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: USER_DELETED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const updateCustomer = (props) => (dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
  dispatch({
    type: UPDATING_CUSTOMER,
    payload: null,
  });
  axios
    .put(`${API_URL}/customer`, { ...props, customerId: props._id })
    .then((response) => {
      dispatch({
        type: CUSTOMER_UPDATED,
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: CUSTOMER_UPDATED,
        payload: { error: true, userMessage: message },
      });
    });
};

export const getLogedInUser = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  axios.get(`${API_URL}/logedInUser`).then((response) => {
    //console.log(response.data);
    dispatch({
      type: LOGED_IN_USER,
      payload: response.data,
    });
    if (!response.data.error) {
      // toastr.success("Success", response.data.userMessage);
    } else {
      toastr.error("Error", response.data.userMessage);
    }
  });
};

export const getOrganisation = (props) => (dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  dispatch({
    type: FETCHING_ORGANISATION,
    payload: null,
  });

  axios
    .get(`${API_URL}/organisation?id=${props}`, {})
    .then((response) => {
      dispatch({
        type: FETCHING_ORGANISATION,
        payload: response.data.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }

      dispatch({
        type: FETCHING_ORGANISATION,
        payload: { error: true, userMessage: message },
      });
    });
};