import {
  FETCH_SUBDOMAIN_DETAIL,
  SUBDOMAIN_FETCHED,
  SUBDOMAIN_SCAN_STARTED,
  SCAN_LIST,
  FETCH_DNS_DETAILS,
  FETCH_SUCCESS_DNS_DETAILS,
  SECURITY_HEADER,
  SUBDOMAIN_FETCHED_GRAPH,
  SUBDOMAIN_FETCHED_SSL,
  SUBDOMAIN_FETCHED_TOTAL,
  SUBDOMAIN_FETCHED_SSL_TOTAL,
  SUBDOMAIN_FETCHED_SSL_GRAPH,
  SUBDOMAIN_FETCHED_EXP,
  FETCH_SUCCESS_DNS_DETAILS_TOTAL,
  FETCH_SUCCESS_DNS_DETAILS_GRAPH,
  GET_PROCESS_LIST,
  GET_PROCESS_CONTAINERS,
  GET_PROCESS_CONTAINERS_BODY_LIST
} from "../actions";

const initialState = {
  loading: true,
  response: { lookalike: [] },
  list: null,
  subDomainDetail: {},
  securityHeader: [],
  scanList: [],
  totalCount: [],
  garphData: [],
  dnsDetails: [],
  subdomainDnsDetails: [],
  scanCompleted: true,
  subDomainScanCompleted: true,
  expiredtotal: [],
  expiredgraph: [],
  responseexp: [],
  subDomainDetailTotal: {},
  subDomainSslTotal: {},
  subDomainDetailGraph: [],
  processList: [],
  processContainerList: [],
  processContainerBodyList: []
};

const subDomain = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROCESS_LIST:
      return {
        ...state,
        processList: action.payload,
      }
      case GET_PROCESS_CONTAINERS_BODY_LIST:
        return {
          ...state,
          processContainerBodyList: action.payload,
        }
    case GET_PROCESS_CONTAINERS:
      return {
        ...state,
        processContainerList: action.payload,
      }
    case SUBDOMAIN_FETCHED_SSL_GRAPH:
      return {
        ...state,
        expiredgraph: action.payload,
        loading: false,
      }
    case FETCH_SUCCESS_DNS_DETAILS_GRAPH:
      return {
        ...state,
        subDomainDetailGraph: action.payload,
      };
    case SUBDOMAIN_FETCHED:
      return {
        ...state,
        response: action.payload,
        list: action.payload.entries ? action.payload.entries : [],
        loading: false,
        subDomainScanCompleted: action.payload.scanCompleted,
      };
    case SUBDOMAIN_FETCHED_EXP:
      return {
        ...state,
        responseexp: action.payload,
        list: action.payload.entries ? action.payload.entries : [],
        loading: false,
      };
    case FETCH_SUBDOMAIN_DETAIL:
      return {
        ...state,
        subDomainDetail: action.subDomainDetail,
      };
    case SUBDOMAIN_SCAN_STARTED:
      return {
        ...state,
        loading: false,
        scanStarted: true,
      };
    case SCAN_LIST:
      return {
        ...state,
        scanList: action.payload,
        loading: false,
      };
    case FETCH_DNS_DETAILS:
      return {
        ...state,
        dnsDetails: action.payload,
        loading: false,
        scanCompleted: action.payload.scanCompleted,
      };
    case FETCH_SUCCESS_DNS_DETAILS:
      return {
        ...state,
        subdomainDnsDetails: action.payload,
        loading: false,
        scanCompleted: action.payload.scanCompleted,
      };
    case SECURITY_HEADER:
      return {
        ...state,
        securityHeader: action.payload.data,
      };
    case SUBDOMAIN_FETCHED:
      return {
        ...state,
        response: action.payload,
        list: action.payload.entries ? action.payload.entries : [],
        loading: false,
        subDomainScanCompleted: action.payload.scanCompleted,
      };
    case SUBDOMAIN_FETCHED_TOTAL:
      return {
        ...state,
        totalCount: action.payload,
      };
    case SUBDOMAIN_FETCHED_GRAPH:
      return {
        ...state,
        garphData: action.payload,
      };
    case SUBDOMAIN_FETCHED_SSL_TOTAL:
      return {
        ...state,
        expiredtotal: action.payload,
      };
    case FETCH_SUCCESS_DNS_DETAILS_TOTAL:
      return {
        ...state,
        subDomainDetailTotal: action.payload,
      };
    case SUBDOMAIN_FETCHED_SSL_TOTAL:
      return {
        ...state,
        subDomainSslTotal: action.payload,
      }
    default:
      return state;
  }
};

export default subDomain;
