import React from "react";
import { FormText } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import Logo from "../../assets/images/dummyImage.png";
// import AgrimLogo from "../assets/img/agrim-logo.png";
import { resetPassword } from "../../actions/auth";
import { toastr } from "react-redux-toastr";
import Particles from './particle';
import $ from "jquery";

var Model = require("./model");


class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = { userMessage: "", email: "" };

        if (this.props.match.params.reset_code) {
            Model.default
                .encryptEmail(this.props.match.params.reset_code)
                .then((response) => {
                    if (response.data.error) {
                        toastr.error("Error", response.data.userMessage);
                    } else {
                        this.setState({
                            email: response.data.data,
                        });
                    }
                });
        }
    }


    componentDidUpdate() {
        if (this.state.loading && this.props.auth.user && this.props.auth.user.passwordReset) {
            this.setState({
                loading: false,
            });

            this.props.history.push("/summary");
        }
    }

    resetPassword = () => {
        let password = $("#passwords").val();
        let confirmPassword = $("#confirmPassword").val();
        if (password == "") {
            toastr.error("Error", "Please enter password");
            return false;
        }
        if (confirmPassword == "") {
            toastr.error("Error", "Please enter confirm password");
            return false;
        }
        if (password != confirmPassword) {
            toastr.error("Error", "Password mismatch");
            return false;
        }
        let props = { password, confirmPassword }
        this.setState({ loading: true });
        this.props.resetPassword({
            ...props,
            resetCode: this.props.match.params.reset_code,
        });
    }

    render() {
        return (
            <>
                <div class="pattern">
                    <span class="red"></span>
                    <span class="indigo"></span>
                    <span class="blue"></span>
                    <span class="green"></span>
                    <span class="orange"></span>
                </div>
                <div
                    //  style={{ backgroundColor: '#0F4B6D', display: 'flex', height: '100vh' }}
                    className="login_container"
                >
                    <div style={{ margin: 'auto' }}>
                        {/* <div className="auth_brand">
                            <Link className="navbar-brand" to="/">
                                BrandSek
                            </Link>
                        </div> */}
                        <div className="login loginBg"
                            style={this.props.auth.twoFactor ? { height: "446px" } : {}}
                        >
                            <br />
                            <span>
                                <img style={{ width: '120px', display: 'flex', margin: '10px auto' }} src="../assets/images/image-gallery/logon_with_text.svg" />
                            </span>
                            {/* <div className=""> */}
                            <h1 className="lead">Reset Password</h1>

                            <form className="form-auth-small m-t-20" action="/">
                                <div className="form-group">
                                    <label htmlFor="passwords" className="control-label sr-only">
                                        New Password
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control-new round"
                                        id="passwords"
                                        required='required'
                                        placeholder="New Password"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="confirmPassword" className="control-label sr-only">
                                        Confirm Password
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control-new round"
                                        id="confirmPassword"
                                        required='required'
                                        placeholder="Confirm Password"
                                    />
                                </div>


                                <div className="loginFooter">
                                    <p className="remember_me">
                                        <span className="helper-text m-b-10">
                                            <i className="fa fa-lock"></i>{" "}
                                            Remember your password? <Link to="/login">Login</Link>
                                        </span>
                                    </p>
                                    <p class="submit">
                                        <button
                                            type="button"
                                            onClick={this.resetPassword}
                                            className="loginButton"
                                        >
                                            Reset
                                        </button>
                                    </p>
                                </div>



                                <div className="bottom" style={{ marginTop: "12px" }}>

                                </div>
                            </form>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps({ auth }) {
    return {
        auth,
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetPassword,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
