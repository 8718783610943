import React, { useEffect, useState, useRef } from "react";
import imgOne from "../../assets/images/identify.png"
import imgTwo from "../../assets/images/network-security.png"
import imgThree from "../../assets/images/data-protection.png"
import imgFour from "../../assets/images/application-security.png"
import imgFive from "../../assets/images/cloud-security.png"
import imgSix from "../../assets/images/security-awarness.png"
import imgSeven from "../../assets/images/end-point.png"
const Protect = () => {
  return (
    <div>
      <div className='row clearfix no_margin' style={{ marginTop: '20px' }}>
        <div className="col-md-2"></div>
        <div className='col-md-2 gradinet-effect p-3 m-2'>
          <img src={imgOne} className="serviceImg" />
          <h6>
            Identity and Access Management
          </h6>
          <p>
            We assist clients in managing user, device, application, and data identities and access rights and implementing authentication, authorization, and auditing mechanisms.
          </p>
        </div>
        <div className='col-md-2 gradinet-effect p-3 m-2'>
          <img src={imgTwo} className="serviceImg" />
          <h6>
            Network Security
          </h6>
          <p>
            We help clients secure their networks and communications using firewalls, intrusion detection and prevention systems (IDS/IPS), virtual private networks (VPNs), secure sockets layer (SSL), and other methods.
          </p>
        </div>
        <div className='col-md-2 gradinet-effect p-3 m-2'>
          <img src={imgThree} className="serviceImg" />
          <h6>
            Data Protection
            and Encryption
          </h6>
          <p>
            We use encryption, masking, tokenization, backup, recovery, and other techniques to protect client data confidentiality, integrity, and availability at rest and in transit.
          </p>
        </div>
        <div className='col-md-2 gradinet-effect p-3 m-2'>
          <img src={imgFour} className="serviceImg" />
          <h6>
            Application Security
          </h6>
          <p>
            We assist clients in securing their applications and software development lifecycle through secure coding practices, code analysis tools, testing tools, and vulnerability scanning tools.
          </p>
        </div>
      </div>
      <div className='row clearfix no_margin'>
        <div className='col-md-2'></div>
        <div className='col-md-2 gradinet-effect p-3 m-2'>
          <img src={imgFive} className="serviceImg" />
          <h6>
            Cloud Security
          </h6>
          <p>
            We assist our clients in ensuring the safety of their cloud environments and services, which include infrastructure as a service (IaaS), platform as a service (PaaS), and software as a service (SaaS). We implement the best practices and tools for cloud security governance, architecture, configuration, monitoring, and more.
          </p>
        </div>
        <div className='col-md-2 gradinet-effect p-3 m-2'>
          <img src={imgSix} className="serviceImg" />
          <h6>
            Security Awareness
            and Training
          </h6>
          <p>
            We help clients educate their employees and stakeholders about cybersecurity threats and best practices and provide them with the skills and knowledge to prevent or respond to cyber incidents.
          </p>
        </div>
        <div className='col-md-2 gradinet-effect p-3 m-2'>
          <img src={imgSeven} className="serviceImg" />
          <h6>
            Endpoint Security
          </h6>
          <p>
            We help clients secure their devices, including laptops, desktops, and mobile devices, using antivirus, firewall, patch management, device management, encryption, and other tools.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Protect;