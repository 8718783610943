import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";

class BasicBar extends Component {
    constructor(props) {
        console.log(props,'props')
        super(props);
        this.state = {
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    width: '400px',
                    toolbar: {
                        show: false,
                    },
                },
                colors: ['#3fa9f5'],
                stroke: {
                    width: [5, 10]
                },
                dataLabels: {
                    enabled: false
                },
                yaxis: {
                    min: 0,
                    tickAmount: 4,
                    title: {
                        text: props?.yAxis || "Y Axis", // Default title if yAxis not provided
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        borderRadius: 10,
                        columnWidth: "2%"
                    },
                },
                xaxis: {
                    categories: props?.date?.map(date => new Date(date).toLocaleDateString()) || [], // Map date to a readable format
                    labels: {
                        show: true // Show x-axis labels
                    },
                },
            },
            series: [{
                name: props?.yAxis || "Data", // Default series name
                type: 'column',
                data: props?.data || [] // Use data from props
            }]
        };
    }

    // // Update the chart if new props are received
    // static getDerivedStateFromProps(nextProps, prevState) {
    //     if (nextProps.date !== prevState.options.xaxis.categories || nextProps.data !== prevState.series[0].data) {
    //         return {
    //             options: {
    //                 ...prevState.options,
    //                 xaxis: {
    //                     ...prevState.options.xaxis,
    //                     categories: nextProps.date?.map(date => new Date(date).toLocaleDateString()) || []
    //                 },
    //                 yaxis: {
    //                     ...prevState.options.yaxis,
    //                     title: {
    //                         text: nextProps?.yAxis || "Y Axis"
    //                     }
    //                 }
    //             },
    //             series: [{
    //                 name: nextProps?.yAxis || "Data",
    //                 data: nextProps?.data || []
    //             }]
    //         };
    //     }
    //     return null; // No update required if props are the same
    // }

    render() {
        return (
            <div>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="line"
                    height="200px"
                />
            </div>
        );
    }
}

export default BasicBar;
