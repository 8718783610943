import React, { useEffect, useState, useRef } from "react";
import imgOne from "../../assets/images/cyber-security-service.png"
import imgTwo from "../../assets/images/RegulatoryComplianceandSupport.png"
import imgThree from "../../assets/images/CyberRisk AssessmentandManagement.png"
import imgFour from "../../assets/images/supply-chain.png"
import imgFive from "../../assets/images/cyber-security.png"
import { Link, useLocation, } from "react-router-dom";
import askAI from '../../assets/images/Ask_Ai_Icon.svg'
import cyberSecurityService from '../../assets/images/cyber-security-service.png'
import RegulatoryComplianceandAuditSupport from '../../assets/images/Regulatory-Compliance-and-Audit-Support.svg'
import CyberRisk from '../../assets/images/Cyber-Risk-Assessment-and-Management.svg'
import supplyChain from '../../assets/images/supply-chain.png'
import cyberSecurityAssigment from '../../assets/images/CybersecurityMaturityAssessment.svg'
import icon from "../../assets/images/Icon_process.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import detectSecurityMonitoring from '../../assets/images/Detect-Security-monitoring.svg'
import detectThreat from '../../assets/images/Detect-Threat-Intelligence.svg'
import detectVlunerability from '../../assets/images/Detect-Vulnerabillity-pentest.svg'
import IdentifyMaturaity from '../../assets/images/Identify-Maturity-Assessment.svg'
import IdentifyRed from '../../assets/images/Identify-Reg-compliance.svg'
import IdentifyRiskAssessment from '../../assets/images/Identify-risk-Assessment-Mangement.svg'
import IdentifySupplyChain from '../../assets/images/Identify-Risk-supply-chain.svg'
import IdentifyStrategy from '../../assets/images/Identify-Strategy.svg'











const Identify = () => {
    const location = useLocation();


    const [pathname, setPathname] = useState(location.pathname);
    const params = new URLSearchParams(location?.search);
    const [hash, setHash] = useState(location.hash.replace('#', ''));
    const modeValue = params.get('mode');

    useEffect(() => {
        setHash(location.hash.replace('#', ''));
    }, [location.hash])

    const MenuTitle = {
        "/security":
        {
            title: "Pr",
            icon: "/assets/images/image-gallery/security_health.svg",
            subTitle: "Domain & DNS Health Check",
            subIcon: "/assets/images/image-gallery/domain_dns.svg"
        }
    }

    const tabUpDown = (e) => {
        e.preventDefault();
        var tab_uppper_button = document.getElementById('tab_uppper_button');
        var mypanel = document.getElementById('bottom_panel');

        tab_uppper_button.classList.toggle('active_circle');
        mypanel.classList.toggle('active_bottom_panel');
    }

    const onTabClick = (tabId) => (e) => {
        console.log(e.target);
        let buttons = document.getElementsByClassName('nav-link');
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].classList.remove('active');
        }
        document.getElementById(tabId).classList.toggle('active');

        let tabs = document.getElementsByClassName('tab-pane');
        for (var i = 0; i < tabs.length; i++) {
            tabs[i].classList.remove('show');
            tabs[i].classList.remove('active');
        }
        document.getElementById(tabId + '-pane').classList.toggle('show');
        document.getElementById(tabId + '-pane').classList.toggle('active');
    }

    return (
        <div>
            <div className="serviceBox">
                <div class="textboxMain">
                    <h6>Valkyrie Services</h6>
                    <div className="box">
                        <p>
                            Browse thorough our service and read-up on the details.
                            Use the "+" (Add) BUtton, to add cross section service of interest.
                        </p>
                        <p>
                            When you created your collection, click "Compile".
                            Then a Pre-Flood from will appear.
                        </p>
                        <p>
                            We will then get back to you with a suggested meeting and prepare a presentation
                            based on your selection and send out an invite pr. e-mail.
                        </p>
                    </div>
                </div>
                {hash == 'identify' &&
                    (<div class="bottom_panel bottom_panel_Services active_bottom_panel" id="bottom_panel">
                        <div class="bottom_panel_header">
                            <div class="tab_boxes">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <div class="boxA">
                                            <div class="upper_box">
                                                <img src="/assets/images/Identify_Valkyrie.svg" class="img-fluid" alt="" />
                                                <div class="text_box">
                                                    <p>Identify Services</p>
                                                    <div
                                                        class="progress"
                                                        role="progressbar"
                                                        aria-label="Basic example"
                                                        aria-valuenow="100"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    >
                                                        <div
                                                            class={`progress-bar ${modeValue == 'report-mode' ?
                                                                'green_progress_bar' : modeValue == 'assessment-mode' ?
                                                                    'red_progress_bar' : modeValue == 'settings-mode' ? 'yellow_progress_bar' : 'red_progress_bar'}`}
                                                            style={{ width: "100%" }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" onClick={onTabClick("home-tab")}>
                                            <div class="inner_tab_button">
                                                <img src={cyberSecurityService} class="img-fluid" alt="" />
                                                <p>Cybersecurity Strategy and Governance Services</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" onClick={onTabClick("profile-tab")}>
                                            <div class="inner_tab_button">
                                                <img src={RegulatoryComplianceandAuditSupport} class="img-fluid" alt="" />
                                                <p>Regulatory Compliance and Audit Support</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false" onClick={onTabClick("contact-tab")}>
                                            <div class="inner_tab_button">
                                                <img src={CyberRisk} class="img-fluid" alt="" />
                                                <p>Cyber Risk Assessment and Management</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contactFour-tab" data-bs-toggle="tab" data-bs-target="#contactFour-tab-pane" type="button" role="tab" aria-controls="contactFour-tab-pane" aria-selected="false" onClick={onTabClick("contactFour-tab")}>
                                            <div class="inner_tab_button">
                                                <img src={supplyChain} class="img-fluid" alt="" />
                                                <p>Supply Chain Risk Management</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contactFive-tab" data-bs-toggle="tab" data-bs-target="#contactFive-tab-pane" type="button" role="tab" aria-controls="contactFive-tab-pane" aria-selected="false" onClick={onTabClick("contactFive-tab")}>
                                            <div class="inner_tab_button">
                                                <img src={cyberSecurityAssigment} class="img-fluid" alt="" />
                                                <p>Cybersecurity Maturity Assessment</p>
                                            </div>
                                        </button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div className="d-flex-serviceTab">
                                                <img src={cyberSecurityService}></img>
                                                <h2>Cybersecurity Strategy and Governance Services</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6>
                                                    Introduction :
                                                </h6>
                                                <p>
                                                    We assist clients in defining their cybersecurity objectives, policies, roles and responsibilities and aligning them with their business goals and risk appetite.
                                                </p>
                                                <h6>Service Overview</h6>
                                                <p>
                                                    Our Cybersecurity Strategy and Governance services are designed to help clients define and implement effective cybersecurity strategies that align with their business goals and risk appetite. By establishing clear cybersecurity objectives, policies, roles, and responsibilities, we enable organizations to create a robust governance framework that supports proactive risk management and ensures comprehensive protection of digital assets.
                                                </p>
                                                <h6>
                                                    Key Components
                                                </h6>
                                                <h6 className="mb-1">Cybersecurity Strategy Development</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Strategic Planning: Collaborate with clients to develop a long-term cybersecurity strategy that aligns with their business objectives and risk tolerance.
                                                    </li>
                                                    <li>
                                                        Threat Landscape Analysis: Conduct a thorough analysis of the current threat landscape to identify potential risks and vulnerabilities specific to the organization.
                                                    </li>
                                                    <li>
                                                        Risk Assessment and Management: Perform detailed risk assessments to prioritize threats and vulnerabilities and develop risk management strategies to mitigate identified risks.
                                                    </li>
                                                    <li>
                                                        Roadmap Creation: Create a detailed cybersecurity roadmap that outlines key initiatives, timelines, and milestones for implementing the strategy.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Policy and Procedure Development</h6>
                                                <ul className="mb-2">
                                                    <li>Cybersecurity Policies: Develop comprehensive cybersecurity policies that cover all aspects of security, including data protection, access control, incident response, and compliance.</li>
                                                    <li>Standard Operating Procedures (SOPs): Create SOPs to guide employees and stakeholders in implementing and adhering to cybersecurity policies.</li>
                                                    <li>Policy Review and Updates: Regularly review and update policies and procedures to ensure they remain relevant and effective in addressing emerging threats and regulatory changes.</li>
                                                    <li>Policy Dissemination: Ensure that cybersecurity policies and procedures are effectively communicated and accessible to all employees and stakeholders.</li>
                                                </ul>
                                                <h6 className="mb-1">Roles and Responsibilities</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Governance Framework: Establish a cybersecurity governance framework that defines clear roles, responsibilities, and accountability for cybersecurity across the organization.
                                                    </li>
                                                    <li>
                                                        Executive Oversight: Define the roles and responsibilities of executive leadership in overseeing and supporting cybersecurity initiatives.
                                                    </li>
                                                    <li>
                                                        Security Committees: Create security committees or working groups to facilitate collaboration and decision-making on cybersecurity matters.
                                                    </li>
                                                    <li>
                                                        Role-Based Training: Provide role-based training to ensure that employees and stakeholders understand their specific responsibilities and how to fulfill them.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Alignment with Business Goals</h6>
                                                <ul className="mb-2">
                                                    <li>Business Integration: Align cybersecurity objectives with overall business goals to ensure that security initiatives support and enhance business operations.</li>
                                                    <li>Risk Appetite Definition: Work with stakeholders to define the organization's risk appetite and tolerance levels, guiding the prioritization of cybersecurity efforts.</li>
                                                    <li>Performance Metrics: Establish key performance indicators (KPIs) and metrics to measure the effectiveness of cybersecurity initiatives and their alignment with business objectives.</li>
                                                    <li>Continuous Improvement: Implement a continuous improvement process to regularly assess and refine cybersecurity strategies and practices in response to evolving business needs and threats.</li>
                                                </ul>
                                                <h6 className="mb-1">Compliance and Regulatory Requirements</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Regulatory Mapping: Identify relevant regulatory requirements and industry standards that apply to the organization, such as GDPR, HIPAA, PCI DSS, and ISO/IEC 27001.
                                                    </li>
                                                    <li>
                                                        Compliance Programs: Develop and implement compliance programs to ensure adherence to regulatory requirements and industry standards.
                                                    </li>
                                                    <li>
                                                        Audit Preparation: Assist in preparing for security audits and assessments by external regulators and auditors, ensuring that necessary documentation and evidence are in place.
                                                    </li>
                                                    <li>
                                                        Compliance Monitoring: Continuously monitor compliance status and address any gaps or deficiencies to maintain a strong compliance posture.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Incident Response and Management</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Incident Response Plan: Develop a comprehensive incident response plan that outlines procedures for detecting, reporting, and responding to cybersecurity incidents.
                                                    </li>
                                                    <li>
                                                        Incident Response Team (IRT): Establish an incident response team with defined roles and responsibilities for managing and mitigating security incidents.
                                                    </li>
                                                    <li>
                                                        ncident Drills and Simulations: Conduct regular incident response drills and simulations to test the effectiveness of the incident response plan and improve readiness.
                                                    </li>
                                                    <li>
                                                        Post-Incident Review: Perform post-incident reviews to analyze the root causes of incidents, evaluate response effectiveness, and implement lessons learned.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Security Awareness and Training</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Employee Training Programs: Develop and deliver training programs to educate employees on cybersecurity best practices, policies, and procedures.
                                                    </li>
                                                    <li>
                                                        Executive Training: Provide targeted training for executive leadership to ensure they understand their roles in cybersecurity governance and strategy.
                                                    </li>
                                                    <li>
                                                        Security Culture: Promote a security-conscious culture across the organization by encouraging proactive reporting of security concerns and fostering a sense of shared responsibility.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Technology and Tools Assessment</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Technology Review: Assess the current security technologies and tools in use, identifying gaps and recommending enhancements to improve the security posture.
                                                    </li>
                                                    <li>
                                                        Tool Selection and Implementation: Assist in selecting and implementing security tools and technologies that align with the organization's cybersecurity strategy and objectives.
                                                    </li>
                                                    <li>
                                                        Integration and Optimization: Ensure that security tools are effectively integrated into the overall security architecture and optimized for maximum efficiency and effectiveness.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Third-Party Risk Management</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Vendor Assessment:</span> Conduct assessments of third-party vendors and partners to evaluate their security practices and potential risks to the organization.
                                                    </li>
                                                    <li>
                                                        <span>Contractual Requirements:</span> Establish security requirements and expectations in contracts with third-party vendors and partners.
                                                    </li>
                                                    <li>
                                                        <span>Continuous Monitoring:</span> Continuously monitor third-party security practices and compliance to ensure ongoing protection and risk management.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Continuous Monitoring and Reporting</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Security Monitoring:</span> Implement continuous monitoring solutions to detect and respond to security threats in real-time.
                                                        Reporting and Dashboards: Develop reporting mechanisms and dashboards to provide visibility into the organization's security posture and progress towards cybersecurity objectives.
                                                    </li>
                                                    <li>
                                                        Executive Reporting: Provide regular reports to executive leadership and the board of directors to keep them informed of cybersecurity status and developments
                                                    </li>
                                                </ul>
                                                <p>
                                                    By leveraging our comprehensive Cybersecurity Strategy and Governance services, organizations can establish a strong foundation for managing cybersecurity risks and aligning security efforts with business objectives. This holistic approach ensures that all aspects of cybersecurity are addressed, from policy development and compliance to incident response and continuous improvement, creating a resilient and secure environment for business operations.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div className="d-flex-serviceTab">
                                                <img src={RegulatoryComplianceandAuditSupport}></img>
                                                <h2>Regulatory Compliance and Audit Support</h2>
                                            </div>
                                            <div className="boxContent">

                                                <h6>
                                                    Introduction :
                                                </h6>
                                                <p>
                                                    We assist clients in complying with applicable laws, regulations, standards, and frameworks specific to their industry or sector and aid them in preparing for external audits or assessments.
                                                </p>
                                                <h6>Service Overview</h6>
                                                <p>
                                                    Our Regulatory Compliance and Audit Support services are designed to help clients navigate the complex landscape of laws, regulations, standards, and frameworks specific to their industry or sector. We provide comprehensive assistance in achieving and maintaining compliance, as well as preparing for and managing external audits or assessments, ensuring that organizations meet all regulatory requirements and avoid potential penalties.
                                                </p>
                                                <h6>
                                                    Key Components
                                                </h6>
                                                <h6 className="mb-1">Regulatory Compliance Assessment</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Gap Analysis:</span> Conduct a thorough gap analysis to identify areas where the organization’s current practices fall short of regulatory requirements and industry standards.
                                                    </li>
                                                    <li>
                                                        <span>Compliance Mapping:</span> Map applicable laws, regulations, standards, and frameworks to the organization’s processes and controls, ensuring a clear understanding of compliance obligations.
                                                    </li>
                                                    <li>
                                                        <span>Risk Assessment:</span> Evaluate the risk levels associated with non-compliance and prioritize remediation efforts based on the severity and impact of potential violations.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Compliance Program Development</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Policy and Procedure Development: Develop and document comprehensive compliance policies and procedures tailored to the specific requirements of the organization’s industry or sector.
                                                    </li>
                                                    <li>
                                                        Compliance Framework Implementation: Implement industry-recognized compliance frameworks (such as ISO 27001, NIST, PCI DSS, HIPAA, GDPR, SOX) to establish a structured approach to managing compliance.
                                                    </li>
                                                    <li>
                                                        Roles and Responsibilities: Define clear roles and responsibilities for compliance management, ensuring accountability and effective oversight.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Regulatory Change Management</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Monitoring and Updates:</span> Continuously monitor changes in relevant laws, regulations, and standards to ensure the organization remains informed of new and evolving requirements.
                                                    </li>
                                                    <li>
                                                        <span>Impact Analysis:</span> Assess the impact of regulatory changes on the organization’s operations and compliance posture.
                                                    </li>
                                                    <li>
                                                        <span>Implementation of Changes:</span> Develop and implement action plans to address regulatory changes, updating policies, procedures, and controls as necessary.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Audit Preparation and Support</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Pre-Audit Assessments:</span> Conduct pre-audit assessments to identify potential issues and areas for improvement before an external audit or assessment.
                                                    </li>
                                                    <li>
                                                        <span>Documentation and Evidence Collection:</span> Assist in the collection and organization of necessary documentation and evidence to demonstrate compliance during audits.
                                                    </li>
                                                    <li>
                                                        <span>Audit Readiness Training:</span> Provide training and guidance to employees on audit readiness, ensuring they understand their roles and responsibilities during the audit process.
                                                    </li>
                                                    <li>
                                                        <span>Mock Audits:</span> Perform mock audits to simulate the external audit experience, identifying gaps and refining responses to potential auditor inquiries.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">External Audit Management</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Audit Coordination:</span> Act as a liaison between the organization and external auditors, coordinating audit activities and facilitating communication.
                                                    </li>
                                                    <li>
                                                        <span>Audit Response Management:</span> Assist in responding to auditor requests for information, ensuring timely and accurate submission of required documents and evidence.
                                                    </li>
                                                    <li>
                                                        <span>Issue Resolution:</span> Work with internal teams to address any issues or findings identified during the audit, developing corrective action plans and overseeing their implementation.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Post-Audit Support</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Audit Findings Remediation:</span> Develop and implement remediation plans to address any findings or deficiencies identified during the audit.
                                                    </li>
                                                    <li>
                                                        <span>Continuous Improvement:</span> Integrate lessons learned from the audit into the organization’s compliance program, promoting continuous improvement.
                                                    </li>
                                                    <li>
                                                        <span>Follow-Up Audits:</span> Conduct follow-up audits to verify the effectiveness of remediation efforts and ensure ongoing compliance.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Compliance Training and Awareness</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Employee Training Programs:</span> Develop and deliver compliance training programs to educate employees on regulatory requirements, organizational policies, and their specific roles in maintaining compliance.
                                                    </li>
                                                    <li>
                                                        <span>Executive and Board Training:</span> Provide targeted training for executive leadership and board members on their responsibilities for regulatory compliance and governance.
                                                    </li>
                                                    <li>
                                                        <span>Ongoing Awareness Campaigns:</span> Implement ongoing awareness campaigns to keep compliance top of mind for all employees and stakeholders.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Compliance Monitoring and Reporting</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Continuous Monitoring:</span> Implement continuous monitoring solutions to track compliance with regulatory requirements and detect any deviations in real-time.
                                                    </li>
                                                    <li>
                                                        <span>Compliance Reporting:</span> Develop and distribute regular compliance reports to key stakeholders, providing visibility into the organization’s compliance status and progress.
                                                    </li>
                                                    <li>
                                                        <span>Dashboard and Metrics:</span> Create dashboards and metrics to measure and visualize compliance performance, facilitating informed decision-making.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Third-Party Compliance Management</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Vendor Assessments:</span> Conduct compliance assessments of third-party vendors and partners to ensure they meet regulatory requirements and contractual obligations.
                                                    </li>
                                                    <li>
                                                        <span>Third-Party Risk Management:</span> Implement risk management practices to monitor and mitigate compliance risks associated with third-party relationships.
                                                    </li>
                                                    <li>
                                                        <span>Contractual Clauses:</span> Develop and enforce contractual clauses that require third parties to adhere to relevant regulatory requirements and standards.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Industry-Specific Compliance Support</h6>
                                                <ul>
                                                    <li>
                                                        <span>Healthcare Compliance:</span> Provide specialized support for healthcare organizations in meeting HIPAA, HITECH, and other healthcare-specific regulations.
                                                    </li>
                                                    <li>
                                                        <span>Financial Services Compliance:</span> Assist financial institutions in complying with regulations such as SOX, GLBA, and Dodd-Frank.
                                                    </li>
                                                    <li>
                                                        <span>Data Protection and Privacy:</span> Support organizations in meeting data protection and privacy regulations, including GDPR, CCPA, and other data privacy laws.
                                                    </li>
                                                    <li>
                                                        <span>Manufacturing and Industry Standards:</span> Help manufacturing, and industrial organizations comply with standards such as ISO 9001 and industry-specific regulations.
                                                    </li>
                                                </ul>
                                                <p>
                                                    By leveraging our Regulatory Compliance and Audit Support services, organizations can confidently navigate the complexities of regulatory requirements and ensure they are well-prepared for external audits and assessments. This comprehensive approach to compliance management helps mitigate risks, avoid penalties, and maintain a strong reputation for regulatory adherence.
                                                </p>
                                                <h6 className="mb-1">Industry-Specific Compliance Support</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div className="d-flex-serviceTab">
                                                <img src={CyberRisk}></img>
                                                <h2>Cyber Risk Assessment and Management</h2>
                                            </div>
                                            <div className="boxContent">

                                                <h6>
                                                    Introduction :
                                                </h6>
                                                <p>
                                                    We assist clients in identifying, analyzing, evaluating, and prioritizing their cybersecurity risks. We also help them develop appropriate controls and mitigation strategies.
                                                </p>
                                                <h6>Service Overview</h6>
                                                <p>
                                                    Our Cyber Risk Assessment and Management services are designed to help clients identify, analyze, evaluate, and prioritize their cybersecurity risks. By developing appropriate controls and mitigation strategies, we ensure that organizations can effectively manage their cyber risks, protect critical assets, and enhance their overall security posture. This proactive approach helps clients stay ahead of potential threats and maintain business continuity.
                                                </p>
                                                <h6>
                                                    Key Components
                                                </h6>
                                                <h6 className="mb-1">Risk Identification</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Asset Inventory:</span> Create a comprehensive inventory of all critical assets, including hardware, software, data, and network components.
                                                    </li>
                                                    <li>
                                                        <span>Threat Modeling:</span> Identify and document potential threats and attack vectors specific to the organization’s environment and industry.
                                                    </li>
                                                    <li>
                                                        <span>Vulnerability Assessment:</span> Perform vulnerability assessments to identify weaknesses and security gaps in systems, applications, and networks.
                                                    </li>
                                                    <li>
                                                        <span>Business Impact Analysis (BIA):</span> Conduct a BIA to understand the potential impact of various cyber risks on business operations and objectives.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Risk Analysis</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Threat Analysis:</span> Analyze identified threats to determine their likelihood and potential impact on the organization.
                                                    </li>
                                                    <li>
                                                        <span>Vulnerability Analysis:</span> Evaluate the severity of identified vulnerabilities and their potential exploitation by threat actors.
                                                    </li>
                                                    <li>
                                                        <span>Risk Scenarios:</span> Develop detailed risk scenarios that describe how different threats and vulnerabilities could affect the organization.
                                                    </li>
                                                    <span>
                                                        <span>Quantitative and Qualitative Analysis:</span> Use both quantitative (e.g., financial impact) and qualitative (e.g., reputational damage) methods to assess the potential consequences of risks.
                                                    </span>
                                                </ul>
                                                <h6 className="mb-1">Risk Evaluation</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Risk Scoring and Ranking:</span> Assign risk scores and prioritize risks based on their likelihood and potential impact, using frameworks such as NIST, ISO 27005, or FAIR.
                                                    </li>
                                                    <li>
                                                        <span>Risk Appetite and Tolerance:</span> Work with stakeholders to define the organization’s risk appetite and tolerance levels, guiding the prioritization of risk management efforts.
                                                    </li>
                                                    <li>
                                                        <span>Risk Heat Maps:</span> Create risk heat maps to visualize the distribution and severity of identified risks, facilitating informed decision-making.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Risk Mitigation and Control Development</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Control Selection:</span> Identify and select appropriate security controls to mitigate identified risks, considering industry best practices and regulatory requirements.
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Control Implementation:
                                                        </span> Develop and implement technical, administrative, and physical controls to address prioritized risks.
                                                    </li>
                                                    <li>
                                                        <span>Control Validation:</span> Test and validate the effectiveness of implemented controls through regular audits, assessments, and penetration testing.
                                                    </li>
                                                    <li>
                                                        <span>
                                                            Defense in Depth:
                                                        </span>
                                                        Apply a layered security approach to ensure multiple lines of defense against cyber threats.
                                                    </li>
                                                </ul>
                                                <h6 class="mb-1">Risk Treatment and Response Planning</h6>
                                                <ul class="mb-2">
                                                    <li>
                                                        <span>Risk Treatment Plans:</span> Develop detailed risk treatment plans that outline the steps, responsibilities, and timelines for mitigating identified risks.
                                                    </li>
                                                    <li>
                                                        <span>Incident Response Planning:</span> Create and maintain incident response plans to ensure quick and effective responses to security incidents.
                                                    </li>
                                                    <li>
                                                        <span>Contingency Planning:</span> Develop contingency plans, including disaster recovery and business continuity plans, to ensure resilience against major cyber incidents.
                                                    </li>
                                                    <li>
                                                        <span>Insurance and Risk Transfer:</span> Evaluate options for cyber insurance and other risk transfer mechanisms to mitigate residual risks.
                                                    </li>
                                                </ul>

                                                <h6 class="mb-1">Continuous Monitoring and Risk Management</h6>
                                                <ul class="mb-2">
                                                    <li>
                                                        <span>Real-Time Monitoring:</span> Implement continuous monitoring solutions to detect and respond to new threats and vulnerabilities in real-time.
                                                    </li>
                                                    <li>
                                                        <span>Risk Metrics and Dashboards:</span> Develop metrics and dashboards to track and report on the organization’s risk posture and the effectiveness of risk management efforts.
                                                    </li>
                                                    <li>
                                                        <span>Regular Reviews and Updates:</span> Conduct regular reviews and updates of the risk management program to ensure it remains effective and aligned with evolving threats and business needs.
                                                    </li>
                                                </ul>

                                                <h6 class="mb-1">Governance and Compliance</h6>
                                                <ul class="mb-2">
                                                    <li>
                                                        <span>Policy and Procedure Development:</span> Develop and enforce policies and procedures that support effective risk management and compliance with regulatory requirements.
                                                    </li>
                                                    <li>
                                                        <span>Compliance Assessments:</span> Conduct compliance assessments to ensure adherence to relevant regulations and industry standards, such as GDPR, HIPAA, PCI DSS, and ISO 27001.
                                                    </li>
                                                    <li>
                                                        <span>Audit Support:</span> Provide support for internal and external audits, including the preparation of necessary documentation and evidence.
                                                    </li>
                                                </ul>

                                                <h6 class="mb-1">Training and Awareness</h6>
                                                <ul class="mb-2">
                                                    <li>
                                                        <span>Risk Management Training:</span> Offer training programs to educate employees on risk management principles, processes, and their specific roles in managing cyber risks.
                                                    </li>
                                                    <li>
                                                        <span>Security Awareness Campaigns:</span> Conduct ongoing security awareness campaigns to promote a risk-aware culture and encourage proactive risk management behaviors.
                                                    </li>
                                                    <li>
                                                        <span>Executive and Board Briefings:</span> Provide targeted briefings for executive leadership and board members on the organization’s risk posture and key risk management initiatives.
                                                    </li>
                                                </ul>

                                                <h6 class="mb-1">Third-Party Risk Management</h6>
                                                <ul class="mb-2">
                                                    <li>
                                                        <span>Vendor Risk Assessments:</span> Assess the cybersecurity risks associated with third-party vendors and partners, ensuring they meet the organization’s security standards.
                                                    </li>
                                                    <li>
                                                        <span>Third-Party Risk Monitoring:</span> Continuously monitor third-party security practices and compliance to manage and mitigate risks from external sources.
                                                    </li>
                                                    <li>
                                                        <span>Contractual Requirements:</span> Develop and enforce contractual requirements that obligate third parties to adhere to security policies and risk management practices.
                                                    </li>
                                                </ul>

                                                <h6 class="mb-1">Continuous Improvement</h6>
                                                <ul class="mb-2">
                                                    <li>
                                                        <span>Risk Management Program Reviews:</span> Conduct periodic reviews of the risk management program to identify areas for improvement and incorporate feedback from stakeholders.
                                                    </li>
                                                    <li>
                                                        <span>Lessons Learned:</span> Analyze past incidents and near-misses to identify lessons learned and integrate them into the risk management process.
                                                    </li>
                                                    <li>
                                                        <span>Emerging Threats and Trends:</span> Stay informed about emerging threats and cybersecurity trends, ensuring the risk management program adapts to new challenges.
                                                    </li>
                                                </ul>

                                                <p>
                                                    By leveraging our comprehensive Cyber Risk Assessment and Management services, organizations can proactively identify and mitigate cybersecurity risks, protecting their critical assets and maintaining business continuity. This structured approach to risk management helps clients stay ahead of potential threats, enhance their security posture, and align their cybersecurity efforts with business objectives.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="contactFour-tab-pane" role="tabpanel" aria-labelledby="contactFour-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div className="d-flex-serviceTab">
                                                <img src={supplyChain} alt="Cybersecurity Service"></img>
                                                <h2>Supply Chain Risk Management</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6>Introduction</h6>
                                                <p>We help clients evaluate and manage cybersecurity risks associated with their partners, suppliers, vendors, and customers. We also ensure the integrity and security of their products and services.</p>

                                                <h6>Service Overview</h6>
                                                <p>Our Supply Chain Risk Management services are designed to help clients evaluate and manage cybersecurity risks associated with their partners, suppliers, vendors, and customers. By implementing comprehensive risk assessment and management strategies, we ensure the integrity and security of our clients' products and services throughout the entire supply chain. This proactive approach mitigates potential disruptions and safeguards the organization’s operational resilience and reputation.</p>

                                                <h6>Key Components</h6>

                                                <h6 className="mb-1">Risk Assessment and Evaluation</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Initial Risk Assessment:</span> Conduct a thorough risk assessment of the supply chain to identify potential vulnerabilities and threats posed by partners, suppliers, vendors, and customers.
                                                    </li>
                                                    <li>
                                                        <span>Risk Profiling:</span> Develop risk profiles for each entity in the supply chain based on factors such as industry, location, compliance status, and historical security incidents.
                                                    </li>
                                                    <li>
                                                        <span>Criticality Analysis:</span> Assess the criticality of each supplier and partner to the organization’s operations, prioritizing those with the highest impact on business continuity.
                                                    </li>
                                                </ul>

                                                <h6 className="mb-1">Third-Party Risk Management</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Vendor Risk Assessments:</span> Perform detailed security assessments of vendors and suppliers, including their cybersecurity practices, policies, and incident history.
                                                    </li>
                                                    <li>
                                                        <span>Questionnaires and Audits:</span> Utilize security questionnaires and conduct on-site or remote audits to gather information on third-party security postures.
                                                    </li>
                                                    <li>
                                                        <span>Compliance Verification:</span> Ensure that vendors and suppliers comply with relevant regulatory requirements and industry standards, such as GDPR, HIPAA, PCI DSS, and ISO 27001.
                                                    </li>
                                                </ul>

                                                <h6 className="mb-1">Contractual Security Requirements</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Security Clauses:</span> Develop and incorporate robust security clauses into contracts with suppliers and vendors, specifying cybersecurity requirements and expectations.
                                                    </li>
                                                    <li>
                                                        <span>Service Level Agreements (SLAs):</span> Define and enforce SLAs that include specific cybersecurity metrics and performance indicators.
                                                    </li>
                                                    <li>
                                                        <span>Liability and Accountability:</span> Establish clear accountability and liability provisions in contracts to address potential security breaches and incidents.
                                                    </li>
                                                </ul>

                                                <h6 className="mb-1">Continuous Monitoring and Risk Mitigation</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Ongoing Monitoring:</span> Implement continuous monitoring of third-party security practices, leveraging threat intelligence, and automated tools to detect and respond to risks in real-time.
                                                    </li>
                                                    <li>
                                                        <span>Risk Mitigation Strategies:</span> Develop and implement risk mitigation strategies to address identified vulnerabilities, including technical controls, process improvements, and policy updates.
                                                    </li>
                                                    <li>
                                                        <span>Remediation Plans:</span> Work with third parties to develop and execute remediation plans for addressing identified security gaps and improving their overall security posture.
                                                    </li>
                                                </ul>

                                                <h6 className="mb-1">Supply Chain Integrity and Security</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Product and Service Integrity:</span> Ensure the integrity and security of products and services by implementing secure development, manufacturing, and delivery practices.
                                                    </li>
                                                    <li>
                                                        <span>Secure Logistics:</span> Assess and secure the logistics and transportation processes to protect against tampering, theft, and other security risks during transit.
                                                    </li>
                                                    <li>
                                                        <span>End-to-End Encryption:</span> Implement end-to-end encryption for data shared between the organization and its suppliers and partners to ensure confidentiality and integrity.
                                                    </li>
                                                </ul>

                                                <h6 className="mb-1">Incident Response and Management</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Incident Reporting and Communication:</span> Establish clear protocols for incident reporting and communication between the organization and its suppliers and partners.
                                                    </li>
                                                    <li>
                                                        <span>Collaborative Response Plans:</span> Develop and coordinate incident response plans that involve key suppliers and partners, ensuring a unified and effective response to security incidents.
                                                    </li>
                                                    <li>
                                                        <span>Post-Incident Analysis:</span> Conduct post-incident reviews and analysis to identify root causes, lessons learned, and opportunities for improving supply chain security.
                                                    </li>
                                                </ul>

                                                <h6 className="mb-1">Compliance and Governance</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Regulatory Compliance:</span> Ensure that all aspects of the supply chain comply with relevant regulations and industry standards, maintaining a high level of governance and accountability.
                                                    </li>
                                                    <li>
                                                        <span>Policy Development:</span> Develop and enforce supply chain security policies and procedures that align with organizational and regulatory requirements.
                                                    </li>
                                                    <li>
                                                        <span>Regular Audits and Assessments:</span> Conduct regular audits and assessments to verify compliance and identify areas for improvement in supply chain security practices.
                                                    </li>
                                                </ul>

                                                <h6 className="mb-1">Training and Awareness</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Supplier and Vendor Training:</span> Provide cybersecurity training and resources to suppliers and vendors to enhance their understanding of security best practices and requirements.
                                                    </li>
                                                    <li>
                                                        <span>Employee Training:</span> Train internal employees on supply chain security risks and the importance of maintaining robust security practices when interacting with third parties.
                                                    </li>
                                                    <li>
                                                        <span>Awareness Campaigns:</span> Conduct ongoing awareness campaigns to keep security top-of-mind for all stakeholders involved in the supply chain.
                                                    </li>
                                                </ul>

                                                <h6 className="mb-1">Technology and Tools Integration</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Risk Management Platforms:</span> Utilize advanced risk management platforms and tools to automate the assessment, monitoring, and management of supply chain risks.
                                                    </li>
                                                    <li>
                                                        <span>Threat Intelligence:</span> Integrate threat intelligence feeds to stay informed about emerging threats and vulnerabilities affecting the supply chain.
                                                    </li>
                                                    <li>
                                                        <span>Secure Communication Channels:</span> Implement secure communication channels for exchanging sensitive information with suppliers and partners.
                                                    </li>
                                                </ul>

                                                <h6 className="mb-1">Continuous Improvement</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        <span>Feedback Mechanism:</span> Establish feedback mechanisms to gather insights from suppliers, partners, and internal stakeholders on the effectiveness of supply chain security measures.
                                                    </li>
                                                    <li>
                                                        <span>Regular Reviews and Updates:</span> Regularly review and update supply chain risk management strategies and practices to address evolving threats and changes in the business environment.
                                                    </li>
                                                    <li>
                                                        <span>Benchmarking and Best Practices:</span> Benchmark supply chain security practices against industry best practices and standards, continually striving for excellence.
                                                    </li>
                                                </ul>

                                                <p>By leveraging our comprehensive Supply Chain Risk Management services, organizations can ensure the security and integrity of their supply chains, mitigating
                                                    potential risks associated with third parties and maintaining operational resilience. This holistic approach to supply chain security helps protect critical assets, ensures compliance, and enhances overall business continuity.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="contactFive-tab-pane" role="tabpanel" aria-labelledby="contactFive-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div className="d-flex-serviceTab">
                                                <img src={cyberSecurityAssigment}></img>
                                                <h2>Cybersecurity Maturity Assessment Services</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6>
                                                    Introduction:
                                                </h6>
                                                <p>
                                                    We assist clients in assessing their cybersecurity capabilities and performance and identifying areas for improvement and gaps.
                                                </p>
                                                <h6>Service Overview</h6>
                                                <p>
                                                    Our Cybersecurity Maturity Assessment services are designed to help clients evaluate their current cybersecurity capabilities and performance, identify areas for improvement, and uncover gaps in their security posture. By conducting a comprehensive maturity assessment, we provide actionable insights and recommendations that enable organizations to enhance their cybersecurity resilience and align their security practices with industry best practices and standards.
                                                </p>
                                                <h6>
                                                    Key Components
                                                </h6>
                                                <h6 className="mb-1">Initial Assessment and Planning</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Kickoff Meeting: Conduct an initial meeting with key stakeholders to understand the organization’s objectives, scope, and expectations for the cybersecurity maturity assessment.
                                                    </li>
                                                    <li>
                                                        Assessment Framework Selection: Select an appropriate cybersecurity maturity assessment framework (e.g., NIST Cybersecurity Framework, CIS Controls, ISO 27001) that aligns with the organization’s industry, regulatory requirements, and business goals.
                                                    </li>
                                                    <li>
                                                        Data Collection Preparation: Prepare for data collection by identifying necessary documents, systems, and personnel required for the assessment.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Cybersecurity Capability Evaluation</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Policy and Governance Review: Assess the organization’s cybersecurity policies, procedures, and governance structures to ensure they provide a solid foundation for cybersecurity management.
                                                    </li>
                                                    <li>
                                                        Risk Management: Evaluate the organization’s risk management practices, including risk assessment processes, risk treatment plans, and risk monitoring activities.
                                                    </li>
                                                    <li>
                                                        Security Controls Assessment: Review the implementation and effectiveness of technical, administrative, and physical security controls across the organization.
                                                    </li>
                                                    <li>
                                                        Incident Response: Analyze the organization’s incident response capabilities, including incident detection, response procedures, and recovery processes.
                                                    </li>
                                                    <li>
                                                        Compliance and Regulatory Adherence: Evaluate the organization’s compliance with relevant regulations, standards, and industry best practices.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Performance Analysis</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Current State Analysis: Assess the current state of the organization’s cybersecurity practices and performance, identifying strengths and weaknesses.
                                                    </li>
                                                    <li>
                                                        Benchmarking: Benchmark the organization’s cybersecurity maturity against industry peers and best practices to provide context for the assessment results.
                                                    </li>
                                                    <li>
                                                        Key Performance Indicators (KPIs): Identify and analyze relevant KPIs to measure the effectiveness of the organization’s cybersecurity efforts.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Gap Analysis</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Gap Identification: Identify gaps between the organization’s current cybersecurity capabilities and the desired maturity level based on the selected assessment framework.
                                                    </li>
                                                    <li>
                                                        Root Cause Analysis: Conduct a root cause analysis to understand the underlying reasons for identified gaps and weaknesses.
                                                    </li>
                                                    <li>
                                                        Prioritization: Prioritize identified gaps based on their potential impact on the organization’s security posture and business objectives.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Recommendations and Roadmap Development</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Actionable Recommendations: Provide detailed, actionable recommendations to address identified gaps and enhance cybersecurity capabilities.
                                                    </li>
                                                    <li>
                                                        Maturity Improvement Roadmap: Develop a strategic roadmap that outlines the steps, timelines, and resources required to achieve the desired cybersecurity maturity level.
                                                    </li>
                                                    <li>
                                                        Quick Wins and Long-Term Initiatives: Identify quick wins that can provide immediate improvements and long-term initiatives that require more substantial effort and investment.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Implementation Support</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Project Planning: Assist in planning and prioritizing cybersecurity initiatives based on the maturity improvement roadmap.
                                                    </li>
                                                    <li>
                                                        Resource Allocation: Provide guidance on resource allocation, including personnel, budget, and tools needed to implement recommended improvements.
                                                    </li>
                                                    <li>
                                                        Execution Assistance: Offer hands-on support to help implement recommended improvements, including policy updates, control enhancements, and technology deployments.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Training and Awareness</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Employee Training Programs: Develop and deliver training programs to educate employees on new policies, procedures, and best practices resulting from the maturity assessment.
                                                    </li>
                                                    <li>
                                                        Executive and Board Briefings: Provide targeted briefings for executive leadership and board members on assessment findings, recommended improvements, and their roles in enhancing cybersecurity maturity.
                                                    </li>
                                                    <li>
                                                        Continuous Awareness Campaigns: Conduct ongoing awareness campaigns to reinforce the importance of cybersecurity and encourage adherence to new practices.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Continuous Monitoring and Review</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Ongoing Monitoring: Implement continuous monitoring solutions to track progress on the maturity improvement roadmap and ensure sustained improvements.
                                                    </li>
                                                    <li>
                                                        Regular Reviews: Schedule regular reviews and assessments to evaluate the effectiveness of implemented improvements and adjust the roadmap as necessary.
                                                    </li>
                                                    <li>
                                                        Performance Reporting: Develop and deliver regular performance reports to key stakeholders, highlighting progress, achievements, and areas needing further attention.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Documentation and Reporting</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Detailed Assessment Report: Provide a comprehensive report detailing the findings of the cybersecurity maturity assessment, including current state analysis, identified gaps, and recommendations.
                                                    </li>
                                                    <li>
                                                        Executive Summary: Create an executive summary that highlights key findings, strategic recommendations, and the overall maturity level in a clear and concise format for senior leadership.
                                                    </li>
                                                    <li>
                                                        Action Plans: Develop detailed action plans for each recommendation, outlining specific tasks, responsible parties, and timelines for completion.
                                                    </li>
                                                </ul>
                                                <h6 className="mb-1">Continuous Improvement</h6>
                                                <ul className="mb-2">
                                                    <li>
                                                        Feedback Integration: Incorporate feedback from stakeholders and lessons learned into the cybersecurity maturity improvement process.
                                                    </li>
                                                    <li>
                                                        Adaptation to Emerging Threats: Ensure that the cybersecurity maturity framework remains adaptive to new and emerging threats, updating practices and controls as necessary.
                                                    </li>
                                                    <li>
                                                        Maturity Re-Assessments: Conduct periodic re-assessments to measure progress and adjust strategies, ensuring continuous improvement in cybersecurity maturity.
                                                    </li>
                                                </ul>
                                                <p>
                                                    By leveraging our comprehensive Cybersecurity Maturity Assessment services, organizations can gain a deep understanding of their current cybersecurity capabilities, identify critical areas for improvement, and implement strategic initiatives to enhance their security posture. This holistic approach ensures that cybersecurity efforts are aligned with business goals and industry standards, providing a resilient defense against evolving cyber threats.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="boxA boxB">
                                <div class="upper_box">
                                    <div class="text_box">
                                        <span>Your at</span>
                                        <p> Valkyrie Services</p>
                                        <div
                                            class="progress"
                                            role="progressbar"
                                            aria-label="Basic example"
                                            aria-valuenow="100"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            <div
                                                class={`progress-bar ${modeValue == 'report-mode' ?
                                                    'green_progress_bar' : modeValue == 'assessment-mode' ?
                                                        'red_progress_bar' : modeValue == 'settings-mode' ? 'yellow_progress_bar' : 'red_progress_bar'}`}
                                                style={{ width: "100%" }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="servicesBoxFlex-icon">
                                    <div className="box">
                                        <div class="iconBoxSvg">
                                            <a>
                                                <FontAwesomeIcon
                                                    icon={faPlus} className="img-fluid" style={{ width: '20px', color: 'rgb(51 154 227)' }} />
                                            </a>
                                        </div>
                                        <p>
                                            Add <br /> to <br /> Request
                                        </p>
                                    </div>
                                    <div className="box">
                                        <div class="up_down_circle up_down_circle_service" id="tab_uppper_button">
                                            <a>
                                                <img src={icon} class="img-fluid" alt="" />
                                            </a>
                                        </div>
                                        <p>
                                            Compile <br /> Request
                                        </p>
                                    </div>
                                    <div className="box">
                                        <div class="iconBoxSvg">
                                            <a>
                                                <FontAwesomeIcon icon={faMinus}
                                                    style={{ width: '20px', color: '#ff1d25' }}
                                                    className="img-fluid" />
                                            </a>
                                        </div>
                                        <p>
                                            Remove <br /> from <br /> Request
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>)
                }

                {hash == 'protect' &&
                    (<div class="bottom_panel bottom_panel_Services active_bottom_panel" id="bottom_panel">
                        <div class="bottom_panel_header">

                            <div class="tab_boxes">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li>
                                        <div class="boxA">
                                            <div class="upper_box">
                                                <img src="/assets/images/Protect_Valkyrie.svg" class="img-fluid" alt="" />
                                                <div class="text_box">
                                                    <p>Protect Services</p>
                                                    <div
                                                        class="progress"
                                                        role="progressbar"
                                                        aria-label="Basic example"
                                                        aria-valuenow="100"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    >
                                                        <div
                                                            class={`progress-bar ${modeValue == 'report-mode' ?
                                                                'green_progress_bar' : modeValue == 'assessment-mode' ?
                                                                    'red_progress_bar' : modeValue == 'settings-mode' ? 'yellow_progress_bar' : 'red_progress_bar'}`}
                                                            style={{ width: "100%" }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" onClick={onTabClick("home-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Protect-Access_management.svg" class="img-fluid" alt="" />
                                                <p>Identity and Access Management Service (IAM)</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" onClick={onTabClick("profile-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Protect-Network-security.svg" class="img-fluid" alt="" />
                                                <p>Network Security Services</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false" onClick={onTabClick("contact-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Protect-Data-Protection-encrypted.svg" class="img-fluid" alt="" />
                                                <p>Data Protection and Encryption Services</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contactFour-tab" data-bs-toggle="tab" data-bs-target="#contactFour-tab-pane" type="button" role="tab" aria-controls="contactFour-tab-pane" aria-selected="false" onClick={onTabClick("contactFour-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Protect-Application-Security.svg" class="img-fluid" alt="" />
                                                <p>Application Security Services</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contactFive-tab" data-bs-toggle="tab" data-bs-target="#contactFive-tab-pane" type="button" role="tab" aria-controls="contactFive-tab-pane" aria-selected="false" onClick={onTabClick("contactFive-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Protect-Cloud-security.svg" class="img-fluid" alt="" />
                                                <p>Cloud Security Services</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contactSix-tab" data-bs-toggle="tab" data-bs-target="#contactSix-tab-pane" type="button" role="tab" aria-controls="contactSix-tab-pane" aria-selected="false" onClick={onTabClick("contactSix-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Protect-Security-Awareness.svg" class="img-fluid" alt="" />
                                                <p>Security Awareness and Training Services</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contactSeven-tab" data-bs-toggle="tab" data-bs-target="#contactSeven-tab-pane" type="button" role="tab" aria-controls="contactSeven-tab-pane" aria-selected="false" onClick={onTabClick("contactSeven-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Protect-endpoint-Security.svg" class="img-fluid" alt="" />
                                                <p>Endpoint Security Services</p>
                                            </div>
                                        </button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Protect-Access_management.svg" alt="IAM Service"></img>
                                                <h2>Identity and Access Management Service (IAM)</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We assist clients in managing user, device, application, and data identities and access rights and implementing authentication, authorization, and auditing mechanisms.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Identity and Access Management (IAM) service is designed to help organizations manage and secure user, device, application, and data identities, ensuring appropriate access rights across the enterprise. By implementing robust authentication, authorization, and auditing mechanisms, we ensure that only authorized users and devices can access critical resources, thus enhancing security and compliance.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>
                                                <h6 class="mb-1">Identity Management</h6>
                                                <ul class="mb-2">
                                                    <li><span>User Identity Management:</span> Create and manage digital identities for all users, ensuring secure and streamlined access to systems and applications.</li>
                                                    <li><span>Device Identity Management:</span> Implement mechanisms to manage identities for devices accessing the network, ensuring each device is authenticated and authorized.</li>
                                                    <li><span>Application Identity Management:</span> Manage identities for applications, enabling secure interaction between applications and services.</li>
                                                    <li><span>Data Identity Management:</span> Assign and manage identities to data objects, ensuring data access is controlled and monitored.</li>
                                                </ul>
                                                <h6 class="mb-1">Access Rights Management</h6>
                                                <ul class="mb-2">
                                                    <li><span>Role-Based Access Control (RBAC):</span> Define and enforce access policies based on user roles, ensuring that users have the minimum necessary access for their job functions.</li>
                                                    <li><span>Attribute-Based Access Control (ABAC):</span> Implement policies that consider user attributes, environmental conditions, and resource characteristics to grant access dynamically.</li>
                                                    <li><span>Least Privilege Principle:</span> Ensure that users and devices are granted the least amount of privilege necessary to perform their tasks, reducing the attack surface.</li>
                                                    <li><span>Access Reviews and Certification:</span> Conduct periodic access reviews and certifications to ensure that access rights remain appropriate over time.</li>
                                                </ul>
                                                <h6 class="mb-1">Authentication Mechanisms</h6>
                                                <ul class="mb-2">
                                                    <li><span>Single Sign-On (SSO):</span> Implement SSO solutions to enable users to authenticate once and gain access to multiple applications, enhancing user experience and security.</li>
                                                    <li><span>Multi-Factor Authentication (MFA):</span> Deploy MFA solutions to add an extra layer of security, requiring users to provide two or more verification factors to gain access.</li>
                                                    <li><span>Adaptive Authentication:</span> Utilize adaptive authentication methods that assess the risk of access attempts and adjust authentication requirements accordingly.</li>
                                                    <li><span>Biometric Authentication:</span> Implement biometric authentication solutions, such as fingerprint, facial recognition, and iris scanning, to provide high-security access control.</li>
                                                </ul>
                                                <h6 class="mb-1">Authorization Mechanisms</h6>
                                                <ul class="mb-2">
                                                    <li><span>Policy-Based Access Control:</span> Develop and enforce access control policies that govern how resources are accessed and by whom.</li>
                                                    <li><span>Fine-Grained Access Control:</span> Implement fine-grained access control mechanisms that provide detailed control over who can access specific resources and perform specific actions.</li>
                                                    <li><span>Contextual Authorization:</span> Incorporate contextual factors, such as user location, device health, and time of access, into authorization decisions to enhance security.</li>
                                                </ul>
                                                <h6 class="mb-1">Auditing and Compliance</h6>
                                                <ul class="mb-2">
                                                    <li><span>Access Logging and Monitoring:</span> Implement comprehensive logging and monitoring solutions to track access events, ensuring visibility into who accessed what and when.</li>
                                                    <li><span>Audit Trails:</span> Maintain detailed audit trails for all identity and access management activities, supporting forensic investigations and compliance reporting.</li>
                                                    <li><span>Compliance Reporting:</span> Generate reports that demonstrate compliance with regulatory requirements and industry standards, such as GDPR, HIPAA, and SOX.</li>
                                                    <li><span>Continuous Monitoring:</span> Continuously monitor access activities and policies to detect and respond to anomalies and potential security incidents in real-time.</li>
                                                </ul>
                                                <h6 class="mb-1">Identity Lifecycle Management</h6>
                                                <ul class="mb-2">
                                                    <li><span>Provisioning and De-Provisioning:</span> Automate the provisioning and de-provisioning of user accounts, ensuring that access is granted and revoked promptly as users join, move within, or leave the organization.</li>
                                                    <li><span>Self-Service Identity Management:</span> Enable users to manage their own profiles, reset passwords, and request access through self-service portals, reducing administrative overhead.</li>
                                                    <li><span>Identity Synchronization:</span> Ensure consistent identity information across all systems and applications through synchronization and integration with directory services and HR systems.</li>
                                                    <li><span>Federated Identity Management:</span> Implement federated identity solutions to enable secure access across organizational boundaries, supporting collaboration with partners and customers.</li>
                                                </ul>
                                                <h6 class="mb-1">IAM Technology Integration</h6>
                                                <ul class="mb-2">
                                                    <li><span>Directory Services Integration:</span> Integrate with directory services such as Active Directory, LDAP, and cloud-based directories to centralize identity management.</li>
                                                    <li><span>Access Management Tools:</span> Deploy and configure access management tools that support authentication, authorization, and auditing functions.</li>
                                                    <li><span>Cloud IAM Solutions:</span> Implement cloud-based IAM solutions to manage identities and access rights in cloud environments, ensuring scalability and flexibility.</li>
                                                    <li><span>Identity Governance and Administration (IGA):</span> Utilize IGA tools to provide governance over identity and access management processes, ensuring compliance and risk management.</li>
                                                </ul>
                                                <h6 class="mb-1">Continuous Improvement and Support</h6>
                                                <ul class="mb-2">
                                                    <li><span>Ongoing Support:</span> Provide ongoing support and maintenance for IAM solutions, ensuring they remain effective and up to date with evolving security requirements.</li>
                                                    <li><span>Training and Awareness:</span> Offer training programs to educate users and administrators on IAM policies, best practices, and tools.</li>
                                                    <li><span>Performance Optimization:</span> Continuously optimize IAM processes and technologies to enhance performance, security, and user experience.</li>
                                                    <li><span>Regular Assessments:</span> Conduct regular assessments and audits of IAM policies, procedures, and technologies to identify and address gaps and areas for improvement.</li>
                                                </ul>
                                                <p>
                                                    By leveraging our Identity and Access Management (IAM) services, organizations can ensure secure and efficient management of identities and access rights, reducing the risk of unauthorized access and enhancing overall security posture. This comprehensive approach to IAM helps organizations protect their critical resources, comply with regulatory requirements, and improve operational efficiency.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="1">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Protect-Network-security.svg" alt="Network Security Services" />
                                                <h2>Network Security Services</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We help clients secure their networks and communications using firewalls, intrusion detection and prevention systems (IDS/IPS), virtual private networks (VPNs), secure sockets layer (SSL), and other methods.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Network Security Services are designed to help clients secure their networks and communications, protecting sensitive data and ensuring robust defense against cyber threats. By employing a combination of firewalls, IDS/IPS, VPNs, SSL, and other advanced security methods, we deliver comprehensive solutions that safeguard your network infrastructure.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>
                                                <h6 class="mb-1">Firewall Management</h6>
                                                <ul class="mb-2">
                                                    <li><span>Firewall Deployment:</span> Design and implement firewall solutions that create a robust barrier between internal networks and external threats.</li>
                                                    <li><span>Next-Generation Firewalls (NGFW):</span> Utilize advanced NGFWs to provide deep packet inspection, application-level security, and integrated threat intelligence.</li>
                                                    <li><span>Policy Configuration:</span> Develop and configure firewall policies to control access, block unauthorized traffic, and enforce security rules.</li>
                                                    <li><span>Firewall Monitoring and Maintenance:</span> Continuously monitor firewall performance, apply updates, and fine-tune configurations to adapt to evolving threats.</li>
                                                </ul>
                                                <h6 class="mb-1">Intrusion Detection and Prevention Systems (IDS/IPS)</h6>
                                                <ul class="mb-2">
                                                    <li><span>IDS/IPS Deployment:</span> Deploy IDS/IPS solutions to detect and prevent unauthorized access and malicious activity within the network.</li>
                                                    <li><span>Signature-Based Detection:</span> Use signature-based detection methods to identify known threats based on predefined patterns and signatures.</li>
                                                    <li><span>Anomaly-Based Detection:</span> Implement anomaly-based detection techniques to identify unusual behavior that may indicate new or unknown threats.</li>
                                                    <li><span>Real-Time Threat Response:</span> Provide real-time threat detection and automated response capabilities to quickly mitigate potential security incidents.</li>
                                                </ul>
                                                <h6 class="mb-1">Virtual Private Networks (VPNs)</h6>
                                                <ul class="mb-2">
                                                    <li><span>VPN Implementation:</span> Establish secure VPN solutions to enable encrypted communications between remote users and corporate networks.</li>
                                                    <li><span>Site-to-Site VPNs:</span> Implement site-to-site VPNs to securely connect multiple office locations, ensuring secure data transmission across sites.</li>
                                                    <li><span>Remote Access VPNs:</span> Deploy remote access VPNs to allow employees to securely access the network from remote locations.</li>
                                                    <li><span>VPN Monitoring and Management:</span> Monitor VPN performance and usage, ensuring reliable and secure remote connectivity.</li>
                                                </ul>
                                                <h6 class="mb-1">Secure Sockets Layer (SSL) and Transport Layer Security (TLS)</h6>
                                                <ul class="mb-2">
                                                    <li><span>SSL/TLS Certificate Management:</span> Manage SSL/TLS certificates to ensure encrypted communication between web servers and clients.</li>
                                                    <li><span>Encryption Implementation:</span> Implement SSL/TLS encryption for web applications, email servers, and other critical services to protect data in transit.</li>
                                                    <li><span>Certificate Renewal and Rotation:</span> Automate the renewal and rotation of SSL/TLS certificates to prevent lapses in security.</li>
                                                    <li><span>Vulnerability Scanning:</span> Perform regular scans to identify and address SSL/TLS vulnerabilities, ensuring robust encryption standards.</li>
                                                </ul>
                                                <h6 class="mb-1">Network Access Control (NAC)</h6>
                                                <ul class="mb-2">
                                                    <li><span>NAC Implementation:</span> Deploy NAC solutions to enforce security policies and control access to the network based on user, device, and location.</li>
                                                    <li><span>Endpoint Security Integration:</span> Integrate NAC with endpoint security solutions to ensure devices meet security standards before granting access.</li>
                                                    <li><span>Guest Network Management:</span> Provide secure guest network access with appropriate restrictions and monitoring.</li>
                                                    <li><span>Continuous Monitoring:</span> Continuously monitor network access to detect and respond to unauthorized access attempts.</li>
                                                </ul>
                                                <h6 class="mb-1">Advanced Network Security Solutions</h6>
                                                <ul class="mb-2">
                                                    <li><span>Zero Trust Architecture:</span> Implement Zero Trust principles to continuously verify and authenticate users and devices, regardless of their location.</li>
                                                    <li><span>Network Segmentation:</span> Design and deploy network segmentation strategies to isolate critical assets and limit the spread of potential threats.</li>
                                                    <li><span>DDoS Protection:</span> Implement Distributed Denial of Service (DDoS) protection solutions to defend against large-scale attacks aimed at disrupting network services.</li>
                                                    <li><span>Cloud Network Security:</span> Secure cloud-based networks and services with cloud-native security tools and best practices.</li>
                                                </ul>
                                                <h6 class="mb-1">Monitoring and Incident Response</h6>
                                                <ul class="mb-2">
                                                    <li><span>24/7 Network Monitoring:</span> Provide round-the-clock network monitoring to detect and respond to security incidents in real-time.</li>
                                                    <li><span>Security Operations Center (SOC):</span> Operate a dedicated SOC staffed by experienced analysts who oversee network security operations and incident response.</li>
                                                    <li><span>Incident Investigation and Forensics:</span> Conduct thorough investigations and forensic analysis of security incidents to determine the root cause and impact.</li>
                                                    <li><span>Incident Response Planning:</span> Develop and implement incident response plans to ensure quick and effective response to network security breaches.</li>
                                                </ul>
                                                <h6 class="mb-1">Compliance and Reporting</h6>
                                                <ul class="mb-2">
                                                    <li><span>Regulatory Compliance:</span> Ensure network security solutions comply with relevant regulations and industry standards, such as GDPR, HIPAA, and PCI DSS.</li>
                                                    <li><span>Security Audits:</span> Perform regular security audits and assessments to evaluate the effectiveness of network security measures.</li>
                                                    <li><span>Reporting and Analytics:</span> Generate detailed reports and analytics to provide insights into network security posture and incident trends.</li>
                                                    <li><span>Continuous Improvement:</span> Continuously assess and improve network security practices to address emerging threats and vulnerabilities.</li>
                                                </ul>
                                                <h6 class="mb-1">Training and Awareness</h6>
                                                <ul class="mb-2">
                                                    <li><span>Security Awareness Training:</span> Conduct training programs to educate employees on network security best practices and threat awareness.</li>
                                                    <li><span>Technical Training:</span> Provide technical training for IT and security staff on the deployment, management, and optimization of network security tools.</li>
                                                    <li><span>Simulation Exercises:</span> Conduct simulation exercises and drills to test the effectiveness of network security protocols and incident response plans.</li>
                                                </ul>
                                                <p>
                                                    By leveraging our comprehensive Network Security Services, organizations can protect their network infrastructure from a wide range of cyber threats, ensuring secure and reliable communications. This proactive approach to network security helps mitigate risks, maintain regulatory compliance, and safeguard critical data and operations.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Protect-Data-Protection-encrypted.svg" alt="Data Protection and Encryption Services" />
                                                <h2>Data Protection and Encryption Services</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We use encryption, masking, tokenization, backup, recovery, and other techniques to protect client data confidentiality, integrity, and availability at rest and in transit.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Data Protection and Encryption Services are designed to safeguard client data by ensuring its confidentiality, integrity, and availability both at rest and in transit. We employ a variety of advanced techniques including encryption, masking, tokenization, backup, and recovery to protect sensitive information from unauthorized access and breaches.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>
                                                <h6 class="mb-1">Data Encryption</h6>
                                                <ul class="mb-2">
                                                    <li><span>Encryption at Rest:</span> Implement encryption technologies to protect data stored on databases, file systems, and other storage mediums.</li>
                                                    <li><span>Encryption in Transit:</span> Secure data transmitted over networks using robust encryption protocols such as TLS/SSL, ensuring data privacy during transfer.</li>
                                                    <li><span>Advanced Encryption Standards:</span> Utilize industry-standard encryption algorithms, such as AES-256, to ensure high levels of security.</li>
                                                    <li><span>Encryption Key Management:</span> Manage encryption keys securely, including generation, distribution, rotation, and revocation, using hardware security modules (HSMs) and key management systems (KMS).</li>
                                                </ul>
                                                <h6 class="mb-1">Data Masking</h6>
                                                <ul class="mb-2">
                                                    <li><span>Static Data Masking:</span> Apply masking techniques to sensitive data in non-production environments, ensuring realistic but obfuscated data for testing and development.</li>
                                                    <li><span>Dynamic Data Masking:</span> Implement dynamic data masking to protect sensitive data in real-time as it is accessed by authorized users, without altering the underlying data.</li>
                                                    <li><span>Masking Policies:</span> Develop and enforce data masking policies to determine which data elements require masking and under what conditions.</li>
                                                </ul>
                                                <h6 class="mb-1">Tokenization</h6>
                                                <ul class="mb-2">
                                                    <li><span>Data Tokenization:</span> Replace sensitive data elements with non-sensitive equivalents (tokens) that have no exploitable value, reducing the risk of data breaches.</li>
                                                    <li><span>Token Vault Management:</span> Securely manage token vaults where the mapping between tokens and original data is stored, ensuring access controls and audit logging.</li>
                                                    <li><span>Compliance Support:</span> Utilize tokenization to achieve compliance with data protection regulations such as PCI DSS by reducing the scope of compliance.</li>
                                                </ul>
                                                <h6 class="mb-1">Data Backup and Recovery</h6>
                                                <ul class="mb-2">
                                                    <li><span>Automated Backup Solutions:</span> Implement automated backup solutions to ensure regular and reliable backups of critical data, minimizing the risk of data loss.</li>
                                                    <li><span>Offsite and Cloud Backup:</span> Utilize offsite and cloud-based backup solutions to protect data from physical damage and ensure disaster recovery capabilities.</li>
                                                    <li><span>Data Integrity Checks:</span> Perform regular integrity checks on backup data to ensure it remains accurate and uncorrupted.</li>
                                                    <li><span>Rapid Data Recovery:</span> Develop and implement data recovery plans to enable quick restoration of data following incidents, minimizing downtime and data loss.</li>
                                                </ul>
                                                <h6 class="mb-1">Data Integrity Assurance</h6>
                                                <ul class="mb-2">
                                                    <li><span>Checksum and Hashing:</span> Use checksums and hashing algorithms to verify the integrity of data, ensuring it has not been tampered with or corrupted.</li>
                                                    <li><span>Digital Signatures:</span> Implement digital signatures to validate the authenticity and integrity of data and documents.</li>
                                                    <li><span>Tamper-Evident Technologies:</span> Deploy tamper-evident technologies to detect unauthorized modifications to data.</li>
                                                </ul>
                                                <h6 class="mb-1">Access Controls and Authentication</h6>
                                                <ul class="mb-2">
                                                    <li><span>Role-Based Access Control (RBAC):</span> Enforce RBAC policies to ensure that users have access only to the data necessary for their roles.</li>
                                                    <li><span>Multi-Factor Authentication (MFA):</span> Implement MFA for accessing sensitive data to provide an additional layer of security.</li>
                                                    <li><span>Access Monitoring and Auditing:</span> Continuously monitor and audit access to sensitive data to detect and respond to unauthorized access attempts.</li>
                                                </ul>
                                                <h6 class="mb-1">Compliance and Governance</h6>
                                                <ul class="mb-2">
                                                    <li><span>Regulatory Compliance:</span> Ensure data protection measures comply with relevant regulations and standards, such as GDPR, HIPAA, and CCPA.</li>
                                                    <li><span>Policy Development:</span> Develop and enforce data protection policies that align with legal requirements and industry best practices.</li>
                                                    <li><span>Data Governance Frameworks:</span> Establish data governance frameworks to oversee data protection strategies and ensure accountability.</li>
                                                </ul>
                                                <h6 class="mb-1">Data Protection in Cloud Environments</h6>
                                                <ul class="mb-2">
                                                    <li><span>Cloud Data Encryption:</span> Implement encryption solutions tailored for cloud environments to protect data stored and processed in the cloud.</li>
                                                    <li><span>Cloud Access Security Brokers (CASBs):</span> Use CASBs to enforce data protection policies and provide visibility into cloud data usage.</li>
                                                    <li><span>Cloud Backup and Recovery:</span> Utilize cloud-native backup and recovery solutions to ensure data protection and disaster recovery in cloud environments.</li>
                                                </ul>
                                                <h6 class="mb-1">Continuous Monitoring and Improvement</h6>
                                                <ul class="mb-2">
                                                    <li><span>Security Monitoring:</span> Continuously monitor data protection measures to detect vulnerabilities and potential threats in real-time.</li>
                                                    <li><span>Regular Assessments:</span> Conduct regular assessments and audits of data protection practices to identify and address gaps.</li>
                                                    <li><span>Incident Response:</span> Develop and implement incident response plans to quickly address data breaches and mitigate their impact.</li>
                                                </ul>
                                                <h6 class="mb-1">Training and Awareness</h6>
                                                <ul class="mb-2">
                                                    <li><span>Employee Training:</span> Provide training programs to educate employees on data protection best practices and the importance of data security.</li>
                                                    <li><span>Security Awareness Campaigns:</span> Conduct ongoing security awareness campaigns to keep data protection at the forefront of organizational priorities.</li>
                                                </ul>
                                                <p>
                                                    By leveraging our comprehensive Data Protection and Encryption Services, organizations can ensure their sensitive data remains secure against unauthorized access, breaches, and other cyber threats. This robust approach to data protection helps maintain data privacy, integrity, and availability, while also ensuring compliance with regulatory requirements and industry standards.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="contactFour-tab-pane" role="tabpanel" aria-labelledby="contactFour-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Protect-Application-Security.svg" alt="Application Security Services" />
                                                <h2>Application Security Services</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We assist clients in securing their applications and software development lifecycle through secure coding practices, code analysis tools, testing tools, and vulnerability scanning tools.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Application Security Services are designed to help clients secure their applications and software development lifecycle (SDLC) by implementing secure coding practices, utilizing code analysis tools, testing tools, and vulnerability scanning tools. This comprehensive approach ensures that security is embedded into every phase of the application development process, reducing the risk of vulnerabilities and enhancing the overall security posture of your applications.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>
                                                <h6 class="mb-1">Secure Coding Practices</h6>
                                                <ul class="mb-2">
                                                    <li><span>Development Training:</span> Provide training programs for developers on secure coding practices to ensure they understand how to write secure code and avoid common vulnerabilities.</li>
                                                    <li><span>Coding Standards and Guidelines:</span> Develop and enforce secure coding standards and guidelines tailored to the organization's development environment and technologies.</li>
                                                    <li><span>Code Reviews and Peer Reviews:</span> Establish processes for regular code reviews and peer reviews to identify and address security issues early in the development cycle.</li>
                                                    <li><span>Security Design Patterns:</span> Promote the use of security design patterns to build resilient applications that can withstand attacks.</li>
                                                </ul>
                                                <h6 class="mb-1">Code Analysis Tools</h6>
                                                <ul class="mb-2">
                                                    <li><span>Static Application Security Testing (SAST):</span> Integrate SAST tools into the SDLC to analyze source code for security vulnerabilities without executing the application.</li>
                                                    <li><span>Dynamic Application Security Testing (DAST):</span> Use DAST tools to test running applications for vulnerabilities by simulating attacks from an external perspective.</li>
                                                    <li><span>Interactive Application Security Testing (IAST):</span> Implement IAST tools to analyze applications during runtime, providing detailed insights into security flaws.</li>
                                                    <li><span>Software Composition Analysis (SCA):</span> Utilize SCA tools to identify and manage vulnerabilities in third-party libraries and open-source components used in applications.</li>
                                                </ul>
                                                <h6 class="mb-1">Security Testing Tools</h6>
                                                <ul class="mb-2">
                                                    <li><span>Penetration Testing:</span> Conduct regular penetration testing to simulate real-world attacks and identify security weaknesses in applications.</li>
                                                    <li><span>Fuzz Testing:</span> Use fuzz testing tools to send random or malformed inputs to applications to discover unknown vulnerabilities.</li>
                                                    <li><span>Unit Testing for Security:</span> Incorporate security-specific unit tests into the development process to catch vulnerabilities early.</li>
                                                    <li><span>Regression Testing:</span> Perform regression testing to ensure that new code changes do not introduce security vulnerabilities into the application.</li>
                                                </ul>
                                                <h6 class="mb-1">Vulnerability Scanning Tools</h6>
                                                <ul class="mb-2">
                                                    <li><span>Automated Vulnerability Scanners:</span> Deploy automated vulnerability scanning tools to continuously monitor applications for known security issues.</li>
                                                    <li><span>Manual Vulnerability Assessments:</span> Conduct manual vulnerability assessments to identify complex security issues that automated tools may miss.</li>
                                                    <li><span>Continuous Integration (CI) Integration:</span> Integrate vulnerability scanning tools into the CI pipeline to automatically scan code for vulnerabilities during each build and deployment cycle.</li>
                                                    <li><span>Remediation Guidance:</span> Provide detailed remediation guidance for identified vulnerabilities, including technical solutions and best practices for mitigation.</li>
                                                </ul>
                                                <h6 class="mb-1">Secure Development Lifecycle (SDLC) Integration</h6>
                                                <ul class="mb-2">
                                                    <li><span>Security Requirements Definition:</span> Define security requirements at the beginning of the SDLC to ensure that security considerations are incorporated into the project from the outset.</li>
                                                    <li><span>Threat Modeling:</span> Perform threat modeling to identify potential threats and design security controls to mitigate them during the design phase.</li>
                                                    <li><span>Secure Architecture Reviews:</span> Conduct secure architecture reviews to evaluate the security of application designs and ensure they meet security requirements.</li>
                                                    <li><span>Security Testing and Validation:</span> Integrate security testing and validation into each phase of the SDLC to catch vulnerabilities early and ensure secure releases.</li>
                                                </ul>
                                                <h6 class="mb-1">DevSecOps Practices</h6>
                                                <ul class="mb-2">
                                                    <li><span>Security Automation:</span> Automate security processes and integrate security tools into DevOps pipelines to ensure continuous security throughout the development lifecycle.</li>
                                                    <li><span>Shift-Left Security:</span> Adopt a shift-left approach to move security activities earlier in the SDLC, reducing the cost and effort of fixing security issues.</li>
                                                    <li><span>Continuous Monitoring:</span> Implement continuous monitoring to detect and respond to security issues in real-time, ensuring applications remain secure post-deployment.</li>
                                                    <li><span>Collaboration and Culture:</span> Foster a culture of collaboration between development, security, and operations teams to integrate security into every aspect of the SDLC.</li>
                                                </ul>
                                                <h6 class="mb-1">Compliance and Governance</h6>
                                                <ul class="mb-2">
                                                    <li><span>Regulatory Compliance:</span> Ensure that application security practices comply with relevant regulations and standards, such as GDPR, HIPAA, PCI DSS, and OWASP.</li>
                                                    <li><span>Policy Development:</span> Develop and enforce security policies and procedures that govern application security practices.</li>
                                                    <li><span>Audit and Assessment:</span> Conduct regular audits and assessments to evaluate the effectiveness of application security measures and identify areas for improvement.</li>
                                                    <li><span>Reporting and Documentation:</span> Generate detailed reports and documentation to provide visibility into application security activities and demonstrate compliance.</li>
                                                </ul>
                                                <h6 class="mb-1">Incident Response and Management</h6>
                                                <ul class="mb-2">
                                                    <li><span>Incident Detection:</span> Implement mechanisms to detect security incidents in applications, including monitoring for suspicious activity and anomalies.</li>
                                                    <li><span>Incident Response Plans:</span> Develop and maintain incident response plans to ensure quick and effective responses to application security incidents.</li>
                                                    <li><span>Post-Incident Analysis:</span> Conduct post-incident analysis to understand the root cause of security incidents and implement measures to prevent recurrence.</li>
                                                    <li><span>Continuous Improvement:</span> Continuously improve application security practices based on lessons learned from incidents and emerging threats.</li>
                                                </ul>
                                                <h6 class="mb-1">Training and Awareness</h6>
                                                <ul class="mb-2">
                                                    <li><span>Security Awareness Programs:</span> Conduct ongoing security awareness programs to educate developers, testers, and other stakeholders on the latest security threats and best practices.</li>
                                                    <li><span>Hands-On Workshops:</span> Offer hands-on workshops and labs to provide practical experience with secure coding, code analysis, and security testing tools.</li>
                                                    <li><span>Knowledge Sharing:</span> Encourage knowledge sharing and collaboration among teams to promote a security-first mindset across the organization.</li>
                                                </ul>
                                                <p>
                                                    By leveraging our comprehensive Application Security Services, organizations can build and maintain secure applications that protect sensitive data, reduce the risk of breaches, and ensure compliance with regulatory requirements. This holistic approach to application security ensures that security is an integral part of the software development lifecycle, delivering resilient and trustworthy applications to users.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="contactFive-tab-pane" role="tabpanel" aria-labelledby="contactFive-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Protect-Cloud-security.svg" alt="Cloud Security Services" />
                                                <h2>Cloud Security Services</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We assist our clients in ensuring the safety of their cloud environments and services, which include Infrastructure as a Service (IaaS), Platform as a Service (PaaS), and Software as a Service (SaaS). We implement the best practices and tools for cloud security governance, architecture, configuration, monitoring, and more.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Cloud Security Services are designed to ensure the safety and security of our clients' cloud environments and services, covering Infrastructure as a Service (IaaS), Platform as a Service (PaaS), and Software as a Service (SaaS). We employ industry best practices and advanced tools for cloud security governance, architecture, configuration, monitoring, and more to protect against threats and ensure compliance.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>
                                                <h6 class="mb-1">Cloud Security Governance</h6>
                                                <ul class="mb-2">
                                                    <li><span>Policy Development and Enforcement:</span> Develop and enforce comprehensive cloud security policies and procedures tailored to your organization's specific needs.</li>
                                                    <li><span>Compliance Management:</span> Ensure compliance with industry standards and regulations such as GDPR, HIPAA, PCI DSS, and ISO/IEC 27001.</li>
                                                    <li><span>Risk Management:</span> Conduct risk assessments to identify and mitigate potential security risks associated with cloud adoption.</li>
                                                    <li><span>Security Awareness Training:</span> Provide training programs to educate employees on cloud security best practices and policies.</li>
                                                </ul>
                                                <h6 class="mb-1">Cloud Security Architecture</h6>
                                                <ul class="mb-2">
                                                    <li><span>Secure Architecture Design:</span> Design secure cloud architectures that align with security best practices and your organization's requirements.</li>
                                                    <li><span>Zero Trust Architecture:</span> Implement Zero Trust principles to ensure continuous verification and minimal trust, even within the network perimeter.</li>
                                                    <li><span>Identity and Access Management (IAM):</span> Implement robust IAM solutions to control and monitor access to cloud resources, ensuring that only authorized users have access.</li>
                                                    <li><span>Network Security:</span> Design secure network architectures, including virtual private clouds (VPCs), subnet configurations, and security group policies.</li>
                                                </ul>
                                                <h6 class="mb-1">Cloud Configuration Management</h6>
                                                <ul class="mb-2">
                                                    <li><span>Secure Configuration Baselines:</span> Establish and enforce secure configuration baselines for cloud resources to prevent misconfigurations and vulnerabilities.</li>
                                                    <li><span>Automation and Orchestration:</span> Use automation tools such as Infrastructure as Code (IaC) to ensure consistent and secure cloud configurations.</li>
                                                    <li><span>Configuration Monitoring:</span> Continuously monitor cloud configurations for deviations from established baselines and best practices.</li>
                                                    <li><span>Change Management:</span> Implement change management processes to ensure that any changes to cloud configurations are reviewed and approved.</li>
                                                </ul>
                                                <h6 class="mb-1">Cloud Security Monitoring and Incident Response</h6>
                                                <ul class="mb-2">
                                                    <li><span>Continuous Monitoring:</span> Deploy advanced monitoring solutions to continuously track activities and detect anomalies in cloud environments.</li>
                                                    <li><span>Threat Detection:</span> Implement tools and techniques for threat detection, including Security Information and Event Management (SIEM) systems, to identify and respond to potential threats.</li>
                                                    <li><span>Incident Response Planning:</span> Develop and maintain incident response plans tailored to cloud environments, ensuring quick and effective responses to security incidents.</li>
                                                    <li><span>Forensic Analysis:</span> Conduct forensic analysis of cloud-based incidents to determine root causes and improve future defenses.</li>
                                                </ul>
                                                <h6 class="mb-1">Data Protection and Encryption</h6>
                                                <ul class="mb-2">
                                                    <li><span>Data Encryption:</span> Implement robust encryption solutions for data at rest and in transit to ensure data confidentiality and integrity.</li>
                                                    <li><span>Data Loss Prevention (DLP):</span> Deploy DLP solutions to protect sensitive data and prevent unauthorized access or exfiltration.</li>
                                                    <li><span>Backup and Recovery:</span> Ensure reliable data backup and recovery solutions are in place to protect against data loss and ensure business continuity.</li>
                                                    <li><span>Access Controls:</span> Enforce strict access controls to sensitive data, including fine-grained access policies and multi-factor authentication (MFA).</li>
                                                </ul>
                                                <h6 class="mb-1">Cloud Application Security</h6>
                                                <ul class="mb-2">
                                                    <li><span>Secure Development Lifecycle (SDLC):</span> Integrate security into the SDLC to ensure applications deployed in the cloud are secure from development through deployment.</li>
                                                    <li><span>Application Security Testing:</span> Conduct regular security testing of cloud applications, including static and dynamic analysis, to identify and remediate vulnerabilities.</li>
                                                    <li><span>Container Security:</span> Implement security best practices for containerized applications, including image scanning, runtime protection, and orchestration security.</li>
                                                    <li><span>API Security:</span> Ensure that APIs used in cloud applications are secure, implementing strong authentication, authorization, and encryption mechanisms.</li>
                                                </ul>
                                                <h6 class="mb-1">Cloud Service Management</h6>
                                                <ul class="mb-2">
                                                    <li><span>Service-Level Agreements (SLAs):</span> Define and manage SLAs with cloud service providers to ensure security and performance requirements are met.</li>
                                                    <li><span>Vendor Risk Management:</span> Assess and manage the security risks associated with third-party cloud service providers.</li>
                                                    <li><span>Cost Management:</span> Implement cost management strategies to optimize cloud spending while maintaining security standards.</li>
                                                    <li><span>Resource Optimization:</span> Continuously optimize cloud resources for performance, scalability, and security.</li>
                                                </ul>
                                                <h6 class="mb-1">DevSecOps Integration</h6>
                                                <ul class="mb-2">
                                                    <li><span>Security Automation:</span> Integrate security into DevOps processes to create a DevSecOps culture, ensuring security is an integral part of the development and deployment pipeline.</li>
                                                    <li><span>Continuous Integration/Continuous Deployment (CI/CD):</span> Embed security checks into CI/CD pipelines to detect and address security issues early in the development process.</li>
                                                    <li><span>Collaboration and Culture:</span> Foster collaboration between development, security, and operations teams to ensure a unified approach to cloud security.</li>
                                                    <li><span>Tools and Technologies:</span> Leverage advanced DevSecOps tools to automate security tasks, such as vulnerability scanning, compliance checks, and configuration management.</li>
                                                </ul>
                                                <p>
                                                    By leveraging our comprehensive Cloud Security Services, organizations can secure their cloud environments and services effectively, ensuring the confidentiality, integrity, and availability of their data and applications. This holistic approach to cloud security helps mitigate risks, maintain compliance, and achieve operational excellence in the cloud.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="contactSix-tab-pane" role="tabpanel" aria-labelledby="contactSix-tab" tabIndex="5">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Protect-Security-Awareness.svg" alt="Security Awareness and Training Services" />
                                                <h2>Security Awareness and Training Services</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We help clients educate their employees and stakeholders about cybersecurity threats and best practices and provide them with the skills and knowledge to prevent or respond to cyber incidents.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Security Awareness and Training Services are designed to educate employees and stakeholders about cybersecurity threats and best practices, equipping them with the skills and knowledge needed to prevent and respond to cyber incidents. Through comprehensive training programs, interactive workshops, and continuous learning initiatives, we help build a security-conscious culture within organizations, significantly reducing the risk of human error and enhancing overall security posture.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>
                                                <h6 class="mb-1">Comprehensive Training Programs</h6>
                                                <ul class="mb-2">
                                                    <li><span>Customized Training Modules:</span> Develop tailored training modules that address the specific needs and threat landscape of the organization.</li>
                                                    <li><span>Role-Based Training:</span> Provide specialized training for different roles within the organization, ensuring relevant and practical knowledge for each group (e.g., executives, IT staff, general employees).</li>
                                                    <li><span>Interactive E-Learning:</span> Offer engaging and interactive e-learning courses that employees can access at their convenience, covering key topics such as phishing, password security, and data protection.</li>
                                                    <li><span>In-Person Workshops:</span> Conduct in-person workshops and training sessions to provide hands-on experience and direct interaction with security experts.</li>
                                                </ul>
                                                <h6 class="mb-1">Threat Awareness and Education</h6>
                                                <ul class="mb-2">
                                                    <li><span>Phishing Simulations:</span> Run regular phishing simulation campaigns to test employee awareness and response to phishing attacks, providing feedback and additional training as needed.</li>
                                                    <li><span>Threat Landscape Updates:</span> Keep employees informed about the latest cybersecurity threats and trends through regular updates, newsletters, and alerts.</li>
                                                    <li><span>Case Studies and Real-World Examples:</span> Use case studies and real-world examples to illustrate the impact of cyber threats and the importance of best practices.</li>
                                                </ul>
                                                <h6 class="mb-1">Best Practices and Policy Education</h6>
                                                <ul class="mb-2">
                                                    <li><span>Security Policies and Procedures:</span> Educate employees about the organization’s security policies and procedures, ensuring they understand their responsibilities and the importance of compliance.</li>
                                                    <li><span>Password Management:</span> Provide training on creating and managing strong passwords, including the use of password managers.</li>
                                                    <li><span>Safe Internet and Email Usage:</span> Teach employees safe internet and email usage practices to avoid falling victim to scams and malicious websites.</li>
                                                    <li><span>Data Protection and Privacy:</span> Train employees on the importance of data protection and privacy, including handling sensitive information securely.</li>
                                                </ul>
                                                <h6 class="mb-1">Incident Response Training</h6>
                                                <ul class="mb-2">
                                                    <li><span>Incident Reporting:</span> Educate employees on how to recognize and report security incidents promptly and accurately.</li>
                                                    <li><span>Response Procedures:</span> Provide training on the organization’s incident response procedures, ensuring employees know what to do in the event of a cyber incident.</li>
                                                    <li><span>Crisis Management:</span> Offer crisis management training for key stakeholders, focusing on communication, decision-making, and maintaining business continuity during a cyber crisis.</li>
                                                </ul>
                                                <h6 class="mb-1">Continuous Learning and Improvement</h6>
                                                <ul class="mb-2">
                                                    <li><span>Regular Refreshers:</span> Schedule regular refresher courses and training sessions to reinforce key concepts and keep security top of mind.</li>
                                                    <li><span>Knowledge Assessments:</span> Conduct periodic assessments to gauge employee understanding of cybersecurity topics and identify areas for improvement.</li>
                                                    <li><span>Feedback Mechanism:</span> Implement feedback mechanisms to gather input from employees on the effectiveness of training programs and make necessary adjustments.</li>
                                                </ul>
                                                <h6 class="mb-1">Gamification and Incentives</h6>
                                                <ul class="mb-2">
                                                    <li><span>Gamified Learning:</span> Use gamification techniques, such as quizzes, challenges, and leaderboards, to make learning about cybersecurity fun and engaging.</li>
                                                    <li><span>Rewards and Recognition:</span> Offer rewards and recognition for employees who demonstrate strong cybersecurity practices and perform well in training exercises.</li>
                                                </ul>
                                                <h6 class="mb-1">Executive and Board Training</h6>
                                                <ul class="mb-2">
                                                    <li><span>Executive Briefings:</span> Provide targeted training sessions for executives and board members, focusing on strategic cybersecurity issues, risk management, and regulatory compliance.</li>
                                                    <li><span>Board-Level Workshops:</span> Conduct workshops for board members to enhance their understanding of cybersecurity governance and their role in overseeing the organization’s security strategy.</li>
                                                </ul>
                                                <h6 class="mb-1">Security Champions Program</h6>
                                                <ul class="mb-2">
                                                    <li><span>Champion Identification:</span> Identify and train security champions within various departments to promote and reinforce cybersecurity best practices across the organization.</li>
                                                    <li><span>Ongoing Support:</span> Provide continuous support and advanced training for security champions to keep them updated on the latest threats and security measures.</li>
                                                </ul>
                                                <h6 class="mb-1">Compliance and Regulatory Training</h6>
                                                <ul class="mb-2">
                                                    <li><span>Regulatory Requirements:</span> Ensure that employees are aware of and comply with relevant regulatory requirements and industry standards, such as GDPR, HIPAA, and PCI DSS.</li>
                                                    <li><span>Audit Preparation:</span> Offer training to help employees prepare for security audits and understand their role in maintaining compliance.</li>
                                                </ul>
                                                <h6 class="mb-1">Awareness Campaigns</h6>
                                                <ul class="mb-2">
                                                    <li><span>Security Awareness Month:</span> Organize security awareness campaigns, such as Security Awareness Month, with themed activities, presentations, and contests to promote cybersecurity awareness.</li>
                                                    <li><span>Visual Aids and Resources:</span> Distribute visual aids, such as posters, infographics, and cheat sheets, to reinforce key security messages throughout the workplace.</li>
                                                </ul>
                                                <p>
                                                    By leveraging our Security Awareness and Training Services, organizations can create a knowledgeable and vigilant workforce capable of recognizing and responding to cybersecurity threats effectively. This proactive approach to security education fosters a culture of security awareness, significantly reducing the risk of cyber incidents and enhancing the overall resilience of the organization.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="contactSeven-tab-pane" role="tabpanel" aria-labelledby="contactSeven-tab" tabIndex="6">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Protect-endpoint-Security.svg" alt="Endpoint Security Services" />
                                                <h2>Endpoint Security Services</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We help clients secure their devices, including laptops, desktops, and mobile devices, using antivirus, firewall, patch management, device management, encryption, and other tools.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Endpoint Security Services are designed to help clients secure their devices, including laptops, desktops, and mobile devices, against cyber threats. By deploying a comprehensive suite of security tools and practices such as antivirus, firewalls, patch management, device management, and encryption, we ensure that endpoints are protected, compliant, and resilient against attacks.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>
                                                <h6 class="mb-1">Antivirus and Anti-Malware Protection</h6>
                                                <ul class="mb-2">
                                                    <li><span>Real-Time Protection:</span> Deploy advanced antivirus and anti-malware solutions to provide real-time protection against a wide range of threats, including viruses, worms, Trojans, ransomware, and spyware.</li>
                                                    <li><span>Heuristic and Behavioral Analysis:</span> Utilize heuristic and behavioral analysis to detect and block unknown and zero-day threats.</li>
                                                    <li><span>Regular Scanning and Updates:</span> Schedule regular scans and ensure antivirus definitions are continuously updated to protect against the latest threats.</li>
                                                </ul>
                                                <h6 class="mb-1">Firewall Management</h6>
                                                <ul class="mb-2">
                                                    <li><span>Personal Firewalls:</span> Implement and configure personal firewalls on all endpoints to monitor and control incoming and outgoing network traffic based on security policies.</li>
                                                    <li><span>Network-Level Protection:</span> Ensure network-level firewall policies are enforced across all devices, providing an additional layer of defense.</li>
                                                    <li><span>Intrusion Prevention Systems (IPS):</span> Integrate IPS capabilities to detect and prevent attempts to exploit vulnerabilities on endpoint devices.</li>
                                                </ul>
                                                <h6 class="mb-1">Patch Management</h6>
                                                <ul class="mb-2">
                                                    <li><span>Automated Patch Deployment:</span> Use automated patch management tools to deploy security patches and updates promptly across all endpoints.</li>
                                                    <li><span>Patch Testing and Validation:</span> Test patches in a controlled environment before deployment to ensure they do not cause disruptions or conflicts.</li>
                                                    <li><span>Compliance Reporting:</span> Generate reports to verify that all endpoints are up to date with the latest security patches and comply with organizational policies.</li>
                                                </ul>
                                                <h6 class="mb-1">Device Management</h6>
                                                <ul class="mb-2">
                                                    <li><span>Unified Endpoint Management (UEM):</span> Implement UEM solutions to manage and secure all endpoints, including desktops, laptops, tablets, and smartphones, from a single platform.</li>
                                                    <li><span>Asset Inventory:</span> Maintain an accurate inventory of all endpoint devices, including hardware and software details, to ensure comprehensive management and security.</li>
                                                    <li><span>Policy Enforcement:</span> Enforce security policies, such as password requirements, screen locks, and application controls, across all devices.</li>
                                                </ul>
                                                <h6 class="mb-1">Encryption</h6>
                                                <ul class="mb-2">
                                                    <li><span>Full-Disk Encryption:</span> Implement full-disk encryption on all endpoint devices to protect data at rest, ensuring that data remains secure even if a device is lost or stolen.</li>
                                                    <li><span>File and Folder Encryption:</span> Use file and folder encryption to protect sensitive data, ensuring that it can only be accessed by authorized users.</li>
                                                    <li><span>Encryption Key Management:</span> Securely manage encryption keys, including generation, distribution, and rotation, to ensure data remains protected.</li>
                                                </ul>
                                                <h6 class="mb-1">Mobile Device Security</h6>
                                                <ul class="mb-2">
                                                    <li><span>Mobile Device Management (MDM):</span> Deploy MDM solutions to manage and secure mobile devices, enforcing security policies and ensuring compliance.</li>
                                                    <li><span>Remote Wipe and Lock:</span> Enable remote wipe and lock capabilities to protect data on lost or stolen devices.</li>
                                                    <li><span>App Security:</span> Implement app security measures to control and monitor the installation and usage of applications on mobile devices.</li>
                                                </ul>
                                                <h6 class="mb-1">Endpoint Detection and Response (EDR)</h6>
                                                <ul class="mb-2">
                                                    <li><span>Behavioral Monitoring:</span> Use EDR solutions to continuously monitor endpoint activity and detect suspicious behavior that may indicate a security incident.</li>
                                                    <li><span>Threat Hunting:</span> Conduct proactive threat hunting on endpoints to identify and mitigate advanced threats.</li>
                                                    <li><span>Incident Response:</span> Provide rapid incident response capabilities to investigate and remediate security incidents on endpoint devices.</li>
                                                </ul>
                                                <h6 class="mb-1">Data Loss Prevention (DLP)</h6>
                                                <ul class="mb-2">
                                                    <li><span>DLP Solutions:</span> Implement DLP solutions to monitor and control the movement of sensitive data across endpoints, preventing unauthorized access and exfiltration.</li>
                                                    <li><span>Policy Creation:</span> Develop and enforce DLP policies to protect sensitive information, such as intellectual property and personal data.</li>
                                                    <li><span>Monitoring and Reporting:</span> Continuously monitor data transfers and generate reports to ensure compliance with DLP policies.</li>
                                                </ul>
                                                <h6 class="mb-1">User Training and Awareness</h6>
                                                <ul class="mb-2">
                                                    <li><span>Security Awareness Training:</span> Conduct regular training sessions to educate users on endpoint security best practices.</li>
                                                    <li><span>Phishing Simulations:</span> Run phishing simulations to test user awareness and response to phishing attempts.</li>
                                                    <li><span>Incident Reporting:</span> Encourage users to report suspicious activity or potential security incidents promptly.</li>
                                                </ul>
                                                <h6 class="mb-1">Compliance and Governance</h6>
                                                <ul class="mb-2">
                                                    <li><span>Regulatory Compliance:</span> Ensure endpoint security measures comply with relevant regulations and industry standards.</li>
                                                    <li><span>Security Audits:</span> Conduct regular security audits and assessments to evaluate the effectiveness of endpoint security controls.</li>
                                                    <li><span>Policy Development:</span> Develop and enforce endpoint security policies and procedures to ensure consistent and effective protection.</li>
                                                </ul>
                                                <h6 class="mb-1">Continuous Monitoring and Improvement</h6>
                                                <ul class="mb-2">
                                                    <li><span>Security Monitoring:</span> Implement continuous monitoring of endpoint devices to detect and respond to security threats in real-time.</li>
                                                    <li><span>Vulnerability Assessments:</span> Perform regular vulnerability assessments to identify and address weaknesses in endpoint security.</li>
                                                    <li><span>Continuous Improvement:</span> Continuously improve endpoint security measures based on threat intelligence and incident analysis.</li>
                                                </ul>
                                                <p>
                                                    By leveraging our comprehensive Endpoint Security Services, organizations can ensure that all endpoint devices are secured against a wide range of cyber threats, protecting sensitive data and maintaining compliance with security policies and regulations. This proactive approach to endpoint security helps mitigate risks and ensures that devices remain resilient against evolving cyber threats.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="boxA boxB">
                                <div class="upper_box">
                                    <div class="text_box">
                                        <span>Your at</span>
                                        <p> Valkyrie Services</p>
                                        <div
                                            class="progress"
                                            role="progressbar"
                                            aria-label="Basic example"
                                            aria-valuenow="100"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            <div
                                                class={`progress-bar ${modeValue == 'report-mode' ?
                                                    'green_progress_bar' : modeValue == 'assessment-mode' ?
                                                        'red_progress_bar' : modeValue == 'settings-mode' ? 'yellow_progress_bar' : 'red_progress_bar'}`}
                                                style={{ width: "100%" }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="servicesBoxFlex-icon">
                                    <div className="box">
                                        <div class="iconBoxSvg">
                                            <a>
                                                <FontAwesomeIcon
                                                    icon={faPlus} className="img-fluid" style={{ width: '20px', color: 'rgb(51 154 227)' }} />
                                            </a>
                                        </div>
                                        <p>
                                            Add <br /> to <br /> Request
                                        </p>
                                    </div>
                                    <div className="box">
                                        <div class="up_down_circle up_down_circle_service" id="tab_uppper_button">
                                            <a>
                                                <img src={icon} class="img-fluid" alt="" />
                                            </a>
                                        </div>
                                        <p>
                                            Compile <br /> Request
                                        </p>
                                    </div>
                                    <div className="box">
                                        <div class="iconBoxSvg">
                                            <a>
                                                <FontAwesomeIcon icon={faMinus}
                                                    style={{ width: '20px', color: '#ff1d25' }}
                                                    className="img-fluid" />
                                            </a>
                                        </div>
                                        <p>
                                            Remove <br /> from <br /> Request
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)
                }

                {hash == 'detect' &&
                    (<div class="bottom_panel bottom_panel_Services active_bottom_panel" id="bottom_panel">
                        <div class="bottom_panel_header">
                            <div class="tab_boxes">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li>
                                        <div class="boxA">
                                            <div class="upper_box">
                                                <img src="/assets/images/Detect_Valkyrie.svg" class="img-fluid" alt="" />
                                                <div class="text_box">
                                                    <p>Detect Services</p>
                                                    <div
                                                        class="progress"
                                                        role="progressbar"
                                                        aria-label="Basic example"
                                                        aria-valuenow="100"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    >
                                                        <div
                                                            class={`progress-bar ${modeValue == 'report-mode' ?
                                                                'green_progress_bar' : modeValue == 'assessment-mode' ?
                                                                    'red_progress_bar' : modeValue == 'settings-mode' ? 'yellow_progress_bar' : 'red_progress_bar'}`}
                                                            style={{ width: "100%" }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" onClick={onTabClick("home-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Detect-Security-monitoring.svg" class="img-fluid" alt="" />
                                                <p>Security Monitoring and Analytics Service</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" onClick={onTabClick("profile-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Detect-Threat-Intelligence.svg" class="img-fluid" alt="" />
                                                <p>Threat Intelligence Services</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false" onClick={onTabClick("contact-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Detect-Vulnerabillity-pentest.svg" class="img-fluid" alt="" />
                                                <p>Vulnerability Assessment and Penetration Testing Services (VAPT)</p>
                                            </div>
                                        </button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Detect-Security-monitoring.svg" alt="Security Monitoring Services" />
                                                <h2>Security Monitoring and Analytics Service</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We assist our clients using security information and event management (SIEM), security orchestration automation and response (SOAR), AI, and ML to monitor systems and networks for malicious or anomalous activity.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Security Monitoring and Analytics service is dedicated to providing comprehensive oversight of your IT infrastructure to detect and respond to malicious or anomalous activity. By leveraging advanced Security Information and Event Management (SIEM) systems, Security Orchestration, Automation, and Response (SOAR) platforms, and cutting-edge AI and ML technologies, we ensure continuous monitoring and rapid incident response to safeguard your organization against cyber threats.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>

                                                <h6 class="mb-1">Security Information and Event Management (SIEM)</h6>
                                                <ul class="mb-2">
                                                    <li><span>SIEM Deployment:</span> Implement and configure SIEM solutions tailored to your organization’s specific needs and environment.</li>
                                                    <li><span>Log Collection and Management:</span> Aggregate logs from diverse sources, including network devices, servers, applications, and cloud services, into a centralized system.</li>
                                                    <li><span>Real-Time Monitoring:</span> Continuously monitor log data to identify security events and anomalies in real-time.</li>
                                                    <li><span>Correlation and Analysis:</span> Utilize advanced correlation rules and algorithms to detect patterns indicative of security incidents across different data sources.</li>
                                                    <li><span>Alerting and Reporting:</span> Generate real-time alerts and detailed reports on potential security incidents, ensuring timely awareness and action.</li>
                                                </ul>

                                                <h6 class="mb-1">Security Orchestration, Automation, and Response (SOAR)</h6>
                                                <ul class="mb-2">
                                                    <li><span>SOAR Integration:</span> Integrate SOAR platforms with existing SIEM systems and other security tools to enhance incident response capabilities.</li>
                                                    <li><span>Automated Playbooks:</span> Develop and deploy automated response playbooks to handle common security incidents efficiently and consistently.</li>
                                                    <li><span>Incident Enrichment:</span> Automatically enrich incident data with contextual information to aid in faster and more informed decision-making.</li>
                                                    <li><span>Workflow Automation:</span> Streamline and automate routine security tasks, such as threat intelligence gathering, incident triage, and alert prioritization.</li>
                                                    <li><span>Collaboration and Coordination:</span> Facilitate seamless communication and coordination among security teams through integrated collaboration tools.</li>
                                                </ul>

                                                <h6 class="mb-1">Artificial Intelligence (AI) and Machine Learning (ML)</h6>
                                                <ul class="mb-2">
                                                    <li><span>Behavioral Analytics:</span> Implement AI and ML models to analyze user and entity behavior, identifying deviations from normal patterns that may indicate insider threats or compromised accounts.</li>
                                                    <li><span>Anomaly Detection:</span> Use ML algorithms to detect unusual activity across the network and endpoints, flagging potential security incidents that traditional methods might miss.</li>
                                                    <li><span>Threat Prediction:</span> Leverage predictive analytics to anticipate and proactively mitigate emerging threats based on historical data and threat intelligence.</li>
                                                    <li><span>Adaptive Learning:</span> Continuously train and refine AI/ML models to improve their accuracy and effectiveness in detecting evolving threats.</li>
                                                </ul>

                                                <h6 class="mb-1">Comprehensive Monitoring Solutions</h6>
                                                <ul class="mb-2">
                                                    <li><span>Network Security Monitoring:</span> Monitor network traffic for signs of intrusion, malware, and other malicious activities using advanced network detection systems.</li>
                                                    <li><span>Endpoint Security Monitoring:</span> Deploy and manage endpoint detection and response (EDR) solutions to monitor and protect endpoints from sophisticated threats.</li>
                                                    <li><span>Cloud Security Monitoring:</span> Extend monitoring capabilities to cloud environments, ensuring visibility and security across cloud infrastructure, applications, and services.</li>
                                                    <li><span>Application Security Monitoring:</span> Implement application monitoring to detect vulnerabilities and attacks on web applications, APIs, and other critical software.</li>
                                                </ul>

                                                <h6 class="mb-1">Incident Response and Management</h6>
                                                <ul class="mb-2">
                                                    <li><span>24/7 Security Operations Center (SOC):</span> Operate a round-the-clock SOC staffed by experienced security analysts who monitor, analyze, and respond to security incidents.</li>
                                                    <li><span>Incident Detection and Escalation:</span> Rapidly detect and escalate incidents to appropriate response teams, ensuring swift containment and remediation.</li>
                                                    <li><span>Incident Investigation:</span> Conduct thorough investigations of security incidents to understand the root cause, impact, and necessary remediation steps.</li>
                                                    <li><span>Post-Incident Analysis:</span> Perform post-incident reviews and develop actionable insights to prevent future occurrences and improve security posture.</li>
                                                </ul>

                                                <h6 class="mb-1">Threat Intelligence Integration</h6>
                                                <ul class="mb-2">
                                                    <li><span>Threat Feed Aggregation:</span> Integrate multiple threat intelligence feeds into monitoring systems to stay updated on the latest threat landscape.</li>
                                                    <li><span>Contextual Threat Analysis:</span> Use threat intelligence to provide context for detected incidents, enhancing the accuracy of threat detection and response.</li>
                                                    <li><span>Proactive Threat Hunting:</span> Conduct proactive threat hunting activities using threat intelligence to identify and mitigate hidden threats within the network.</li>
                                                </ul>

                                                <p>
                                                    By leveraging our Security Monitoring and Analytics service, organizations can achieve a heightened level of security visibility and responsiveness. This comprehensive approach ensures that potential threats are identified and addressed promptly, minimizing the risk and impact of cyber incidents on your business operations.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="1">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Detect-Threat-Intelligence.svg" alt="Threat Intelligence Services" />
                                                <h2>Threat Intelligence Services</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We provide our clients with a comprehensive threat intelligence solution, iOdin, which enables us to identify and analyze current and emerging cyber threats from various sources.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Threat Intelligence service, powered by the comprehensive iOdin platform, provides clients with a robust solution for identifying and analyzing current and emerging cyber threats. By aggregating data from various sources and leveraging advanced analytical capabilities, iOdin enables proactive threat management, helping organizations stay ahead of potential cyber risks and safeguarding their digital assets.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>

                                                <h6 class="mb-1">iOdin Platform</h6>
                                                <ul class="mb-2">
                                                    <li><span>Data Aggregation:</span> Collects threat data from a wide array of sources, including open-source intelligence (OSINT), dark web monitoring, proprietary threat feeds, and industry-specific intelligence.</li>
                                                    <li><span>Real-Time Analysis:</span> Provides real-time analysis of threat data to ensure timely detection of emerging threats and vulnerabilities.</li>
                                                    <li><span>User-Friendly Interface:</span> Features an intuitive interface that allows users to easily navigate, visualize, and interpret threat intelligence data.</li>
                                                </ul>

                                                <h6 class="mb-1">Comprehensive Threat Identification</h6>
                                                <ul class="mb-2">
                                                    <li><span>Malware and Ransomware:</span> Identifies new strains of malware and ransomware, analyzing their behavior and potential impact on systems.</li>
                                                    <li><span>Phishing and Social Engineering:</span> Detects phishing campaigns and social engineering tactics targeting employees and customers.</li>
                                                    <li><span>Vulnerability Exploitation:</span> Monitors for the latest exploits targeting known vulnerabilities in software and hardware.</li>
                                                    <li><span>Advanced Persistent Threats (APTs):</span> Tracks sophisticated threat actors and their tactics, techniques, and procedures (TTPs).</li>
                                                </ul>

                                                <h6 class="mb-1">Advanced Threat Analysis</h6>
                                                <ul class="mb-2">
                                                    <li><span>Contextual Threat Insights:</span> Provides contextual information about identified threats, including threat actor profiles, attack vectors, and targeted sectors.</li>
                                                    <li><span>Threat Correlation:</span> Correlates threat data across multiple sources to identify patterns and connections between different threats and incidents.</li>
                                                    <li><span>Risk Scoring:</span> Assigns risk scores to identified threats based on their severity, potential impact, and likelihood of occurrence.</li>
                                                </ul>

                                                <h6 class="mb-1">Proactive Threat Management</h6>
                                                <ul class="mb-2">
                                                    <li><span>Threat Hunting:</span> Conducts proactive threat hunting to identify hidden threats within the organization’s environment before they can cause harm.</li>
                                                    <li><span>Incident Prediction:</span> Utilizes predictive analytics to anticipate potential cyber incidents based on historical data and threat trends.</li>
                                                    <li><span>Actionable Intelligence:</span> Provides actionable recommendations and mitigation strategies to address identified threats effectively.</li>
                                                </ul>

                                                <h6 class="mb-1">Threat Intelligence Feeds</h6>
                                                <ul class="mb-2">
                                                    <li><span>Customizable Feeds:</span> Offers customizable threat intelligence feeds tailored to the organization’s specific needs and industry requirements.</li>
                                                    <li><span>Integration with Security Tools:</span> Integrates seamlessly with existing security tools and platforms, such as SIEM, SOAR, and endpoint protection systems, to enhance overall security posture.</li>
                                                    <li><span>Automated Updates:</span> Ensures that threat intelligence feeds are automatically updated with the latest information to maintain relevance and accuracy.</li>
                                                </ul>

                                                <h6 class="mb-1">Reporting and Visualization</h6>
                                                <ul class="mb-2">
                                                    <li><span>Detailed Reports:</span> Generates comprehensive threat intelligence reports that provide in-depth analysis and insights into identified threats.</li>
                                                    <li><span>Dashboards:</span> Features interactive dashboards that visualize threat data, making it easier to identify trends, anomalies, and potential risks.</li>
                                                    <li><span>Alerting System:</span> Includes an alerting system that notifies stakeholders of critical threats and incidents in real-time.</li>
                                                </ul>

                                                <h6 class="mb-1">Threat Intelligence Sharing</h6>
                                                <ul class="mb-2">
                                                    <li><span>Information Sharing Partnerships:</span> Engages in information-sharing partnerships with industry groups, governmental agencies, and other organizations to enhance collective threat intelligence.</li>
                                                    <li><span>Collaboration Platforms:</span> Provides secure platforms for collaboration and information exchange among security teams and partners.</li>
                                                </ul>

                                                <h6 class="mb-1">Continuous Improvement</h6>
                                                <ul class="mb-2">
                                                    <li><span>Feedback Mechanism:</span> Incorporates feedback from users to continuously improve the iOdin platform and the overall threat intelligence service.</li>
                                                    <li><span>Training and Support:</span> Offers training and support to ensure that clients can effectively utilize the iOdin platform and understand the threat intelligence provided.</li>
                                                </ul>

                                                <p>
                                                    By leveraging our Threat Intelligence service and the powerful iOdin platform, organizations can gain a comprehensive understanding of the cyber threat landscape, enabling them to proactively defend against current and emerging threats. This service ensures that clients are equipped with the knowledge and tools necessary to protect their digital assets and maintain a robust security posture.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Detect-Vulnerabillity-pentest.svg" alt="Vulnerability Assessment and Penetration Testing Services" />
                                                <h2>Vulnerability Assessment and Penetration Testing Services (VAPT)</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We help clients identify and exploit weaknesses in their systems and networks by using vulnerability scanning and penetration testing tools.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Vulnerability Assessment and Penetration Testing (VAPT) services provide a comprehensive approach to identifying and exploiting weaknesses in your systems and networks. By utilizing advanced vulnerability scanning tools and sophisticated penetration testing techniques, we help clients uncover security flaws, assess potential risks, and implement effective remediation strategies to fortify their defenses against cyber threats.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>

                                                <h6 class="mb-1">Vulnerability Assessment</h6>
                                                <ul class="mb-2">
                                                    <li><span>Automated Vulnerability Scanning:</span> Employ industry-leading scanning tools to systematically examine systems, networks, and applications for known vulnerabilities.</li>
                                                    <li><span>Manual Verification:</span> Conduct manual verification of identified vulnerabilities to eliminate false positives and prioritize genuine security risks.</li>
                                                    <li><span>Comprehensive Coverage:</span> Assess a wide range of assets, including servers, databases, web applications, network devices, and endpoints, to ensure thorough vulnerability detection.</li>
                                                    <li><span>Detailed Reporting:</span> Provide detailed reports that categorize vulnerabilities based on severity, potential impact, and recommended remediation steps.</li>
                                                </ul>

                                                <h6 class="mb-1">Penetration Testing</h6>
                                                <ul class="mb-2">
                                                    <li><span>External Penetration Testing:</span> Simulate external attacks to test the security of public-facing systems and identify vulnerabilities that could be exploited by external threat actors.</li>
                                                    <li><span>Internal Penetration Testing:</span> Conduct internal assessments to uncover vulnerabilities that could be exploited by insider threats or attackers who have breached the perimeter.</li>
                                                    <li><span>Web Application Testing:</span> Perform in-depth testing of web applications to identify issues such as SQL injection, cross-site scripting (XSS), and other common web vulnerabilities.</li>
                                                    <li><span>Network Penetration Testing:</span> Test network infrastructure for weaknesses in firewalls, routers, switches, and other network components.</li>
                                                    <li><span>Wireless Network Testing:</span> Assess the security of wireless networks to detect vulnerabilities in Wi-Fi configurations and encryption protocols.</li>
                                                    <li><span>Social Engineering:</span> Simulate social engineering attacks, such as phishing and pretexting, to evaluate the effectiveness of security awareness programs and user susceptibility.</li>
                                                </ul>

                                                <h6 class="mb-1">Advanced Testing Techniques</h6>
                                                <ul class="mb-2">
                                                    <li><span>Black Box Testing:</span> Conduct testing without prior knowledge of the internal workings of the target system, mimicking the perspective of an external attacker.</li>
                                                    <li><span>White Box Testing:</span> Perform testing with full knowledge of the system’s architecture and source code to identify vulnerabilities from an insider’s perspective.</li>
                                                    <li><span>Grey Box Testing:</span> Combine elements of both black box and white box testing to provide a balanced assessment with partial knowledge of the system.</li>
                                                    <li><span>Exploit Development:</span> Develop custom exploits to demonstrate the practical impact of identified vulnerabilities and validate the effectiveness of security controls.</li>
                                                </ul>

                                                <h6 class="mb-1">Risk Assessment and Prioritization</h6>
                                                <ul class="mb-2">
                                                    <li><span>Threat Modeling:</span> Analyze identified vulnerabilities in the context of potential threats to determine the overall risk to the organization.</li>
                                                    <li><span>Impact Analysis:</span> Evaluate the potential business impact of exploiting identified vulnerabilities, considering factors such as data loss, financial damage, and reputational harm.</li>
                                                    <li><span>Risk Prioritization:</span> Prioritize vulnerabilities based on risk level, providing clear guidance on which issues require immediate attention and which can be addressed over time.</li>
                                                </ul>

                                                <h6 class="mb-1">Remediation Guidance and Support</h6>
                                                <ul class="mb-2">
                                                    <li><span>Actionable Recommendations:</span> Offer detailed remediation recommendations for each identified vulnerability, including technical fixes, configuration changes, and policy updates.</li>
                                                    <li><span>Collaborative Remediation:</span> Work closely with clients’ IT and security teams to assist in implementing remediation measures and verifying their effectiveness.</li>
                                                    <li><span>Patch Management Support:</span> Provide guidance on effective patch management practices to ensure timely and systematic application of security updates.</li>
                                                </ul>

                                                <h6 class="mb-1">Continuous Improvement</h6>
                                                <ul class="mb-2">
                                                    <li><span>Retesting and Validation:</span> Conduct retesting of previously identified vulnerabilities to verify successful remediation and ensure no new issues have been introduced.</li>
                                                    <li><span>Periodic Assessments:</span> Offer periodic vulnerability assessments and penetration tests to maintain a strong security posture and adapt to evolving threats.</li>
                                                    <li><span>Knowledge Transfer:</span> Provide training and knowledge transfer to internal teams to enhance their understanding of vulnerabilities, attack vectors, and remediation techniques.</li>
                                                </ul>

                                                <h6 class="mb-1">Compliance and Reporting</h6>
                                                <ul class="mb-2">
                                                    <li><span>Regulatory Compliance:</span> Ensure that VAPT services align with relevant regulatory requirements and industry standards, such as PCI DSS, HIPAA, and ISO 27001.</li>
                                                    <li><span>Executive Summaries:</span> Produce executive-level summaries that highlight key findings, risks, and remediation efforts in a clear and concise manner.</li>
                                                    <li><span>Technical Reports:</span> Deliver comprehensive technical reports that detail the methodologies, findings, and evidence for each identified vulnerability and test case.</li>
                                                </ul>

                                                <p>
                                                    By leveraging our VAPT services, organizations can gain a deep understanding of their security weaknesses, prioritize remediation efforts, and enhance their overall cybersecurity posture. This proactive approach to vulnerability management helps safeguard critical assets, reduce the risk of breaches, and ensure ongoing protection against cyber threats.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="boxA boxB">
                                <div class="upper_box">
                                    <div class="text_box">
                                        <span>Your at</span>
                                        <p> Valkyrie Services</p>
                                        <div
                                            class="progress"
                                            role="progressbar"
                                            aria-label="Basic example"
                                            aria-valuenow="100"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            <div
                                                class={`progress-bar ${modeValue == 'report-mode' ?
                                                    'green_progress_bar' : modeValue == 'assessment-mode' ?
                                                        'red_progress_bar' : modeValue == 'settings-mode' ? 'yellow_progress_bar' : 'red_progress_bar'}`}
                                                style={{ width: "100%" }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="servicesBoxFlex-icon">
                                    <div className="box">
                                        <div class="iconBoxSvg">
                                            <a>
                                                <FontAwesomeIcon
                                                    icon={faPlus} className="img-fluid" style={{ width: '20px', color: 'rgb(51 154 227)' }} />
                                            </a>
                                        </div>
                                        <p>
                                            Add <br /> to <br /> Request
                                        </p>
                                    </div>
                                    <div className="box">
                                        <div class="up_down_circle up_down_circle_service" id="tab_uppper_button">
                                            <a>
                                                <img src={icon} class="img-fluid" alt="" />
                                            </a>
                                        </div>
                                        <p>
                                            Compile <br /> Request
                                        </p>
                                    </div>
                                    <div className="box">
                                        <div class="iconBoxSvg">
                                            <a>
                                                <FontAwesomeIcon icon={faMinus}
                                                    style={{ width: '20px', color: '#ff1d25' }}
                                                    className="img-fluid" />
                                            </a>
                                        </div>
                                        <p>
                                            Remove <br /> from <br /> Request
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)
                }

                {hash == 'respond' &&
                    (<div class="bottom_panel bottom_panel_Services active_bottom_panel" id="bottom_panel">
                        <div class="bottom_panel_header">

                            <div class="tab_boxes">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li>
                                        <div class="boxA">
                                            <div class="upper_box">
                                                <img src="/assets/images/Respond_Valkyrie.svg" class="img-fluid" alt="" />
                                                <div class="text_box">
                                                    <p>Respond Services</p>
                                                    <div
                                                        class="progress"
                                                        role="progressbar"
                                                        aria-label="Basic example"
                                                        aria-valuenow="100"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    >
                                                        <div
                                                            class={`progress-bar ${modeValue == 'report-mode' ?
                                                                'green_progress_bar' : modeValue == 'assessment-mode' ?
                                                                    'red_progress_bar' : modeValue == 'settings-mode' ? 'yellow_progress_bar' : 'red_progress_bar'}`}
                                                            style={{ width: "100%" }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" onClick={onTabClick("home-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Respond-Post-inncident-remediation.svg" class="img-fluid" alt="" />
                                                <p>Post-Incident Remediation and Improvement</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" onClick={onTabClick("profile-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Respond-disaster-recovery.svg" class="img-fluid" alt="" />
                                                <p>Business Continuity and Disaster Recovery Planning Service</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false" onClick={onTabClick("contact-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Respond-Cyber-Resillience.svg" class="img-fluid" alt="" />
                                                <p>Cyber Resilience Strategy and Implementation Service</p>
                                            </div>
                                        </button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Respond-Post-inncident-remediation.svg" alt="Post-Incident Remediation and Improvement" />
                                                <h2>Post-Incident Remediation and Improvement</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We help clients prevent and solve cyber incidents by conducting root-cause analysis, implementing lessons learned, action plans, and continuous improvement.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Post-Incident Remediation and Improvement service is designed to support clients in both preventing and resolving cyber incidents through a structured and comprehensive approach. By conducting thorough root-cause analyses, implementing lessons learned, and developing action plans, we ensure that our clients not only recover from incidents but also strengthen their overall security posture to prevent future occurrences.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>

                                                <h6 class="mb-1">Root-Cause Analysis</h6>
                                                <ul class="mb-2">
                                                    <li><span>Incident Investigation:</span> Perform a detailed investigation of the incident to identify the exact cause and contributing factors. This includes examining logs, system configurations, and network traffic.</li>
                                                    <li><span>Impact Assessment:</span> Assess the scope and impact of the incident on the organization's operations, data integrity, and reputation.</li>
                                                    <li><span>Forensic Analysis:</span> Utilize advanced forensic tools and techniques to uncover how the breach occurred, including identifying compromised systems and entry points.</li>
                                                </ul>

                                                <h6 class="mb-1">Lessons Learned</h6>
                                                <ul class="mb-2">
                                                    <li><span>Incident Review Workshops:</span> Conduct workshops with key stakeholders to review the incident, discuss findings, and extract valuable insights.</li>
                                                    <li><span>Documentation:</span> Compile a comprehensive report detailing the incident, findings from the root-cause analysis, and identified vulnerabilities or weaknesses.</li>
                                                    <li><span>Knowledge Transfer:</span> Ensure that the knowledge gained from the incident is effectively communicated to relevant teams to enhance their understanding and preparedness.</li>
                                                </ul>

                                                <h6 class="mb-1">Action Plans</h6>
                                                <ul class="mb-2">
                                                    <li><span>Remediation Strategies:</span> Develop tailored remediation strategies to address identified vulnerabilities and prevent recurrence. This includes patching, reconfiguring systems, and enhancing security controls.</li>
                                                    <li><span>Prioritization and Timeline:</span> Provide a prioritized list of remediation actions with clear timelines and responsibilities to ensure effective implementation.</li>
                                                    <li><span>Resource Allocation:</span> Advise on necessary resources, including tools, technologies, and personnel, to implement the action plans successfully.</li>
                                                </ul>

                                                <h6 class="mb-1">Continuous Improvement</h6>
                                                <ul class="mb-2">
                                                    <li><span>Monitoring and Evaluation:</span> Establish ongoing monitoring and evaluation processes to track the effectiveness of remediation efforts and detect potential issues early.</li>
                                                    <li><span>Security Policy Updates:</span> Recommend updates to security policies, procedures, and standards based on lessons learned and industry best practices.</li>
                                                </ul>

                                                <p>
                                                    By leveraging our Post-Incident Remediation and Improvement service, organizations can effectively mitigate the impact of cyber incidents, enhance their resilience against future threats, and maintain a proactive security posture.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="1">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Respond-disaster-recovery.svg" alt="Business Continuity and Disaster Recovery Planning Service" />
                                                <h2>Business Continuity and Disaster Recovery Planning Service</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We assist customers in preparing and executing plans to resume vital functions and services following a cyber incident. This is achieved through business continuity plans, disaster recovery plans, business impact analysis, recovery point objectives (RPO), and recovery time objectives (RTO).
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Business Continuity and Disaster Recovery Planning service is designed to ensure that organizations can quickly resume vital functions and services following a cyber incident. By developing robust business continuity plans, disaster recovery plans, conducting business impact analyses, and establishing clear recovery objectives, we help our clients maintain operational resilience and minimize downtime during disruptive events.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>

                                                <h6 class="mb-1">Business Continuity Plans (BCP)</h6>
                                                <ul class="mb-2">
                                                    <li><span>Risk Assessment and Analysis:</span> Identify potential threats and vulnerabilities that could impact business operations, including cyber threats, natural disasters, and other disruptions.</li>
                                                    <li><span>Continuity Strategies:</span> Develop strategies to maintain essential functions and services during and after an incident. This includes identifying critical business processes and establishing alternative procedures.</li>
                                                    <li><span>Plan Development:</span> Create detailed business continuity plans that outline roles, responsibilities, communication protocols, and step-by-step procedures for maintaining operations.</li>
                                                    <li><span>Plan Integration:</span> Ensure that the BCP is integrated with overall corporate policies and aligns with regulatory requirements and industry standards.</li>
                                                </ul>

                                                <h6 class="mb-1">Disaster Recovery Plans (DRP)</h6>
                                                <ul class="mb-2">
                                                    <li><span>Infrastructure Assessment:</span> Evaluate existing IT infrastructure and systems to identify critical components and potential points of failure.</li>
                                                    <li><span>Recovery Solutions:</span> Design and implement disaster recovery solutions, such as data backups, off-site storage, and failover systems, to ensure rapid recovery of IT services.</li>
                                                    <li><span>Technical Procedures:</span> Develop detailed technical recovery procedures to restore IT systems, applications, and data to their operational state following an incident.</li>
                                                    <li><span>Coordination with IT Teams:</span> Work closely with IT teams to ensure that disaster recovery plans are feasible, regularly tested, and updated.</li>
                                                </ul>

                                                <h6 class="mb-1">Business Impact Analysis (BIA)</h6>
                                                <ul class="mb-2">
                                                    <li><span>Critical Functions Identification:</span> Identify and prioritize critical business functions and processes that are essential for the organization's survival.</li>
                                                    <li><span>Impact Scenarios:</span> Analyze potential impact scenarios to understand the financial, operational, and reputational consequences of disruptions.</li>
                                                    <li><span>Dependency Mapping:</span> Map dependencies between business functions and IT systems to identify interdependencies and potential bottlenecks.</li>
                                                    <li><span>Impact Assessment Report:</span> Produce a comprehensive report detailing the potential impacts and recommending mitigation strategies to reduce risks.</li>
                                                </ul>

                                                <h6 class="mb-1">Recovery Point Objectives (RPO)</h6>
                                                <ul class="mb-2">
                                                    <li><span>Data Loss Tolerance:</span> Determine the maximum acceptable amount of data loss measured in time, which informs the frequency of data backups.</li>
                                                    <li><span>Backup Strategies:</span> Implement backup strategies that align with the defined RPO, ensuring data is backed up at appropriate intervals to minimize loss.</li>
                                                    <li><span>Backup Verification:</span> Regularly verify and test backup processes to ensure data integrity and reliability.</li>
                                                </ul>

                                                <h6 class="mb-1">Recovery Time Objectives (RTO)</h6>
                                                <ul class="mb-2">
                                                    <li><span>Downtime Tolerance:</span> Establish the maximum acceptable downtime for critical systems and processes.</li>
                                                    <li><span>Recovery Procedures:</span> Develop and document recovery procedures that meet the defined RTO, ensuring that critical systems are restored within the acceptable timeframe.</li>
                                                    <li><span>Resource Planning:</span> Identify and allocate necessary resources, including personnel, technology, and facilities, to achieve the RTO.</li>
                                                    <li><span>Drills and Simulations:</span> Conduct regular disaster recovery drills and simulations to validate the effectiveness of recovery procedures and ensure readiness.</li>
                                                </ul>

                                                <p>
                                                    By leveraging our comprehensive Business Continuity and Disaster Recovery Planning service, organizations can significantly enhance their resilience against disruptions, ensuring they can quickly and effectively resume vital functions and services following a cyber incident.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Respond-Cyber-Resillience.svg" alt="Cyber Resilience Strategy and Implementation Service" />
                                                <h2>Cyber Resilience Strategy and Implementation Service</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We assist clients in strengthening their ability to withstand and adapt to cyber incidents by utilizing cyber resilience frameworks, metrics, controls, and culture.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Cyber Resilience Strategy and Implementation service is dedicated to helping organizations enhance their capability to withstand, respond to, and recover from cyber incidents. By leveraging comprehensive cyber resilience frameworks, defining robust metrics, implementing effective controls, and fostering a resilient culture, we ensure that our clients are well-prepared to handle the ever-evolving cyber threat landscape.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>

                                                <h6 class="mb-1">Cyber Resilience Frameworks</h6>
                                                <ul class="mb-2">
                                                    <li><span>Framework Selection:</span> Assist clients in selecting the most suitable cyber resilience frameworks, such as NIST Cybersecurity Framework, ISO 27001, or the Cyber Resilience Review (CRR).</li>
                                                    <li><span>Framework Customization:</span> Customize the chosen framework to align with the organization's specific needs, industry requirements, and regulatory obligations.</li>
                                                    <li><span>Implementation Roadmap:</span> Develop a detailed implementation roadmap that outlines the steps, timelines, and milestones for integrating the framework into the organization's operations.</li>
                                                    <li><span>Framework Integration:</span> Ensure seamless integration of the framework with existing cybersecurity policies, procedures, and technologies.</li>
                                                </ul>

                                                <h6 class="mb-1">Metrics and Key Performance Indicators (KPIs)</h6>
                                                <ul class="mb-2">
                                                    <li><span>Metric Identification:</span> Identify critical metrics and KPIs that provide insights into the organization's cyber resilience posture, such as mean time to detect (MTTD), mean time to respond (MTTR), and recovery time objectives (RTO).</li>
                                                    <li><span>Data Collection and Analysis:</span> Implement systems and processes for collecting and analyzing data related to cyber resilience metrics.</li>
                                                    <li><span>Performance Monitoring:</span> Continuously monitor and evaluate the performance of cyber resilience initiatives using the defined metrics and KPIs.</li>
                                                    <li><span>Reporting and Dashboards:</span> Develop customized reports and dashboards to visualize and communicate cyber resilience performance to stakeholders.</li>
                                                </ul>

                                                <h6 class="mb-1">Cyber Resilience Controls</h6>
                                                <ul class="mb-2">
                                                    <li><span>Preventive Controls:</span> Implement preventive controls to reduce the likelihood of cyber incidents, including firewalls, intrusion detection systems, and secure coding practices.</li>
                                                    <li><span>Detective Controls:</span> Establish detective controls to quickly identify and respond to cyber incidents, such as real-time monitoring, threat intelligence, and anomaly detection.</li>
                                                    <li><span>Corrective Controls:</span> Develop corrective controls to mitigate the impact of incidents and facilitate swift recovery, including incident response plans, disaster recovery plans, and business continuity plans.</li>
                                                    <li><span>Control Assessment and Improvement:</span> Regularly assess the effectiveness of implemented controls and make necessary improvements based on findings from audits, tests, and real-world incidents.</li>
                                                </ul>

                                                <h6 class="mb-1">Resilient Culture</h6>
                                                <ul class="mb-2">
                                                    <li><span>Awareness and Training:</span> Conduct comprehensive cyber resilience training and awareness programs for employees at all levels to foster a culture of vigilance and preparedness.</li>
                                                    <li><span>Leadership Engagement:</span> Engage organizational leadership to champion cyber resilience initiatives and ensure top-down support.</li>
                                                    <li><span>Resilience Mindset:</span> Promote a resilience mindset across the organization by encouraging proactive risk management, continuous learning, and adaptability.</li>
                                                    <li><span>Behavioral Change:</span> Implement strategies to drive behavioral change, such as simulations, phishing exercises, and reward systems for reporting suspicious activities.</li>
                                                </ul>

                                                <h6 class="mb-1">Implementation Support</h6>
                                                <ul class="mb-2">
                                                    <li><span>Consultation and Advisory:</span> Provide expert consultation and advisory services to guide the implementation of cyber resilience strategies and initiatives.</li>
                                                    <li><span>Project Management:</span> Offer project management support to ensure that cyber resilience projects are executed efficiently and effectively, meeting predefined objectives and deadlines.</li>
                                                    <li><span>Technology Integration:</span> Assist in the selection, deployment, and integration of technologies that support cyber resilience, such as security information and event management (SIEM) systems, endpoint detection and response (EDR) solutions, and backup and recovery tools.</li>
                                                    <li><span>Continuous Improvement:</span> Establish a continuous improvement process to regularly review, update, and enhance the organization's cyber resilience posture in response to emerging threats and evolving business needs.</li>
                                                </ul>

                                                <p>
                                                    By leveraging our Cyber Resilience Strategy and Implementation service, organizations can significantly enhance their ability to withstand and adapt to cyber incidents, ensuring robust protection, swift response, and rapid recovery in the face of cyber threats.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="boxA boxB">
                                <div class="upper_box">
                                    <div class="text_box">
                                        <span>Your at</span>
                                        <p> Valkyrie Services</p>
                                        <div
                                            class="progress"
                                            role="progressbar"
                                            aria-label="Basic example"
                                            aria-valuenow="100"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            <div
                                                class={`progress-bar ${modeValue == 'report-mode' ?
                                                    'green_progress_bar' : modeValue == 'assessment-mode' ?
                                                        'red_progress_bar' : modeValue == 'settings-mode' ? 'yellow_progress_bar' : 'red_progress_bar'}`}
                                                style={{ width: "100%" }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="servicesBoxFlex-icon">
                                    <div className="box">
                                        <div class="iconBoxSvg">
                                            <a>
                                                <FontAwesomeIcon
                                                    icon={faPlus} className="img-fluid" style={{ width: '20px', color: 'rgb(51 154 227)' }} />
                                            </a>
                                        </div>
                                        <p>
                                            Add <br /> to <br /> Request
                                        </p>
                                    </div>
                                    <div className="box">
                                        <div class="up_down_circle up_down_circle_service" id="tab_uppper_button">
                                            <a>
                                                <img src={icon} class="img-fluid" alt="" />
                                            </a>
                                        </div>
                                        <p>
                                            Compile <br /> Request
                                        </p>
                                    </div>
                                    <div className="box">
                                        <div class="iconBoxSvg">
                                            <a>
                                                <FontAwesomeIcon icon={faMinus}
                                                    style={{ width: '20px', color: '#ff1d25' }}
                                                    className="img-fluid" />
                                            </a>
                                        </div>
                                        <p>
                                            Remove <br /> from <br /> Request
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)
                }


                {hash == 'recover' &&
                    (<div class="bottom_panel bottom_panel_Services active_bottom_panel" id="bottom_panel">
                        <div class="bottom_panel_header">
                            <div class="tab_boxes">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li>
                                        <div class="boxA">
                                            <div class="upper_box">
                                                <img src="/assets/images/Recover_Valkyrie.svg" class="img-fluid" alt="" />
                                                <div class="text_box">
                                                    <p>Recover Services</p>
                                                    <div
                                                        class="progress"
                                                        role="progressbar"
                                                        aria-label="Basic example"
                                                        aria-valuenow="100"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    >
                                                        <div
                                                            class={`progress-bar ${modeValue == 'report-mode' ?
                                                                'green_progress_bar' : modeValue == 'assessment-mode' ?
                                                                    'red_progress_bar' : modeValue == 'settings-mode' ? 'yellow_progress_bar' : 'red_progress_bar'}`}
                                                            style={{ width: "100%" }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" onClick={onTabClick("home-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Recover-Inncient-respond-Forensic.svg" class="img-fluid" alt="" />
                                                <p>Incident Response and Forensics</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" onClick={onTabClick("profile-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Recover-Crisis-Management.svg" class="img-fluid" alt="" />
                                                <p>Crisis Management and Communication</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false" onClick={onTabClick("contact-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Recover-Ransomware.svg" class="img-fluid" alt="" />
                                                <p>Ransomware Recovery and Mitigation</p>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contactFour-tab" data-bs-toggle="tab" data-bs-target="#contactFour-tab-pane" type="button" role="tab" aria-controls="contactFour-tab-pane" aria-selected="false" onClick={onTabClick("contactFour-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="../assets/images/Recover-Breach-Simulation.svg" class="img-fluid" alt="" />
                                                <p>Breach Simulation and Testing</p>
                                            </div>
                                        </button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Recover-Inncient-respond-Forensic.svg" alt="Incident Response and Forensics" />
                                                <h2>Incident Response and Forensics</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We assist clients in containing and resolving cyber incidents by utilizing incident response plans, teams, and forensic analysis tools.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Incident Response and Forensics services are designed to help clients swiftly contain and resolve cyber incidents by leveraging comprehensive incident response plans, specialized teams, and advanced forensic analysis tools. We provide a structured approach to managing and mitigating the impact of cyber incidents, ensuring rapid recovery and enhancing future resilience.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>

                                                <h6 class="mb-1">Incident Response Planning</h6>
                                                <ul class="mb-2">
                                                    <li><span>Customized Incident Response Plans:</span> Develop and tailor incident response plans specific to the organization’s needs, including detailed procedures for different types of incidents.</li>
                                                    <li><span>Roles and Responsibilities:</span> Define clear roles and responsibilities for incident response team members, ensuring accountability and effective coordination.</li>
                                                    <li><span>Communication Protocols:</span> Establish communication protocols for internal and external stakeholders, including escalation procedures and media handling.</li>
                                                    <li><span>Playbooks and Runbooks:</span> Create detailed incident response playbooks and runbooks for common incident scenarios, providing step-by-step guidance on containment, eradication, and recovery.</li>
                                                </ul>

                                                <h6 class="mb-1">Incident Detection and Monitoring</h6>
                                                <ul class="mb-2">
                                                    <li><span>Real-Time Monitoring:</span> Implement continuous monitoring solutions to detect potential incidents in real-time using Security Information and Event Management (SIEM) systems, Intrusion Detection Systems (IDS), and other advanced tools.</li>
                                                    <li><span>Anomaly Detection:</span> Utilize machine learning and behavioral analysis to identify anomalies that may indicate a security incident.</li>
                                                    <li><span>Threat Intelligence Integration:</span> Incorporate threat intelligence feeds to enhance detection capabilities and provide context for incident analysis.</li>
                                                </ul>

                                                <h6 class="mb-1">Incident Response Team (IRT)</h6>
                                                <ul class="mb-2">
                                                    <li><span>Dedicated IRT:</span> Assemble a dedicated Incident Response Team with specialized skills in cyber forensics, malware analysis, network security, and crisis management.</li>
                                                    <li><span>Training and Drills:</span> Conduct regular training sessions and incident response drills to ensure the IRT is well-prepared to handle various types of incidents.</li>
                                                    <li><span>Collaboration Tools:</span> Utilize collaboration tools to facilitate real-time communication and coordination among IRT members during an incident.</li>
                                                </ul>

                                                <h6 class="mb-1">Incident Containment and Eradication</h6>
                                                <ul class="mb-2">
                                                    <li><span>Immediate Containment Actions:</span> Execute immediate containment actions to limit the spread and impact of the incident, such as isolating affected systems and blocking malicious traffic.</li>
                                                    <li><span>Eradication Strategies:</span> Develop and implement eradication strategies to remove the threat from the environment, including malware removal, patching vulnerabilities, and closing attack vectors.</li>
                                                    <li><span>System Hardening:</span> Apply system hardening measures to prevent reinfection and enhance the security of affected systems.</li>
                                                </ul>

                                                <h6 class="mb-1">Forensic Analysis</h6>
                                                <ul class="mb-2">
                                                    <li><span>Data Collection and Preservation:</span> Collect and preserve digital evidence using forensically sound methods to ensure its integrity for analysis and potential legal proceedings.</li>
                                                    <li><span>Forensic Tools and Techniques:</span> Utilize advanced forensic tools and techniques to analyze compromised systems, identify the root cause, and determine the extent of the breach.</li>
                                                    <li><span>Log Analysis:</span> Perform detailed log analysis to reconstruct the timeline of the incident and identify the attacker’s actions and entry points.</li>
                                                    <li><span>Malware Analysis:</span> Analyze malware samples to understand their behavior, capabilities, and potential impact on the organization.</li>
                                                </ul>

                                                <h6 class="mb-1">Recovery and Restoration</h6>
                                                <ul class="mb-2">
                                                    <li><span>System Restoration:</span> Restore affected systems to a known good state, ensuring they are free from malware and properly configured.</li>
                                                    <li><span>Data Recovery:</span> Recover lost or compromised data from backups and verify its integrity.</li>
                                                    <li><span>Post-Incident Cleanup:</span> Conduct a thorough cleanup of the environment to remove any residual threats and ensure all systems are secure.</li>
                                                </ul>

                                                <h6 class="mb-1">Post-Incident Review and Reporting</h6>
                                                <ul class="mb-2">
                                                    <li><span>Incident Review Meetings:</span> Conduct post-incident review meetings with key stakeholders to analyze the response process and identify areas for improvement.</li>
                                                    <li><span>Root Cause Analysis:</span> Perform a root cause analysis to determine the underlying factors that contributed to the incident and develop strategies to address them.</li>
                                                    <li><span>Incident Reports:</span> Prepare detailed incident reports that document the timeline, actions taken, findings, and recommendations for future prevention.</li>
                                                    <li><span>Executive Summaries:</span> Provide executive summaries of the incident for senior management and board members, highlighting key insights and strategic recommendations.</li>
                                                </ul>

                                                <h6 class="mb-1">Continuous Improvement</h6>
                                                <ul class="mb-2">
                                                    <li><span>Lessons Learned:</span> Integrate lessons learned from the incident into the organization’s incident response plans and security policies.</li>
                                                    <li><span>Process Enhancements:</span> Continuously improve incident response processes based on feedback and post-incident analysis.</li>
                                                    <li><span>Regular Updates:</span> Regularly update incident response plans and playbooks to reflect new threats, technologies, and best practices.</li>
                                                </ul>

                                                <h6 class="mb-1">Regulatory Compliance and Legal Support</h6>
                                                <ul class="mb-2">
                                                    <li><span>Regulatory Reporting:</span> Assist with regulatory reporting requirements related to the incident, ensuring compliance with data breach notification laws and industry regulations.</li>
                                                    <li><span>Legal Coordination:</span> Coordinate with legal teams to provide forensic evidence and support for potential legal actions or investigations.</li>
                                                    <li><span>Audit Support:</span> Provide documentation and evidence for audits and assessments related to the incident and the organization’s incident response capabilities.</li>
                                                </ul>

                                                <h6 class="mb-1">Awareness and Training</h6>
                                                <ul class="mb-2">
                                                    <li><span>Employee Training Programs:</span> Develop and deliver training programs to educate employees on recognizing and reporting potential incidents.</li>
                                                    <li><span>Phishing Simulations:</span> Conduct phishing simulations to test and improve employee awareness and response to phishing attacks.</li>
                                                    <li><span>Executive and Board Training:</span> Offer specialized training for executive leadership and board members on incident response roles, responsibilities, and expectations.</li>
                                                </ul>

                                                <p>
                                                    By leveraging our comprehensive Incident Response and Forensics services, organizations can effectively contain and resolve cyber incidents, minimizing damage and ensuring rapid recovery. This structured approach enhances the organization’s resilience against future incidents and strengthens its overall cybersecurity posture.
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="1">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Recover-Crisis-Management.svg" alt="Crisis Management and Communication" />
                                                <h2>Crisis Management and Communication</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We assist clients in managing and communicating the impact and consequences of cyber incidents using crisis management plans, teams, and communication strategies.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Crisis Management and Communication services are designed to help clients effectively manage and communicate the impact and consequences of cyber incidents. By implementing comprehensive crisis management plans, assembling specialized teams, and developing robust communication strategies, we ensure that organizations can navigate cyber crises with minimal disruption and maintain stakeholder trust.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>

                                                <h6 class="mb-1">Crisis Management Planning</h6>
                                                <ul class="mb-2">
                                                    <li><span>Customized Crisis Management Plans:</span> Develop tailored crisis management plans that outline procedures for identifying, managing, and recovering from cyber incidents.</li>
                                                    <li><span>Risk Assessment:</span> Conduct risk assessments to identify potential crisis scenarios and their impact on the organization.</li>
                                                    <li><span>Business Impact Analysis (BIA):</span> Perform BIAs to understand the potential effects of cyber incidents on critical business operations and prioritize response efforts.</li>
                                                    <li><span>Policy and Procedure Development:</span> Establish policies and procedures that support effective crisis management and align with regulatory requirements and industry best practices.</li>
                                                </ul>

                                                <h6 class="mb-1">Crisis Management Team (CMT)</h6>
                                                <ul class="mb-2">
                                                    <li><span>Team Formation:</span> Assemble a dedicated Crisis Management Team with representatives from key functions, including IT, security, legal, HR, communications, and executive leadership.</li>
                                                    <li><span>Roles and Responsibilities:</span> Define clear roles and responsibilities for CMT members, ensuring accountability and effective coordination during a crisis.</li>
                                                    <li><span>Training and Exercises:</span> Conduct regular training sessions and crisis simulation exercises to ensure the CMT is prepared to respond to various crisis scenarios.</li>
                                                    <li><span>Collaboration Tools:</span> Utilize collaboration tools to facilitate real-time communication and coordination among CMT members during a crisis.</li>
                                                </ul>

                                                <h6 class="mb-1">Crisis Detection and Response</h6>
                                                <ul class="mb-2">
                                                    <li><span>Incident Monitoring:</span> Implement continuous monitoring solutions to detect potential crises in real-time using advanced threat detection and incident management tools.</li>
                                                    <li><span>Immediate Response Actions:</span> Develop and execute immediate response actions to contain the impact of a crisis, such as isolating affected systems and activating the crisis management plan.</li>
                                                    <li><span>Coordination with Incident Response:</span> Ensure seamless coordination between the Crisis Management Team and Incident Response Team to manage the technical aspects of the crisis.</li>
                                                </ul>

                                                <h6 class="mb-1">Communication Strategies</h6>
                                                <ul class="mb-2">
                                                    <li><span>Communication Plan Development:</span> Develop comprehensive communication plans that outline strategies for internal and external communication during a crisis.</li>
                                                    <li><span>Stakeholder Identification:</span> Identify key stakeholders, including employees, customers, partners, regulators, and the media, and establish communication protocols for each group.</li>
                                                    <li><span>Message Crafting:</span> Craft clear, concise, and consistent messages that address the nature of the crisis, its impact, and the organization’s response efforts.</li>
                                                    <li><span>Communication Channels:</span> Utilize appropriate communication channels, such as email, social media, press releases, and internal communication platforms, to disseminate information.</li>
                                                </ul>

                                                <h6 class="mb-1">Media and Public Relations Management</h6>
                                                <ul class="mb-2">
                                                    <li><span>Media Relations:</span> Develop and maintain relationships with key media contacts to ensure accurate and timely dissemination of information during a crisis.</li>
                                                    <li><span>Press Releases and Statements:</span> Prepare press releases and public statements that provide transparent and factual information about the crisis and the organization’s response.</li>
                                                    <li><span>Media Training:</span> Provide media training for designated spokespersons to ensure they can effectively communicate with the media and manage press interactions.</li>
                                                    <li><span>Press Conferences and Interviews:</span> Organize and manage press conferences and interviews, ensuring that key messages are conveyed and media queries are addressed.</li>
                                                </ul>

                                                <h6 class="mb-1">Internal Communication</h6>
                                                <ul class="mb-2">
                                                    <li><span>Employee Briefings:</span> Conduct regular briefings to keep employees informed about the crisis, its impact on the organization, and their roles in the response effort.</li>
                                                    <li><span>FAQ and Guidance Documents:</span> Develop FAQs and guidance documents to address common employee concerns and provide clear instructions on actions to take.</li>
                                                    <li><span>Leadership Communication:</span> Ensure that executive leadership communicates directly with employees to provide reassurance and maintain morale.</li>
                                                </ul>

                                                <h6 class="mb-1">Regulatory and Legal Communication</h6>
                                                <ul class="mb-2">
                                                    <li><span>Regulatory Notifications:</span> Ensure timely and accurate notification to relevant regulatory bodies as required by law.</li>
                                                    <li><span>Legal Coordination:</span> Coordinate with legal teams to manage communication related to legal and compliance issues, including potential liability and regulatory investigations.</li>
                                                    <li><span>Documentation and Reporting:</span> Maintain thorough documentation of all communication activities for regulatory compliance and post-crisis analysis.</li>
                                                </ul>

                                                <h6 class="mb-1">Post-Crisis Review and Improvement</h6>
                                                <ul class="mb-2">
                                                    <li><span>Post-Crisis Analysis:</span> Conduct a thorough post-crisis analysis to evaluate the effectiveness of the crisis management and communication efforts.</li>
                                                    <li><span>Lessons Learned:</span> Identify lessons learned from the crisis and integrate them into the organization’s crisis management and communication plans.</li>
                                                    <li><span>Plan Updates:</span> Regularly update crisis management and communication plans based on feedback and evolving best practices.</li>
                                                    <li><span>Continuous Improvement:</span> Implement continuous improvement processes to enhance the organization’s readiness for future crises.</li>
                                                </ul>

                                                <h6 class="mb-1">Training and Awareness</h6>
                                                <ul class="mb-2">
                                                    <li><span>Crisis Management Training:</span> Develop and deliver training programs to educate employees and stakeholders on crisis management principles and their specific roles during a crisis.</li>
                                                    <li><span>Simulation Exercises:</span> Conduct regular crisis simulation exercises to test and improve the organization’s crisis response capabilities.</li>
                                                    <li><span>Awareness Campaigns:</span> Implement ongoing awareness campaigns to reinforce the importance of crisis preparedness and effective communication.</li>
                                                </ul>

                                                <h6 class="mb-1">Technology and Tools Integration</h6>
                                                <ul class="mb-2">
                                                    <li><span>Crisis Management Platforms:</span> Utilize advanced crisis management platforms and tools to streamline the planning, execution, and monitoring of crisis response efforts.</li>
                                                    <li><span>Real-Time Monitoring and Alerting:</span> Integrate real-time monitoring and alerting systems to provide early warning of potential crises and facilitate rapid response.</li>
                                                    <li><span>Communication Tools:</span> Deploy secure communication tools to ensure reliable and confidential communication during a crisis.</li>
                                                </ul>

                                                <p>
                                                    By leveraging our comprehensive Crisis Management and Communication services, organizations can effectively manage and communicate the impact of cyber incidents, maintaining stakeholder trust and minimizing disruption to business operations. This structured approach ensures a coordinated and resilient response to crises, enhancing the organization’s overall security posture and readiness.
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Recover-Ransomware.svg" alt="Ransomware Recovery and Mitigation" />
                                                <h2>Ransomware Recovery and Mitigation</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We assist clients in recovering and restoring their data and operations following a ransomware attack using recovery, decryption, ransom negotiation, and backup options.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Ransomware Recovery and Mitigation services are designed to help clients recover and restore their data and operations following a ransomware attack. By leveraging comprehensive recovery strategies, decryption techniques, ransom negotiation, and robust backup solutions, we ensure that organizations can quickly regain access to critical systems and data, minimize downtime, and enhance their resilience against future attacks.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>

                                                <h6 class="mb-1">Immediate Response and Containment</h6>
                                                <ul class="mb-2">
                                                    <li><span>Incident Assessment:</span> Conduct an immediate assessment to understand the scope and impact of the ransomware attack.</li>
                                                    <li><span>Containment Measures:</span> Implement containment measures to prevent the spread of ransomware, such as isolating affected systems and networks.</li>
                                                    <li><span>Incident Response Team (IRT):</span> Activate the Incident Response Team to coordinate and manage the recovery process.</li>
                                                </ul>

                                                <h6 class="mb-1">Data Recovery and Decryption</h6>
                                                <ul class="mb-2">
                                                    <li><span>Backup Restoration:</span> Identify and restore data from existing backups, ensuring the integrity and completeness of the restored data.</li>
                                                    <li><span>Decryption Tools:</span> Utilize available decryption tools and techniques to decrypt files encrypted by known ransomware variants.</li>
                                                    <li><span>Forensic Analysis:</span> Conduct forensic analysis to identify the ransomware strain and determine the feasibility of decryption without paying the ransom.</li>
                                                </ul>

                                                <h6 class="mb-1">Ransom Negotiation</h6>
                                                <ul class="mb-2">
                                                    <li><span>Negotiation Team:</span> Deploy a specialized ransom negotiation team experienced in dealing with ransomware incidents.</li>
                                                    <li><span>Risk Assessment:</span> Assess the risks and benefits of negotiating with the attackers, including potential legal and ethical considerations.</li>
                                                    <li><span>Negotiation Strategy:</span> Develop and execute a negotiation strategy to potentially reduce the ransom demand and secure decryption keys if necessary.</li>
                                                    <li><span>Communication with Attackers:</span> Manage all communications with the attackers to ensure a controlled and strategic negotiation process.</li>
                                                </ul>

                                                <h6 class="mb-1">System and Data Restoration</h6>
                                                <ul class="mb-2">
                                                    <li><span>Comprehensive Recovery Plan:</span> Develop a detailed recovery plan that outlines the steps for restoring systems, applications, and data to their pre-attack state.</li>
                                                    <li><span>Incremental Data Restoration:</span> Prioritize the restoration of critical systems and data to minimize operational disruption.</li>
                                                    <li><span>Validation and Testing:</span> Validate and test the restored systems and data to ensure they are fully operational and free from malware.</li>
                                                </ul>

                                                <h6 class="mb-1">Backup Solutions and Strategies</h6>
                                                <ul class="mb-2">
                                                    <li><span>Backup Assessment:</span> Evaluate the organization’s existing backup solutions and identify any gaps or weaknesses.</li>
                                                    <li><span>Enhanced Backup Solutions:</span> Implement robust backup solutions that provide regular, automated, and secure backups of critical data.</li>
                                                    <li><span>Offsite and Cloud Backups:</span> Utilize offsite and cloud-based backup options to ensure data availability even in the event of a localized attack.</li>
                                                    <li><span>Backup Verification:</span> Regularly verify and test backups to ensure data integrity and reliability.</li>
                                                </ul>

                                                <h6 class="mb-1">Post-Incident Analysis and Improvement</h6>
                                                <ul class="mb-2">
                                                    <li><span>Root Cause Analysis:</span> Perform a thorough root cause analysis to determine how the ransomware attack occurred and identify vulnerabilities that were exploited.</li>
                                                    <li><span>Lessons Learned:</span> Document lessons learned from the incident and integrate them into the organization’s security policies and procedures.</li>
                                                    <li><span>Security Enhancements:</span> Implement security enhancements based on the findings of the root cause analysis to prevent future attacks.</li>
                                                </ul>

                                                <h6 class="mb-1">Mitigation Strategies and Preventive Measures</h6>
                                                <ul class="mb-2">
                                                    <li><span>Endpoint Protection:</span> Deploy advanced endpoint protection solutions to detect and block ransomware before it can cause damage.</li>
                                                    <li><span>Network Security:</span> Strengthen network security through firewalls, intrusion detection systems (IDS), and secure network configurations.</li>
                                                    <li><span>Patch Management:</span> Implement a robust patch management process to ensure that all systems are up to date with the latest security patches.</li>
                                                    <li><span>User Training and Awareness:</span> Conduct regular training sessions to educate employees on recognizing and avoiding ransomware threats.</li>
                                                </ul>

                                                <h6 class="mb-1">Compliance and Reporting</h6>
                                                <ul class="mb-2">
                                                    <li><span>Regulatory Compliance:</span> Ensure that the recovery and mitigation efforts comply with relevant regulatory requirements and industry standards.</li>
                                                    <li><span>Incident Reporting:</span> Prepare and submit required incident reports to regulatory authorities and stakeholders.</li>
                                                    <li><span>Documentation:</span> Maintain comprehensive documentation of the incident response, recovery, and mitigation processes for audit and compliance purposes.</li>
                                                </ul>

                                                <h6 class="mb-1">Continuous Monitoring and Improvement</h6>
                                                <ul class="mb-2">
                                                    <li><span>Security Monitoring:</span> Implement continuous monitoring solutions to detect and respond to ransomware threats in real-time.</li>
                                                    <li><span>Threat Intelligence:</span> Integrate threat intelligence feeds to stay informed about emerging ransomware threats and trends.</li>
                                                    <li><span>Regular Security Assessments:</span> Conduct regular security assessments and vulnerability scans to identify and address potential weaknesses.</li>
                                                </ul>

                                                <h6 class="mb-1">Crisis Management and Communication</h6>
                                                <ul class="mb-2">
                                                    <li><span>Communication Plan:</span> Develop a communication plan to inform stakeholders, including employees, customers, and partners, about the incident and recovery efforts.</li>
                                                    <li><span>Media Relations:</span> Manage media relations to ensure accurate and timely dissemination of information while maintaining the organization’s reputation.</li>
                                                    <li><span>Executive Briefings:</span> Provide regular briefings to executive leadership on the status of recovery efforts and strategic recommendations.</li>
                                                </ul>

                                                <p>
                                                    By leveraging our comprehensive Ransomware Recovery and Mitigation services, organizations can effectively recover from ransomware attacks, minimize operational disruption, and strengthen their defenses against future threats. This holistic approach ensures a swift and efficient recovery while enhancing overall cybersecurity resilience.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="contactFour-tab-pane" role="tabpanel" aria-labelledby="contactFour-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="../assets/images/Recover-Breach-Simulation.svg" alt="Breach Simulation and Testing" />
                                                <h2>Breach Simulation and Testing</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We assist clients in testing and enhancing their readiness and response capabilities by simulating realistic cyber scenarios and attacks using tools such as breach simulation, red teaming, blue teaming, purple teaming, and more.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Breach Simulation and Testing services are designed to help clients evaluate and enhance their cybersecurity readiness and response capabilities through the simulation of realistic cyber scenarios and attacks. By leveraging tools and techniques such as breach simulation, red teaming, blue teaming, purple teaming, and more, we provide a comprehensive assessment of an organization’s defenses and foster a proactive security posture.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>

                                                <h6 class="mb-1">Initial Assessment and Planning</h6>
                                                <ul class="mb-2">
                                                    <li><span>Requirements Gathering:</span> Collaborate with key stakeholders to understand the organization's objectives, scope, and specific requirements for breach simulation and testing.</li>
                                                    <li><span>Risk Assessment:</span> Conduct a preliminary risk assessment to identify critical assets, potential threats, and areas of concern.</li>
                                                    <li><span>Customized Scenarios:</span> Develop customized cyber-attack scenarios tailored to the organization’s unique environment, threat landscape, and security goals.</li>
                                                </ul>

                                                <h6 class="mb-1">Breach Simulation</h6>
                                                <ul class="mb-2">
                                                    <li><span>Automated Breach and Attack Simulation (BAS):</span> Deploy BAS tools to simulate various cyber-attacks and assess the effectiveness of existing security controls.</li>
                                                    <li><span>Controlled Attack Execution:</span> Execute controlled attack simulations in a safe environment to identify vulnerabilities and measure the organization's detection and response capabilities.</li>
                                                    <li><span>Attack Vector Analysis:</span> Simulate multiple attack vectors, including phishing, malware, ransomware, and network breaches, to provide a comprehensive assessment.</li>
                                                </ul>

                                                <h6 class="mb-1">Red Teaming</h6>
                                                <ul class="mb-2">
                                                    <li><span>Adversarial Emulation:</span> Conduct red team exercises to emulate the tactics, techniques, and procedures (TTPs) of real-world adversaries.</li>
                                                    <li><span>Objective-Based Testing:</span> Design red team operations with specific objectives, such as gaining unauthorized access, data exfiltration, or privilege escalation.</li>
                                                    <li><span>Stealth and Persistence:</span> Test the organization’s ability to detect and respond to stealthy and persistent attacks by skilled adversaries.</li>
                                                </ul>

                                                <h6 class="mb-1">Blue Teaming</h6>
                                                <ul class="mb-2">
                                                    <li><span>Defensive Assessment:</span> Evaluate the effectiveness of the blue team (internal security team) in detecting, responding to, and mitigating simulated attacks.</li>
                                                    <li><span>Operational Readiness:</span> Assess the blue team’s readiness and response capabilities, including incident detection, analysis, and remediation.</li>
                                                    <li><span>Continuous Improvement:</span> Provide feedback and recommendations to enhance the blue team’s skills, processes, and tools.</li>
                                                </ul>

                                                <h6 class="mb-1">Purple Teaming</h6>
                                                <ul class="mb-2">
                                                    <li><span>Collaborative Exercises:</span> Facilitate purple team exercises that involve both red and blue teams working together to identify and address security gaps.</li>
                                                    <li><span>Knowledge Sharing:</span> Promote knowledge sharing between red and blue teams to improve overall security posture and foster a culture of continuous improvement.</li>
                                                    <li><span>Tactical and Strategic Insights:</span> Provide tactical and strategic insights to enhance detection, response, and prevention capabilities.</li>
                                                </ul>

                                                <h6 class="mb-1">Post-Simulation Analysis and Reporting</h6>
                                                <ul class="mb-2">
                                                    <li><span>Detailed Findings:</span> Document detailed findings from breach simulations, including identified vulnerabilities, attack paths, and areas for improvement.</li>
                                                    <li><span>Impact Assessment:</span> Assess the potential impact of identified vulnerabilities on the organization’s operations, data integrity, and reputation.</li>
                                                    <li><span>Executive Summary:</span> Prepare an executive summary that highlights key findings, strategic recommendations, and a roadmap for remediation and enhancement.</li>
                                                </ul>

                                                <h6 class="mb-1">Remediation and Mitigation Strategies</h6>
                                                <ul class="mb-2">
                                                    <li><span>Actionable Recommendations:</span> Provide actionable recommendations to address identified vulnerabilities and improve security defenses.</li>
                                                    <li><span>Remediation Plans:</span> Develop detailed remediation plans that outline specific actions, timelines, and responsible parties for addressing security gaps.</li>
                                                    <li><span>Follow-Up Assessments:</span> Conduct follow-up assessments to verify the effectiveness of implemented remediation measures and ensure continuous improvement.</li>
                                                </ul>

                                                <h6 class="mb-1">Continuous Monitoring and Threat Intelligence</h6>
                                                <ul class="mb-2">
                                                    <li><span>Ongoing Monitoring:</span> Implement continuous monitoring solutions to detect and respond to emerging threats in real-time.</li>
                                                    <li><span>Threat Intelligence Integration:</span> Integrate threat intelligence feeds to stay informed about the latest threats, vulnerabilities, and attack techniques.</li>
                                                    <li><span>Proactive Threat Hunting:</span> Conduct proactive threat hunting activities to identify and mitigate threats before they can cause harm.</li>
                                                </ul>

                                                <h6 class="mb-1">Training and Awareness</h6>
                                                <ul class="mb-2">
                                                    <li><span>Security Training Programs:</span> Develop and deliver targeted training programs to enhance the skills and knowledge of security personnel.</li>
                                                    <li><span>Simulation Exercises:</span> Conduct regular simulation exercises to test and improve the organization’s incident response capabilities.</li>
                                                    <li><span>Phishing and Social Engineering:</span> Include phishing and social engineering simulations to raise awareness and improve user resilience against these common attack vectors.</li>
                                                </ul>

                                                <h6 class="mb-1">Regulatory Compliance and Governance</h6>
                                                <ul class="mb-2">
                                                    <li><span>Compliance Assessments:</span> Ensure that breach simulation and testing activities align with relevant regulatory requirements and industry standards, such as GDPR, HIPAA, and PCI DSS.</li>
                                                    <li><span>Policy and Procedure Development:</span> Develop and update security policies and procedures based on insights gained from simulation and testing exercises.</li>
                                                    <li><span>Audit Support:</span> Provide documentation and evidence to support internal and external audits and assessments related to cybersecurity readiness and response.</li>
                                                </ul>

                                                <h6 class="mb-1">Continuous Improvement</h6>
                                                <ul class="mb-2">
                                                    <li><span>Feedback Loop:</span> Establish a feedback loop to integrate lessons learned from breach simulations and testing into the organization’s security strategy.</li>
                                                    <li><span>Metrics and KPIs:</span> Develop metrics and key performance indicators (KPIs) to measure the effectiveness of security improvements and response capabilities.</li>
                                                    <li><span>Regular Reviews and Updates:</span> Conduct regular reviews and updates of breach simulation and testing programs to ensure they remain relevant and effective.</li>
                                                </ul>

                                                <p>
                                                    By leveraging our comprehensive Breach Simulation and Testing services, organizations can gain a realistic understanding of their cybersecurity readiness, identify and address vulnerabilities, and enhance their overall security posture. This proactive approach ensures that organizations are well-prepared to detect, respond to, and recover from cyber-attacks, minimizing potential damage and maintaining business continuity.
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div class="boxA boxB">
                                <div class="upper_box">
                                    <div class="text_box">
                                        <span>Your at</span>
                                        <p> Valkyrie Services</p>
                                        <div
                                            class="progress"
                                            role="progressbar"
                                            aria-label="Basic example"
                                            aria-valuenow="100"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            <div
                                                class={`progress-bar ${modeValue == 'report-mode' ?
                                                    'green_progress_bar' : modeValue == 'assessment-mode' ?
                                                        'red_progress_bar' : modeValue == 'settings-mode' ? 'yellow_progress_bar' : 'red_progress_bar'}`}
                                                style={{ width: "100%" }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="servicesBoxFlex-icon">
                                    <div className="box">
                                        <div class="iconBoxSvg">
                                            <a>
                                                <FontAwesomeIcon
                                                    icon={faPlus} className="img-fluid" style={{ width: '20px', color: 'rgb(51 154 227)' }} />
                                            </a>
                                        </div>
                                        <p>
                                            Add <br /> to <br /> Request
                                        </p>
                                    </div>
                                    <div className="box">
                                        <div class="up_down_circle up_down_circle_service" id="tab_uppper_button">
                                            <a>
                                                <img src={icon} class="img-fluid" alt="" />
                                            </a>
                                        </div>
                                        <p>
                                            Compile <br /> Request
                                        </p>
                                    </div>
                                    <div className="box">
                                        <div class="iconBoxSvg">
                                            <a>
                                                <FontAwesomeIcon icon={faMinus}
                                                    style={{ width: '20px', color: '#ff1d25' }}
                                                    className="img-fluid" />
                                            </a>
                                        </div>
                                        <p>
                                            Remove <br /> from <br /> Request
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)
                }

                {hash == 'custom' &&
                    (<div class="bottom_panel bottom_panel_Services active_bottom_panel" id="bottom_panel">
                        <div class="bottom_panel_header">
                            <div class="tab_boxes">
                                <ul class="nav nav-tabs" id="myTab" role="tablist" style={{justifyContent:'flex-start'}}>
                                    <li className="mr-3">
                                        <div class="boxA">
                                            <div class="upper_box">
                                                <img src="/assets/images/Custom_Valkyrie.svg" class="img-fluid" alt="" />
                                                <div class="text_box">
                                                    <p>Custom Services</p>
                                                    <div
                                                        class="progress"
                                                        role="progressbar"
                                                        aria-label="Basic example"
                                                        aria-valuenow="100"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    >
                                                        <div
                                                            class={`progress-bar ${modeValue == 'report-mode' ?
                                                                'green_progress_bar' : modeValue == 'assessment-mode' ?
                                                                    'red_progress_bar' : modeValue == 'settings-mode' ? 'yellow_progress_bar' : 'red_progress_bar'}`}
                                                            style={{ width: "100%" }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" onClick={onTabClick("home-tab")}>
                                            <div class="inner_tab_button">
                                                <img src="/assets/images/Custom_Valkyrie.svg" class="img-fluid" alt="" />
                                                <p>Custom Cybersecurity Services</p>
                                            </div>
                                        </button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                        <div className="d-flex">
                                            <div class="d-flex-serviceTab">
                                                <img src="/assets/images/Custom_Valkyrie.svg" alt="Custom Cybersecurity Services" />
                                                <h2>Custom Cybersecurity Services</h2>
                                            </div>
                                            <div className="boxContent">
                                                <h6 class="mb-1">Introduction:</h6>
                                                <p>
                                                    We can assist clients based on custom requests and tailored services.
                                                </p>
                                                <h6 class="mb-1">Service Overview</h6>
                                                <p>
                                                    Our Custom Cybersecurity Services are designed to meet the unique and specialized needs of our clients through the expertise and versatility of our Valkyrie Team. With over 300 ethical hackers and subject matter experts holding a wide range of cybersecurity certifications, we offer tailored solutions for any cybersecurity challenge, whether offensive or defensive. From special forensics and break glass response teams to advanced red teaming, black box testing, and honeypot creation, our custom services address all requests, large or small.
                                                </p>
                                                <h6 class="mb-1">Key Components</h6>

                                                <h6 class="mb-1">Custom Request Handling</h6>
                                                <ul class="mb-2">
                                                    <li><span>Consultation and Needs Assessment:</span> Engage in a detailed consultation with clients to understand their specific cybersecurity needs and objectives.</li>
                                                    <li><span>Customized Service Proposal:</span> Develop a tailored proposal outlining the scope, approach, and resources required to address the client's request.</li>
                                                    <li><span>Flexible Engagement Models:</span> Offer flexible engagement models, including short-term projects, ongoing support, and ad-hoc services, to suit the client's needs.</li>
                                                </ul>

                                                <h6 class="mb-1">Advanced Forensics Services</h6>
                                                <ul class="mb-2">
                                                    <li><span>Specialized Forensic Analysis:</span> Provide in-depth forensic analysis to uncover and investigate complex cyber incidents, including data breaches, malware infections, and insider threats.</li>
                                                    <li><span>Digital Evidence Collection:</span> Collect and preserve digital evidence using forensically sound methods to support legal proceedings and incident investigations.</li>
                                                    <li><span>Detailed Reporting:</span> Generate comprehensive forensic reports that detail findings, methodologies, and recommendations for remediation and future prevention.</li>
                                                </ul>

                                                <h6 class="mb-1">Break Glass Response Team</h6>
                                                <ul class="mb-2">
                                                    <li><span>Rapid Deployment:</span> Deploy a dedicated break glass response team to handle critical cybersecurity incidents that require immediate attention.</li>
                                                    <li><span>24/7 Incident Response:</span> Provide around-the-clock incident response services to ensure swift containment and mitigation of security breaches.</li>
                                                    <li><span>Crisis Management:</span> Coordinate with the client's internal teams and stakeholders to manage the crisis effectively and restore normal operations as quickly as possible.</li>
                                                </ul>

                                                <h6 class="mb-1">Incident Containment and Eradication</h6>
                                                <ul class="mb-2">
                                                    <li><span>Immediate Containment Actions:</span> Execute immediate containment actions to limit the spread and impact of the incident, such as isolating affected systems and blocking malicious traffic.</li>
                                                    <li><span>Eradication Strategies:</span> Develop and implement eradication strategies to remove the threat from the environment, including malware removal, patching vulnerabilities, and closing attack vectors.</li>
                                                    <li><span>System Hardening:</span> Apply system hardening measures to prevent reinfection and enhance the security of affected systems.</li>
                                                </ul>

                                                <h6 class="mb-1">Red Team and Offensive Security</h6>
                                                <ul class="mb-2">
                                                    <li><span>Red Team Engagements:</span> Conduct advanced red teaming exercises to simulate sophisticated attacks and identify vulnerabilities in the client's security defenses.</li>
                                                    <li><span>Black Box Testing:</span> Perform black box testing to evaluate the security of systems and applications without prior knowledge, mimicking the approach of real-world attackers.</li>
                                                    <li><span>Penetration Testing:</span> Offer comprehensive penetration testing services to identify and exploit weaknesses in the client's network, applications, and infrastructure.</li>
                                                </ul>

                                                <h6 class="mb-1">Defensive Security Services</h6>
                                                <ul class="mb-2">
                                                    <li><span>Blue Team Operations:</span> Enhance the client's defensive capabilities by providing blue team services that focus on threat detection, incident response, and mitigation.</li>
                                                    <li><span>Purple Team Collaboration:</span> Facilitate purple team exercises that combine the strengths of both red and blue teams to improve overall security posture through collaborative testing and defense.</li>
                                                    <li><span>Security Architecture Review:</span> Conduct thorough reviews of the client's security architecture to ensure it is robust and aligned with best practices and industry standards.</li>
                                                </ul>

                                                <h6 class="mb-1">Honeypot Creation and Deployment</h6>
                                                <ul class="mb-2">
                                                    <li><span>Custom Honeypot Design:</span> Design and deploy custom honeypots tailored to the client's environment to detect and analyze malicious activities.</li>
                                                    <li><span>Threat Intelligence Gathering:</span> Use honeypots to gather valuable threat intelligence on attack methods, tactics, and behaviors used by cyber adversaries.</li>
                                                    <li><span>Continuous Monitoring:</span> Provide continuous monitoring and analysis of honeypot data to identify emerging threats and enhance the client's security defenses.</li>
                                                </ul>

                                                <h6 class="mb-1">Special Projects and Requests</h6>
                                                <ul class="mb-2">
                                                    <li><span>Tailored Solutions:</span> Develop and implement tailored cybersecurity solutions for unique and complex challenges faced by the client.</li>
                                                    <li><span>Research and Development:</span> Engage in research and development projects to explore new technologies, tools, and methodologies that address the client's specific needs.</li>
                                                    <li><span>Advisory Services:</span> Offer expert advisory services to guide clients on strategic cybersecurity initiatives, regulatory compliance, and risk management.</li>
                                                </ul>

                                                <h6 class="mb-1">Comprehensive Skill Set</h6>
                                                <ul class="mb-2">
                                                    <li><span>Certified Experts:</span> Leverage the diverse skill set of our team, which includes certifications such as CISSP, CEH, OSCP, CISM, CISA, and many others.</li>
                                                    <li><span>Industry-Specific Expertise:</span> Provide industry-specific expertise to address the unique cybersecurity challenges faced by sectors such as finance, healthcare, retail, manufacturing, and government.</li>
                                                    <li><span>Cutting-Edge Tools and Techniques:</span> Utilize cutting-edge tools and techniques to deliver high-quality, effective cybersecurity services.</li>
                                                </ul>

                                                <h6 class="mb-1">Ongoing Support and Collaboration</h6>
                                                <ul class="mb-2">
                                                    <li><span>Dedicated Account Management:</span> Assign dedicated account managers to ensure seamless communication and coordination throughout the engagement.</li>
                                                    <li><span>Regular Updates and Reports:</span> Provide regular updates and detailed reports on the progress and outcomes of custom projects and services.</li>
                                                    <li><span>Client Collaboration:</span> Foster a collaborative approach, working closely with the client's internal teams to ensure alignment and successful implementation of cybersecurity initiatives.</li>
                                                </ul>

                                                <h6 class="mb-1">Request Submission Process</h6>
                                                <ul class="mb-2">
                                                    <li><span>Easy Submission:</span> Enable clients to easily submit custom service requests through a dedicated portal or contact point.</li>
                                                    <li><span>Rapid Response:</span> Ensure rapid response to custom requests, with initial consultations and assessments conducted promptly.</li>
                                                    <li><span>Transparent Pricing:</span> Offer transparent pricing models based on the scope and complexity of the custom service request.</li>
                                                </ul>

                                                <p>
                                                    By leveraging our Custom Cybersecurity Services, clients can address their unique cybersecurity needs with confidence, knowing they have access to a team of highly skilled ethical hackers and subject matter experts. Whether the request involves offensive security, defensive measures, or specialized projects, our Valkyrie Team is equipped to deliver tailored solutions that enhance the client's security posture and resilience against cyber threats.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="boxA boxB">
                                <div class="upper_box">
                                    <div class="text_box">
                                        <span>Your at</span>
                                        <p> Valkyrie Services</p>
                                        <div
                                            class="progress"
                                            role="progressbar"
                                            aria-label="Basic example"
                                            aria-valuenow="100"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            <div
                                                class={`progress-bar ${modeValue == 'report-mode' ?
                                                    'green_progress_bar' : modeValue == 'assessment-mode' ?
                                                        'red_progress_bar' : modeValue == 'settings-mode' ? 'yellow_progress_bar' : 'red_progress_bar'}`}
                                                style={{ width: "100%" }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="servicesBoxFlex-icon">
                                    <div className="box">
                                        <div class="iconBoxSvg">
                                            <a>
                                                <FontAwesomeIcon
                                                    icon={faPlus} className="img-fluid" style={{ width: '20px', color: 'rgb(51 154 227)' }} />
                                            </a>
                                        </div>
                                        <p>
                                            Add <br /> to <br /> Request
                                        </p>
                                    </div>
                                    <div className="box">
                                        <div class="up_down_circle up_down_circle_service" id="tab_uppper_button">
                                            <a>
                                                <img src={icon} class="img-fluid" alt="" />
                                            </a>
                                        </div>
                                        <p>
                                            Compile <br /> Request
                                        </p>
                                    </div>
                                    <div className="box">
                                        <div class="iconBoxSvg">
                                            <a>
                                                <FontAwesomeIcon icon={faMinus}
                                                    style={{ width: '20px', color: '#ff1d25' }}
                                                    className="img-fluid" />
                                            </a>
                                        </div>
                                        <p>
                                            Remove <br /> from <br /> Request
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>)
                }
            </div>
        </div>
    );
}

export default Identify;