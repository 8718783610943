import React, { Component } from "react";
import DropzoneComponent from "react-dropzone-component";
import "dropzone/dist/min/dropzone.min.css";
import { toastr } from "react-redux-toastr";
var Model = require("../information/model");

class Dropzone extends Component {
  constructor(props){
    super(props);
    this.state = {
      fileName:""
    }

    // //console.log(props,'propspropsprops')

  }
  render() {
    //console.log(this.props,'propspropsprops')
    var componentConfig = { postUrl: 'no-url' };
    var djsConfig = { autoProcessQueue: false }
    var eventHandlers = { addedfile: (file) => {
        if(this.props.isUpload && this.props.isUpload == "false"){
          //console.log(file,'upload here')
          this.setState({
            fileName: file.upload.filename?.replace(".json",'')
          })
          var reader = new FileReader();
          reader.onload = onReaderLoad;
          reader.readAsText(file);
        }else{
          Model.default.saveOrgLogo(file).then((response) => {
            // //console.log({ response });
            if (!response.error) {
              //console.log(response.data,'socialAttachment')
              this.props.variable(response.data);
            }
          });
        }
      } 
    }
    const onReaderLoad = (event) => {
      //console.log(event,'events');
      try{
        var obj = JSON.parse(event.target.result);
        // //console.log(obj,'json file');
        this.props.variable(obj);
        this.props.setProjectId(obj?.project_id);
        this.props.setEmail(obj?.client_email);
        this.props.setPrivateKey(obj?.private_key);
        this.props.setName(this.state.fileName);
      }catch(err){
        toastr.error("Error", "Please upload json file.");
      }
  }
    return (
      <DropzoneComponent
        config={componentConfig}
        eventHandlers={eventHandlers} className="dropify"
        djsConfig={djsConfig}
        {...this.props}
      />
    );
  }
}

export default Dropzone;
